import { PatientHistoryType } from 'src/graphql.generated'
import { PatientHistoryEntry } from 'src/legacy/components/ParticipantRecords/ParticipantHistory/types'
import * as localStorageWithTTL from 'src/legacy/lib/localStorageWithTTL'
import { DEFAULT_INTAKE_NOTE_TTL_IN_HOURS, PatientHistoryFormType } from 'src/participantHistory/constants'

const getFormID = (uid: string, formType: PatientHistoryFormType) => `PatientHistoryForm-${uid}-${formType}`
// TODO: Remove this code by August 15th 2023 (Legacy localstorage form id should be out of use by then)
const getLegacyFormID = (uid: string) => `PatientHistoryForm-${uid}`

export const saveFormToLocalStorage = (uid: string, formType: PatientHistoryFormType, values: PatientHistoryEntry) => {
  const formID = getFormID(uid, formType)
  const TTL_IN_HOURS = formType === PatientHistoryType.IntakeNote ? DEFAULT_INTAKE_NOTE_TTL_IN_HOURS : undefined
  localStorageWithTTL.setItem(formID, values, TTL_IN_HOURS)
}

export const getFormFromLocalStorage = (uid: string, formType: PatientHistoryFormType) => {
  const formID = getFormID(uid, formType)
  const legacyFormID = getLegacyFormID(uid)
  return localStorageWithTTL.getItem(formID) || localStorageWithTTL.getItem(legacyFormID)
}

export const clearFormFromLocalStorage = (uid: string, formType: PatientHistoryFormType) => {
  const formID = getFormID(uid, formType)
  const legacyFormID = getLegacyFormID(uid)
  localStorageWithTTL.clearItem(formID)
  localStorageWithTTL.clearItem(legacyFormID)
}
