/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

const defaultOptions = {} as const
export type GetParticipantVideoCallBookingInformationQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetParticipantVideoCallBookingInformationQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    uid: string
    firstname?: string | null
    lastname?: string | null
    videoCallBookingInformation?:
      | {
          __typename?: 'InProgramVideoCallBookingInformation'
          id: string
          numberOfCallsHad?: number | null
          callBookingEnabled: boolean
          latestCallHad?: {
            __typename?: 'VideoCall'
            id: string
            startTime: Date
            endTime: Date
            zoomMeetingID: string
            callType: Types.VideoCallType
            joinUrl?: string | null
          } | null
          videoCalls: Array<{
            __typename?: 'VideoCall'
            notes?: string | null
            callException?: Types.VideoCallException | null
            id: string
            startTime: Date
            endTime: Date
            zoomMeetingID: string
            callType: Types.VideoCallType
            joinUrl?: string | null
          }>
        }
      | {
          __typename?: 'IntakeVideoCallBookingInformation'
          id: string
          videoCall?: {
            __typename?: 'VideoCall'
            id: string
            startTime: Date
            endTime: Date
            zoomMeetingID: string
            callType: Types.VideoCallType
            joinUrl?: string | null
          } | null
        }
      | null
    enrollment: { __typename?: 'Enrollment'; id: string; programID: string }
  }
}

export type GetJoinCallUrlQueryVariables = Types.Exact<{
  zoomMeetingID: Types.Scalars['String']['input']
}>

export type GetJoinCallUrlQuery = { __typename?: 'Query'; videoCallUrl: string }

export type SetInProgramCallBookingEnabledMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  enabled: Types.Scalars['Boolean']['input']
  description?: Types.InputMaybe<Types.Scalars['String']['input']>
}>

export type SetInProgramCallBookingEnabledMutation = {
  __typename?: 'Mutation'
  setInProgramCallBookingEnabled: {
    __typename?: 'InProgramVideoCallBookingInformation'
    id: string
    callBookingEnabled: boolean
  }
}

export type ScheduleVideoCallMutationVariables = Types.Exact<{
  startTime: Types.Scalars['DateTime']['input']
  durationMinutes: Types.Scalars['Int']['input']
  uid: Types.Scalars['ID']['input']
  reason: Types.ScheduledVideoCallReason
}>

export type ScheduleVideoCallMutation = {
  __typename?: 'Mutation'
  scheduleVideoCallByCoach: {
    __typename?: 'ScheduleVideoCallResult'
    callID: string
    uid: string
    coachID: string
    scheduledStartTime: Date
    zoomMeetingID: string
    scheduledEndTime: Date
    reason: Types.ScheduledVideoCallReason
  }
}

export type CallBookingParticipantFragment = {
  __typename?: 'Patient'
  uid: string
  firstname?: string | null
  lastname?: string | null
  enrollment: { __typename?: 'Enrollment'; id: string; programID: string }
}

export type CallBookingVideoCallFragment = {
  __typename?: 'VideoCall'
  id: string
  startTime: Date
  endTime: Date
  zoomMeetingID: string
  callType: Types.VideoCallType
  joinUrl?: string | null
}

export type InProgramVideoCallFragment = {
  __typename?: 'VideoCall'
  notes?: string | null
  callException?: Types.VideoCallException | null
  id: string
  startTime: Date
  endTime: Date
  zoomMeetingID: string
  callType: Types.VideoCallType
  joinUrl?: string | null
}

export type InProgramVideoCallBookingInformationFragment = {
  __typename?: 'InProgramVideoCallBookingInformation'
  id: string
  numberOfCallsHad?: number | null
  callBookingEnabled: boolean
  latestCallHad?: {
    __typename?: 'VideoCall'
    id: string
    startTime: Date
    endTime: Date
    zoomMeetingID: string
    callType: Types.VideoCallType
    joinUrl?: string | null
  } | null
  videoCalls: Array<{
    __typename?: 'VideoCall'
    notes?: string | null
    callException?: Types.VideoCallException | null
    id: string
    startTime: Date
    endTime: Date
    zoomMeetingID: string
    callType: Types.VideoCallType
    joinUrl?: string | null
  }>
}

export type IntakeVideoCallBookingInformationFragment = {
  __typename?: 'IntakeVideoCallBookingInformation'
  id: string
  videoCall?: {
    __typename?: 'VideoCall'
    id: string
    startTime: Date
    endTime: Date
    zoomMeetingID: string
    callType: Types.VideoCallType
    joinUrl?: string | null
  } | null
}

export const CallBookingParticipantFragmentDoc = gql`
  fragment CallBookingParticipant on Patient {
    uid
    firstname
    lastname
    enrollment {
      id
      programID
    }
  }
`
export const CallBookingVideoCallFragmentDoc = gql`
  fragment CallBookingVideoCall on VideoCall {
    id
    startTime
    endTime
    zoomMeetingID
    callType
    joinUrl
  }
`
export const InProgramVideoCallFragmentDoc = gql`
  fragment InProgramVideoCall on VideoCall {
    ...CallBookingVideoCall
    notes
    callException
  }
  ${CallBookingVideoCallFragmentDoc}
`
export const InProgramVideoCallBookingInformationFragmentDoc = gql`
  fragment InProgramVideoCallBookingInformation on InProgramVideoCallBookingInformation {
    id
    numberOfCallsHad
    callBookingEnabled
    latestCallHad {
      ...CallBookingVideoCall
    }
    videoCalls {
      ...InProgramVideoCall
    }
  }
  ${CallBookingVideoCallFragmentDoc}
  ${InProgramVideoCallFragmentDoc}
`
export const IntakeVideoCallBookingInformationFragmentDoc = gql`
  fragment IntakeVideoCallBookingInformation on IntakeVideoCallBookingInformation {
    id
    videoCall {
      ...CallBookingVideoCall
    }
  }
  ${CallBookingVideoCallFragmentDoc}
`
export const GetParticipantVideoCallBookingInformationDocument = gql`
  query getParticipantVideoCallBookingInformation($uid: ID!) {
    patient(uid: $uid) {
      ...CallBookingParticipant
      videoCallBookingInformation {
        ... on InProgramVideoCallBookingInformation {
          ...InProgramVideoCallBookingInformation
        }
        ... on IntakeVideoCallBookingInformation {
          ...IntakeVideoCallBookingInformation
        }
      }
    }
  }
  ${CallBookingParticipantFragmentDoc}
  ${InProgramVideoCallBookingInformationFragmentDoc}
  ${IntakeVideoCallBookingInformationFragmentDoc}
`

/**
 * __useGetParticipantVideoCallBookingInformationQuery__
 *
 * To run a query within a React component, call `useGetParticipantVideoCallBookingInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantVideoCallBookingInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantVideoCallBookingInformationQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetParticipantVideoCallBookingInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParticipantVideoCallBookingInformationQuery,
    GetParticipantVideoCallBookingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetParticipantVideoCallBookingInformationQuery,
    GetParticipantVideoCallBookingInformationQueryVariables
  >(GetParticipantVideoCallBookingInformationDocument, options)
}
export function useGetParticipantVideoCallBookingInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParticipantVideoCallBookingInformationQuery,
    GetParticipantVideoCallBookingInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetParticipantVideoCallBookingInformationQuery,
    GetParticipantVideoCallBookingInformationQueryVariables
  >(GetParticipantVideoCallBookingInformationDocument, options)
}
export type GetParticipantVideoCallBookingInformationQueryHookResult = ReturnType<
  typeof useGetParticipantVideoCallBookingInformationQuery
>
export type GetParticipantVideoCallBookingInformationLazyQueryHookResult = ReturnType<
  typeof useGetParticipantVideoCallBookingInformationLazyQuery
>
export type GetParticipantVideoCallBookingInformationQueryResult = Apollo.QueryResult<
  GetParticipantVideoCallBookingInformationQuery,
  GetParticipantVideoCallBookingInformationQueryVariables
>
export const GetJoinCallUrlDocument = gql`
  query getJoinCallUrl($zoomMeetingID: String!) {
    videoCallUrl(zoomMeetingID: $zoomMeetingID)
  }
`

/**
 * __useGetJoinCallUrlQuery__
 *
 * To run a query within a React component, call `useGetJoinCallUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJoinCallUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJoinCallUrlQuery({
 *   variables: {
 *      zoomMeetingID: // value for 'zoomMeetingID'
 *   },
 * });
 */
export function useGetJoinCallUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetJoinCallUrlQuery, GetJoinCallUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetJoinCallUrlQuery, GetJoinCallUrlQueryVariables>(GetJoinCallUrlDocument, options)
}
export function useGetJoinCallUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJoinCallUrlQuery, GetJoinCallUrlQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetJoinCallUrlQuery, GetJoinCallUrlQueryVariables>(GetJoinCallUrlDocument, options)
}
export type GetJoinCallUrlQueryHookResult = ReturnType<typeof useGetJoinCallUrlQuery>
export type GetJoinCallUrlLazyQueryHookResult = ReturnType<typeof useGetJoinCallUrlLazyQuery>
export type GetJoinCallUrlQueryResult = Apollo.QueryResult<GetJoinCallUrlQuery, GetJoinCallUrlQueryVariables>
export const SetInProgramCallBookingEnabledDocument = gql`
  mutation setInProgramCallBookingEnabled($uid: ID!, $enabled: Boolean!, $description: String) {
    setInProgramCallBookingEnabled(uid: $uid, enabled: $enabled, description: $description) {
      id
      callBookingEnabled
    }
  }
`
export type SetInProgramCallBookingEnabledMutationFn = Apollo.MutationFunction<
  SetInProgramCallBookingEnabledMutation,
  SetInProgramCallBookingEnabledMutationVariables
>

/**
 * __useSetInProgramCallBookingEnabledMutation__
 *
 * To run a mutation, you first call `useSetInProgramCallBookingEnabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInProgramCallBookingEnabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInProgramCallBookingEnabledMutation, { data, loading, error }] = useSetInProgramCallBookingEnabledMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      enabled: // value for 'enabled'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSetInProgramCallBookingEnabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetInProgramCallBookingEnabledMutation,
    SetInProgramCallBookingEnabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetInProgramCallBookingEnabledMutation, SetInProgramCallBookingEnabledMutationVariables>(
    SetInProgramCallBookingEnabledDocument,
    options
  )
}
export type SetInProgramCallBookingEnabledMutationHookResult = ReturnType<
  typeof useSetInProgramCallBookingEnabledMutation
>
export type SetInProgramCallBookingEnabledMutationResult = Apollo.MutationResult<SetInProgramCallBookingEnabledMutation>
export type SetInProgramCallBookingEnabledMutationOptions = Apollo.BaseMutationOptions<
  SetInProgramCallBookingEnabledMutation,
  SetInProgramCallBookingEnabledMutationVariables
>
export const ScheduleVideoCallDocument = gql`
  mutation scheduleVideoCall(
    $startTime: DateTime!
    $durationMinutes: Int!
    $uid: ID!
    $reason: ScheduledVideoCallReason!
  ) {
    scheduleVideoCallByCoach(
      callDetails: { startTime: $startTime, durationMinutes: $durationMinutes, uid: $uid, reason: $reason }
    ) {
      callID
      uid
      coachID
      scheduledStartTime
      zoomMeetingID
      scheduledEndTime
      reason
    }
  }
`
export type ScheduleVideoCallMutationFn = Apollo.MutationFunction<
  ScheduleVideoCallMutation,
  ScheduleVideoCallMutationVariables
>

/**
 * __useScheduleVideoCallMutation__
 *
 * To run a mutation, you first call `useScheduleVideoCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleVideoCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleVideoCallMutation, { data, loading, error }] = useScheduleVideoCallMutation({
 *   variables: {
 *      startTime: // value for 'startTime'
 *      durationMinutes: // value for 'durationMinutes'
 *      uid: // value for 'uid'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useScheduleVideoCallMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleVideoCallMutation, ScheduleVideoCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ScheduleVideoCallMutation, ScheduleVideoCallMutationVariables>(
    ScheduleVideoCallDocument,
    options
  )
}
export type ScheduleVideoCallMutationHookResult = ReturnType<typeof useScheduleVideoCallMutation>
export type ScheduleVideoCallMutationResult = Apollo.MutationResult<ScheduleVideoCallMutation>
export type ScheduleVideoCallMutationOptions = Apollo.BaseMutationOptions<
  ScheduleVideoCallMutation,
  ScheduleVideoCallMutationVariables
>
