import React from 'react'
import { Box, Typography } from '@mui/material'

import { Cards } from 'src/legacy/components/mui'
import ParticipantHistoryList from 'src/participantHistory/ParticipantHistoryList'
import ParticipantHistoryForm from 'src/participantHistoryForm/ParticipantHistoryForm'

type Props = {
  uid: string
  programID: string | null
}
const ParticipantHistory = ({ programID, uid }: Props) => (
  <Box mt={3}>
    <Box alignItems="center" display="flex" justifyContent="space-between" mb={1}>
      <Typography variant="h3">Participant History</Typography>
    </Box>
    <Cards>
      <ParticipantHistoryForm uid={uid} programID={programID} />
      <ParticipantHistoryList uid={uid} />
    </Cards>
  </Box>
)

export default ParticipantHistory
