import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'

import TextButtonSelect, { SelectOption } from '../TextButtonSelect'
import { useSetInProgramCallBookingEnabledMutation } from '../videoCallBookingHeaderQueries.generated'
import useTrackToogleBookingEnabledMixpanelEvent from './useTrackToogleBookingEnabledMixpanelEvent'
import DisableBookingModal from './DisableBookingModal/DisableBookingModal'

type Props = {
  bookingEnabled: boolean
  uid: string
}

type BookingEnabledOptionValue = 'enabled' | 'disabled'

const BOOKING_ENABLED_OPTIONS: SelectOption<BookingEnabledOptionValue>[] = [
  {
    value: 'enabled',
    label: 'Booking allowed',
  },
  {
    value: 'disabled',
    label: 'Booking disabled',
  },
]

const BookingEnabledSelect = ({ bookingEnabled, uid }: Props) => {
  const { addToast } = useToasts()
  const [setInProgramCallBookingEnabled, { loading }] = useSetInProgramCallBookingEnabledMutation()
  const [disableBookingModalOpen, setDisableBookingModalOpen] = useState(false)

  const trackMixpanelEvent = useTrackToogleBookingEnabledMixpanelEvent()

  const enableBooking = async () => {
    trackMixpanelEvent('enable')
    try {
      await setInProgramCallBookingEnabled({
        variables: { uid, enabled: true },
      })
      addToast('The participant can now book check-in calls from the app', { appearance: 'success' })
    } catch (error) {
      addToast(`Failed to allow check-in call booking from the app for the participant`)
    }
  }

  const selectValue = bookingEnabled ? 'enabled' : 'disabled'

  const tooltipTitle = `Check-in call booking from the app is ${
    bookingEnabled ? 'allowed' : 'disabled'
  } for the participant`

  return (
    <>
      <TextButtonSelect
        disabled={loading}
        value={selectValue}
        options={BOOKING_ENABLED_OPTIONS}
        onChange={(value) => (value === 'enabled' ? enableBooking() : setDisableBookingModalOpen(true))}
        tooltipTitle={tooltipTitle}
        tooltipPlacement="left"
      />
      <DisableBookingModal uid={uid} onClose={() => setDisableBookingModalOpen(false)} open={disableBookingModalOpen} />
    </>
  )
}

export default BookingEnabledSelect
