import React from 'react'
import { Chip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field, FieldArray, useFormikContext } from 'formik'
import { DECLINE_REASON_NAMES } from 'src/participant'
import { CustomTextField } from 'src/form'
import { PatientDeclinedReasonV2 } from 'src/graphql.generated'

const DECLINED_REASONS_V1: Record<string, string> = {
  // Declined reasons
  NOT_INTERESTED: 'Not Interested',
  NO_PAYER: 'No Payer',
  NOT_RESPONDING: 'Not Responding',
  PREFERS_F2F_THERAPY: 'Prefers F2F Therapy',
  TIME_COMMITMENT: 'Time Commitment',
  OTHER: 'Other',

  // Not eligibile reasons
  ACUTE_TRAUMA_SYMPTOMS: 'Acute Trauma Symptoms',
  NO_SYMPTOMS: 'No Symptoms',
  OTHER_MENTAL_DISORDERS: 'Other Mental Disorders',
  SUICIDALITY: 'Suicidality',
}

const getDeclinedReasonLabel = (reason: string, version?: number) =>
  version === 1 ? DECLINED_REASONS_V1[reason] : DECLINE_REASON_NAMES.get(reason as PatientDeclinedReasonV2)

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}))

export type ParticipantDeclinedSchema = {
  __version: number
  description?: string
  reasons: string[]
}

const ParticipantDeclinedFields = () => {
  const { values } = useFormikContext<{ value: ParticipantDeclinedSchema }>()
  const styles = useStyles()
  return (
    <>
      <FieldArray
        name="value.reasons"
        render={() => (
          <>
            Reasons:
            {values.value.reasons?.map((reason) => (
              <Chip
                key={reason}
                classes={{ root: styles.chip }}
                variant="outlined"
                color="primary"
                // eslint-disable-next-line no-underscore-dangle
                label={getDeclinedReasonLabel(reason, values.value.__version)}
              />
            ))}
          </>
        )}
      />
      {values.value.description && (
        <Field
          component={CustomTextField}
          fullWidth
          label="Description"
          multiline
          name="value.description"
          minRows={4}
        />
      )}
    </>
  )
}

export default ParticipantDeclinedFields
