import React, { FunctionComponent, ReactElement } from 'react'

import { useIsAdmin, useIsManager } from 'src/session'

type Render = (isAllowed: boolean) => ReactElement | null

type Props = {
  allowManager?: boolean
  render?: Render
}

const Authentication: FunctionComponent<Props> = (props) => {
  const { allowManager, children, render } = props
  const admin = useIsAdmin()
  const manager = useIsManager()
  if (admin) {
    return <>{!render ? children : render(true)}</>
  }
  if (allowManager && manager) {
    return <>{!render ? children : render(true)}</>
  }
  return <>{!render ? null : render(false)}</>
}

export default Authentication
