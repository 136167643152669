import { useFormikContext } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import ScoreFields from './ScoreFields'

const PHQ9_QUESTIONS = [
  {
    key: 'PHQ9_QUESTION_1',
    label: 'Little interest or pleasure in doing things',
  },
  {
    key: 'PHQ9_QUESTION_2',
    label: 'Feeling down, depressed, or hopeless',
  },
  {
    key: 'PHQ9_QUESTION_3',
    label: 'Trouble falling or staying asleep, or sleeping too much',
  },
  {
    key: 'PHQ9_QUESTION_4',
    label: 'Feeling tired or having little energy',
  },
  {
    key: 'PHQ9_QUESTION_5',
    label: 'Poor appetite or overeating',
  },
  {
    key: 'PHQ9_QUESTION_6',
    label: 'Feeling bad about yourself, or that you are a failure, or have let yourself or your family down',
  },
  {
    key: 'PHQ9_QUESTION_7',
    label: 'Trouble concentrating on things, such as reading the newspaper or watching television',
  },
  {
    key: 'PHQ9_QUESTION_8',
    label:
      'Moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual',
  },
  {
    key: 'PHQ9_QUESTION_9',
    label: 'Thoughts that you would be better off dead or of hurting yourself in some way',
  },
  {
    key: 'PHQ9_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 3,
}

const ScoreValueSchema = Yup.number().required().min(SCORE_RANGE.VALUE_MIN).max(SCORE_RANGE.VALUE_MAX)

export const PHQ9_SCHEMA = Yup.object()
  .shape({
    PHQ9_QUESTION_1: ScoreValueSchema,
    PHQ9_QUESTION_2: ScoreValueSchema,
    PHQ9_QUESTION_3: ScoreValueSchema,
    PHQ9_QUESTION_4: ScoreValueSchema,
    PHQ9_QUESTION_5: ScoreValueSchema,
    PHQ9_QUESTION_6: ScoreValueSchema,
    PHQ9_QUESTION_7: ScoreValueSchema,
    PHQ9_QUESTION_8: ScoreValueSchema,
    PHQ9_QUESTION_9: ScoreValueSchema,
    PHQ9_SCORE: Yup.number().required().min(0).max(27),
  })
  .default({})
  .required()

export type PHQ9Schema = Yup.InferType<typeof PHQ9_SCHEMA>

const PHQ9Fields = () => {
  const { setFieldValue, values } = useFormikContext<{ value: PHQ9Schema }>()
  return <ScoreFields type="PHQ9" values={values} setFieldValue={setFieldValue} questions={PHQ9_QUESTIONS} />
}

export default PHQ9Fields
