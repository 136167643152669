import { useEffect } from 'react'
import { clearExpiredItems } from './localStorageWithTTL'

const useLocalStorageCleanUp = () => {
  useEffect(() => {
    clearExpiredItems()
  }, [])
}

export default useLocalStorageCleanUp
