import React from 'react'
import { Box, CardContent, Chip } from '@mui/material'

import { Cards, ColoredChip, EmptyPlaceholder, ExpandableCard } from 'src/legacy/components/mui'
import { FormatDateTime } from 'src/date'
import HeightAwareIframe from 'src/legacy/components/HeightAwareIframe'
import ScrollContainer from 'src/legacy/components/ScrollContainer'
import { Loader } from 'src/designSystem'
import { useGetEmailsQuery } from './getEmails.generated'

const ParticipantEmails = ({ uid }: { uid: string }) => {
  const { data, loading } = useGetEmailsQuery({ variables: { uid } })
  const noEntriesFound = data?.emails.length === 0

  return (
    <ScrollContainer>
      <Cards>
        {loading && <Loader size="large" />}
        {noEntriesFound && <EmptyPlaceholder />}
        {data?.emails.map((email) => (
          <ExpandableCard
            key={email.emailID}
            title={email.subject as string}
            subheader={
              <Box display="flex" justifyContent="space-between">
                <span>
                  {email.from && (
                    <>
                      From: {email.from}
                      <br />
                    </>
                  )}
                  To: {email.to}
                </span>
                <span>
                  <ColoredChip
                    color={email.status === 'BOUNCED' ? 'warning' : null}
                    label={
                      <>
                        {email.status} <FormatDateTime value={email.statusChangedAt} />
                      </>
                    }
                    size="small"
                  />
                </span>
              </Box>
            }
          >
            <CardContent>
              {!email.htmlBody && (
                <Box alignItems="center" display="flex" justifyContent="center">
                  <Chip label="Sending" />
                </Box>
              )}
              {email.htmlBody && <HeightAwareIframe html={email.htmlBody} />}
            </CardContent>
          </ExpandableCard>
        ))}
      </Cards>
    </ScrollContainer>
  )
}

export default ParticipantEmails
