/* eslint-disable react/no-array-index-key, react/no-unused-prop-types */

import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { scaleLinear } from 'd3-scale'
import { line, curveCardinal } from 'd3-shape'
import { Paper, Typography } from '@mui/material'
import ResizeAware from 'react-resize-aware'

import { GET_PATIENT_HISTORY_VALUES } from 'src/legacy/lib/graphql/queries'
import MeruDate from 'src/legacy/lib/MeruDate'
import { rejectScoresDuringProgram } from 'src/participant/ParticipantActions/SetProgramEndForm/Baseline'
import ParticipantHistoryScoreChange from './ParticipantHistoryScoreChange'

const legacyStyles = {
  chart: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '0 10px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  change: {
    alignItems: 'center',
    display: 'flex',
    float: 'right',
  },
  label: {
    color: '#979797',
    fontSize: '13px',
    fontWeight: '300',
    marginRight: '5px',
  },
  value: {
    color: '#6fbc64',
    fontSize: '18px',
  },
}

const DATA_TYPES = {
  GAD7: { maxScore: 21, label: 'GAD-7' },
  PHQ9: { maxScore: 27, label: 'PHQ-9' },
}

// This is the same logic how the basline is selected in Baseline.tsx
export const removeScoresBeforeBaseline = (patientHistory, programStartDate) => {
  const baselineRecord = _.chain(patientHistory)
    .filter(rejectScoresDuringProgram(programStartDate))
    .last() // The order of records is reversed compared to Baseline.tsx so instead of first we take last here
    .value()

  const baselineIndex = patientHistory.indexOf(baselineRecord)

  return patientHistory.slice(baselineIndex)
}

class ParticipantHistoryScoreGraph extends Component {
  static propTypes = {
    data: propType(GET_PATIENT_HISTORY_VALUES).isRequired,
    height: PropTypes.number,
    programStartDate: PropTypes.string,
    type: PropTypes.oneOf(['GAD7', 'PHQ9']).isRequired,
  }

  static defaultProps = {
    height: 80,
  }

  state = {
    points: [],
    svgLine: '',
    width: 20,
  }

  UNSAFE_componentWillMount() {
    this.updateChart()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.updateChart(nextProps)
  }

  updateChart({ height, data, programStartDate, type } = this.props) {
    const scoresFromBaseline = data.patientHistory
      ? removeScoresBeforeBaseline(data.patientHistory, programStartDate)
      : []

    const { width } = this.state

    const points = _.map(scoresFromBaseline, (entry) => ({
      week: programStartDate ? new MeruDate(entry.date).formatWeekNumber(programStartDate, true) : null,
      value: entry.value[`${type}_SCORE`],
    }))
    const minWeek = points.length > 0 ? _.first(points).week : 1
    const maxWeek = points.length > 1 ? _.last(points).week : 12

    const xScale = scaleLinear().range([0, width - 20])
    const yScale = scaleLinear().range([height, 0])
    xScale.domain([minWeek, maxWeek])
    yScale.domain([0, DATA_TYPES[type].maxScore]).nice()
    const svgLine = line()
      .x((d) => (d.week === null ? 0 : xScale(d.week)))
      .y((d) => yScale(d.value))
      .curve(curveCardinal)(points)
    this.setState({
      points,
      svgLine,
      xScale,
      yScale,
    })
  }

  getXScale(weekNumber) {
    const { xScale } = this.state
    if (weekNumber === null) {
      return 0
    }
    return xScale(weekNumber)
  }

  renderLine() {
    const { svgLine } = this.state
    return <path d={svgLine} stroke="#5B5B5D" fill="#fff" />
  }

  renderCircles() {
    const { points, yScale } = this.state

    return points.map((d, i) => (
      <svg x={this.getXScale(d.week) - 4} y={yScale(d.value) - 4} key={i}>
        <circle cx="4" cy="4" r="4" fill="#8CEE7E" />
      </svg>
    ))
  }

  renderValues() {
    const { points, yScale } = this.state

    return points.map((d, i) => (
      <text
        key={i}
        fill="#6FBC64"
        fontSize="12.7"
        fontWeight="bold"
        x={this.getXScale(d.week) - 3}
        y={yScale(d.value) - 7}
      >
        {d.value}
      </text>
    ))
  }

  renderAxis() {
    const { height } = this.props
    const { points } = this.state

    return points.map((d, i) => (
      <text key={i} x={this.getXScale(d.week) - 3} y={height + 13} fill="#5B5B5D" fontSize="10" fontWeight="bold">
        {d.week}
      </text>
    ))
  }

  renderLines() {
    const { height } = this.props
    const { points } = this.state

    const lines = points.map((d, i) => (
      <svg key={i} y="5" x={this.getXScale(d.week) - 20} height="100">
        <path fill="#C6C6C6" strokeWidth="1" strokeDasharray="5, 5" stroke="#C6C6C6" d="M30 0 v600 400" />
      </svg>
    ))

    lines.push(
      <svg x="0" y={height + 18} key={lines.length}>
        <path fill="#C6C6C6" stroke="#C6C6C6" strokeWidth="2" d="M0 0 l1120 0" />
      </svg>
    )

    return lines
  }

  handleResize = ({ width }) => {
    this.setState({ width }, () => {
      this.updateChart()
    })
  }

  render() {
    const { data, height, type, programStartDate } = this.props

    const scoresFromBaseline =
      data.patientHistory && programStartDate ? removeScoresBeforeBaseline(data.patientHistory, programStartDate) : []

    const renderTotalChange = programStartDate && scoresFromBaseline.length > 1

    return (
      <div>
        <div style={legacyStyles.header}>
          <Typography color="textSecondary" gutterBottom style={{ width: '100%' }} variant="h6">
            {DATA_TYPES[type].label}

            {renderTotalChange && (
              <span style={legacyStyles.change}>
                <span style={legacyStyles.label}>total change</span>
                <span style={legacyStyles.value}>
                  <ParticipantHistoryScoreChange participantHistory={scoresFromBaseline} type={type} />
                </span>
              </span>
            )}
          </Typography>
        </div>
        <Paper elevation={0}>
          <div style={legacyStyles.chart}>
            <ResizeAware style={{ position: 'relative' }} onlyEvent onResize={this.handleResize}>
              <svg width="100%" height={height + 40}>
                {this.renderLines()}
                <g transform="translate(10, 18)">
                  {this.renderLine()}
                  {this.renderCircles()}
                  {this.renderValues()}
                  {this.renderAxis()}
                </g>
              </svg>
            </ResizeAware>
          </div>
        </Paper>
      </div>
    )
  }
}

export default ParticipantHistoryScoreGraph
