import { Box, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalPhoneOutlined } from '@mui/icons-material'
import React from 'react'
import { DateTime } from 'luxon'
import { VideoCallType } from 'src/graphql.generated'
import { CallBookingVideoCallFragment } from '../videoCallBookingHeaderQueries.generated'

const getCallDescription = (callType: VideoCallType, endTime: Date) => {
  const callHad = Date.now() >= endTime.getTime()

  const callTypeLabel = callType === VideoCallType.Intake ? 'Intake call' : 'Check-in call'

  const callTimingLabel = callHad ? 'on' : 'scheduled for'

  return `${callTypeLabel} ${callTimingLabel}`
}

const getCallDayDetails = (startTime: Date) => {
  const currentMoment = DateTime.fromJSDate(new Date())
  const callStartTime = DateTime.fromJSDate(startTime)
  const callStartsInSameDay = currentMoment.hasSame(callStartTime, 'day')

  const formattedDateAndTime = DateTime.fromJSDate(startTime).toFormat('EEE MMM d, h:mm a')

  if (callStartsInSameDay) {
    return `Today at ${DateTime.fromJSDate(startTime).toFormat('h:mm a')}`
  }

  return formattedDateAndTime
}

type Props = {
  videoCall: CallBookingVideoCallFragment
  callActions: React.ReactNode
}

const VideoCallBookingInformation: React.FC<Props> = ({ videoCall, callActions, children }) => {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <Grid container justifyContent="space-between" spacing={1}>
        <Grid item>
          <Grid container>
            <LocalPhoneOutlined className={styles.phoneIcon} />
            <Typography variant="h6">
              {getCallDescription(videoCall.callType, videoCall.endTime)}
              <br />
              {getCallDayDetails(videoCall.startTime)}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>{callActions}</Grid>
      </Grid>
      {children && <Box>{children}</Box>}
    </div>
  )
}

export default VideoCallBookingInformation

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F3F5F3',
    borderRadius: 8,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  phoneIcon: {
    alignSelf: 'center',
    marginRight: theme.spacing(2),
  },
}))
