import { combineReducers, Reducer } from 'redux'
import { firebaseReducer, FirebaseReducer } from 'react-redux-firebase'

import { Root as FirebaseRoot } from 'src/legacy/models/firebase'
import session from 'src/session/reducer'

export default function setupReducers() {
  return combineReducers({
    firebase: firebaseReducer as Reducer<FirebaseReducer.Reducer<Record<string, unknown>, FirebaseRoot>>,
    session,
  })
}

export type AppState = ReturnType<ReturnType<typeof setupReducers>> & {
  data: Record<string, unknown>
}
