import React from 'react'
import { Button, Menu, MenuItem } from '@mui/material'
import { useChangeAccessLevel } from 'src/session'
import { AccessLevel } from 'src/graphql.generated'

export default function DeveloperMenu() {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)
  const changeAccessLevel = useChangeAccessLevel()
  const handleClick = (event: React.MouseEvent) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  return (
    <>
      <Button color="inherit" onClick={handleClick}>
        Developer
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => changeAccessLevel(AccessLevel.Coach)}>Switch to Coach</MenuItem>
        <MenuItem onClick={() => changeAccessLevel(AccessLevel.Manager)}>Switch to Manager</MenuItem>
        <MenuItem onClick={() => changeAccessLevel(AccessLevel.CareCoordinator)}>Switch to Care Coordinator</MenuItem>
        <MenuItem onClick={() => changeAccessLevel(AccessLevel.CommunityFacilitator)}>
          Switch to Community Facilitator
        </MenuItem>
      </Menu>
    </>
  )
}
