import React from 'react'
import * as Yup from 'yup'
import { Box, Button, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'
import { DeleteButton, InlineForm, TableCellIcon } from 'src/legacy/components/mui'
import { Loader } from 'src/designSystem'
import GraphQLForm from 'src/form/GraphQLForm'
import { UpdateQueryOptions } from '@apollo/client'
import { Field } from 'formik'
import { CustomTextField } from 'src/form'
import { GetTagsDocument, useCreateTagMutation, useDeleteTagMutation, useGetTagsQuery } from './tagQueries.generated'

const AddTagSchema = Yup.object({
  name: Yup.string().required(),
}).required()

type Tag = Yup.InferType<typeof AddTagSchema>

const Tags = () => {
  const { data, loading } = useGetTagsQuery()
  const [createTag] = useCreateTagMutation()
  const [deleteTag] = useDeleteTagMutation()

  if (!data || loading) {
    return <Loader size="large" />
  }

  const { patientTags } = data

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Tag</TableCell>
          <TableCellIcon>Delete</TableCellIcon>
        </TableRow>
      </TableHead>
      <TableBody>
        {patientTags.map((tag) => (
          <TableRow key={tag.id}>
            <TableCell>{tag.name}</TableCell>
            <TableCellIcon>
              <DeleteButton
                onDelete={() => deleteTag({ variables: { id: tag.id }, refetchQueries: [GetTagsDocument] })}
                title="Delete Tag"
              />
            </TableCellIcon>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={2}>
            <Box display="flex" justifyContent="flex-end">
              <GraphQLForm
                validationSchema={AddTagSchema}
                onSubmit={async ({ variables }: UpdateQueryOptions<Tag>) => {
                  await createTag({ variables, refetchQueries: [GetTagsDocument] })
                }}
                render={({ isSubmitting }) => (
                  <InlineForm variant="dense">
                    <Field component={CustomTextField} label="Title" name="name" />
                    <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
                      Add
                    </Button>
                  </InlineForm>
                )}
              />
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default Tags
