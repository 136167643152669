import { PatientWithCareTeamFragment, useGetPatientsWithCareTeamQuery } from '../patientQueries.generated'

export type ParticipantWithCareTeam = {
  uid: string
  firstname?: string | null
  lastname?: string | null
  oldCareTeam: CareTeam
}

export type CareTeam = {
  primaryCoachID?: string
  careteamCoachIDs: Array<string>
}

type SetCareTeamData = {
  participantsWithCareTeams: Array<ParticipantWithCareTeam>
}

export const useLoadSetCareTeamData = (uids: Array<string>): SetCareTeamData => {
  const { data } = useGetPatientsWithCareTeamQuery({ variables: { uids } })

  const participants: Array<PatientWithCareTeamFragment> = data?.patients || []

  const participantsWithCareTeams = participants.map((participant) => {
    const { firstname, lastname, uid, careTeam } = participant
    return {
      firstname,
      lastname,
      uid,
      oldCareTeam: {
        primaryCoachID: careTeam?.find((careTeamMember) => careTeamMember.isPrimary)?.coachID,
        careteamCoachIDs: careTeam
          ? careTeam
              .filter((careTeamMember) => !careTeamMember.isPrimary)
              .map((careTeamMember) => careTeamMember.coachID)
          : [],
      },
    }
  })

  return { participantsWithCareTeams }
}
