import React from 'react'
import { Chip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export type Props<T> = {
  currentSelections: Array<T>
  label: string
  selected: T
  onSelect: (value: T) => void
}

const ChipSelection = <T,>({ currentSelections, label, selected, onSelect }: Props<T>) => (
  <Chip
    className={useStyles().chip}
    clickable
    color={currentSelections.includes(selected) ? 'primary' : undefined}
    label={label}
    variant={currentSelections.includes(selected) ? undefined : 'outlined'}
    onClick={() => onSelect(selected)}
  />
)

export default ChipSelection
