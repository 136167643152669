import React from 'react'
import { PatientProgramWeek } from 'src/graphql.generated'
import MultiSelectField from './MultiSelectField'

export type Props = {
  selectedWeeks: Array<PatientProgramWeek>
  selectWeeks: (weeks: Array<PatientProgramWeek>) => void
}

type WeekSelector = {
  label: string
  value: PatientProgramWeek
}

const WEEKS: Array<WeekSelector> = [
  { value: PatientProgramWeek.Week_0, label: 'Week 0' },
  { value: PatientProgramWeek.Week_1, label: 'Week 1' },
  { value: PatientProgramWeek.Week_2, label: 'Week 2' },
  { value: PatientProgramWeek.Week_3, label: 'Week 3' },
  { value: PatientProgramWeek.Week_4, label: 'Week 4' },
  { value: PatientProgramWeek.Week_5, label: 'Week 5' },
  { value: PatientProgramWeek.Week_6, label: 'Week 6' },
  { value: PatientProgramWeek.Week_7, label: 'Week 7' },
  { value: PatientProgramWeek.Week_8, label: 'Week 8' },
  { value: PatientProgramWeek.Week_9, label: 'Week 9' },
  { value: PatientProgramWeek.Week_10, label: 'Week 10' },
  { value: PatientProgramWeek.Week_11, label: 'Week 11' },
  { value: PatientProgramWeek.Week_12, label: 'Week 12' },
  { value: PatientProgramWeek.Week_12Plus, label: 'Week 12+' },
]

const SelectWeeks = (props: Props) => {
  const { selectedWeeks, selectWeeks } = props

  return (
    <MultiSelectField<WeekSelector>
      label="Program Week"
      name="week"
      identifier={(week) => week.value}
      renderLabel={(week) => week.label}
      onSelect={(weeks) => selectWeeks(weeks as PatientProgramWeek[])}
      selected={selectedWeeks}
      values={WEEKS}
    />
  )
}

export default SelectWeeks
