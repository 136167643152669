import { split } from 'lodash'
import React, { FunctionComponent } from 'react'

import CoachName from 'src/legacy/components/CoachName/CoachName'

const parseSource = (value: string | null | undefined) => {
  const [system, , id] = split(value, ':')
  switch (system) {
    case 'dataSheet':
      return 'the old tracking sheet'
    case 'dashboard':
      return 'the dashboard'
    case 'external':
      return `partner (${id})`
    case 'form':
      return `Typeform (ID: ${id})`
    case 'formstack':
      return `Formstack (ID: ${id})`
    case 'response':
      return 'the app'
    default:
      return null
  }
}

type Props = {
  coachID?: string | null
  value?: string | null
}

const Source: FunctionComponent<Props> = ({ coachID, value }) => {
  if (coachID) {
    return (
      <>
        by <CoachName coachID={coachID} />
      </>
    )
  }
  const sourceText = parseSource(value)
  return sourceText ? <>from {sourceText}</> : null
}

export default Source
