import React from 'react'
import { US_STATES } from 'src/participant/participantConstants'
import MultiSelectField from './MultiSelectField'

export type Props = {
  selectedStates: Array<string>
  selectStates: (states: Array<string>) => void
}

const SelectStateOfResidence = (props: Props) => {
  const { selectedStates, selectStates } = props

  return (
    <MultiSelectField
      label="States of residence"
      name="statesOfResidence"
      identifier={(state) => state.code}
      renderLabel={(state) => `${state.name} (${state.code})`}
      onSelect={selectStates}
      selected={selectedStates}
      values={US_STATES}
    />
  )
}

export default SelectStateOfResidence
