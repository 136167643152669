import { Button, ButtonGroup, Menu, MenuItem, MenuProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ExpandMore } from '@mui/icons-material'
import React, { useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { DateTime, Interval } from 'luxon'

import { CallBookingVideoCallFragment, useGetJoinCallUrlLazyQuery } from './videoCallBookingHeaderQueries.generated'

const tryCopyJoinUrlToClipboard = async (joinUrl: string) => {
  try {
    await navigator.clipboard.writeText(joinUrl)
    return true
  } catch (error) {
    return false
  }
}

const useStyles = makeStyles({
  buttonGroup: {
    '& >:not(:last-of-type)': {
      borderRightColor: '#fff !important',
    },
  },
})

type JoinMenuProps = Pick<MenuProps, 'open' | 'anchorEl'> & {
  joinUrl: string
  onClose: () => void
}

const JoinMenu = ({ joinUrl, onClose, open, anchorEl }: JoinMenuProps) => {
  const { addToast } = useToasts()

  const handleCopyJoinUrl = async () => {
    const isSuccess = await tryCopyJoinUrlToClipboard(joinUrl)

    if (isSuccess) {
      addToast(
        'The Zoom link has been copied to the clipboard. You can share it with the participant if they have trouble joining the call from the app',
        { appearance: 'success' }
      )
    } else {
      addToast('Failed to copy the Zoom link to the clipboard', { appearance: 'error' })
    }

    onClose()
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <MenuItem onClick={handleCopyJoinUrl}>Copy Zoom Link</MenuItem>
    </Menu>
  )
}

const JoinCallButton: React.FunctionComponent<{
  videoCall: CallBookingVideoCallFragment
  mixpanelTrackingEvent: (zoomMeetingID: string) => void
}> = ({ videoCall, mixpanelTrackingEvent }) => {
  const { startTime, zoomMeetingID, joinUrl, endTime } = videoCall

  const { addToast } = useToasts()

  const [getVideoCallUrl, { loading: loadingCallUrl }] = useGetJoinCallUrlLazyQuery({
    onError: () => {
      addToast('Failed to join zoom call', { appearance: 'error' })
    },
  })

  const handleLaunchZoom = async () => {
    const { data: videoCallUrlData, loading } = await getVideoCallUrl({ variables: { zoomMeetingID } })
    if (videoCallUrlData?.videoCallUrl && !loading) {
      window.open(videoCallUrlData.videoCallUrl, '_blank')
      mixpanelTrackingEvent(zoomMeetingID)
    }
  }

  const joinCallEnabledTimeInterval = Interval.fromDateTimes(
    DateTime.fromJSDate(startTime).minus({ hours: 1 }),
    DateTime.fromJSDate(endTime).plus({ hours: 1 })
  )

  const disableJoinCallButton = loadingCallUrl || !joinCallEnabledTimeInterval.contains(DateTime.now())
  const disableMenuButton = DateTime.fromJSDate(endTime).plus({ hours: 1 }).toJSDate() < DateTime.now().toJSDate()

  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const styles = useStyles()

  const menuButton = (
    <Button size="small" ref={menuButtonRef} disabled={disableMenuButton} onClick={() => setMenuOpen(true)}>
      <ExpandMore titleAccess="Select join method" />
    </Button>
  )

  return (
    <>
      {joinUrl && (
        <JoinMenu
          joinUrl={joinUrl}
          open={menuOpen}
          anchorEl={menuButtonRef.current}
          onClose={() => setMenuOpen(false)}
        />
      )}
      <ButtonGroup color="primary" variant="contained" className={styles.buttonGroup} disableElevation>
        <Button disabled={disableJoinCallButton} onClick={handleLaunchZoom}>
          {loadingCallUrl ? 'JOINING CALL' : 'JOIN CALL'}
        </Button>
        {joinUrl && menuButton}
      </ButtonGroup>
    </>
  )
}

export default JoinCallButton
