import { Box, Grid } from '@mui/material'
import { Form, Formik } from 'formik'
import { DateTime } from 'luxon'
import React from 'react'
import { PatientHistoryType } from 'src/graphql.generated'
import { BillingCode } from 'src/participantHistory/intakeNote/billingCodeConstants'
import {
  GetIntakeNotesQuery,
  GetTreatmentPlansQuery,
  useGetIntakeNotesQuery,
  useGetTreatmentPlansQuery,
} from 'src/participantHistory/patientHistoryQueries.generated'
import TreatmentPlanFields from 'src/participantHistory/TreatmentPlanFields'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'
import SubmitButton from './SubmitButton'

const MAX_INPUT_LENGTH = 3000

const TREATMENT_PLAN_FORM_SCHEMA = z.object({
  presentingProblem: z.string().max(MAX_INPUT_LENGTH),
  treatmentGoals: z.string().max(MAX_INPUT_LENGTH),
  billingCodes: z
    .array(z.enum(Object.values(BillingCode) as [string, ...string[]]))
    .min(1, 'At least one billing code is required'),
  interventionsAndEngagementPlan: z.object({
    participateTreatmentProgram: z.boolean(),
    followupTelehealthSessions: z.boolean(),
    utilizeCommunicationChannels: z.boolean(),
    participantAgreement: z.boolean(),
    additionalResources: z.string().max(MAX_INPUT_LENGTH).optional(),
    coordinationWithExternalProviders: z.string().max(MAX_INPUT_LENGTH).optional(),
    other: z.string().max(MAX_INPUT_LENGTH).optional(),
  }),
  obstaclesAndActionPlan: z.string().max(MAX_INPUT_LENGTH),
})

export type TreatmentPlanFormType = z.infer<typeof TREATMENT_PLAN_FORM_SCHEMA>

const initialValues = {
  presentingProblem: '',
  treatmentGoals: '',
  billingCodes: [],
  interventionsAndEngagementPlan: {
    participateTreatmentProgram: true,
    followupTelehealthSessions: true,
    utilizeCommunicationChannels: true,
    participantAgreement: true,
    additionalResources: '',
    coordinationWithExternalProviders: '',
    other: '',
  },
  obstaclesAndActionPlan: '',
}

const resolveInitialBillingCodes = (
  intakeNotes: GetIntakeNotesQuery | undefined,
  treatmentPlans: GetTreatmentPlansQuery | undefined
): BillingCode[] => {
  const patientHistory = treatmentPlans?.patientHistory[0] || intakeNotes?.patientHistory[0]

  if (patientHistory?.__typename === 'CommonPatientHistory') {
    return (
      (patientHistory.type === PatientHistoryType.IntakeNote
        ? (patientHistory.value?.BILLING_CODES as BillingCode[])
        : (patientHistory.value?.billingCodes as BillingCode[])) || []
    )
  }
  return []
}

const TreatmentPlanForm = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  const { data: intakeNotes } = useGetIntakeNotesQuery({ variables: { uid } })
  const { data: treatmentPlans, refetch } = useGetTreatmentPlansQuery({ variables: { uid } })

  const billingCodes = resolveInitialBillingCodes(intakeNotes, treatmentPlans)
  const initialValuesAfterFetchingBillingCodes = { ...initialValues, billingCodes }

  return (
    <Grid item>
      <Formik<TreatmentPlanFormType>
        enableReinitialize
        initialValues={initialValuesAfterFetchingBillingCodes}
        validationSchema={toFormikValidationSchema(TREATMENT_PLAN_FORM_SCHEMA)}
        onSubmit={async (values, { resetForm }) => {
          await addPatientHistoryEntry({
            uid,
            date: DateTime.now().toFormat('yyyy-MM-dd'),
            type: PatientHistoryType.TreatmentPlan,
            value: values,
          })
          resetForm()
          await refetch()
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <TreatmentPlanFields />
            <Box justifyContent="flex-end" display="flex" mt={4}>
              <SubmitButton isSubmitting={isSubmitting} />
            </Box>
          </Form>
        )}
      </Formik>
    </Grid>
  )
}

export default TreatmentPlanForm
