import { useEffect } from 'react'
import { FeedItemFragment, useMarkFeedSeenMutation } from './feedQueries.generated'

const MARK_AS_SEEN_DELAY = 1000

export default function useMarkFeedAsSeen(feedID: string, feedItems: FeedItemFragment[]) {
  const [markFeedSeen] = useMarkFeedSeenMutation({
    variables: {
      uid: feedID,
    },
  })
  useEffect(() => {
    if (feedItems.length === 0) {
      // There is nothing to see in the feed
      return undefined
    }

    // If feed is still active after timeout, mark it as seen
    const timeout = setTimeout(async () => {
      await markFeedSeen()
    }, MARK_AS_SEEN_DELAY)
    return () => {
      clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedID, feedItems])
}
