import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

type Props = {
  bottom?: number | string
}

const useStyles = makeStyles({
  container: {
    overflowY: 'auto',
    padding: '15px',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: (props: Props) => props.bottom ?? 0,
    left: 0,
  },
})
const ScrollContainer: React.FunctionComponent<Props> = (props) => {
  const { children } = props
  const classes = useStyles(props)
  return <div className={classes.container}>{children}</div>
}

export default ScrollContainer
