import React, { useCallback, useMemo, useState } from 'react'
import moment from 'moment/moment'
import { Typography } from '@mui/material'

import { ALL_STATES, UNKNOWN_STATE } from 'src/participant'
import { CustomTooltip } from 'src/designSystem'
import useInterval from './useInterval'

const MiddotDivider = () => <> &middot; </>

interface ParticipantSubtitleProps {
  birthDate?: string | null
  stateOfResidence?: string | null
  timezone?: string | null
}

const ParticipantSubtitleDetails = ({ timezone, stateOfResidence, birthDate }: ParticipantSubtitleProps) => {
  const participantStateOfResidence = ALL_STATES.find((s) => s.code === stateOfResidence) ?? {
    name: UNKNOWN_STATE.name,
    code: stateOfResidence ?? UNKNOWN_STATE.code,
  }

  const birthDateSeemsCorrect =
    birthDate && birthDate.match(/\d{4}-\d{2}-\d{2}/) && moment(birthDate).isBefore(moment())

  const participantAgeInfo = (() => {
    if (birthDateSeemsCorrect) {
      const age = moment().diff(moment(birthDate), 'years')
      return <span>{age} yo</span>
    }
    const error = birthDate && !birthDateSeemsCorrect ? 'Invalid birthdate' : 'Birthdate not set'
    return (
      <CustomTooltip title={error}>
        <span>Age N/A</span>
      </CustomTooltip>
    )
  })()

  const [participantTimeInfo, setParticipantTimeInfo] = useState<{ tooltipTitle: string; text: string }>()
  const updateParticipantTime = useCallback(() => {
    if (timezone) {
      setParticipantTimeInfo({
        tooltipTitle: `In participant's timezone (${moment.tz(timezone).format('z')})`,
        text: moment().tz(timezone).format('LT'),
      })
    } else {
      setParticipantTimeInfo({
        tooltipTitle: 'User timezone not set',
        text: 'TZ N/A',
      })
    }
  }, [timezone])
  const minuteInMs = 60_000
  const msUntilNextFullMinute = useMemo(
    () => minuteInMs - (new Date().getSeconds() * 1000 + new Date().getMilliseconds()),
    []
  )
  useInterval(updateParticipantTime, minuteInMs, { leading: true, delayFirstInterval: msUntilNextFullMinute })

  return (
    <Typography variant="subtitle1">
      {participantAgeInfo}
      <MiddotDivider />
      <span>
        {participantStateOfResidence.name} ({participantStateOfResidence.code})
      </span>
      <MiddotDivider />
      {participantTimeInfo && (
        <CustomTooltip title={participantTimeInfo.tooltipTitle}>
          <span>{participantTimeInfo.text}</span>
        </CustomTooltip>
      )}
    </Typography>
  )
}

export default ParticipantSubtitleDetails
