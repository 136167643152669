import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const CardTitle = ({ action, children }) => (
  <Box display="flex">
    <Box flex={1}>{children}</Box>
    <Box justifySelf="flex-end" height="0px" mt={-1} onClick={(event) => event.stopPropagation()}>
      {action}
    </Box>
  </Box>
)

CardTitle.defaultProps = {
  action: null,
}

CardTitle.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node.isRequired,
}

export default CardTitle
