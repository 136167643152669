import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const BOX = {
  large: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    py: 2,
    width: '100%',
  },
  small: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}

const SIZE = {
  small: 30,
  large: 40,
}

const THICKNESS = {
  small: 2.7,
  large: 3.6,
}

type Props = {
  size: 'small' | 'large'
}

const Loader = ({ size }: Props) => (
  <Box {...BOX[size]}>
    <CircularProgress
      color={size === 'large' ? 'primary' : 'secondary'}
      size={SIZE[size]}
      thickness={THICKNESS[size]}
    />
  </Box>
)

export default Loader
