/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

const defaultOptions = {} as const
export type CreateChatMessageMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  text: Types.Scalars['String']['input']
  linksTo?: Types.InputMaybe<Types.ChatMessageLinksToInput>
}>

export type CreateChatMessageMutation = {
  __typename?: 'Mutation'
  createChatMessage: {
    __typename: 'ChatMessageCreated'
    chatMessage?: { __typename?: 'ChatMessage'; messageID: string; text: string; createdAt: Date } | null
  }
}

export type EditChatMessageMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  messageIDHash: Types.Scalars['ID']['input']
  text: Types.Scalars['String']['input']
}>

export type EditChatMessageMutation = {
  __typename?: 'Mutation'
  editChatMessage: {
    __typename: 'ChatMessageEdited'
    chatMessage: { __typename?: 'ChatMessage'; messageID: string; text: string; createdAt: Date }
  }
}

export type CreateSmsMessageMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  text: Types.Scalars['String']['input']
}>

export type CreateSmsMessageMutation = {
  __typename?: 'Mutation'
  createSmsMessage: {
    __typename: 'CreateSmsMessageResult'
    smsMessage?: { __typename?: 'SmsMessage'; twilioMessageID: string; text: string; createdAt: Date } | null
  }
}

export type MarkFeedSeenMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type MarkFeedSeenMutation = {
  __typename?: 'Mutation'
  markFeedSeen: {
    __typename?: 'FeedSeenResult'
    patient: {
      __typename: 'Patient'
      uid: string
      indicators?: {
        __typename?: 'PatientIndicators'
        newMessages?: { __typename?: 'PatientNewMessageIndicator'; count: number } | null
      } | null
    }
  }
}

export type GetFeedItemsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>
  order?: Types.InputMaybe<Types.FeedOrder>
  before?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
  after?: Types.InputMaybe<Types.Scalars['DateTime']['input']>
}>

export type GetFeedItemsQuery = {
  __typename?: 'Query'
  feed: {
    __typename?: 'FeedSuccess'
    lastSeenAt?: Date | null
    items: Array<
      | {
          __typename: 'ChatMessage'
          messageID: string
          feedID: string
          createdAt: Date
          text: string
          coachID?: string | null
          itemID: string
          chatMessageDirection: Types.ChatMessageDirection
          linkedResponse?: {
            __typename?: 'PatientResponse'
            responseID: string
            title?: string | null
            category?: string | null
            createdAt: Date
          } | null
        }
      | {
          __typename: 'PatientResponse'
          category?: string | null
          clientTime: string
          createdAt: Date
          duration?: number | null
          groupID?: string | null
          isCompleted: boolean
          lessonID: string
          meditationSeconds?: number | null
          programID: string
          sentAt: Date
          title?: string | null
          uid: string
          weekNbr?: number | null
          itemID: string
          sections: Array<{
            __typename?: 'PatientResponseSection'
            responseID: string
            sectionID: string
            description?: string | null
            isSharedWithGroup: boolean
            title?: string | null
            type?: string | null
            entries: Array<{
              __typename?: 'PatientResponseSectionEntry'
              entryID: string
              question?: string | null
              rawValue?: Types.JSONValue | null
              tag?: string | null
            }>
          }>
        }
      | {
          __typename: 'ResumedPatientResponse'
          createdAt: Date
          itemID: string
          resumedResponse: {
            __typename: 'PatientResponse'
            category?: string | null
            clientTime: string
            createdAt: Date
            duration?: number | null
            groupID?: string | null
            isCompleted: boolean
            lessonID: string
            meditationSeconds?: number | null
            programID: string
            sentAt: Date
            title?: string | null
            uid: string
            weekNbr?: number | null
            itemID: string
            sections: Array<{
              __typename?: 'PatientResponseSection'
              responseID: string
              sectionID: string
              description?: string | null
              isSharedWithGroup: boolean
              title?: string | null
              type?: string | null
              entries: Array<{
                __typename?: 'PatientResponseSectionEntry'
                entryID: string
                question?: string | null
                rawValue?: Types.JSONValue | null
                tag?: string | null
              }>
            }>
          }
        }
      | {
          __typename: 'SmsMessage'
          twilioMessageID: string
          uid: string
          coachID?: string | null
          createdAt: Date
          status: Types.SmsMessageStatus
          text: string
          itemID: string
          smsMessageDirection: Types.SmsMessageDirection
        }
      | {
          __typename: 'VideoCall'
          createdAt: Date
          startTime: Date
          endTime: Date
          cancelledAt?: Date | null
          cancelledBy?: Types.VideoCallCancelledBy | null
          callType: Types.VideoCallType
          itemID: string
          coach: { __typename?: 'CoachProfile'; id: string; email: string }
        }
    >
  }
}

export type FeedItem_ChatMessage_Fragment = {
  __typename: 'ChatMessage'
  messageID: string
  feedID: string
  createdAt: Date
  text: string
  coachID?: string | null
  itemID: string
  chatMessageDirection: Types.ChatMessageDirection
  linkedResponse?: {
    __typename?: 'PatientResponse'
    responseID: string
    title?: string | null
    category?: string | null
    createdAt: Date
  } | null
}

export type FeedItem_PatientResponse_Fragment = {
  __typename: 'PatientResponse'
  category?: string | null
  clientTime: string
  createdAt: Date
  duration?: number | null
  groupID?: string | null
  isCompleted: boolean
  lessonID: string
  meditationSeconds?: number | null
  programID: string
  sentAt: Date
  title?: string | null
  uid: string
  weekNbr?: number | null
  itemID: string
  sections: Array<{
    __typename?: 'PatientResponseSection'
    responseID: string
    sectionID: string
    description?: string | null
    isSharedWithGroup: boolean
    title?: string | null
    type?: string | null
    entries: Array<{
      __typename?: 'PatientResponseSectionEntry'
      entryID: string
      question?: string | null
      rawValue?: Types.JSONValue | null
      tag?: string | null
    }>
  }>
}

export type FeedItem_ResumedPatientResponse_Fragment = {
  __typename: 'ResumedPatientResponse'
  createdAt: Date
  itemID: string
  resumedResponse: {
    __typename: 'PatientResponse'
    category?: string | null
    clientTime: string
    createdAt: Date
    duration?: number | null
    groupID?: string | null
    isCompleted: boolean
    lessonID: string
    meditationSeconds?: number | null
    programID: string
    sentAt: Date
    title?: string | null
    uid: string
    weekNbr?: number | null
    itemID: string
    sections: Array<{
      __typename?: 'PatientResponseSection'
      responseID: string
      sectionID: string
      description?: string | null
      isSharedWithGroup: boolean
      title?: string | null
      type?: string | null
      entries: Array<{
        __typename?: 'PatientResponseSectionEntry'
        entryID: string
        question?: string | null
        rawValue?: Types.JSONValue | null
        tag?: string | null
      }>
    }>
  }
}

export type FeedItem_SmsMessage_Fragment = {
  __typename: 'SmsMessage'
  twilioMessageID: string
  uid: string
  coachID?: string | null
  createdAt: Date
  status: Types.SmsMessageStatus
  text: string
  itemID: string
  smsMessageDirection: Types.SmsMessageDirection
}

export type FeedItem_VideoCall_Fragment = {
  __typename: 'VideoCall'
  createdAt: Date
  startTime: Date
  endTime: Date
  cancelledAt?: Date | null
  cancelledBy?: Types.VideoCallCancelledBy | null
  callType: Types.VideoCallType
  itemID: string
  coach: { __typename?: 'CoachProfile'; id: string; email: string }
}

export type FeedItemFragment =
  | FeedItem_ChatMessage_Fragment
  | FeedItem_PatientResponse_Fragment
  | FeedItem_ResumedPatientResponse_Fragment
  | FeedItem_SmsMessage_Fragment
  | FeedItem_VideoCall_Fragment

export type FeedChatMessageItemFragment = {
  __typename?: 'ChatMessage'
  messageID: string
  feedID: string
  createdAt: Date
  text: string
  coachID?: string | null
  itemID: string
  chatMessageDirection: Types.ChatMessageDirection
  linkedResponse?: {
    __typename?: 'PatientResponse'
    responseID: string
    title?: string | null
    category?: string | null
    createdAt: Date
  } | null
}

export type FeedSmsMessageItemFragment = {
  __typename?: 'SmsMessage'
  twilioMessageID: string
  uid: string
  coachID?: string | null
  createdAt: Date
  status: Types.SmsMessageStatus
  text: string
  itemID: string
  smsMessageDirection: Types.SmsMessageDirection
}

export type ChatMessageLinkedResponseFragment = {
  __typename?: 'PatientResponse'
  responseID: string
  title?: string | null
  category?: string | null
  createdAt: Date
}

export type FeedPatientResponseItemFragment = {
  __typename?: 'PatientResponse'
  category?: string | null
  clientTime: string
  createdAt: Date
  duration?: number | null
  groupID?: string | null
  isCompleted: boolean
  lessonID: string
  meditationSeconds?: number | null
  programID: string
  sentAt: Date
  title?: string | null
  uid: string
  weekNbr?: number | null
  itemID: string
  sections: Array<{
    __typename?: 'PatientResponseSection'
    responseID: string
    sectionID: string
    description?: string | null
    isSharedWithGroup: boolean
    title?: string | null
    type?: string | null
    entries: Array<{
      __typename?: 'PatientResponseSectionEntry'
      entryID: string
      question?: string | null
      rawValue?: Types.JSONValue | null
      tag?: string | null
    }>
  }>
}

export type FeedPatientResponseItemSectionFragment = {
  __typename?: 'PatientResponseSection'
  responseID: string
  sectionID: string
  description?: string | null
  isSharedWithGroup: boolean
  title?: string | null
  type?: string | null
  entries: Array<{
    __typename?: 'PatientResponseSectionEntry'
    entryID: string
    question?: string | null
    rawValue?: Types.JSONValue | null
    tag?: string | null
  }>
}

export type FeedPatientResponseItemSectionEntryFragment = {
  __typename?: 'PatientResponseSectionEntry'
  entryID: string
  question?: string | null
  rawValue?: Types.JSONValue | null
  tag?: string | null
}

export type FeedResumedPatientResponseItemFragment = {
  __typename?: 'ResumedPatientResponse'
  createdAt: Date
  itemID: string
  resumedResponse: {
    __typename: 'PatientResponse'
    category?: string | null
    clientTime: string
    createdAt: Date
    duration?: number | null
    groupID?: string | null
    isCompleted: boolean
    lessonID: string
    meditationSeconds?: number | null
    programID: string
    sentAt: Date
    title?: string | null
    uid: string
    weekNbr?: number | null
    itemID: string
    sections: Array<{
      __typename?: 'PatientResponseSection'
      responseID: string
      sectionID: string
      description?: string | null
      isSharedWithGroup: boolean
      title?: string | null
      type?: string | null
      entries: Array<{
        __typename?: 'PatientResponseSectionEntry'
        entryID: string
        question?: string | null
        rawValue?: Types.JSONValue | null
        tag?: string | null
      }>
    }>
  }
}

export type FeedVideoCallItemFragment = {
  __typename?: 'VideoCall'
  createdAt: Date
  startTime: Date
  endTime: Date
  cancelledAt?: Date | null
  cancelledBy?: Types.VideoCallCancelledBy | null
  callType: Types.VideoCallType
  itemID: string
  coach: { __typename?: 'CoachProfile'; id: string; email: string }
}

export const ChatMessageLinkedResponseFragmentDoc = gql`
  fragment chatMessageLinkedResponse on PatientResponse {
    responseID
    title
    category
    createdAt
  }
`
export const FeedChatMessageItemFragmentDoc = gql`
  fragment feedChatMessageItem on ChatMessage {
    itemID: messageID
    messageID
    feedID
    createdAt
    text
    coachID
    chatMessageDirection: direction
    linkedResponse {
      ...chatMessageLinkedResponse
    }
  }
  ${ChatMessageLinkedResponseFragmentDoc}
`
export const FeedPatientResponseItemSectionEntryFragmentDoc = gql`
  fragment feedPatientResponseItemSectionEntry on PatientResponseSectionEntry {
    entryID
    question
    rawValue
    tag
  }
`
export const FeedPatientResponseItemSectionFragmentDoc = gql`
  fragment feedPatientResponseItemSection on PatientResponseSection {
    responseID
    sectionID
    description
    isSharedWithGroup
    title
    type
    entries {
      ...feedPatientResponseItemSectionEntry
    }
  }
  ${FeedPatientResponseItemSectionEntryFragmentDoc}
`
export const FeedPatientResponseItemFragmentDoc = gql`
  fragment feedPatientResponseItem on PatientResponse {
    itemID: responseID
    category
    clientTime
    createdAt
    duration
    groupID
    isCompleted
    lessonID
    meditationSeconds
    programID
    sentAt
    title
    uid
    weekNbr
    sections(groupSections: true) {
      ...feedPatientResponseItemSection
    }
  }
  ${FeedPatientResponseItemSectionFragmentDoc}
`
export const FeedSmsMessageItemFragmentDoc = gql`
  fragment feedSmsMessageItem on SmsMessage {
    itemID: twilioMessageID
    twilioMessageID
    uid
    coachID
    createdAt
    smsMessageDirection: direction
    status
    text
  }
`
export const FeedResumedPatientResponseItemFragmentDoc = gql`
  fragment feedResumedPatientResponseItem on ResumedPatientResponse {
    itemID: id
    createdAt
    resumedResponse {
      __typename
      ...feedPatientResponseItem
    }
  }
  ${FeedPatientResponseItemFragmentDoc}
`
export const FeedVideoCallItemFragmentDoc = gql`
  fragment feedVideoCallItem on VideoCall {
    itemID: id
    createdAt
    startTime
    endTime
    cancelledAt
    cancelledBy
    callType
    coach {
      id
      email
    }
  }
`
export const FeedItemFragmentDoc = gql`
  fragment feedItem on FeedItem {
    __typename
    ... on ChatMessage {
      ...feedChatMessageItem
    }
    ... on PatientResponse {
      ...feedPatientResponseItem
    }
    ... on SmsMessage {
      ...feedSmsMessageItem
    }
    ... on ResumedPatientResponse {
      ...feedResumedPatientResponseItem
    }
    ... on VideoCall {
      ...feedVideoCallItem
    }
  }
  ${FeedChatMessageItemFragmentDoc}
  ${FeedPatientResponseItemFragmentDoc}
  ${FeedSmsMessageItemFragmentDoc}
  ${FeedResumedPatientResponseItemFragmentDoc}
  ${FeedVideoCallItemFragmentDoc}
`
export const CreateChatMessageDocument = gql`
  mutation createChatMessage($uid: ID!, $text: String!, $linksTo: ChatMessageLinksToInput) {
    createChatMessage(uid: $uid, text: $text, linksTo: $linksTo) {
      __typename
      ... on ChatMessageCreated {
        chatMessage {
          messageID
          text
          createdAt
        }
      }
    }
  }
`
export type CreateChatMessageMutationFn = Apollo.MutationFunction<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>

/**
 * __useCreateChatMessageMutation__
 *
 * To run a mutation, you first call `useCreateChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatMessageMutation, { data, loading, error }] = useCreateChatMessageMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      text: // value for 'text'
 *      linksTo: // value for 'linksTo'
 *   },
 * });
 */
export function useCreateChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatMessageMutation, CreateChatMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateChatMessageMutation, CreateChatMessageMutationVariables>(
    CreateChatMessageDocument,
    options
  )
}
export type CreateChatMessageMutationHookResult = ReturnType<typeof useCreateChatMessageMutation>
export type CreateChatMessageMutationResult = Apollo.MutationResult<CreateChatMessageMutation>
export type CreateChatMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateChatMessageMutation,
  CreateChatMessageMutationVariables
>
export const EditChatMessageDocument = gql`
  mutation editChatMessage($uid: ID!, $messageIDHash: ID!, $text: String!) {
    editChatMessage(uid: $uid, messageIDHash: $messageIDHash, text: $text) {
      __typename
      ... on ChatMessageEdited {
        __typename
        chatMessage {
          messageID
          text
          createdAt
        }
      }
    }
  }
`
export type EditChatMessageMutationFn = Apollo.MutationFunction<
  EditChatMessageMutation,
  EditChatMessageMutationVariables
>

/**
 * __useEditChatMessageMutation__
 *
 * To run a mutation, you first call `useEditChatMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditChatMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editChatMessageMutation, { data, loading, error }] = useEditChatMessageMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      messageIDHash: // value for 'messageIDHash'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useEditChatMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<EditChatMessageMutation, EditChatMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditChatMessageMutation, EditChatMessageMutationVariables>(EditChatMessageDocument, options)
}
export type EditChatMessageMutationHookResult = ReturnType<typeof useEditChatMessageMutation>
export type EditChatMessageMutationResult = Apollo.MutationResult<EditChatMessageMutation>
export type EditChatMessageMutationOptions = Apollo.BaseMutationOptions<
  EditChatMessageMutation,
  EditChatMessageMutationVariables
>
export const CreateSmsMessageDocument = gql`
  mutation createSmsMessage($uid: ID!, $text: String!) {
    createSmsMessage(uid: $uid, text: $text) {
      __typename
      ... on CreateSmsMessageResult {
        smsMessage {
          twilioMessageID
          text
          createdAt
        }
      }
    }
  }
`
export type CreateSmsMessageMutationFn = Apollo.MutationFunction<
  CreateSmsMessageMutation,
  CreateSmsMessageMutationVariables
>

/**
 * __useCreateSmsMessageMutation__
 *
 * To run a mutation, you first call `useCreateSmsMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmsMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmsMessageMutation, { data, loading, error }] = useCreateSmsMessageMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useCreateSmsMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSmsMessageMutation, CreateSmsMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateSmsMessageMutation, CreateSmsMessageMutationVariables>(
    CreateSmsMessageDocument,
    options
  )
}
export type CreateSmsMessageMutationHookResult = ReturnType<typeof useCreateSmsMessageMutation>
export type CreateSmsMessageMutationResult = Apollo.MutationResult<CreateSmsMessageMutation>
export type CreateSmsMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateSmsMessageMutation,
  CreateSmsMessageMutationVariables
>
export const MarkFeedSeenDocument = gql`
  mutation markFeedSeen($uid: ID!) {
    markFeedSeen(uid: $uid) {
      patient {
        __typename
        uid
        indicators {
          newMessages {
            count
          }
        }
      }
    }
  }
`
export type MarkFeedSeenMutationFn = Apollo.MutationFunction<MarkFeedSeenMutation, MarkFeedSeenMutationVariables>

/**
 * __useMarkFeedSeenMutation__
 *
 * To run a mutation, you first call `useMarkFeedSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkFeedSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markFeedSeenMutation, { data, loading, error }] = useMarkFeedSeenMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useMarkFeedSeenMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkFeedSeenMutation, MarkFeedSeenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<MarkFeedSeenMutation, MarkFeedSeenMutationVariables>(MarkFeedSeenDocument, options)
}
export type MarkFeedSeenMutationHookResult = ReturnType<typeof useMarkFeedSeenMutation>
export type MarkFeedSeenMutationResult = Apollo.MutationResult<MarkFeedSeenMutation>
export type MarkFeedSeenMutationOptions = Apollo.BaseMutationOptions<
  MarkFeedSeenMutation,
  MarkFeedSeenMutationVariables
>
export const GetFeedItemsDocument = gql`
  query getFeedItems($uid: ID!, $limit: Int, $offset: Int, $order: FeedOrder, $before: DateTime, $after: DateTime) {
    feed(uid: $uid, limit: $limit, offset: $offset, order: $order, before: $before, after: $after) {
      ... on FeedSuccess {
        items {
          ...feedItem
        }
        lastSeenAt
      }
    }
  }
  ${FeedItemFragmentDoc}
`

/**
 * __useGetFeedItemsQuery__
 *
 * To run a query within a React component, call `useGetFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedItemsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order: // value for 'order'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetFeedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeedItemsQuery, GetFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetFeedItemsQuery, GetFeedItemsQueryVariables>(GetFeedItemsDocument, options)
}
export function useGetFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeedItemsQuery, GetFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetFeedItemsQuery, GetFeedItemsQueryVariables>(GetFeedItemsDocument, options)
}
export type GetFeedItemsQueryHookResult = ReturnType<typeof useGetFeedItemsQuery>
export type GetFeedItemsLazyQueryHookResult = ReturnType<typeof useGetFeedItemsLazyQuery>
export type GetFeedItemsQueryResult = Apollo.QueryResult<GetFeedItemsQuery, GetFeedItemsQueryVariables>
