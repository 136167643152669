import { useMemo } from 'react'
import _ from 'lodash'
import { EnrollmentState } from 'src/graphql.generated'
import { ParticipantTableColumn } from './constants'

type ColumnMap = Record<EnrollmentState, Array<ParticipantTableColumn>>

const DISABLED_INTAKE_STATES = [ParticipantTableColumn.programStartDate]
const UNSCHEDULED_CALL_STATE = [...DISABLED_INTAKE_STATES, ParticipantTableColumn.callTime]

const DISABLED_COLUMNS_BY_STATE: ColumnMap = {
  [EnrollmentState.IntakePreRegistered]: UNSCHEDULED_CALL_STATE,
  [EnrollmentState.IntakeLoggedIn]: UNSCHEDULED_CALL_STATE,
  [EnrollmentState.IntakeRegistered]: UNSCHEDULED_CALL_STATE,
  [EnrollmentState.IntakeCallScheduled]: DISABLED_INTAKE_STATES,
  [EnrollmentState.InProgram]: [ParticipantTableColumn.financialEligibility, ParticipantTableColumn.callTime],
  [EnrollmentState.PostProgram]: [ParticipantTableColumn.financialEligibility, ParticipantTableColumn.callTime],
  [EnrollmentState.CancellationPending]: [ParticipantTableColumn.financialEligibility, ParticipantTableColumn.callTime],
  [EnrollmentState.DidNotStart]: [
    ParticipantTableColumn.programStartDate,
    ParticipantTableColumn.financialEligibility,
    ParticipantTableColumn.callTime,
  ],
  [EnrollmentState.Test]: [],
}

function getDisabledColumns(state: EnrollmentState): Array<ParticipantTableColumn> {
  return DISABLED_COLUMNS_BY_STATE[state]
}

const EMPTY_SET = new Set<ParticipantTableColumn>()

export default function useDisableColumnsForStates(states: Array<EnrollmentState>): Set<ParticipantTableColumn> {
  const disabledColumns = useMemo(
    () => (states.length ? new Set(_.intersection(...states.map(getDisabledColumns))) : EMPTY_SET),
    [states]
  )

  return disabledColumns
}
