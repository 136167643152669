import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import {
  useGetParticipantVideoCallBookingInformationQuery,
  GetParticipantVideoCallBookingInformationQuery,
} from './videoCallBookingHeaderQueries.generated'

import InProgramVideoCallBookingContainer from './InProgramVideoCallBookingContainer'
import IntakeVideoCallBookingContainer from './IntakeVideoCallBookingContainer'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    maxHeight: '360px',
    overflowY: 'auto',
  },
}))

type Props = {
  uid: string
}

const getContainer = (data?: GetParticipantVideoCallBookingInformationQuery) => {
  if (!data) {
    return null
  }

  const { patient } = data
  const bookingInformation = patient.videoCallBookingInformation

  if (!bookingInformation) {
    return null
  }

  const bookingInformationType = bookingInformation.__typename

  if (bookingInformationType === 'InProgramVideoCallBookingInformation') {
    return <InProgramVideoCallBookingContainer bookingInformation={bookingInformation} participant={patient} />
  }

  if (bookingInformationType === 'IntakeVideoCallBookingInformation') {
    return <IntakeVideoCallBookingContainer bookingInformation={bookingInformation} participant={patient} />
  }

  return null
}

const VideoCallBookingHeader = ({ uid }: Props) => {
  const styles = useStyles()

  const { data } = useGetParticipantVideoCallBookingInformationQuery({ variables: { uid } })

  const container = getContainer(data)

  return container && <div className={styles.container}>{container}</div>
}

export default VideoCallBookingHeader
