import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'recompose'

import { GET_PATIENT_HISTORY_VALUES } from 'src/legacy/lib/graphql/queries'
import reportAndRenderNothingOnGraphQLError from 'src/legacy/lib/reportAndRenderNothingOnGraphQLError'
import ParticipantHistoryScoreGraph from './ParticipantHistoryScoreGraph'

export default compose(
  graphql(GET_PATIENT_HISTORY_VALUES, {
    options: (props) => ({
      variables: {
        uid: props.uid,
        type: props.type,
      },
    }),
  }),
  reportAndRenderNothingOnGraphQLError()
)(ParticipantHistoryScoreGraph)
