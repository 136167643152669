/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql.generated'

import { InProgramVideoCallFragmentDoc } from '../../videoCallBookingHeaderQueries.generated'

const defaultOptions = {} as const
export type SetCallExceptionMutationVariables = Types.Exact<{
  callID: Types.Scalars['String']['input']
  callException?: Types.InputMaybe<Types.VideoCallException>
}>

export type SetCallExceptionMutation = {
  __typename?: 'Mutation'
  setCallException?: {
    __typename?: 'VideoCall'
    notes?: string | null
    callException?: Types.VideoCallException | null
    id: string
    startTime: Date
    endTime: Date
    zoomMeetingID: string
    callType: Types.VideoCallType
    joinUrl?: string | null
  } | null
}

export const SetCallExceptionDocument = gql`
  mutation setCallException($callID: String!, $callException: VideoCallException) {
    setCallException(callID: $callID, callException: $callException) {
      ...InProgramVideoCall
    }
  }
  ${InProgramVideoCallFragmentDoc}
`
export type SetCallExceptionMutationFn = Apollo.MutationFunction<
  SetCallExceptionMutation,
  SetCallExceptionMutationVariables
>

/**
 * __useSetCallExceptionMutation__
 *
 * To run a mutation, you first call `useSetCallExceptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCallExceptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCallExceptionMutation, { data, loading, error }] = useSetCallExceptionMutation({
 *   variables: {
 *      callID: // value for 'callID'
 *      callException: // value for 'callException'
 *   },
 * });
 */
export function useSetCallExceptionMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCallExceptionMutation, SetCallExceptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetCallExceptionMutation, SetCallExceptionMutationVariables>(
    SetCallExceptionDocument,
    options
  )
}
export type SetCallExceptionMutationHookResult = ReturnType<typeof useSetCallExceptionMutation>
export type SetCallExceptionMutationResult = Apollo.MutationResult<SetCallExceptionMutation>
export type SetCallExceptionMutationOptions = Apollo.BaseMutationOptions<
  SetCallExceptionMutation,
  SetCallExceptionMutationVariables
>
