import React from 'react'
import _ from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useIsAdmin } from 'src/session'
import ValueTable from '../../../FeedComponents/Value/ValueTable'
import { SectionTitle } from '../../../FeedComponents'

type EstimateState = 'found' | 'searching'

type ResonanceLevel = 'low' | 'medium' | 'high'

export type HRVSessionResult = {
  appVersion: string
  breathsPerMinute: number
  durationInSec: number
  nbrOfBeats: number
  rmssd?: number
  sdnn?: number
  cvnn?: number
  hrAvg?: number
  hrStdev?: number
  coherenceAvg?: number
  coherenceStdev?: number
  states?: Record<EstimateState, number>
  levels?: Record<ResonanceLevel, number>
}

type HRVMetricsTableProps = {
  value: HRVSessionResult
  isCompleted?: boolean // eslint-disable-line react/no-unused-prop-types
}

export default function HRVMetricsTable(props: HRVMetricsTableProps) {
  const { value } = props
  const isAdmin = useIsAdmin()

  const { durationInSec = 0, rmssd } = value
  const convertToSec = (ms: number) => Math.round(ms / 1000)
  const { searching = null, found = null } = _.mapValues(value.states, convertToSec)
  const { low = null, medium = null, high = null } = _.mapValues(value.levels, convertToSec)

  const classes = useStyles(props)
  const sessionDurations = [
    ['Total time', formatTime(durationInSec), ''],
    ['Optimal resonance', formatTime(found), formatPercentage(durationInSec, found)],
    ['Searching', formatTime(searching), formatPercentage(durationInSec, searching)],
    ['RMSSD', rmssd, ''],
  ].filter(([, measurement]) => Boolean(measurement)) as Array<[string, string]>

  const formattedValues = {
    ...value,
    levels: {
      low: formatTime(low),
      medium: formatTime(medium),
      high: formatTime(high),
    },
    states: {
      found: formatTime(found),
      searching: formatTime(searching),
    },
  }

  const isDetailsVisible = Object.keys(formattedValues).length > 0 && isAdmin
  const isTimingTableVisible = sessionDurations.length > 0
  const isDividerVisible = isTimingTableVisible && isDetailsVisible
  return (
    <>
      {isTimingTableVisible && <ValueTable rows={sessionDurations} />}
      {isDividerVisible && <Box my={2} />}
      {isDetailsVisible && (
        <Accordion classes={classes}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <SectionTitle content="HRV exercise details" />
          </AccordionSummary>
          <AccordionDetails>
            <ValueTable rows={Object.entries(formattedValues)} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

const formatTime = (timeInSeconds: number | null) => {
  if (timeInSeconds === null) {
    return ''
  }

  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = Math.floor(timeInSeconds % 60)

  if (!minutes) {
    return `${seconds}s`
  }
  if (!seconds) {
    return `${minutes}min`
  }
  return `${minutes}min ${seconds}s`
}

const formatPercentage = (total: number, fraction: number | null): string => {
  if (fraction === null) {
    return ''
  }
  return `${((fraction / total) * 100).toLocaleString(undefined, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })}%`
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: (props: HRVMetricsTableProps) => (!props.isCompleted ? theme.palette.lightestGrey : 'transparent'),
  },
}))
