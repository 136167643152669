import React from 'react'
import PropTypes from 'prop-types'
import { TableCell } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: 48,
    '&:first-of-type': {
      paddingLeft: theme.spacing(2),
    },
    '&:last-of-type': {
      paddingRight: theme.spacing(2),
    },
  },
}))

const TableCellIcon = ({ children }) => {
  const classes = useStyles()
  return (
    <TableCell align="center" classes={classes}>
      {children}
    </TableCell>
  )
}

TableCellIcon.defaultProps = {
  children: null,
}

TableCellIcon.propTypes = {
  children: PropTypes.node,
}

export default TableCellIcon
