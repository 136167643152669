import React, { useCallback } from 'react'
import { Field } from 'formik'
import { Button, DialogActions, DialogContent } from '@mui/material'
import * as Yup from 'yup'

import { CustomTextField, RequestForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import DescriptionField from './DescriptionField'
import { useGetEmailTemplatesQuery, useSendEmailsMutation } from './emailTemplates.generated'
import SelectTemplateField from './SelectTemplateField'
import { SendEmailFormType } from './type'

const allowedTemplateVariables = ['firstname', 'lastname', 'email', 'preferredName']

export const EmailSchema = (uids: string[]) =>
  Yup.object()
    .shape({
      uids: Yup.array().of(Yup.string().required()).required().default(uids),
      description: Yup.string()
        .when('.', {
          is: (val) => val?.match(/%([a-zA-Z]+?)%/),
          then: Yup.string().test({
            name: 'description',
            message: `Allowed template variables are: ${allowedTemplateVariables.join(', ')}`,
            test: (value) => {
              const matches = value?.match(/%([a-zA-Z]+?)%/g) ?? []
              const templateVariables: string[] = matches.map((match: string) => match.replace(/%/g, ''))
              return templateVariables.every((variable) => allowedTemplateVariables.includes(variable))
            },
          }),
          otherwise: Yup.string().required(),
        })
        .required(),
      subject: Yup.string().required(),
      templateID: Yup.string(),
      title: Yup.string(),
    })
    .required()

const SendEmailForm = ({ close, onSuccess, uids }: { close: () => void; onSuccess: () => void; uids: string[] }) => {
  const { data: templateData } = useGetEmailTemplatesQuery()
  const [sendEmails] = useSendEmailsMutation()

  const onSubmitCallback = async (values: SendEmailFormType) => {
    await sendEmails({
      variables: {
        uids: values.uids,
        emailTemplateInput: {
          subject: values.subject,
          description: values.description,
        },
      },
    })
  }

  const onSuccessCallback = useCallback(() => {
    close()
    onSuccess()
  }, [close, onSuccess])

  return (
    <RequestForm
      onSubmit={onSubmitCallback}
      onSuccess={onSuccessCallback}
      resetAfterSuccess={false}
      validationSchema={EmailSchema(uids)}
      render={({ isSubmitting }) => (
        <>
          <ClosableDialogTitle onClose={close}>Send Email</ClosableDialogTitle>
          <DialogContent>
            <SelectTemplateField templates={templateData?.emailTemplates ?? []} />
            <Field component={CustomTextField} fullWidth label="Subject" name="subject" />
            <DescriptionField />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" disabled={isSubmitting} type="submit">
              Send
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default SendEmailForm
