import React from 'react'
import { Field, useFormikContext } from 'formik'
import { MenuItem } from '@mui/material'
import { CustomTextField } from 'src/form'
import { PatientHistoryType, ProgramEndMetricsInput } from 'src/graphql.generated'
import { PATIENT_HISTORY_TYPE_NAMES } from './constants'

// Cannot use GraphQL EngagementType because the type value is store in JSONB object
// thus we don't have a resolver to map the the value in database to correct GRAPHQL type
const ENGAGEMENTS = {
  completer: 'Completer',
  dropout: 'Dropout',
  noShow: 'No show',
}

export type ProgramEndSchema = {
  engagement: keyof typeof ENGAGEMENTS
  baselinePHQ9?: number
  programEndPHQ9?: number | null
  baselineGAD7?: number
  programEndGAD7?: number | null
  programEndMetrics?: ProgramEndMetricsInput
}

type LegacyProgramEndFieldValuesProps = {
  engagement: 'completer' | 'dropout' | 'noShow'
}

const LegacyProgramEndFieldValues = ({ engagement }: LegacyProgramEndFieldValuesProps) => (
  <>
    <Field component={CustomTextField} label="Engagement" labelPosition="left" name="value.engagement" type="select">
      <MenuItem value={engagement}>{ENGAGEMENTS[engagement]}</MenuItem>
    </Field>

    <Field component={CustomTextField} label="PHQ-9 Baseline" labelPosition="left" name="value.baselinePHQ9" />
    <Field component={CustomTextField} label="PHQ-9 Program End" labelPosition="left" name="value.programEndPHQ9" />

    <Field component={CustomTextField} label="GAD-7 Baseline" labelPosition="left" name="value.baselineGAD7" />
    <Field component={CustomTextField} label="GAD-7 Program End" labelPosition="left" name="value.programEndGAD7" />
  </>
)

const ProgramEndFields = () => {
  const { values } = useFormikContext<{ value: ProgramEndSchema }>()

  const { engagement, programEndMetrics } = values.value

  if (!programEndMetrics) {
    return <LegacyProgramEndFieldValues engagement={engagement} />
  }

  const metrics = Object.keys(programEndMetrics) as Array<PatientHistoryType>

  return (
    <>
      <Field component={CustomTextField} label="Engagement" labelPosition="left" name="value.engagement" type="select">
        <MenuItem value={engagement}>{ENGAGEMENTS[engagement]}</MenuItem>
      </Field>
      {metrics.map((metric) => (
        <React.Fragment key={metric}>
          <Field
            component={CustomTextField}
            label={`${PATIENT_HISTORY_TYPE_NAMES[metric]} Baseline`}
            labelPosition="left"
            name={`value.programEndMetrics.${metric}.baseline`}
          />
          <Field
            component={CustomTextField}
            label={`${PATIENT_HISTORY_TYPE_NAMES[metric]} Program End`}
            labelPosition="left"
            name={`value.programEndMetrics.${metric}.programEnd`}
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default ProgramEndFields
