import * as React from 'react'
import { isEmpty } from 'lodash'
import { ItemWithSideInfo } from 'src/timeline'
import { FeedPatientResponseItemFragment } from '../feedQueries.generated'
import { FeedItemCard } from '../FeedComponents'
import ResponseActions from './Actions/ResponseActions'
import ResponseHeader from './ResponseHeader'
import Sections from './Sections'

const isResponseExpandable = (response: FeedPatientResponseItemFragment) =>
  response.sections.some((section) => !isEmpty(section.entries))

type ResponseItemProps = {
  participantResponse: FeedPatientResponseItemFragment
  displayedTime: Date
  isResumed?: boolean
}

export default function ResponseItem({ participantResponse, displayedTime, isResumed }: ResponseItemProps) {
  const header = <ResponseHeader response={participantResponse} isResumed={isResumed} />
  const actions = (
    <ResponseActions linkedResponse={{ responseID: participantResponse.itemID, ...participantResponse }} />
  )

  return (
    <ItemWithSideInfo displayedTime={displayedTime} alignItem="left" actions={actions} stickToTop>
      <FeedItemCard
        variant="outlined"
        header={header}
        isCompleted={participantResponse.isCompleted}
        isExpandable={isResponseExpandable(participantResponse)}
      >
        <Sections response={participantResponse} />
      </FeedItemCard>
    </ItemWithSideInfo>
  )
}
