import React, { useState } from 'react'
import { Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { Alert, AlertTitle, Button, Chip, Stack, Typography } from '@mui/material'
import { CustomCheckbox, CustomTextField } from 'src/form'
import useLoadSources from 'src/participantsView/useLoadSources'
import CostEstimateFields from 'src/participantHistory/financialEligibility/CostEstimateFields'
import { FinancialEligibilityPatientHistory } from 'src/graphql.generated'
import PasteCostEstimateButton from 'src/participantHistory/financialEligibility/PasteCostEstimateButton'
import { Loader } from 'src/designSystem'
import './yup.decimalDigitsMaxTwo'

import { HealthPlanLookupDialog } from './healthPlanLookup/HealthPlanLookupDialog'
import InsuranceSubscriberFields from './InsuranceSubscriberFields'
import CostCalculatorLink from './CostCalculatorLink'
import FinancialEligibilityFormSection from './FinancialEligibilityFormSection'
import { AperoEligibilityDetailsLink } from './healthPlanLookup/AperoEligibilityDetailsLink'
import { useInitializeInsuranceSubscriberFields } from './useInitializeInsuranceSubscriberFields'
import PrefillFullyCoveredPlanButton from './PrefillFullyCoveredPlanButton'
import GetCostEstimateButton from './GetCostEstimateButton'
import { centsToDollars } from './centsToDollars'

const requiredWhenInsuranceSubscriberProvided = (schema: Yup.StringSchema) =>
  schema.when('insuranceSubscriberProvided', {
    is: true,
    then: (schemaBuilder: Yup.StringSchema) => schemaBuilder.required(),
  })

export const FINANCIAL_ELIGIBILITY_HEALTH_PLAN_FORM_SCHEMA = Yup.object({
  description: Yup.string().required(),
  insuranceSubscriber: Yup.object({
    insuranceSubscriberProvided: Yup.boolean().default(true).required(),
    coverageActive: Yup.boolean()
      .default(false)
      .required()
      .when('insuranceSubscriberProvided', {
        is: true,
        then: (s: Yup.BooleanSchema) => s.oneOf([true], 'Coverage must be active'),
      }),
    memberID: requiredWhenInsuranceSubscriberProvided(Yup.string()),
    groupDescription: Yup.string().nullable(),
    groupNumber: Yup.string().nullable(),
    firstname: requiredWhenInsuranceSubscriberProvided(Yup.string()),
    lastname: requiredWhenInsuranceSubscriberProvided(Yup.string()),
    birthDate: Yup.string().nullable(),
  }).required(),
  costEstimate: Yup.object()
    .shape({
      intakeCostEstimate: Yup.number().decimalDigitsMaxTwo().required(),
      month1CostEstimate: Yup.number().decimalDigitsMaxTwo().required(),
      month2CostEstimate: Yup.number().decimalDigitsMaxTwo().required(),
      month3CostEstimate: Yup.number().decimalDigitsMaxTwo().required(),
    })
    .required(),
}).required()

export type FinancialEligibilityHealthPlanSchema = Yup.InferType<typeof FINANCIAL_ELIGIBILITY_HEALTH_PLAN_FORM_SCHEMA>

export const getInitialValuesFromFinancialEligibilityPatientHistoryEntry = (
  entry: FinancialEligibilityPatientHistory
) => {
  const { description, costEstimate } = entry

  return {
    value: {
      description,
      costEstimate: {
        intakeCostEstimate: costEstimate ? centsToDollars(costEstimate.intakeCostEstimateInCents) : '',
        month1CostEstimate: costEstimate ? centsToDollars(costEstimate.month1CostEstimateInCents) : '',
        month2CostEstimate: costEstimate ? centsToDollars(costEstimate.month2CostEstimateInCents) : '',
        month3CostEstimate: costEstimate ? centsToDollars(costEstimate.month3CostEstimateInCents) : '',
      },
    },
  }
}

type FormContext = {
  status?: { viewOnly: boolean }
  value: FinancialEligibilityHealthPlanSchema
}

type Props = {
  uid: string
}

const FinancialEligibilityHealthPlanFields = ({ uid }: Props) => {
  const sources = useLoadSources()
  const [aperoEligibilityID, setAperoEligibilityID] = useState<string | undefined>(undefined)
  const [warnings, setWarnings] = useState<string[]>([])
  const [eligibilityCheckLookupToolOpen, setEligibilityCheckLookupToolOpen] = useState(false)
  const { status, values } = useFormikContext<FormContext>()
  const viewOnly = status?.viewOnly ?? false

  const { eligibilityInputsQuery, setInsuranceSubscriberValue } = useInitializeInsuranceSubscriberFields(
    uid,
    (result) => {
      setAperoEligibilityID(result.aperoEligibilityID)
      setWarnings(result.warnings ?? [])
    }
  )

  const costEstimateFailure = eligibilityInputsQuery.data?.patient.costEstimateFailure
  const credentialingHealthPlan = eligibilityInputsQuery.data?.patient?.credentialingHealthPlan
  const insuranceSubscriberProvided = values.value.insuranceSubscriber?.insuranceSubscriberProvided

  if (eligibilityInputsQuery.loading) return <Loader size="large" />
  if (eligibilityInputsQuery.error)
    return (
      <Alert sx={{ my: 2 }} severity="error">
        Failed to load eligibility inputs.
      </Alert>
    )

  return (
    <>
      <HealthPlanLookupDialog
        uid={uid}
        sources={sources}
        onClose={() => setEligibilityCheckLookupToolOpen(false)}
        onCompleted={(response) => {
          setEligibilityCheckLookupToolOpen(false)
          setInsuranceSubscriberValue(response)
        }}
        open={eligibilityCheckLookupToolOpen}
      />

      <Stack spacing={5} mt={2}>
        {!viewOnly && costEstimateFailure && (
          <Alert severity="warning">
            <AlertTitle>Automatic cost estimation warning</AlertTitle>
            Reason: {costEstimateFailure.reason}
          </Alert>
        )}

        {!viewOnly && (
          <FinancialEligibilityFormSection
            title="Insurance details"
            actions={
              <>
                {aperoEligibilityID && <AperoEligibilityDetailsLink eligibilityID={aperoEligibilityID} />}
                <Button variant="outlined" onClick={() => setEligibilityCheckLookupToolOpen(true)}>
                  Lookup tool
                </Button>
              </>
            }
          >
            <Field
              fullWidth
              name="value.insuranceSubscriber.insuranceSubscriberProvided"
              label="Provide insurance details"
              component={CustomCheckbox}
              condensed
            />
            <div style={insuranceSubscriberProvided ? {} : { display: 'none' }}>
              <InsuranceSubscriberFields name="value.insuranceSubscriber" />

              <Stack direction="column" spacing={1} mt={2}>
                <Typography variant="h4">Warnings</Typography>
                {warnings.length ? (
                  <Stack direction="row" spacing={1} mt={2}>
                    {warnings.map((warning) => (
                      <Chip key={warning} label={warning} color="warning" />
                    ))}
                  </Stack>
                ) : (
                  '-'
                )}
              </Stack>
            </div>
          </FinancialEligibilityFormSection>
        )}

        <FinancialEligibilityFormSection
          title="Cost estimate"
          actions={
            !viewOnly && (
              <>
                <CostCalculatorLink eligibilityID={aperoEligibilityID} healthplan={credentialingHealthPlan} />
                <PasteCostEstimateButton />
                <PrefillFullyCoveredPlanButton />
                <GetCostEstimateButton uid={uid} />
              </>
            )
          }
        >
          <CostEstimateFields name="value.costEstimate" />

          <Field
            component={CustomTextField}
            name="value.description"
            label="Description"
            fullWidth
            multiline
            minRows={4}
          />
        </FinancialEligibilityFormSection>
      </Stack>
    </>
  )
}

export default FinancialEligibilityHealthPlanFields
