import { PatientHistoryType } from 'src/graphql.generated'

export type PatientHistoryFormType = PatientHistoryType | 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN'

export const PATIENT_HISTORY_TYPE_NAMES: Record<PatientHistoryFormType, string> = {
  ACCEPTED: 'Participant Accepted',
  ANONYMOUS_NOTE: 'Anonymous Notes for Analytics',
  BURNOUT: 'Burnout Questionnaire',
  CUSTOM: 'Form Submission',
  DECLINED: 'Participant Declined',
  FINANCIAL_ELIGIBILITY_CONFIRMED: 'Financial Eligibility Confirmed',
  FINANCIAL_ELIGIBILITY_HEALTH_PLAN: 'Financial Eligibility Health Plan',
  GAD7: 'GAD-7',
  INTAKE_NOTE: 'Intake Notes',
  INTAKE_NOTE_COACHING: 'Intake Notes (Coaching)',
  MISSED_INTAKE_CALL: 'Missed Intake Call',
  NOTE: 'Note',
  PARTICIPANT_RE_ENROLLED: 'Participant Re-enrolled',
  PHONE_CALL: 'Phone Call',
  PHQ9: 'PHQ-9',
  PROGRAM_END: 'Program End',
  SMS: 'SMS',
  WPAI: 'WPAI',
  WHO5: 'WHO-5',
  PSS4: 'PSS-4',
  BRS: 'BRS',
  TREATMENT_PLAN: 'Treatment Plan',
  VIDEO_CALL: 'Check-in Call',
} as const

export const MISSED_INTAKE_CALL_REASONS = {
  PATIENT_DID_NOT_SHOW_UP: 'Participant did not show up',
  PATIENT_CANCELLED: 'Participant cancelled < 24h before',
} as const

export const DEFAULT_INTAKE_NOTE_TTL_IN_HOURS = 24 * 2
