import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import React from 'react'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { GetPatientHistoryDocument } from 'src/legacy/lib/graphql/queries.generated'
import { GetFullPatientDocument } from '../patientQueries.generated'
import { useSendParticipantBackToCallSchedulingMutation } from './intakeCallQueries.generated'

type Props = {
  close: () => void
  onSuccess: () => void
  uid: string
  programID: string
}

const reEnrollmentDescription = 'Participant re-enrolled to reschedule intake call.'

const ConfirmParticipantReschedule = ({ close, onSuccess, uid, programID }: Props) => {
  const [sendParticipantBackToCallScheduling, { loading }] = useSendParticipantBackToCallSchedulingMutation({
    onCompleted: onSuccess,
    refetchQueries: [
      { query: GetFullPatientDocument, variables: { uid } },
      { query: GetPatientHistoryDocument, variables: { uid } },
    ],
  })

  const submitRescheduling = async () => {
    await sendParticipantBackToCallScheduling({
      variables: { uid, programID, description: reEnrollmentDescription },
    })
  }

  return (
    <>
      <ClosableDialogTitle onClose={close}>Please Note</ClosableDialogTitle>
      <DialogContent>
        <DialogContentText color="textPrimary">
          Once you confirm, this participant will reschedule their intake call directly through the app.
        </DialogContentText>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <DialogContentText color="textPrimary">They won't be re-assigned to the same therapist.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={close}>
          Back
        </Button>
        <Button color="primary" onClick={submitRescheduling} disabled={loading}>
          Confirm
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmParticipantReschedule
