import _ from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Tab, Tabs } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

type LinkDefinition = {
  label: string
  to: string
  alt?: string
}

const getActiveTab = (tabs: LinkDefinition[], pathname: string) => {
  const match = _.findLast(tabs, (value) => pathname.startsWith(value.to))
  const altMatch = _.findLast(tabs, (value) => {
    if (value.alt) {
      return pathname.startsWith(value.alt)
    }
    return false
  })

  const linkTarget = match?.to || altMatch?.to

  if (!linkTarget && linkTarget === '/' && pathname !== linkTarget) {
    return false
  }
  return linkTarget
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

type Props = {
  classes?: Dictionary<string>
  tabs: LinkDefinition[]
}

const TabLinks = ({ classes, tabs }: Props) => {
  const location = useLocation()
  const tabClasses = useStyles()

  const activeTab = getActiveTab(tabs, location.pathname)

  return (
    <Tabs classes={classes} value={activeTab} indicatorColor="primary">
      {tabs.map(({ label, to }) => (
        <Tab color="primary" classes={tabClasses} component={Link} key={to} label={label} to={to} value={to} />
      ))}
    </Tabs>
  )
}

export default TabLinks
