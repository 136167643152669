import React from 'react'
import { Box, Typography } from '@mui/material'
import { isSuccessWithData, useFirebase } from 'src/firebase'
import { Program } from 'src/legacy/models/firebase'
import MeruDate from 'src/legacy/lib/MeruDate'

type WeekThemeProps = {
  lastWeekNbr: number
  weekNbr: number | null
  weeks: Program['weeks']
}
const WeekTheme = ({ weekNbr, lastWeekNbr, weeks }: WeekThemeProps) => {
  const isPostProgram = (weekNbr || 0) > lastWeekNbr
  const weekTheme = isPostProgram ? `Program ended after week ${lastWeekNbr}` : weeks[`W${weekNbr || 0}`].title
  return <Typography variant="h3">{`Week ${weekNbr}: ${weekTheme}`}</Typography>
}

type ProgramStartDateInfoProps = {
  programStartDate: string
  weekNbr: number | null
}
const ProgramStartDateInfo = ({ programStartDate, weekNbr }: ProgramStartDateInfoProps) => {
  const formattedDate = new MeruDate(programStartDate).formatDate()
  const messageTense = weekNbr === 0 ? 'starts on' : 'started on'
  return <Typography variant="caption">{`Program ${messageTense} ${formattedDate}`}</Typography>
}

type Props = {
  programID: string
  programStartDate: string
}

export default function WeekInfo({ programID, programStartDate }: Props) {
  const request = useFirebase<Record<string, Program>>('programs')
  if (!isSuccessWithData(request)) return null
  const { lastWeekNbr, weeks } = request.data[programID]
  const weekNbr = MeruDate.now().formatWeekNumber(programStartDate)
  return (
    <Box>
      <WeekTheme lastWeekNbr={lastWeekNbr} weekNbr={weekNbr} weeks={weeks} />
      <ProgramStartDateInfo programStartDate={programStartDate} weekNbr={weekNbr} />
    </Box>
  )
}
