import React from 'react'
import { EnrollmentState } from 'src/graphql.generated'
import { Chip } from '@mui/material'
import { PARTICIPANT_STATES } from './constants'

type Props = {
  state: EnrollmentState | null
}

const stateToString = (state: EnrollmentState | null): string => {
  const mappedState = PARTICIPANT_STATES.find((participantState) => participantState.state === state)

  if (!mappedState) {
    return 'Unknown'
  }

  return mappedState.label
}

export default function FormattedState(props: Props) {
  const formatted = stateToString(props.state)
  return <Chip size="small" label={formatted} />
}
