import React from 'react'

import { ChatMessageLinkedResponseFragment } from '../../feedQueries.generated'
import { useMentionResponse } from '../../mentionResponse'
import { CardActionsMenu } from '../../../designSystem'

export type ResponseActionsProps = {
  linkedResponse: ChatMessageLinkedResponseFragment
}

export default function ResponseActions({ linkedResponse }: ResponseActionsProps) {
  const { setResponse } = useMentionResponse()
  const actions = [
    {
      label: 'Comment in chat',
      onClick: () => setResponse(linkedResponse),
    },
  ]
  return <CardActionsMenu actions={actions} />
}
