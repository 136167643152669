import { useEffect, useState } from 'react'

const isInPast = (date?: number) => (date ? Date.now() - date > 0 : false)

export default function useIsInPast(date?: number) {
  const [isPast, setIsPast] = useState(isInPast(date))

  useEffect(() => {
    const interval = setInterval(() => {
      setIsPast(isInPast(date))
    }, 1000)
    return () => clearInterval(interval)
  }, [date])

  return isPast
}
