import React, { useState, useRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Menu, MenuItem, TooltipProps } from '@mui/material'

import { CustomTooltip } from 'src/designSystem'
import TextMenuButton from './TextMenuButton'

export type SelectOption<Value extends string = string> = {
  value: Value
  label: string
}

export type TextButtonSelectProps<OptionValue extends string = string> = {
  options: SelectOption<OptionValue>[]
  value: OptionValue | null
  disabled?: boolean
  onChange?: (value: OptionValue) => void
  placeholder?: string
  tooltipTitle?: TooltipProps['title']
  tooltipPlacement?: TooltipProps['placement']
}

const useStyles = makeStyles({
  menuItem: {
    '&.Mui-selected': {
      borderRight: '0px none',
      backgroundColor: '#dfeddf',
    },
  },
})

const TextButtonSelect = <OptionValue extends string>({
  options,
  value,
  disabled,
  onChange,
  placeholder,
  tooltipTitle,
  tooltipPlacement,
}: TextButtonSelectProps<OptionValue>) => {
  const styles = useStyles()
  const menuButtonRef = useRef<HTMLButtonElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const buttonLabelFromOptions = value ? options.find((option) => option.value === value) : null

  const buttonLabel = buttonLabelFromOptions?.label ?? placeholder ?? 'Select an option'

  const handleSelectOption = (selectedValue: OptionValue) => () => {
    if (onChange) {
      onChange(selectedValue)
    }

    setMenuOpen(false)
  }

  const menu = (
    <Menu
      anchorEl={menuButtonRef.current}
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {options.map((option) => (
        <MenuItem
          className={styles.menuItem}
          key={option.value}
          selected={option.value === value}
          onClick={handleSelectOption(option.value)}
        >
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  )

  const button = (
    <TextMenuButton disabled={disabled} ref={menuButtonRef} onClick={() => setMenuOpen(true)}>
      {buttonLabel}
    </TextMenuButton>
  )

  return (
    <>
      {menu}
      {tooltipTitle ? (
        <CustomTooltip title={tooltipTitle} placement={tooltipPlacement}>
          {button}
        </CustomTooltip>
      ) : (
        button
      )}
    </>
  )
}

export default TextButtonSelect
