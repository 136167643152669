import React from 'react'
import { Box, Typography , Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


type Props = {
  title: string
  label: string
  replyingStatus: string
}

export function PostItem({ title, label, replyingStatus }: Props) {
  const styles = useStyles()
  return (
    <PostItemLayout
      label={
        <Typography variant="caption" color="primary" className={styles.label}>
          {label}
        </Typography>
      }
      replyingStatus={<Typography variant="caption">{replyingStatus}</Typography>}
      title={
        <Typography variant="h1" color="textPrimary">
          {title}
        </Typography>
      }
    />
  )
}

export function PostItemSkeleton() {
  return (
    <PostItemLayout
      label={<Skeleton variant="text" width="90px" />}
      replyingStatus={<Skeleton variant="text" width="50px" />}
      title={
        <>
          <Skeleton variant="text" height="1.875rem" />
          <Skeleton variant="text" height="1.875rem" />
          <Skeleton variant="text" height="1.875rem" width="45%" />
        </>
      }
    />
  )
}

type LayoutProps = {
  title: React.ReactNode
  label: React.ReactNode
  replyingStatus: React.ReactNode
}

function PostItemLayout({ title, label, replyingStatus }: LayoutProps) {
  return (
    <Box px={2} pt={2} pb={4}>
      <Box my={1} display="flex" justifyContent="space-between">
        {label}
        {replyingStatus}
      </Box>
      {title}
    </Box>
  )
}

const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
})
