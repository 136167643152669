import React, { useState } from 'react'
import { Field } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { UpdateQueryOptions } from '@apollo/client'
import { FormDatePicker, CustomTextField, GraphQLForm } from 'src/form'
import MeruDate from 'src/legacy/lib/MeruDate'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { GET_PATIENT_HISTORY } from 'src/legacy/lib/graphql/queries'
import { trackRestartProgram } from 'src/mixpanel/api'
import { useRestartParticipantProgramMutation } from '../patientQueries.generated'
import { getProgramStartDateSuggestion, isRestartDateDisabled } from './dateHelpers'

enum MenuOption {
  SELECT_START_DATE = 'SELECT_START_DATE',
  CONFIRM_RESTART = 'CONFIRM_RESTART',
}

type Props = {
  programStartDate: string
  uid: string
  close: () => void
  cancel: () => void
}

const SetRestartProgramSchema = Yup.object({
  uid: Yup.string().required(),
  programStartDate: Yup.string().required(),
  description: Yup.string().required(),
}).required()

type RestartProgramForm = Yup.InferType<typeof SetRestartProgramSchema>

const SetRestartProgramForm = ({ programStartDate, uid, close, cancel }: Props) => {
  const [restartProgram] = useRestartParticipantProgramMutation({
    refetchQueries: [GET_PATIENT_HISTORY],
  })

  const handleSubmit = async ({ variables }: UpdateQueryOptions<RestartProgramForm>) => {
    await restartProgram({ variables })
    trackRestartProgram(MeruDate.now().formatWeekNumber(programStartDate))
  }

  const [renderSection, setRenderSection] = useState<MenuOption>(MenuOption.SELECT_START_DATE)

  const suggestedStartDate = getProgramStartDateSuggestion().format('YYYY-MM-DD')

  return (
    <GraphQLForm
      initialValues={{ uid, programStartDate: suggestedStartDate, description: '' }}
      onSubmit={handleSubmit}
      onSuccess={close}
      validationSchema={SetRestartProgramSchema}
      render={({ isSubmitting, values }) => (
        <>
          <ClosableDialogTitle onClose={close}>Select new start date</ClosableDialogTitle>
          <DialogContent hidden={renderSection === MenuOption.CONFIRM_RESTART}>
            <Typography>Current start date: {moment(programStartDate).format('M/D/YYYY')}</Typography>
            <Field
              component={FormDatePicker}
              label="New start date"
              fullWidth
              name="programStartDate"
              shouldDisableDate={isRestartDateDisabled}
            />
          </DialogContent>
          <DialogContent hidden={renderSection === MenuOption.SELECT_START_DATE}>
            <Typography>
              You are about to restart this participant in the program. Their new start date for week 1 will be{' '}
              {moment(values.programStartDate).format('M/D/YYYY')}.
            </Typography>
            <Field
              component={CustomTextField}
              label="Reason for restart"
              name="description"
              multiline
              minRows={4}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            {renderSection === MenuOption.SELECT_START_DATE && (
              <>
                <Button color="inherit" onClick={cancel}>
                  Cancel
                </Button>
                <Button color="primary" onClick={() => setRenderSection(MenuOption.CONFIRM_RESTART)}>
                  Next
                </Button>
              </>
            )}
            {renderSection === MenuOption.CONFIRM_RESTART && (
              <>
                <Button color="inherit" onClick={() => setRenderSection(MenuOption.SELECT_START_DATE)}>
                  Back
                </Button>
                <Button color="primary" disabled={isSubmitting} type="submit">
                  Confirm
                </Button>
              </>
            )}
          </DialogActions>
        </>
      )}
    />
  )
}

export default SetRestartProgramForm
