import React from 'react'
import { Chip, Grid, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { Check, Close } from '@mui/icons-material'
import { FullPatientFinancialEligibilityCheckFragment } from '../financialEligibilityHealthPlanQueries.generated'
import { AperoEligibilityDetailsLink } from './AperoEligibilityDetailsLink'
import { formatUTCYearMonthDate } from '../formatUTCYearMonthDate'

type Props = {
  eligibilityCheck: FullPatientFinancialEligibilityCheckFragment
}

export function AperoEligibilityResponseView({ eligibilityCheck }: Props) {
  return (
    <Grid item xs={12}>
      <Table>
        <Header />
        <Body eligibilityCheck={eligibilityCheck} />
      </Table>
    </Grid>
  )
}

function Header() {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Member ID</TableCell>
        <TableCell>Group number</TableCell>
        <TableCell>First name</TableCell>
        <TableCell>Last name</TableCell>
        <TableCell>Birthdate</TableCell>
        <TableCell align="center">Coverage active</TableCell>
        <TableCell>Warnings</TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

function Body({ eligibilityCheck }: Props) {
  const {
    subscriber: { id, groupNumber, firstName, lastName, birthDate },
    coverageActive,
    warnings,
    aperoEligibilityID,
  } = eligibilityCheck
  return (
    <TableBody>
      <TableRow>
        <TableCell>{id}</TableCell>
        <TableCell>{groupNumber}</TableCell>
        <TableCell>{firstName}</TableCell>
        <TableCell>{lastName}</TableCell>
        <TableCell>{birthDate ? formatUTCYearMonthDate(birthDate) : ''}</TableCell>
        <TableCell align="center">{coverageActive ? <Check color="primary" /> : <Close color="error" />}</TableCell>
        <TableCell>
          {warnings?.length && (
            <Stack direction="column" spacing={1}>
              {warnings.map((warning) => (
                <Chip key={warning} label={warning} color="warning" />
              ))}
            </Stack>
          )}
        </TableCell>
        <TableCell>
          <AperoEligibilityDetailsLink eligibilityID={aperoEligibilityID} />
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
