import React from 'react'
import { Field } from 'formik'
import { Button, DialogActions, DialogContent } from '@mui/material'
import * as Yup from 'yup'

import { FormDateTimePicker, GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { UpdateQueryOptions } from '@apollo/client'
import { GetParticipantVideoCallBookingInformationDocument } from 'src/videoCall/VideoCallBookingHeader/videoCallBookingHeaderQueries.generated'
import CalendarOwnerEmailField from './CalendarOwnerEmailField'
import ParticipantTime from '../../date/ParticipantTime'
import { useRescheduleIntakeCallMutation } from './intakeCallQueries.generated'
import { GetFullPatientDocument } from '../patientQueries.generated'

type RescheduleCallSchema = {
  calendarOwnerEmail: string
  uid: string
  start: number
}
const RescheduleCallValidationSchema: (uid: string) => Yup.ObjectSchema<RescheduleCallSchema> = (uid) =>
  Yup.object()
    .required()
    .shape({
      calendarOwnerEmail: Yup.string().email().required(),
      uid: Yup.string().required().default(uid),
      start: Yup.number().required(),
    })

type Props = {
  close: () => void
  onSuccess: () => void
  uid: string
}

const RescheduleCallForm: React.FunctionComponent<Props> = (props) => {
  const { close, onSuccess, uid } = props
  const [reschedule] = useRescheduleIntakeCallMutation({
    refetchQueries: [GetFullPatientDocument, GetParticipantVideoCallBookingInformationDocument],
  })
  return (
    <GraphQLForm
      onSubmit={async (values: UpdateQueryOptions<RescheduleCallSchema>) => {
        const { calendarOwnerEmail, start } = values.variables!
        await reschedule({
          variables: {
            uid,
            calendarOwnerEmail,
            start: new Date(start),
          },
        })
        close()
      }}
      onSuccess={onSuccess}
      validationSchema={RescheduleCallValidationSchema(uid)}
      render={({ isSubmitting, values }) => (
        <>
          <ClosableDialogTitle onClose={close}>Reschedule Call</ClosableDialogTitle>
          <DialogContent>
            <Field
              component={FormDateTimePicker}
              fullWidth
              helperText={<ParticipantTime uid={uid} time={values.start} />}
              label="Date and Time"
              name="start"
            />
            <CalendarOwnerEmailField />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={close}>
              Back
            </Button>
            <Button color="primary" disabled={isSubmitting} type="submit">
              Reschedule
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default RescheduleCallForm
