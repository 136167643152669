import { useSelector } from 'react-redux'

import { AppState } from 'src/legacy/reducers'

export const useIsAdmin = () => useSelector((state: AppState) => Boolean(state.session.authRoles?.isAdmin))

export const useIsManager = () => useSelector((state: AppState) => Boolean(state.session.authRoles?.isManager))

export const useIsCareCoordinator = () =>
  useSelector((state: AppState) => Boolean(state.session.authRoles?.isCareCoordinator))

export const useIsCommunityFacilitator = () =>
  useSelector((state: AppState) => Boolean(state.session.authRoles?.isCommunityFacilitator))

export const useSession = () => useSelector((state: AppState) => state.session)

export const useSessionUID = () => useSelector((state: AppState) => state.session.uid!)
