import React from 'react'
import { Button, Link } from '@mui/material'
import qs from 'qs'

export const COST_CALCULATOR_URL = 'https://meruhealth.retool.com/apps/bfdc45a6-ba96-11ed-8eb3-8b1d79404a71'

type Props = {
  eligibilityID?: string | null
  healthplan?: string | null
}

const getCostCalculatorLink = ({ eligibilityID, healthplan }: Props) => {
  const params = qs.stringify({ eligibilityID, healthplan }, { skipNulls: true })

  return `${COST_CALCULATOR_URL}#${params}`
}

const CostCalculatorLink = ({ eligibilityID, healthplan }: Props) => (
  <Button
    component={Link}
    href={getCostCalculatorLink({ eligibilityID, healthplan })}
    target="_blank"
    variant="outlined"
  >
    Open cost calculator
  </Button>
)

export default CostCalculatorLink
