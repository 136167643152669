import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { CustomTooltip } from 'src/designSystem'

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
}))

const SuicidalityIndicator = () => {
  const styles = useStyles()

  return (
    <CustomTooltip title="High suicidality" placement="left-start">
      <WarningRoundedIcon className={styles.warning} titleAccess="Suicidality warning" />
    </CustomTooltip>
  )
}

export default SuicidalityIndicator
