import _ from 'lodash'
import moment from 'moment'

const PREFIX = 'LOCAL_STORAGE_WITH_TTL_'
const DEFAULT_TTL_IN_HOURS = 24

const isPrefixedKey = (key: string) => _.startsWith(key, PREFIX)

export const clearExpiredItems = () => {
  _.keys(window.localStorage)
    .filter(isPrefixedKey)
    .forEach((key) => {
      const item = window.localStorage.getItem(key) || '{}'
      const { expiresAt } = JSON.parse(item)
      if (expiresAt < moment().valueOf()) {
        window.localStorage.removeItem(key)
      }
    })
}

export const getItem = (key: string) => {
  const item = getItemWithExpirationTime(key)
  return item?.value ?? null
}

export const setItem = (key: string, value: unknown, expiresInHours?: number) => {
  const expiresIn = expiresInHours ?? DEFAULT_TTL_IN_HOURS
  window.localStorage.setItem(
    `${PREFIX}${key}`,
    JSON.stringify({
      expiresAt: moment().add(expiresIn, 'hours').valueOf(),
      value,
    })
  )
}
export function getItemWithExpirationTime(key: string) {
  const item = window.localStorage.getItem(`${PREFIX}${key}`)
  if (!item) {
    return null
  }
  const { expiresAt, value } = JSON.parse(item)
  if (expiresAt < Date.now()) {
    window.localStorage.removeItem(`${PREFIX}${key}`)
    return null
  }
  return { expiresAt, value }
}

export function clearItem(key: string) {
  window.localStorage.removeItem(`${PREFIX}${key}`)
}
