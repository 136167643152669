import React from 'react'
import { Box, CardHeader, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LessonCategory } from 'src/legacy/models/firebase'
import { assessmentIcon, cbtIcon, defaultIcon, meditationIcon, videoIcon, videoCallScheduledIcon } from '../icons'

const categoryIcons = {
  // Practice Categories
  CBT: cbtIcon,
  GROUP_REFLECTION: assessmentIcon,
  HEALTH_QUESTIONNAIRE: assessmentIcon,
  MEDITATION_HRV: meditationIcon,
  MEDITATION_MINDFULNESS: meditationIcon,
  PERSONAL_REFLECTION: assessmentIcon,
  SETUP: assessmentIcon,
  VIDEO_INTRODUCTION: videoIcon,
  VIDEO_RECAP: videoIcon,
  VIDEO_TIPS: videoIcon,
  DEFAULT: defaultIcon,
  VIDEO_CALL_SCHEDULED: videoCallScheduledIcon,
} as const

type IconProps = {
  icon: typeof categoryIcons[LessonCategory]
}

const CategoryIcon: React.FunctionComponent<IconProps> = ({ icon }) => (
  <Box display="flex">
    <img width={20} src={icon} />
  </Box>
)

type MainTitleProps = {
  content: string
}

const MainTitle: React.FunctionComponent<MainTitleProps> = ({ content }) => {
  const classes = useMainTitleStyles()
  return (
    <Box className={classes.mainTitleContainer}>
      <Typography classes={{ root: classes.mainTitleText }}>{content}</Typography>
    </Box>
  )
}

type SummaryProps = {
  content: React.ReactNode
}

const Summary: React.FunctionComponent<SummaryProps> = ({ content }) => {
  const classes = useSummaryStyles()
  return (
    <Box className={classes.container}>
      <Typography variant="caption" classes={{ root: classes.text }} component="div" color="textSecondary" noWrap>
        {content}
      </Typography>
    </Box>
  )
}

export type Props = {
  title?: string
  iconCategory?: string | null
  action?: React.ReactNode
  summary?: React.ReactNode
}

const Header: React.FunctionComponent<Props> = (props) => {
  const { action, title, summary, iconCategory = 'DEFAULT' } = props
  const icon = categoryIcons[iconCategory as LessonCategory]
  const classes = useHeaderStyles(props)
  return (
    <CardHeader
      classes={classes}
      action={action}
      disableTypography
      avatar={<CategoryIcon icon={icon} />}
      title={title && <MainTitle content={title} />}
      subheader={summary && <Summary content={summary} />}
    />
  )
}

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
    paddingLeft: theme.spacing(2),
    flexDirection: 'row',
    display: 'flex',
  },
  content: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    gap: theme.spacing(2),
  },
  action: {
    marginTop: -4,
    marginBottom: -4,
    alignSelf: 'center',
  },
}))

const useMainTitleStyles = makeStyles({
  mainTitleContainer: {
    display: 'flex',
    flexShrink: 0,
  },
  mainTitleText: {
    lineHeight: 1.25,
    fontStyle: 'italic',
  },
})

const useSummaryStyles = makeStyles({
  container: {
    minWidth: 0, // needed for enabling textOverflow ellipsis in flex container https://css-tricks.com/flexbox-truncated-text/
  },
  text: {
    lineHeight: 1.25,
  },
})

export default Header
