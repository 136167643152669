import { useEffect } from 'react'
import { FieldProps, getIn } from 'formik'

export type GenericFormProps = {
  errorText?: string
  unregisterOnUnmount: boolean
}

export const getError = (props: GenericFormProps & FieldProps) =>
  props.errorText || getIn(props.form.errors, props.field.name)

export const hasError = (props: GenericFormProps & FieldProps) => props.form.submitCount > 0 && Boolean(getError(props))

export const isViewOnly = (props: FieldProps) => getIn(props.form.status, 'viewOnly', false)

export const isFormLoading = (props: FieldProps) => getIn(props.form.status, 'isLoading', false)

export const useUnregisterFieldOnUnmount = (props: GenericFormProps & FieldProps) => {
  const {
    field: { name },
    form: { setFieldValue, unregisterField },
    unregisterOnUnmount,
  } = props
  useEffect(
    () => () => {
      if (unregisterOnUnmount) {
        setFieldValue(name, undefined, false)
        unregisterField(name)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [name, unregisterOnUnmount]
  )
}
