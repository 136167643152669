/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

const defaultOptions = {} as const
export type TaskRecommendationsForPatientQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  timezone: Types.Scalars['String']['input']
}>

export type TaskRecommendationsForPatientQuery = {
  __typename?: 'Query'
  taskRecommendationsForPatient: {
    __typename?: 'TaskRecommendationsResult'
    taskRecommendations: Array<{
      __typename?: 'TaskRecommendation'
      taskID: string
      lessonID: string
      isDisabled: boolean
      completedAt?: Date | null
    }>
  }
}

export const TaskRecommendationsForPatientDocument = gql`
  query taskRecommendationsForPatient($uid: ID!, $timezone: String!) {
    taskRecommendationsForPatient(uid: $uid, timezone: $timezone) {
      taskRecommendations {
        taskID
        lessonID
        isDisabled
        completedAt
      }
    }
  }
`

/**
 * __useTaskRecommendationsForPatientQuery__
 *
 * To run a query within a React component, call `useTaskRecommendationsForPatientQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskRecommendationsForPatientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskRecommendationsForPatientQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTaskRecommendationsForPatientQuery(
  baseOptions: Apollo.QueryHookOptions<TaskRecommendationsForPatientQuery, TaskRecommendationsForPatientQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TaskRecommendationsForPatientQuery, TaskRecommendationsForPatientQueryVariables>(
    TaskRecommendationsForPatientDocument,
    options
  )
}
export function useTaskRecommendationsForPatientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskRecommendationsForPatientQuery,
    TaskRecommendationsForPatientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TaskRecommendationsForPatientQuery, TaskRecommendationsForPatientQueryVariables>(
    TaskRecommendationsForPatientDocument,
    options
  )
}
export type TaskRecommendationsForPatientQueryHookResult = ReturnType<typeof useTaskRecommendationsForPatientQuery>
export type TaskRecommendationsForPatientLazyQueryHookResult = ReturnType<
  typeof useTaskRecommendationsForPatientLazyQuery
>
export type TaskRecommendationsForPatientQueryResult = Apollo.QueryResult<
  TaskRecommendationsForPatientQuery,
  TaskRecommendationsForPatientQueryVariables
>
