import React from 'react'
import moment from 'moment-timezone'
import { TextField } from '@mui/material'
import { DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

// @ts-expect-error use moment internals to get locale
const { longDateFormat } = moment()._locale._config // eslint-disable-line no-underscore-dangle
const timezoneAbbr = moment.tz(moment.tz.guess()).zoneAbbr()
const format = `${longDateFormat.L} [at] ${longDateFormat.LT} [${timezoneAbbr}]`

type Props = {
  disabled: boolean
  error: boolean
  fullWidth: boolean
  helperText?: string
  label: string
  labelPosition: 'left' | 'top'
  name: string
  setValue: (name: string, value: number | null) => void
  shouldDisableDate?: (date: string) => boolean
  value: moment.Moment | null
  viewOnly: boolean
}

const DatePicker = ({
  error,
  fullWidth,
  helperText,
  label,
  name,
  value,
  setValue,
  shouldDisableDate,
  viewOnly,
}: Props) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiDateTimePicker
      ampm
      disabled={viewOnly}
      disableMaskedInput
      inputFormat={format}
      label={label}
      value={value ?? moment(value, format, true)}
      onChange={(newValue) => {
        if (newValue && moment(newValue).isValid()) {
          setValue(name, moment(newValue).valueOf())
        } else {
          setValue(name, null)
        }
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          error={error}
          fullWidth={fullWidth}
          helperText={helperText}
          margin="normal"
          name={name}
          size="small"
          variant="outlined"
        />
      )}
      shouldDisableDate={shouldDisableDate}
    />
  </LocalizationProvider>
)

export default DatePicker
