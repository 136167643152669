import React from 'react'
import { Field } from 'formik'
import { Grid } from '@mui/material'
import { FormDatePicker, CustomTextField } from 'src/form'

export type ParticipantAcceptedSchema = {
  programStartDate: string
  source: string
}

const ParticipantAcceptedFields = () => (
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Field component={FormDatePicker} label="Program Start Date" name="value.programStartDate" fullWidth />
    </Grid>
    <Grid item xs={6}>
      <Field component={CustomTextField} label="Source" name="value.source" fullWidth />
    </Grid>
  </Grid>
)

export default ParticipantAcceptedFields
