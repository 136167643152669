import * as React from 'react'
import moment from 'moment'
import { formatRelativeTime } from 'src/date'
import { Divider } from 'src/timeline'
import { GetFeedItemsQuery } from './feedQueries.generated'
import { FeedItem } from './feedItemTypes'
import ChatMessageItem from './ChatMessageItem/ChatMessageItem'
import ResponseItem from './ResponseItem/ResponseItem'
import SmsMessageItem from './ChatMessageItem/SmsMessageItem'
import VideoCallItem from './VideoCallItem/VideoCallItem'

type LastSeenAt = GetFeedItemsQuery['feed']['lastSeenAt']

type FeedItemsProps = {
  items: FeedItem[]
  hasOlder: boolean
  lastSeenAt: LastSeenAt
}

const isNewItem = (item: FeedItem, lastSeenAt: LastSeenAt) => {
  if (!lastSeenAt) {
    return false
  }
  if (item.__typename === 'ChatMessage' && item.chatMessageDirection === 'TO_PATIENT') {
    return false
  }
  if (item.__typename === 'SmsMessage' && item.smsMessageDirection === 'TO_PATIENT') {
    return false
  }
  return new Date(lastSeenAt) < new Date(item.createdAt)
}

export default function FeedItems({ items, lastSeenAt, hasOlder }: FeedItemsProps) {
  let lastRelativeTime: string | undefined
  let newIndicatorIsShown = false
  return (
    <div role="feed" aria-live="polite" aria-atomic="false" aria-relevant="additions" aria-label="Feed">
      {items.map((item, index) => {
        if (!item.itemID) {
          return null
        }
        const relativeTime = formatRelativeTime(moment(item.createdAt))
        const relativeTimeHasChanged = relativeTime !== lastRelativeTime
        lastRelativeTime = relativeTime

        const isFirstNewItem = !newIndicatorIsShown && isNewItem(item, lastSeenAt)
        newIndicatorIsShown = newIndicatorIsShown || isFirstNewItem
        const showDivider = relativeTimeHasChanged || isFirstNewItem
        const isOnTop = hasOlder && index === 0

        return (
          <React.Fragment key={item.itemID}>
            {showDivider && (
              <Divider showNewIndicator={isFirstNewItem} isOnTop={isOnTop} isHighlighted={isFirstNewItem && !isOnTop}>
                {relativeTimeHasChanged ? relativeTime : undefined}
              </Divider>
            )}
            <div role="article" aria-setsize={-1}>
              <Item item={item} />
            </div>
          </React.Fragment>
        )
      })}
    </div>
  )
}

function Item({ item }: { item: FeedItem }) {
  switch (item.__typename) {
    case 'ChatMessage':
      return <ChatMessageItem chatMessage={item} />
    case 'SmsMessage':
      return <SmsMessageItem smsMessage={item} />
    case 'PatientResponse':
      return <ResponseItem displayedTime={item.createdAt} participantResponse={item} />
    case 'ResumedPatientResponse':
      return <ResponseItem isResumed displayedTime={item.createdAt} participantResponse={item.resumedResponse} />
    case 'VideoCall':
      return <VideoCallItem videoCall={item} />
    default:
      return null
  }
}
