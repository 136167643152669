import _ from 'lodash'
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Toolbar } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const SelectableItemsContext = createContext(null)

export const SelectableItems = ({ children }) => {
  const [selected, setSelected] = useState([])
  const value = { selected, setSelected }
  return <SelectableItemsContext.Provider value={value}>{children}</SelectableItemsContext.Provider>
}

SelectableItems.propTypes = {
  children: PropTypes.node.isRequired,
}

export const SelectableItemCheckbox = ({ value }) => {
  const { selected, setSelected } = useContext(SelectableItemsContext)
  const isChecked = _.includes(selected, value)
  const onChange = (event) => {
    if (event.target.checked) {
      setSelected([...selected, value])
    } else {
      setSelected(_.without(selected, value))
    }
  }
  return <Checkbox checked={isChecked} color="primary" onChange={onChange} />
}

SelectableItemCheckbox.propTypes = {
  value: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
}))

export const SelectableItemsToolbar = ({ children, filter }) => {
  const classes = useStyles()
  const { selected, setSelected } = useContext(SelectableItemsContext)
  const clearSelection = useCallback(() => setSelected([]), [setSelected])
  useEffect(() => clearSelection(), [clearSelection, filter])
  if (selected.length === 0) {
    return null
  }

  return (
    <Toolbar classes={classes} variant="dense">
      {children({ clearSelection, selected })}
    </Toolbar>
  )
}

SelectableItemsToolbar.propTypes = {
  children: PropTypes.func.isRequired,
  filter: PropTypes.shape({}),
}
