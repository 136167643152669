import { useFirebase, isSuccessWithData } from 'src/firebase'
import { trackEnableOrDisableInProgramCallBooking } from 'src/mixpanel'
import { useSession } from 'src/session'

export default function useTrackToogleBookingEnabledMixpanelEvent() {
  const coachProfile = useLoadCurrentCoachProfile()

  return (action: 'enable' | 'disable') => {
    if (!coachProfile) {
      return
    }

    const { email, firstname, lastname } = coachProfile
    trackEnableOrDisableInProgramCallBooking({ action, email, name: `${firstname} ${lastname}` })
  }
}

type CoachProfile = {
  id: string
  firstname: string
  lastname: string
  email: string
}
function useLoadCurrentCoachProfile() {
  const { uid } = useSession()
  const request = useFirebase<CoachProfile>(`coachProfiles/${uid}`)

  return isSuccessWithData(request) ? request.data : null
}
