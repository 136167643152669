import { Button } from '@mui/material'
import React from 'react'
import { useToasts } from 'react-toast-notifications'
import { CustomTooltip } from 'src/designSystem'
import { VideoCallException } from 'src/graphql.generated'
import { GetPatientHistoryAndProgramStartDateDocument } from 'src/participantHistory/patientHistoryQueries.generated'
import { GetParticipantVideoCallBookingInformationDocument } from '../../videoCallBookingHeaderQueries.generated'
import { useSubmitCallNotesMutation } from './callNotesMutations.generated'
import type { CallExceptionOptionValue } from '../CallExceptionSelect/CallExceptionSelect'

// Therapist can submit these types of note even before the call starts
const TIME_INVARIANT_CALL_EXCEPTIONS: Set<CallExceptionOptionValue | null | undefined> = new Set([
  VideoCallException.LateCancellation,
  VideoCallException.ProviderIssue,
  VideoCallException.OtherException,
])

const CallNotesSubmitButton = ({
  callID,
  callException,
  callNotes,
  startTime,
}: {
  callID: string
  callException?: CallExceptionOptionValue | null
  callNotes: string
  startTime: Date
}) => {
  const [submitCallNotes, { loading: loadingOfSubmitCallNotes }] = useSubmitCallNotesMutation({
    refetchQueries: [GetParticipantVideoCallBookingInformationDocument, GetPatientHistoryAndProgramStartDateDocument],
  })

  const { addToast } = useToasts()

  const now = new Date()
  const callHasStarted = startTime <= now
  const canSubmit = callHasStarted || TIME_INVARIANT_CALL_EXCEPTIONS.has(callException)
  const isDisabled = loadingOfSubmitCallNotes || !callNotes || !canSubmit

  const button = (
    <Button
      type="button"
      color="primary"
      variant="contained"
      onClick={async () => {
        try {
          await submitCallNotes({ variables: { callID, notes: callNotes } })
          addToast('Successfully submitted call notes and created a patient history entry', {
            appearance: 'success',
          })
        } catch (e) {
          addToast('Failed to submit call notes and create a patient history entry', {
            appearance: 'error',
          })
        }
      }}
      disabled={isDisabled}
    >
      Submit
    </Button>
  )

  return !canSubmit ? (
    <CustomTooltip title="Notes can't be submitted before the call has started">
      <span>{button}</span>
    </CustomTooltip>
  ) : (
    button
  )
}

export default CallNotesSubmitButton
