import { ListItem, ListItemText, Typography, Collapse, List } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import React, { FC, useState } from 'react'
import ParticipantListItem from './ParticipantListItem/ParticipantListItem'
import { HomeViewParticipantFragment } from './searchParticipants.generated'

export const Participants: FC<{
  participants: HomeViewParticipantFragment[]
  label: string
  selectedUid?: string
}> = ({ participants, label, selectedUid }) => {
  const [isExpand, setIsExpand] = useState<boolean>(true)
  return (
    <>
      <ListItem button onClick={() => setIsExpand(!isExpand)}>
        <ListItemText primary={<Typography variant="h3">{label}</Typography>} />
        {isExpand ? <ExpandLess titleAccess="collapse" /> : <ExpandMore titleAccess="expand" />}
      </ListItem>
      <Collapse in={isExpand} timeout="auto" unmountOnExit>
        <List disablePadding>
          {participants.map(({ uid, firstname, lastname, indicators, tags }) => (
            <ParticipantListItem
              key={uid}
              active={selectedUid === uid}
              uid={uid}
              firstname={firstname}
              lastname={lastname}
              indicators={indicators}
              tags={tags}
            />
          ))}
        </List>
      </Collapse>
    </>
  )
}
