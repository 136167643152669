import { ApolloError, QueryResult } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import moment from 'moment'
import { useMemo } from 'react'
import { fromJSDate } from 'src/date/DateString'
import { FirebaseRequest, isSuccess, useFirebase } from 'src/firebase'
import {
  CareTeamMember,
  EnrollmentState,
  JSONObject,
  PatientCompletedResponsesIndicator,
  PatientEngagementIndicator,
  VideoCall,
} from 'src/graphql.generated'
import { PatientHistoryType, Program } from 'src/legacy/models/firebase'
import {
  GetFullPatientQuery,
  GetFullPatientQueryVariables,
  GetPatientWithIndicatorsQuery,
  GetPatientWithIndicatorsQueryVariables,
  useGetFullPatientQuery,
  useGetPatientWithIndicatorsQuery,
} from 'src/participant/patientQueries.generated'

const NOW = moment().format('YYYY-MM-DD')

// Get the end date range for the completed responses.
// If the program is still ongoing, fetch data until today
// If the program has ended, fetch data until the last day of the program
const getResponsesCountEndDate = (lastWeekNbr: number, programStartDate?: Maybe<Date>) => {
  if (!programStartDate) {
    return NOW
  }
  const programEndDate = moment(programStartDate).add(lastWeekNbr, 'weeks')
  const currentDate = moment()
  return moment.min(currentDate, programEndDate).format('YYYY-MM-DD')
}

const getResponsesCountStartDate = (programStartDate?: Maybe<Date>) => {
  if (!programStartDate) return NOW
  return moment(programStartDate).format('YYYY-MM-DD')
}

const getAccessCode = (participantDetails: JSONObject | null | undefined): string => {
  const accessCode = participantDetails?.accessCode
  if (!accessCode) return 'N/A'

  if (typeof accessCode === 'string') return accessCode
  if (typeof accessCode === 'number') return accessCode.toString()

  return 'unknown'
}

type ParticipantIndicators = {
  completedResponses: Array<PatientCompletedResponsesIndicator>
  engagement?: PatientEngagementIndicator | null
}

export type ParticipantInfoData = {
  acceptanceCriteria?: Array<PatientHistoryType>
  accessCode?: string
  careTeam?: Array<CareTeamMember> | null
  firstname?: string | null
  firstTimeLoginAt?: Date | null
  isHrvProgram?: boolean
  hrvDevice?: string | null
  lastname?: string | null
  lastWeekNbr: number
  programID?: string
  preferredName?: string | null
  programStartDate?: string
  programEndMetrics?: Array<PatientHistoryType>
  indicators: ParticipantIndicators
  state?: EnrollmentState
  timezone?: string | null
  createdAt?: Date | null
  intakeCallTime?: Date | null
  videoCalls?: VideoCall[] | null
  loading: boolean
  stateOfResidence?: string | null
  birthDate?: string | null
  error?: ApolloError
}

const composeParticipantInfo = (
  fullPatientRequest: QueryResult<GetFullPatientQuery, GetFullPatientQueryVariables>,
  programRequest: FirebaseRequest<Program>,
  patientWithIndicatorRequest: QueryResult<GetPatientWithIndicatorsQuery, GetPatientWithIndicatorsQueryVariables>,
  lastWeekNbr: number
) => {
  const {
    birthDate,
    careTeam,
    enrollment,
    privateUserData,
    firstname,
    lastname,
    preferredName,
    intakeCallTime,
    createdAt,
    stateOfResidence,
    details,
  } = fullPatientRequest.data?.patient ?? {}

  const { firstTimeLoginAt, timezone } = privateUserData ?? {}
  const birthDateString = birthDate ? fromJSDate(birthDate) : undefined
  const { state, programID, programStartDate } = enrollment ?? {}
  const { acceptanceCriteria, isHrvProgram, programEndMetrics } = programRequest.data ?? {}
  const { indicators } = patientWithIndicatorRequest.data?.patients?.[0] || {}
  const loading = fullPatientRequest.loading || patientWithIndicatorRequest.loading || !isSuccess(programRequest)
  const { error } = fullPatientRequest

  return {
    lastWeekNbr,
    indicators: {
      completedResponses: indicators?.completedResponses || [],
      engagement: indicators?.engagement,
    },
    firstname,
    lastname,
    preferredName,
    acceptanceCriteria,
    accessCode: !error ? getAccessCode(details) : undefined,
    isHrvProgram,
    programEndMetrics,
    programStartDate: programStartDate ? moment(programStartDate).format('YYYY-MM-DD') : undefined,
    state,
    timezone,
    createdAt,
    firstTimeLoginAt,
    careTeam,
    programID,
    loading,
    intakeCallTime,
    stateOfResidence,
    birthDate: birthDateString,
    hrvDevice: privateUserData?.variables?.hrvDevice,
    error,
  }
}

const useLoadParticipantInfoData = (uid: string): ParticipantInfoData => {
  const fullPatientRequest = useGetFullPatientQuery({ variables: { uid } })
  const programID = fullPatientRequest.data?.patient?.enrollment?.programID
  const programStartDate = fullPatientRequest.data?.patient?.enrollment?.programStartDate

  const programRequest = useFirebase<Program>(programID ? `programs/${programID}` : null)
  const lastWeekNbr = programRequest.data?.lastWeekNbr ?? 8
  const responsesCountEnd = getResponsesCountEndDate(lastWeekNbr, programStartDate)
  const responsesCountStart = getResponsesCountStartDate(programStartDate)

  const patientWithIndicatorRequest = useGetPatientWithIndicatorsQuery({
    skip: fullPatientRequest.loading,
    variables: {
      uid,
      includeIndicators: Boolean(programStartDate),
      responsesCountStart,
      responsesCountEnd,
    },
  })

  return useMemo(
    () => composeParticipantInfo(fullPatientRequest, programRequest, patientWithIndicatorRequest, lastWeekNbr),
    [fullPatientRequest, programRequest, patientWithIndicatorRequest, lastWeekNbr]
  )
}

export default useLoadParticipantInfoData
