import * as Yup from 'yup'

Yup.addMethod<Yup.NumberSchema>(Yup.number, 'decimalDigitsMaxTwo', function validate() {
  return this.test('Input decimal digits test', 'The input allows maximum two decimal digits', (value) => {
    if (value === null || value === undefined) {
      return true
    }

    const numberWithOptionalTwoDecimalPoints = /^\d+((\.|,)\d{0,2})?$/
    const valueInString = value.toString()
    const isValid = numberWithOptionalTwoDecimalPoints.test(valueInString)
    return isValid
  })
})
