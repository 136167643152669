import { useEffect, useState } from 'react'

declare global {
  interface Window {
    onGoogleLibraryLoad?: () => void
  }
}

export default function useGoogleSignInClient() {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    script.async = true
    document.body.appendChild(script)
    window.onGoogleLibraryLoad = () => {
      setLoaded(true)
    }
    return () => {
      document.body.removeChild(script)
      delete window.onGoogleLibraryLoad
    }
  }, [])

  return { loaded }
}
