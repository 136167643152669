import React, { useCallback } from 'react'
import { Field } from 'formik'
import { Button, CardContent, MenuItem } from '@mui/material'
import * as Yup from 'yup'

import { REGIONS } from 'src/config'
import { EmailField, ProgramIDField, SourceField } from 'src/legacy/components/fields'
import { CustomTextField, GraphQLForm } from 'src/form'
import { InlineForm } from 'src/legacy/components/mui'
import { useAddPatientMutation } from 'src/legacy/lib/graphql/queries.generated'
import { UpdateQueryOptions } from '@apollo/client'

const ParticipantSchema = Yup.object()
  .shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email().required(),
    source: Yup.string().optional(),
    referralName: Yup.string().required(),
    region: Yup.string()
      .required()
      .default(REGIONS.length === 1 ? REGIONS[0] : null),
    programID: Yup.string().required(),
  })
  .required()

type ParticipantFormData = Yup.InferType<typeof ParticipantSchema>

type Props = {
  onAddParticipantSuccess?: () => void
}

const ParticipantForm = ({ onAddParticipantSuccess }: Props) => {
  const [addPatientMutation] = useAddPatientMutation({ onCompleted: onAddParticipantSuccess })

  const handleSubmit = useCallback(
    async (opts: UpdateQueryOptions<ParticipantFormData>): Promise<void> => {
      const { variables } = opts!
      await addPatientMutation({
        variables,
      })
    },
    [addPatientMutation]
  )

  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      ignoredErrors={['DUPLICATE_KEY']}
      validationSchema={ParticipantSchema}
      render={({ isSubmitting }) => (
        <CardContent>
          <InlineForm>
            <Field component={CustomTextField} fullWidth label="First Name" name="firstname" />
            <Field component={CustomTextField} fullWidth label="Last Name" name="lastname" />
            <EmailField />
            <SourceField />
            <Field
              component={CustomTextField}
              fullWidth
              label="Referring HCP"
              name="referralName"
              helperText="Used in welcome email"
            />
            {REGIONS.length > 1 && (
              <Field component={CustomTextField} fullWidth label="Region" name="region" type="select">
                {REGIONS.map((region) => (
                  <MenuItem key={region} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Field>
            )}
            <ProgramIDField />
            <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
              Invite
            </Button>
          </InlineForm>
        </CardContent>
      )}
    />
  )
}

export default ParticipantForm
