import React, { useState } from 'react'
import { Box, Menu, IconButton } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type MessageMenuProps = {
  children: React.ReactNode
}

export default function MessageMenu({ children }: MessageMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  return (
    <Box alignSelf="center">
      <IconButton
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget)
        }}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
        {children}
      </Menu>
    </Box>
  )
}
