import _ from 'lodash'
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'

import { SourceField } from 'src/legacy/components/fields'
import { useGetPatientQuery } from '../../patientQueries.generated'

type Props = {
  uid: string
}
const PreselectedSourceField = ({ uid }: Props) => {
  const { setFieldValue } = useFormikContext()

  const { data } = useGetPatientQuery({
    variables: {
      uid,
    },
  })

  useEffect(() => {
    const source = _.get(data, ['patients', 0, 'details', 'source'])
    if (source) {
      setFieldValue('source', source)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return <SourceField />
}

export default PreselectedSourceField
