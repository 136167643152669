import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

const THROTTLE_DELAY = 1000

const useIsUserActiveInTab = (inactivityThresholdMS: number) => {
  const [isActive, setIsActive] = useState(true)

  let timeout: number

  const resetTimeout = () => {
    window.clearTimeout(timeout)
    timeout = window.setTimeout(() => setIsActive(false), inactivityThresholdMS)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleUserInteraction = useCallback(
    _.throttle(() => {
      resetTimeout()
      setIsActive(true)
    }, THROTTLE_DELAY),
    []
  )

  useEffect(() => {
    resetTimeout()

    window.addEventListener('click', handleUserInteraction)
    window.addEventListener('wheel', handleUserInteraction)
    window.addEventListener('keydown', handleUserInteraction)

    return () => {
      window.removeEventListener('click', handleUserInteraction)
      window.removeEventListener('wheel', handleUserInteraction)
      window.removeEventListener('keydown', handleUserInteraction)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isActive
}

export default useIsUserActiveInTab
