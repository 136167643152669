import React from 'react'
import {
  ToastProvider as ToastNotificationsToastProvider,
  ToastProps,
  ToastProviderProps,
  ToastContainerProps,
} from 'react-toast-notifications'
import { Snackbar , Alert } from '@mui/material'


const Toast = ({ appearance = 'error', autoDismissTimeout, children, onDismiss }: ToastProps) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    autoHideDuration={autoDismissTimeout}
    onClose={() => onDismiss()}
    open
  >
    <Alert severity={appearance} elevation={6} onClose={() => onDismiss()}>
      {children}
    </Alert>
  </Snackbar>
)

const ToastContainer = ({ children }: ToastContainerProps) => <div>{children}</div>

export const ToastProvider = ({ children }: ToastProviderProps) => (
  <ToastNotificationsToastProvider autoDismissTimeout={15000} components={{ Toast, ToastContainer }}>
    {children}
  </ToastNotificationsToastProvider>
)
