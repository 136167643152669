import gql from 'graphql-tag'

export const ADD_PATIENT = gql`
  mutation addPatient(
    $email: EmailAddress!
    $firstname: String!
    $lastname: String!
    $programID: String!
    $region: String!
    $source: String
    $referralName: String!
  ) {
    addPatient(
      email: $email
      firstname: $firstname
      lastname: $lastname
      programID: $programID
      region: $region
      source: $source
      referralName: $referralName
    ) {
      uid
    }
  }
`

export const ADD_TEST_USER = gql`
  mutation addTestUser(
    $email: EmailAddress!
    $firstname: String!
    $lastname: String!
    $primaryCoachID: ID!
    $programID: String!
    $programStartDate: String!
  ) {
    addTestUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      primaryCoachID: $primaryCoachID
      programID: $programID
      programStartDate: $programStartDate
    ) {
      uid
    }
  }
`

export const DELETE_PATIENT = gql`
  mutation deletePatient($uid: ID!) {
    deletePatient(uid: $uid) {
      uid
    }
  }
`

export const GET_PREFILLED_INTAKE_NOTE = gql`
  query getPrefilledIntakeNote($uid: ID!) {
    prefilledIntakeNote(uid: $uid) {
      OBSTACLES
      EMPLOYMENT {
        STATUS
      }
      HEALTH_HISTORY {
        BIPOLAR
        DEPRESSION
        DTD
        GENERALISED_ANXIETY
        PSYCHOSIS
        PTSD
        SUBSTANCE_ABUSE
        SUICIDALITY
        THERAPY
      }
    }
  }
`

export const GET_PATIENTS = gql`
  query getPatients($uids: [ID!]!) {
    patients(uids: $uids) {
      details
      firstname
      lastname
      uid
    }
  }
`

export const GET_PATIENT_HISTORY = gql`
  query getPatientHistory($uid: ID!) {
    patientHistory(uid: $uid, orderBy: date_DESC) {
      __typename
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`

export const GET_PATIENT_HISTORY_VALUES = gql`
  query getPatientHistoryValues(
    $uid: ID!
    $type: PatientHistoryType!
    $orderBy: PatientHistoryOrderByInput = date_ASC
  ) {
    patientHistory(uid: $uid, type: $type, orderBy: $orderBy) {
      __typename
      entryID
      date
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`

export const SET_CARETEAM = gql`
  mutation setCareteam($uid: ID!, $coachIDs: [ID!]!, $primaryCoachID: ID!) {
    setCareteam(uid: $uid, coachIDs: $coachIDs, primaryCoachID: $primaryCoachID) {
      uid
    }
  }
`
