import React from 'react'
import { ListItem, ListItemText, Grid, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalOffer } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Patient } from 'src/graphql.generated'
import { CustomTooltip } from 'src/designSystem'
import Indicators from './Indicators'
import RecentResponses from './RecentResponses'
import DraftMessageIndicator from './DraftMessageIndicator'

type Tag = {
  name: string
}

type Props = {
  uid: string
  active: boolean
  firstname?: string | null
  lastname?: string | null
  indicators?: Patient['indicators']
  tags?: Array<Tag> | null
}

const useStyles = makeStyles((theme) => ({
  inset: {
    paddingLeft: theme.spacing(2),
  },
  root: {
    display: 'flex',
  },
  leftmostGridItem: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
  },
}))

const Ellipsis: React.FC = (props) => <Box overflow="hidden" textOverflow="ellipsis" {...props} />

export default function ParticipantListItem(props: Props) {
  const { uid, active, firstname, lastname, indicators, tags } = props

  const classes = useStyles()

  return (
    <ListItem button component={Link} selected={active} to={`/participant/${uid}`}>
      <Grid container alignItems="center">
        <Grid classes={{ root: classes.leftmostGridItem }} item xs={1}>
          {tags && tags.length > 0 && (
            <CustomTooltip placement="right-end" title={tags.map((tag) => tag.name).join(', ')}>
              <LocalOffer fontSize="small" titleAccess="participant tags" />
            </CustomTooltip>
          )}
        </Grid>

        <Grid item xs={5}>
          <ListItemText
            classes={{ root: classes.root, inset: classes.inset }}
            inset
            primary={`${firstname} ${lastname}`}
            primaryTypographyProps={{
              component: Ellipsis,
            }}
          />
        </Grid>

        <Grid classes={{ root: classes.root }} item xs={1}>
          <DraftMessageIndicator uid={uid} />
        </Grid>

        <Grid classes={{ root: classes.root }} item xs={3}>
          <RecentResponses responses={indicators?.completedResponses || []} />
        </Grid>

        <Indicators indicators={indicators} />
      </Grid>
    </ListItem>
  )
}
