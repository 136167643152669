import React from 'react'
import { Box, Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { Container } from 'src/designSystem'
import ParticipantList from 'src/participantsHome/ParticipantList'
import { RouteComponentProps } from 'react-router-dom'
import ParticipantInfo from '../components/ParticipantInfo'
import Participant from './Participant'

export type Props = RouteComponentProps<{ uid?: string }>

const useStyles = makeStyles({
  wrappedText: { maxWidth: '300px' },
})

const MainView: React.FunctionComponent<Props> = ({ match }) => {
  const classes = useStyles()
  const { uid } = match.params

  const SideBar = <ParticipantList selectedUid={uid} />

  return (
    <Container sidebar={SideBar} infoarea={uid ? <ParticipantInfo uid={uid} /> : undefined} forceShowSidebar={!uid}>
      {!uid && (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Typography classes={{ root: classes.wrappedText }} align="center" variant="h3">
            Select a participant to view their records, chat messages and other activities
          </Typography>
        </Box>
      )}
      {uid && (
        // @ts-expect-error typescript thinks uid might be undefined but we not it is not
        <Participant match={match} uid={uid} />
      )}
    </Container>
  )
}

export default MainView
