import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import { CustomRadio, CustomRadioGroup, FormDatePicker } from 'src/form'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import _ from 'lodash'
import { Box } from '@mui/material'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import SubmitButton from './SubmitButton'

const MISSED_INTAKE_CALL_REASONS = {
  PATIENT_DID_NOT_SHOW_UP: 'Participant did not show up',
  PATIENT_CANCELLED: 'Participant cancelled < 24h before',
} as const

const MISSED_INTAKE_CALL_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    reason: Yup.string().required(),
  })
  .required()

type MissedIntakeCallFormType = Yup.InferType<typeof MISSED_INTAKE_CALL_FORM_SCHEMA>

const MissedIntakeCallForm = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<MissedIntakeCallFormType>
      validationSchema={MISSED_INTAKE_CALL_FORM_SCHEMA}
      initialValues={MISSED_INTAKE_CALL_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.MissedIntakeCall,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <Field component={FormDatePicker} label="Date of event" name="date" />
          </Box>
          <Box>
            <FastField component={CustomRadioGroup} name="reason" type="string">
              {_.map(MISSED_INTAKE_CALL_REASONS, (label, value) => (
                <CustomRadio key={value} label={label} value={value} />
              ))}
            </FastField>
          </Box>
          <Box>
            <SubmitButton isSubmitting={isSubmitting} />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default MissedIntakeCallForm
