import { Box } from '@mui/material'
import React from 'react'

type Props = {
  children: React.ReactNode
  className?: string
}

export default function ReplyItem({ children, className }: Props) {
  return (
    <Box py={3} className={className}>
      {children}
    </Box>
  )
}
