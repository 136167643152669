import React, { useState } from 'react'
import { Dialog } from '@mui/material'
import { SchedulingView } from './types'
import OptionsView from './OptionsView'
import CancelView from './CancelView'
import RescheduleView from './RescheduleView'
import { CallBookingVideoCallFragment } from '../../videoCallBookingHeaderQueries.generated'

type Props = {
  videoCall: CallBookingVideoCallFragment
  uid: string
  open: boolean
  onClose: () => void
}

const ModifyInProgramCallModal = ({ onClose, open, videoCall, uid }: Props) => {
  const [schedulingView, setSchedulingView] = useState<SchedulingView | null>(null)

  const resetSchedulingView = () => {
    setSchedulingView(null)
  }

  const handleClose = () => {
    onClose()
    resetSchedulingView()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="xs">
      {!schedulingView && <OptionsView onClose={handleClose} onConfirmSchedulingView={setSchedulingView} />}
      {schedulingView === SchedulingView.Cancel && (
        <CancelView videoCall={videoCall} onClose={handleClose} onBack={resetSchedulingView} />
      )}
      {schedulingView === SchedulingView.Reschedule && (
        <RescheduleView onClose={handleClose} onBack={resetSchedulingView} uid={uid} videoCall={videoCall} />
      )}
    </Dialog>
  )
}

export default ModifyInProgramCallModal
