import { Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import { VIDEO_CALL_REASON_LABELS } from 'src/videoCall'
import { PatientHistoryEntry_VideoCallPatientHistory_Fragment as PatientHistoryEntryVideoCallPatientHistoryFragment } from './patientHistoryQueries.generated'

const useStyles = makeStyles((theme) => ({
  fieldValue: { color: theme.palette.text.disabled, fontStyle: 'italic' },
}))

const getMinutesFromMillis = (activeDurationInMS?: number | null) =>
  activeDurationInMS ? Math.floor(activeDurationInMS / 1000 / 60) : 0

const VideoCallPatientHistoryFields = ({
  patientHistoryEntry,
}: {
  patientHistoryEntry: PatientHistoryEntryVideoCallPatientHistoryFragment
}) => {
  const classes = useStyles()

  const { videoCall, value } = patientHistoryEntry

  const callParticipants = videoCall.numberOfParticipants ?? 'N/A'

  const callDuration = getMinutesFromMillis(videoCall.activeDurationInMS)
  const callReason =
    videoCall.reason && VIDEO_CALL_REASON_LABELS[videoCall.reason]
      ? VIDEO_CALL_REASON_LABELS[videoCall.reason]
      : 'UNKNOWN'
  const callNotes = value && value.description ? value.description : 'N/A'

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Typography variant="body1">Participants</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography classes={{ root: classes.fieldValue }} variant="body1">
          {callParticipants}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body1">Call Duration</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography classes={{ root: classes.fieldValue }} variant="body1">
          {callDuration} minutes
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body1">Reason</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography classes={{ root: classes.fieldValue }} variant="body1">
          {callReason}
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography variant="body1">Call notes</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography classes={{ root: classes.fieldValue }} variant="body1">
          {callNotes}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default VideoCallPatientHistoryFields
