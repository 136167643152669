import React from 'react'
import { Checkbox, MenuItem, TextField } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

export type Props<T> = {
  selected: Array<string>
  values: Array<T>
  label: string
  name: string
  onSelect: (selectedIDs: Array<string>) => void
  identifier: (value: T) => string
  renderLabel: (value: T) => string
}

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    marginTop: 0,
  },
  label: {
    color: theme.palette.grey[500],
  },
}))

function MultiSelectField<T>(props: Props<T>) {
  const { selected, onSelect, renderLabel, values, identifier, label, name } = props

  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onSelect(event.target.value as unknown as Array<string>)
  }

  const classes = useStyles(props)

  return (
    <TextField
      fullWidth
      classes={{
        root: classes.selectRoot,
      }}
      SelectProps={{
        multiple: true,
        renderValue: () => `${selected.length} selected`,
      }}
      InputLabelProps={{
        className: classes.label,
      }}
      label={label}
      name={name}
      onChange={handleSelect}
      select
      size="small"
      variant="outlined"
      value={selected}
    >
      {values.map((value) => {
        const id = identifier(value)
        return (
          <MenuItem key={id} value={id}>
            <Checkbox checked={selected.includes(id)} color="primary" />
            {renderLabel(value)}
          </MenuItem>
        )
      })}
    </TextField>
  )
}

export default MultiSelectField
