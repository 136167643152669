import _ from 'lodash'
import { useCallback, useEffect, useState } from 'react'

const DEBOUNCE_DELAY = 200

const useIsTabActive = () => {
  const [isFocused, setIsFocused] = useState(true)
  const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleFocusAndBlur = useCallback(
    _.debounce((e?: { type: string }) => {
      switch (e?.type) {
        case 'focus':
          setIsFocused(true)
          break
        case 'blur':
          setIsFocused(false)
          break
        default:
          break
      }
    }, DEBOUNCE_DELAY),
    []
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleVisibilityChange = useCallback(
    _.debounce(() => setIsVisible(document.visibilityState === 'visible'), DEBOUNCE_DELAY),
    []
  )

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('blur', handleFocusAndBlur)
    window.addEventListener('focus', handleFocusAndBlur)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('blur', handleFocusAndBlur)
      window.removeEventListener('focus', handleFocusAndBlur)
    }
  })

  return isFocused && isVisible
}

export default useIsTabActive
