/* eslint-disable max-len */
import React from 'react'
import PropTypes from 'prop-types'
import { SvgIcon, useTheme } from '@mui/material'

// Steamline Icons 2.0
// https://drive.google.com/drive/u/1/folders/1vl2ab02FiUcpqiIWojEl59-qXyERWY7d
// Use outline version and <title> as icon name

const StreamlineIcon = ({ children, color, height, style, width, ...rest }) => (
  <SvgIcon style={{ color, height, verticalAlign: 'middle', width, ...style }} viewBox="0 0 24 24" {...rest}>
    {children}
  </SvgIcon>
)

export const CheckIconBold = (props) => {
  const theme = useTheme()
  return (
    <StreamlineIcon color={theme.palette.primary.main} {...props}>
      <path d="M23.374.287a1.5,1.5,0,0,0-2.093.345L7.246,20.2,2.561,15.511A1.5,1.5,0,1,0,.439,17.632l5.935,5.934a1.525,1.525,0,0,0,2.279-.186l15.066-21A1.5,1.5,0,0,0,23.374.287Z" />
    </StreamlineIcon>
  )
}

export const CloseIconBold = (props) => {
  const theme = useTheme()
  return (
    <StreamlineIcon color={theme.palette.error.main} {...props}>
      <path d="M14.3,12.179a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.442L12.177,9.7a.25.25,0,0,1-.354,0L2.561.442A1.5,1.5,0,0,0,.439,2.563L9.7,11.825a.25.25,0,0,1,0,.354L.439,21.442a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,0,0,2.122-2.121Z" />
    </StreamlineIcon>
  )
}

export const ConversationChatIcon = (props) => (
  <StreamlineIcon {...props}>
    <path
      d="M5.25,13.5c-0.099,0-0.196-0.019-0.287-0.057C4.682,13.326,4.5,13.054,4.5,12.75V9H2.25C1.009,9,0,7.991,0,6.75v-4.5
      C0,1.009,1.009,0,2.25,0h19.5C22.991,0,24,1.009,24,2.25v4.5C24,7.991,22.991,9,21.75,9H10.061l-4.28,4.28
      C5.639,13.422,5.45,13.5,5.25,13.5z M2.25,1.5C1.836,1.5,1.5,1.836,1.5,2.25v4.5c0,0.414,0.336,0.75,0.75,0.75h3
      C5.664,7.5,6,7.836,6,8.25v2.689l3.22-3.22C9.361,7.578,9.55,7.5,9.75,7.5h12c0.414,0,0.75-0.336,0.75-0.75v-4.5
      c0-0.414-0.336-0.75-0.75-0.75H2.25z"
    />
    <path
      d="M18.75,24c-0.2,0-0.389-0.078-0.53-0.22l-4.28-4.28H2.25C1.009,19.5,0,18.491,0,17.25v-4.5c0-1.241,1.009-2.25,2.25-2.25
      C2.664,10.5,3,10.836,3,11.25S2.664,12,2.25,12S1.5,12.336,1.5,12.75v4.5C1.5,17.664,1.836,18,2.25,18h12
      c0.198,0,0.391,0.08,0.53,0.22l3.22,3.22V18.75c0-0.414,0.336-0.75,0.75-0.75h3c0.414,0,0.75-0.336,0.75-0.75v-4.5
      c0-0.414-0.336-0.75-0.75-0.75h-10.5c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h10.5c1.241,0,2.25,1.009,2.25,2.25v4.5
      c0,1.241-1.009,2.25-2.25,2.25H19.5v3.75c0,0.304-0.182,0.576-0.463,0.693C18.946,23.981,18.849,24,18.75,24z"
    />
  </StreamlineIcon>
)

export const ConversationChatTextIcon = (props) => (
  <StreamlineIcon {...props}>
    <path
      d="M5.25,21c-0.116,0-0.232-0.027-0.336-0.079C4.659,20.793,4.5,20.536,4.5,20.25V16.5H2.25C1.009,16.5,0,15.491,0,14.25v-12
      C0,1.009,1.009,0,2.25,0h16.5C19.991,0,21,1.009,21,2.25v4.5c0,0.414-0.336,0.75-0.75,0.75S19.5,7.164,19.5,6.75v-4.5
      c0-0.414-0.336-0.75-0.75-0.75H2.25C1.836,1.5,1.5,1.836,1.5,2.25v12C1.5,14.664,1.836,15,2.25,15h3C5.664,15,6,15.336,6,15.75v3
      l1.8-1.35c0.131-0.098,0.286-0.15,0.45-0.15c0.235,0,0.459,0.112,0.6,0.299c0.248,0.331,0.181,0.802-0.15,1.051l-3,2.25
      C5.571,20.947,5.411,21,5.25,21z"
    />
    <path d="M5.25,6C4.836,6,4.5,5.664,4.5,5.25S4.836,4.5,5.25,4.5h10.5c0.414,0,0.75,0.336,0.75,0.75S16.164,6,15.75,6H5.25z" />
    <path d="M5.25,10.5c-0.414,0-0.75-0.336-0.75-0.75S4.836,9,5.25,9h3C8.664,9,9,9.336,9,9.75S8.664,10.5,8.25,10.5H5.25z" />
    <path
      d="M20.25,24c-0.2,0-0.389-0.078-0.53-0.22l-4.28-4.28H11.25c-0.414,0-0.75-0.336-0.75-0.75v-9C10.5,9.336,10.836,9,11.25,9
      h12C23.664,9,24,9.336,24,9.75v9c0,0.414-0.336,0.75-0.75,0.75H21v3.75c0,0.304-0.182,0.576-0.463,0.693
      C20.446,23.981,20.349,24,20.25,24z M15.75,18c0.198,0,0.391,0.08,0.53,0.22l3.22,3.22V18.75c0-0.414,0.336-0.75,0.75-0.75h2.25
      v-7.5H12V18H15.75z"
    />
    <path
      d="M14.25,13.5c-0.414,0-0.75-0.336-0.75-0.75S13.836,12,14.25,12h6c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75
      H14.25z"
    />
    <path
      d="M14.25,16.5c-0.414,0-0.75-0.336-0.75-0.75S13.836,15,14.25,15h6c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75
      H14.25z"
    />
  </StreamlineIcon>
)

export const EnvelopeIcon = (props) => (
  <StreamlineIcon {...props}>
    <path
      d="M2.25,20.25C1.009,20.25,0,19.241,0,18V6c0-0.537,0.195-1.059,0.548-1.468c0.013-0.016,0.031-0.037,0.051-0.056
      C1.025,4.015,1.626,3.75,2.25,3.75h19.5c0.625,0,1.226,0.265,1.652,0.727c0.018,0.018,0.035,0.037,0.051,0.057
      C23.804,4.938,24,5.461,24,6v12c0,1.241-1.009,2.25-2.25,2.25H2.25z M1.5,18c0,0.414,0.336,0.75,0.75,0.75h19.5
      c0.414,0,0.75-0.336,0.75-0.75V6.187l-5.616,3.873l3.125,2.889c0.304,0.281,0.322,0.756,0.042,1.06
      c-0.142,0.153-0.343,0.241-0.551,0.241c-0.189,0-0.37-0.071-0.509-0.199l-3.373-3.118l-1.825,1.259
      c-0.526,0.364-1.146,0.556-1.793,0.556c-0.646,0-1.266-0.192-1.793-0.556l-1.825-1.258l-3.373,3.118
      C4.87,14.179,4.689,14.25,4.5,14.25c-0.208,0-0.409-0.088-0.551-0.24c-0.281-0.304-0.262-0.78,0.041-1.06l3.125-2.889L1.5,6.187V18
      z M11.059,10.957c0.276,0.191,0.601,0.291,0.941,0.291c0.34,0,0.665-0.101,0.941-0.291l8.276-5.707H2.783L11.059,10.957z"
    />
  </StreamlineIcon>
)

export const MedicalNotesIcon = (props) => (
  <StreamlineIcon {...props}>
    <path
      d="M4.557,24.004c-1.236,0-2.249-0.974-2.306-2.218L2.25,5.252c0.057-1.27,1.078-2.251,2.325-2.251
      c0.03,0,0.06,0.001,0.091,0.002L6.75,3.002c0-0.827,0.673-1.5,1.5-1.5h0.762c0.306-0.41,0.696-0.756,1.139-1.009
      c0.561-0.321,1.198-0.49,1.842-0.49c1.189,0,2.293,0.557,2.994,1.499h0.762c0.827,0,1.5,0.673,1.5,1.5h2.114
      C19.381,3.001,19.413,3,19.445,3c1.235,0,2.247,0.974,2.304,2.218l0.001,16.534c-0.057,1.27-1.08,2.251-2.329,2.251
      c0,0-0.057,0-0.085-0.001l-14.699,0C4.619,24.003,4.588,24.004,4.557,24.004z M4.585,4.501c-0.449,0-0.816,0.345-0.836,0.785
      L3.75,21.752c0.019,0.406,0.386,0.751,0.816,0.751l14.798-0.001l0.06,0.001c0.444,0,0.807-0.345,0.827-0.786L20.25,5.252
      c-0.019-0.406-0.389-0.751-0.824-0.751L17.25,4.502v1.5c0,0.827-0.673,1.5-1.5,1.5h-7.5c-0.827,0-1.5-0.673-1.5-1.5v-1.5H4.636
      L4.585,4.501z M8.25,6.002h7.5v-3h-1.166c-0.268,0-0.518-0.145-0.651-0.378c-0.395-0.692-1.137-1.122-1.937-1.122
      c-0.384,0-0.765,0.101-1.1,0.293c-0.345,0.197-0.631,0.484-0.828,0.828C9.934,2.857,9.684,3.002,9.416,3.002H8.25V6.002z"
    />
    <path
      d="M11.25,19.502c-0.827,0-1.5-0.673-1.5-1.5v-1.5h-1.5c-0.827,0-1.5-0.673-1.5-1.5v-1.5c0-0.827,0.673-1.5,1.5-1.5h1.5v-1.5
      c0-0.827,0.673-1.5,1.5-1.5h1.5c0.827,0,1.5,0.673,1.5,1.5v1.5h1.5c0.827,0,1.5,0.673,1.5,1.5v1.5c0,0.827-0.673,1.5-1.5,1.5h-1.5
      v1.5c0,0.827-0.673,1.5-1.5,1.5H11.25z M8.25,15.002h2.25c0.414,0,0.75,0.336,0.75,0.75v2.25h1.5v-2.25
      c0-0.414,0.336-0.75,0.75-0.75h2.25v-1.5H13.5c-0.414,0-0.75-0.336-0.75-0.75v-2.25h-1.5v2.25c0,0.414-0.336,0.75-0.75,0.75H8.25
      V15.002z"
    />
  </StreamlineIcon>
)

export const PinIcon = (props) => (
  <StreamlineIcon {...props}>
    <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="m4.949 8.783a1.5 1.5 0 0 0 -.3 2.356l8.213 8.213a1.5 1.5 0 0 0 2.356-.3 7.522 7.522 0 0 0 .81-5.54l6.529-4.155a1.5 1.5 0 0 0 .256-2.326l-5.843-5.843a1.5 1.5 0 0 0 -2.326.255l-4.155 6.53a7.522 7.522 0 0 0 -5.54.81z" />
      <path d="m8.753 15.246-8.003 8.003" />
    </g>
  </StreamlineIcon>
)

export const TeamMeetingIcon = (props) => (
  <StreamlineIcon {...props}>
    <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <circle cx="4.499" cy="17.625" r="2.625" />
      <path d="m8.249 23.25a4.25 4.25 0 0 0 -7.5 0" />
      <circle cx="19.499" cy="17.625" r="2.625" />
      <path d="m23.249 23.25a4.25 4.25 0 0 0 -7.5 0" />
      <circle cx="11.999" cy="3.375" r="2.625" />
      <path d="m15.248 8.25a4.269 4.269 0 0 0 -6.5 0" />
      <path d="m9.05 19.707a8.277 8.277 0 0 0 5.944-.018" />
      <path d="m6.348 6a8.217 8.217 0 0 0 -2.6 6c0 .253.015.5.038.75" />
      <path d="m20.211 12.75c.022-.248.038-.5.038-.75a8.214 8.214 0 0 0 -2.6-6" />
    </g>
  </StreamlineIcon>
)

// Other icons

export const SymptomsDecreaseIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon viewBox="0 0 20 9" {...props}>
      <path
        d="M14.263 5.687L.556 2.014 1.073.082l13.703 3.672.77-2.9 3.64 5.117-5.694 2.615.77-2.9z"
        fill={theme.palette.primary.main}
      />
    </SvgIcon>
  )
}

export const SymptomsNeutralIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon viewBox="0 0 18 9" {...props}>
      <path d="M12.635 5.465H.535v-2h12.096l-.007-3 4.84 4-4.822 4-.007-3z" fill={theme.palette.text.secondary} />
    </SvgIcon>
  )
}

export const SymptomsIncreaseIcon = (props) => {
  const theme = useTheme()
  return (
    <SvgIcon viewBox="0 0 18 12" {...props}>
      <path
        d="M13.665 5.438L.805 11.435l-.846-1.813 12.857-5.995L11.542.91l6.076 1.58-2.68 5.663-1.273-2.716z"
        fill={theme.palette.error.main}
      />
    </SvgIcon>
  )
}

export const ArrowLeft = () => (
  <svg width="14" height="16">
    <path
      d="M12.835 14.727L1.721 8.315l11.114-6.412v12.824z"
      stroke="#C6C6C6"
      strokeWidth="1.17"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export const ArrowLeftActive = () => (
  <svg width="14" height="16">
    <path d="M13.04 15.74V.89L.17 8.315z" fill="#D8D8D8" />
  </svg>
)

export const ArrowRight = () => (
  <svg width="14" height="16">
    <path
      d="M1.135 14.727l11.114-6.412L1.135 1.903v12.824z"
      stroke="#C6C6C6"
      strokeWidth="1.17"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export const ArrowRightActive = () => (
  <svg width="14" height="16">
    <path d="M1.135 14.727l11.114-6.412L1.135 1.903v12.824z" fill="#D8D8D8" stroke="#D8D8D8" strokeWidth="1.17" />
  </svg>
)

const mapIcons = {
  'arrow-right': ArrowRight,
  'arrow-right-active': ArrowRightActive,
  'arrow-left': ArrowLeft,
  'arrow-left-active': ArrowLeftActive,
}

const Icon = ({ name, color, className, width, height, style, ...rest }) => {
  if (!mapIcons[name]) {
    console.error(`Icon with name ${name} not found!`)
    return <div />
  }

  const Component = mapIcons[name]

  const alignStyle = {
    ...style,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  if (rest.onClick) {
    alignStyle.cursor = 'pointer'
  }

  return (
    <div className={className} {...rest} style={alignStyle}>
      <Component color={color || '#000'} width={width} height={height} />
    </div>
  )
}

export const Lock = ({ color = '#383838' }) => (
  <svg width="14" height="19">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-919.000000, -359.000000)" fill={color} fillRule="nonzero">
        <path d="M932.611099,366.125 L931.444432,366.125 L931.444432,364.541654 C931.444432,361.485957 929.002125,359 926,359 C922.997875,359 920.555568,361.485957 920.555568,364.541654 L920.555568,366.125 L919.388901,366.125 C919.173943,366.125 919,366.302049 919,366.520846 L919,376.416691 C919,377.289912 919.69763,378 920.555568,378 L931.444469,378 C932.30237,378 933,377.289912 933,376.416654 L933,366.520846 C933,366.302049 932.826057,366.125 932.611099,366.125 Z M927.122776,374.524127 C927.134483,374.645064 927.100452,374.766443 927.029402,374.857286 C926.958352,374.94809 926.85654,375 926.749982,375 L925.249982,375 C925.143425,375 925.041613,374.94809 924.970563,374.857286 C924.899513,374.766483 924.865446,374.645104 924.877188,374.524127 L925.113753,372.092933 C924.729604,371.773595 924.5,371.268432 924.5,370.714291 C924.5,369.768854 925.17271,369 926,369 C926.82729,369 927.5,369.768814 927.5,370.714291 C927.5,371.268432 927.270396,371.773595 926.886247,372.092933 L927.122776,374.524127 Z M929,366 L923,366 L923,364.33332 C923,362.495352 924.345822,361 926,361 C927.654178,361 929,362.495352 929,364.33332 L929,366 Z" />
      </g>
    </g>
  </svg>
)

Lock.propTypes = {
  color: PropTypes.string,
}

StreamlineIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  children: PropTypes.node,
}

Icon.propTypes = {
  ...StreamlineIcon.propTypes,
  name: PropTypes.string,
  className: PropTypes.string,
}

export default Icon
