import React from 'react'
import { Badge } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SmsMessageDirection, SmsMessageStatus } from 'src/graphql.generated'
import MessageBubble, { PaletteColorKey } from 'src/message/MessageBubble'
import { ItemWithSideInfo } from 'src/timeline'
import { FeedSmsMessageItemFragment } from '../feedQueries.generated'
import { getMessageDisplayingProps } from './getMessageDisplayingProps'
import SmsStatusDisplay from './SmsStatusDisplay'

type Props = {
  smsMessage: FeedSmsMessageItemFragment
}

const timeDisplayedStatuses = [SmsMessageStatus.Delivered, SmsMessageStatus.Received]

export default function SmsMessageItem({ smsMessage }: Props) {
  const { text, createdAt, smsMessageDirection, status } = smsMessage
  const isCareteamMessage = smsMessageDirection === SmsMessageDirection.ToPatient
  const { bubblePaletteColor, direction } = getMessageDisplayingProps(isCareteamMessage)
  const styles = useStyles({ bubblePaletteColor })
  const cleanedText = text.trimLeft()

  const alignment = direction === 'incoming' ? 'left' : 'right'

  return (
    <ItemWithSideInfo
      alignItem={alignment}
      displayedTime={timeDisplayedStatuses.includes(status) ? createdAt : undefined}
      metadata={<SmsStatusDisplay status={status} />}
    >
      <Badge
        className={styles.customBadge}
        badgeContent="sms"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <MessageBubble bubblePaletteColor={bubblePaletteColor}>{cleanedText}</MessageBubble>
      </Badge>
    </ItemWithSideInfo>
  )
}

interface ColorProps {
  bubblePaletteColor: PaletteColorKey
}

const useStyles = makeStyles((theme) => ({
  customBadge: {
    flexShrink: 'inherit',
    '& .MuiBadge-badge': {
      backgroundColor: (props: ColorProps) => theme.palette[props.bubblePaletteColor].contrastText,
      color: (props: ColorProps) => theme.palette[props.bubblePaletteColor].main,
      border: (props: ColorProps) => `2px solid ${theme.palette[props.bubblePaletteColor].main}`,
      height: '18px',
      padding: '0 5px',
      right: '30px',
    },
  },
}))
