import React from 'react'
import * as Yup from 'yup'
import { Field } from 'formik'
import { Autosave, CustomTextField, RequestForm } from 'src/form'
import AutosaveIcon from '../ParticipantInfo/AutosaveIcon'
import { useGetParticipantQuickNoteQuery, useUpdateParticipantQuickNoteMutation } from './quickNoteQueries.generated'

type QuickNoteSchema = {
  uid: string
  quickNote: string
}

const QuickNoteValidationSchema: Yup.ObjectSchema<QuickNoteSchema> = Yup.object({
  uid: Yup.string(),
  quickNote: Yup.string(),
}).required()

type Props = {
  uid: string
}

const QuickNote = ({ uid }: Props) => {
  const { data, loading } = useGetParticipantQuickNoteQuery({ variables: { uid } })
  const [updateParticipantQuickNote] = useUpdateParticipantQuickNoteMutation()

  const quickNote = data?.patient.quickNote ?? ''
  // Having uid in the initial values is necessary to reinitialize the form once the uid prop changes
  const initialValues = { quickNote, uid }

  return (
    <RequestForm
      isLoading={loading}
      resetAfterSuccess={false}
      validationSchema={QuickNoteValidationSchema}
      initialValues={initialValues}
      onSubmit={(values) => updateParticipantQuickNote({ variables: { uid, quickNote: values.quickNote } })}
      render={({ isSubmitting, submitCount }) => {
        const hasSaved = submitCount > 0
        return (
          <>
            <Autosave debounceMs={700} />
            <Field
              component={CustomTextField}
              placeholder="Quick note"
              label="Quick note"
              fullWidth
              multiline
              name="quickNote"
              minRows={4}
            />
            <AutosaveIcon hasSaved={hasSaved} isSaving={isSubmitting} />
          </>
        )
      }}
    />
  )
}

export default QuickNote
