import React, { useState } from 'react'
import { Button, Collapse, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { trackStartCheckInCall } from 'src/mixpanel/api'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import VideoCallBookingInformation from '../VideoCallBookingInformation'
import { InProgramVideoCallFragment } from '../videoCallBookingHeaderQueries.generated'
import JoinCallButton from '../JoinCallButton'
import ModifyInProgramCallModal from './ModifyInProgramCallModal/ModifyInProgramCallModal'

import CallNotesForm from './CallNotesForm/CallNotesForm'
import useIsPrimaryCoachOfPatient from '../useIsPrimaryCoachOfPatient'

type Props = { videoCall: InProgramVideoCallFragment; uid: string }

const InProgramVideoCallBookingInformation = ({ uid, videoCall }: Props) => {
  const [expanded, setExpanded] = useState<boolean>(false)
  const isPrimaryCoachOfPatient = useIsPrimaryCoachOfPatient(uid)
  const styles = useStyles()

  const [modifyModalOpen, setModifyModalOpen] = useState(false)

  const { startTime, callException } = videoCall
  const now = new Date()
  const isUpcomingCall = startTime > now

  return (
    <>
      <ModifyInProgramCallModal
        videoCall={videoCall}
        uid={uid}
        onClose={() => setModifyModalOpen(false)}
        open={modifyModalOpen}
      />
      <VideoCallBookingInformation
        videoCall={videoCall}
        callActions={
          <>
            {isPrimaryCoachOfPatient && (
              <Button
                color="primary"
                variant="outlined"
                className={styles.notesButton}
                onClick={() => setExpanded(!expanded)}
                endIcon={expanded ? <ExpandLess titleAccess="collapse" /> : <ExpandMore titleAccess="expand" />}
              >
                <Typography variant="button">Notes</Typography>
              </Button>
            )}
            {isUpcomingCall && (
              <Button
                variant="outlined"
                color="primary"
                className={styles.modifyButton}
                onClick={() => setModifyModalOpen(true)}
              >
                Modify
              </Button>
            )}
            <JoinCallButton videoCall={videoCall} mixpanelTrackingEvent={trackStartCheckInCall} />
          </>
        }
      >
        {isPrimaryCoachOfPatient && (
          <Collapse in={expanded}>
            <CallNotesForm
              callID={videoCall.id}
              notes={videoCall.notes}
              callException={callException}
              startTime={startTime}
            />
          </Collapse>
        )}
      </VideoCallBookingInformation>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  modifyButton: {
    backgroundColor: '#fff',
    marginRight: theme.spacing(1),
  },
  notesButton: {
    backgroundColor: '#FFF',
    marginRight: theme.spacing(1),
  },
}))

export default InProgramVideoCallBookingInformation
