import React, { useCallback } from 'react'
import moment from 'moment'

import { DeleteButton } from 'src/legacy/components/mui'
import {
  GetPatientHistoryAndProgramStartDateDocument,
  useDeletePatientHistoryMutation,
} from '../patientHistoryQueries.generated'

const PARTICIPANT_HISTORY_DELETION_PERIOD_IN_HOURS = 48

type Props = {
  createdAt: string | Date
  entryID: string
  source?: string | null
}

const ParticipantHistoryDelete = ({ createdAt, entryID, source }: Props) => {
  const [deletePatientHistory] = useDeletePatientHistoryMutation({
    refetchQueries: [GetPatientHistoryAndProgramStartDateDocument],
  })

  const handleDeletePatientHistory = useCallback(async () => {
    await deletePatientHistory({ variables: { entryID } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePatientHistory])

  if (
    moment().diff(moment(createdAt), 'hours') <= PARTICIPANT_HISTORY_DELETION_PERIOD_IN_HOURS &&
    source === 'dashboard'
  ) {
    return <DeleteButton onDelete={handleDeletePatientHistory} title="Delete Entry" />
  }
  return null
}

export default ParticipantHistoryDelete
