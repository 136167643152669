import { CssBaseline } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

const GlobalStyles = withStyles({
  '@global': {
    'html, body, #app': {
      height: '100%',
      margin: 0,
      overflow: 'hidden',
    },
    'body a': {
      color: 'inherit',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
})(CssBaseline)

export default GlobalStyles
