import React from 'react'
import { TableRow, TableCell, Typography , Skeleton } from '@mui/material'

import { isNumber } from 'lodash'

const calculateChange = (baseline?: number | null, programEndResult?: number | null) => {
  if (!isNumber(baseline) || !isNumber(programEndResult) || baseline === 0) {
    return null
  }
  const change = ((programEndResult - baseline) / baseline) * 100
  return `${Math.round(change)}%`
}

type Props = {
  baseline?: number | null
  programEndResult?: number | null
  isLoading: boolean
}

const PercentageChange = ({ baseline, programEndResult, isLoading }: Props) => {
  const change = calculateChange(baseline, programEndResult)
  return (
    <TableRow>
      <TableCell>Change from start</TableCell>
      {isLoading ? (
        <>
          <TableCell>
            <Skeleton />
          </TableCell>
          <TableCell />
        </>
      ) : (
        <TableCell colSpan={2}>
          <Typography variant="inherit">{change || 'n/a'}</Typography>
        </TableCell>
      )}
    </TableRow>
  )
}

export default PercentageChange
