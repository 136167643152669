import _ from 'lodash'
import React from 'react'
import { TableCell, TableRow , Skeleton } from '@mui/material'

import { isSuccess, useFirebase } from 'src/firebase'
import { DevicesData } from 'src/legacy/models/firebase'

type Props = {
  uid: string
}

const TechnicalDeviceDetails = ({ uid }: Props) => {
  const devicesRequest = useFirebase<DevicesData>(`metaUserData/${uid}/devices`)

  const latestDevice = _(devicesRequest.data).values().orderBy('updatedAt', 'desc').first()
  return (
    <>
      <TableRow>
        <TableCell>App Version</TableCell>
        <TableCell>
          {!isSuccess(devicesRequest) && <Skeleton />}
          {latestDevice && latestDevice.appVersion}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Device</TableCell>
        <TableCell>
          {!isSuccess(devicesRequest) && <Skeleton />}
          {latestDevice && `${latestDevice.deviceModel} (${latestDevice.platform} ${latestDevice.osVersion})`}
        </TableCell>
      </TableRow>
    </>
  )
}

export default TechnicalDeviceDetails
