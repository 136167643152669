import { useRef } from 'react'
import useWindowWidthEffect from 'src/designSystem/Container/useWindowWidthEffect'

type ContainerWidthState = 'wideEnoughForBothSidebars' | 'wideEnoughForOneSidebar' | 'notWideEnoughForAnySidebars'

type DrawersAutohidingProps = {
  setSidebarOpen: (open: boolean) => void
  setInfoAreaOpen: (open: boolean) => void
  sidebarWidth: number
  infoareaWidth: number
  minContentWidth: number
  hasInfoArea: boolean
}

const useDrawersAutohiding = ({
  setSidebarOpen,
  setInfoAreaOpen,
  sidebarWidth,
  infoareaWidth,
  minContentWidth,
  hasInfoArea,
}: DrawersAutohidingProps) => {
  const previousWindowWidthRef = useRef<number | null>(null)

  useWindowWidthEffect(
    (width) => {
      const firstRender = previousWindowWidthRef.current === null
      const isWidening = previousWindowWidthRef.current && width > previousWindowWidthRef.current
      const isNarrowing = previousWindowWidthRef.current && width < previousWindowWidthRef.current
      previousWindowWidthRef.current = width

      const widthState: ContainerWidthState = (() => {
        const wideEnoughForBoth = width > sidebarWidth + infoareaWidth + minContentWidth
        const wideEnoughForOne = width > sidebarWidth + minContentWidth

        if (wideEnoughForBoth) return 'wideEnoughForBothSidebars'
        if (wideEnoughForOne) return 'wideEnoughForOneSidebar'
        return 'notWideEnoughForAnySidebars'
      })()

      const enableOpening = firstRender || !isNarrowing
      const enableClosing = firstRender || !isWidening

      const closeSidebar = () => enableClosing && setSidebarOpen(false)
      const closeInfoarea = () => enableClosing && setInfoAreaOpen(false)
      const openSidebar = () => enableOpening && setSidebarOpen(true)
      const openInfoarea = () => enableOpening && setInfoAreaOpen(true)

      // wide enough for both, show both
      const handleWideEnoughForBoth = () => {
        openSidebar()
        openInfoarea()
      }

      // wide enough for one, show infoarea if available, otherwise show sidebar
      const handleWideEnoughForOne = () => {
        if (hasInfoArea) {
          openInfoarea()
          closeSidebar()
        } else {
          openSidebar()
          closeInfoarea()
        }
      }
      const handleNotWideEnoughForAny = () => {
        closeSidebar()
        closeInfoarea()
      }

      switch (widthState) {
        case 'wideEnoughForBothSidebars':
          return handleWideEnoughForBoth()
        case 'wideEnoughForOneSidebar':
          return handleWideEnoughForOne()
        case 'notWideEnoughForAnySidebars':
          return handleNotWideEnoughForAny()
        default:
          return undefined
      }
    },
    [hasInfoArea, infoareaWidth, minContentWidth, setInfoAreaOpen, setSidebarOpen, sidebarWidth]
  )
}

export default useDrawersAutohiding
