import React from 'react'
import { Box, Button } from '@mui/material'
import { PatientHistoryFormType } from 'src/participantHistory/constants'

type Props = {
  historyType: PatientHistoryFormType
  isSubmitting: boolean
}

const ParticipantHistoryFormActions = ({ historyType, isSubmitting }: Props) => (
  <Box mt={2}>
    <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
      {historyType === 'INTAKE_NOTE' ? 'Intake note is complete' : 'Add'}
    </Button>
  </Box>
)

export default ParticipantHistoryFormActions
