import React from 'react'
import { Field } from 'formik'
import { MenuItem } from '@mui/material'
import useLoadSources from 'src/participantsView/useLoadSources'
import { CustomTextField } from 'src/form'

type Props = {
  name?: string
}

const SourceField: React.FunctionComponent<Props> = ({ name }) => {
  const sources = useLoadSources()
  return (
    <Field component={CustomTextField} fullWidth label="Source" name={name} type="select">
      {sources.map((source) => (
        <MenuItem key={source} value={source}>
          {source}
        </MenuItem>
      ))}
    </Field>
  )
}

SourceField.defaultProps = {
  name: 'source',
}

export default SourceField
