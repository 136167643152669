import React, { useState } from 'react'
import { Accordion, AccordionProps, AccordionSummary, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ExpandMore } from '@mui/icons-material'

type FeedItemCardProps = AccordionProps & {
  isCompleted: boolean
  isExpandable: boolean
  header: React.ReactElement
}

const TransitionProps = { mountOnEnter: false, timeout: 200, unmountOnExit: true }

export const FeedItemCard = (props: FeedItemCardProps) => {
  const { isCompleted: _, isExpandable, header, children, ...restProps } = props

  const panelStyles = usePanelStyles(props)
  const headerStyles = useHeaderStyles()
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const onExpandingChange = (_event: React.ChangeEvent<unknown>, expanded: boolean) => setIsExpanded(expanded)

  return (
    <Box width="75%" maxWidth={800}>
      <Accordion
        classes={panelStyles}
        onChange={onExpandingChange}
        expanded={isExpanded}
        disabled={!isExpandable}
        TransitionProps={TransitionProps}
        {...restProps}
      >
        <AccordionSummary classes={headerStyles} expandIcon={isExpandable && <ExpandMore />}>
          <Box>{header}</Box>
        </AccordionSummary>

        {children}
      </Accordion>
    </Box>
  )
}
export default FeedItemCard

const usePanelStyles = makeStyles((theme) => ({
  root: {
    border: 'solid 1px',
    borderColor: theme.palette.lightGrey,
    backgroundColor: (props: FeedItemCardProps) =>
      props.isCompleted ? theme.palette.background.paper : theme.palette.lightestGrey,
    '&$disabled': {
      backgroundColor: (props: FeedItemCardProps) =>
        props.isCompleted ? theme.palette.background.paper : theme.palette.lightestGrey,
    },
  },
  disabled: {
    backgroundColor: () => '',
  },
}))

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    minHeight: 0,
    padding: '0px 16px 0px 0px',
    '&$expanded': {
      minHeight: 0,
    },
    '&$disabled': {
      opacity: 1,
    },
  },
  content: {
    overflow: 'hidden',
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
  disabled: {},
  expandIcon: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))
