/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash'
import React, { useCallback } from 'react'
import { ApolloError, UpdateQueryOptions } from '@apollo/client'
import { Formik, FormikErrors, FormikProps } from 'formik'
import { useToasts } from 'react-toast-notifications'
import { ObjectSchema } from 'yup'
import FormComponent from './FormComponent'

const formatErrors = (error: ApolloError) => {
  const graphQLErrors = _.map(error.graphQLErrors, 'extensions.code')
  return graphQLErrors.length > 0 ? graphQLErrors : ['NETWORK_ERROR']
}

type Props<T extends Record<string, any>> = {
  ignoredErrors?: Array<string>
  onSubmit: (options: UpdateQueryOptions<any>) => Promise<void>
  onSuccess?: (values: T) => void
  render: (props: FormikProps<T>) => React.ReactElement
  resetAfterSuccess?: boolean
  validationSchema: ObjectSchema<T>
  isLoading?: boolean
  initialValues?: T
  initialErrors?: FormikErrors<T>
}

function GraphQLForm<T extends Record<string, any>>(props: Props<T>) {
  const {
    ignoredErrors,
    onSubmit,
    onSuccess,
    render,
    resetAfterSuccess,
    validationSchema,
    isLoading,
    initialValues,
    initialErrors,
  } = props
  const { addToast } = useToasts()

  const onSubmitCallback = useCallback(
    async (formValues, { resetForm, setStatus, setSubmitting }) => {
      const castedValues = validationSchema.cast(formValues, { stripUnknown: true })
      setSubmitting(true)
      setStatus({
        errors: [],
        viewOnly: false,
      })
      try {
        await onSubmit({ variables: castedValues })
        if (resetAfterSuccess) {
          resetForm({ values: validationSchema.cast() })
        }
        setSubmitting(false)
        if (onSuccess) {
          onSuccess(castedValues)
        }
      } catch (error) {
        setSubmitting(false)
        const errors = formatErrors(error as ApolloError)
        setStatus({
          errors,
          viewOnly: false,
        })
        if (error instanceof ApolloError && error.graphQLErrors[0]?.extensions.clientError) {
          addToast(error.message)
        } else if (_.difference(errors, ignoredErrors || []).length > 0) {
          addToast('Something went wrong! Please try again.')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ignoredErrors, onSubmit, onSuccess, resetAfterSuccess, validationSchema]
  )

  return (
    <Formik
      initialErrors={initialErrors}
      initialStatus={{ errors: [], viewOnly: false, isLoading }}
      initialValues={initialValues || validationSchema.default()!}
      enableReinitialize
      onSubmit={onSubmitCallback}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<T>) => <FormComponent props={formikProps} isLoading={isLoading} render={render} />}
    </Formik>
  )
}

GraphQLForm.defaultProps = {
  resetAfterSuccess: true,
}

export default GraphQLForm
