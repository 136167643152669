import { Checkbox, ListItemText, MenuItem } from '@mui/material'
import { Field, useField } from 'formik'
import React from 'react'
import { CustomTextField } from 'src/form'

const RACIAL_IDENTITY_SELECTIONS = [
  {
    label: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN_OR_ALASKA_NATIVE',
  },
  { label: 'Asian', value: 'ASIAN' },
  { label: 'Black or African American', value: 'BLACK_OR_AFRICAN_AMERICAN' },
  { label: 'Hispanic or Latino', value: 'HISPANIC_OR_LATINO' },
  { label: 'Middle Eastern or North African', value: 'MIDDLE_EASTERN_OR_NORTH_AFRICAN' },
  {
    label: 'Native Hawaiian or Pacific Islander',
    value: 'NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER',
  },
  { label: 'White', value: 'WHITE' },
  { label: 'Prefer not to disclose', value: 'PREFER_NOT_DISCLOSE' },
]

const RACIAL_IDENTITY_FIELD_NAME = 'patient.racialIdentities'

export const RacialIdentityField = () => {
  const [field] = useField(RACIAL_IDENTITY_FIELD_NAME)

  return (
    <Field
      component={CustomTextField}
      fullWidth
      label="Racial Identity"
      name={RACIAL_IDENTITY_FIELD_NAME}
      type="select"
      multiple
      renderValue={(racialIdentities: string[]) => joinRacialIdentityLabels(racialIdentities)}
    >
      {RACIAL_IDENTITY_SELECTIONS.map(({ value, label }) => (
        <MenuItem key={value} value={value}>
          <Checkbox checked={field.value === undefined ? false : field.value.includes(value)} />
          <ListItemText primary={label} />
        </MenuItem>
      ))}
    </Field>
  )
}

function joinRacialIdentityLabels(racialIdentities: string[]) {
  return RACIAL_IDENTITY_SELECTIONS.filter((selection) => racialIdentities.includes(selection.value))
    .map((selection) => selection.label)
    .join(',')
}
