import React, { useState } from 'react'
import { Button, DialogActions, DialogContent } from '@mui/material'
import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { CustomRadio, CustomRadioGroup } from 'src/form'
import { PatientEngagementIndicator } from 'src/graphql.generated'
import SetProgramStartDateForm from './SetProgramStartDateForm'
import SetProgramEndForm from './SetProgramEndForm'
import { ProgramEndMetrics } from './SetProgramEndForm/programEndMetricSchemas'
import SetRestartProgramForm from './SetRestartProgramForm'

enum MenuOption {
  RESTART_PROGRAM = 'RESTART_PROGRAM',
  UPDATE_START_DATE = 'UPDATE_START_DATE',
  PROGRAM_END = 'PROGRAM_END',
}

const UpdateProgramMenuSchema = Yup.object()
  .required()
  .shape({
    value: Yup.string().oneOf(Object.keys(MenuOption)),
  })

type Props = {
  close: () => void
  engagement: PatientEngagementIndicator
  lastWeekNbr: number
  programStartDate: string
  programEndMetrics: Array<keyof ProgramEndMetrics>
  uid: string
}

const UpdateProgramMenu: React.FunctionComponent<Props> = (props) => {
  const { close, uid, engagement, lastWeekNbr, programStartDate, programEndMetrics } = props
  const [renderComponent, setRenderComponent] = useState<MenuOption | null>(null)

  const startDateInTheFuture = new Date(programStartDate) > new Date()

  const updateOptionLabel = startDateInTheFuture ? 'Change start date' : 'Restart program'
  const updateOptionValue = startDateInTheFuture ? MenuOption.UPDATE_START_DATE : MenuOption.RESTART_PROGRAM

  return (
    <>
      {!renderComponent && (
        <Formik
          onSubmit={(values) => setRenderComponent(values.value)}
          validationSchema={UpdateProgramMenuSchema}
          initialValues={{ value: renderComponent }}
        >
          <Form>
            <ClosableDialogTitle onClose={close}>How would you like to update the program?</ClosableDialogTitle>
            <DialogContent>
              <Field component={CustomRadioGroup} name="value" type="string">
                <CustomRadio label={updateOptionLabel} value={updateOptionValue} />
                <CustomRadio label="Terminate program" value={MenuOption.PROGRAM_END} />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" disabled={false} type="submit">
                Next
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      )}
      {renderComponent === MenuOption.UPDATE_START_DATE && (
        <SetProgramStartDateForm
          uid={uid}
          programStartDate={programStartDate}
          cancel={() => setRenderComponent(null)}
          close={close}
        />
      )}
      {renderComponent === MenuOption.RESTART_PROGRAM && (
        <SetRestartProgramForm
          uid={uid}
          programStartDate={programStartDate}
          cancel={() => setRenderComponent(null)}
          close={close}
        />
      )}
      {renderComponent === MenuOption.PROGRAM_END && (
        <SetProgramEndForm
          cancel={() => setRenderComponent(null)}
          close={close}
          uid={uid}
          engagement={engagement!}
          lastWeekNbr={lastWeekNbr}
          programStartDate={programStartDate!}
          programEndMetrics={programEndMetrics}
        />
      )}
    </>
  )
}

export default UpdateProgramMenu
