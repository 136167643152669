/* eslint-disable react/no-array-index-key */
import _ from 'lodash'
import React, { Children } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/material'

const toArrayWithoutEmptyChildren = (children) => _.compact(Children.toArray(children))

const isLastChild = (children, i) => i === toArrayWithoutEmptyChildren(children).length - 1

const InlineForm = ({ children, variant }) => (
  <Box display="flex" justifyContent="space-between" mt={variant === 'dense' ? -2 : 0}>
    {toArrayWithoutEmptyChildren(children).map((child, i) => (
      <Box
        flex={isLastChild(children, i) ? 0 : 1}
        key={i}
        mr={isLastChild(children, i) ? 0 : 2}
        mt={isLastChild(children, i) ? 2 : 0}
      >
        {child}
      </Box>
    ))}
  </Box>
)

InlineForm.defaultProps = {
  children: null,
  variant: 'regular',
}

InlineForm.propTypes = {
  children: PropTypes.node,
  variant: PropTypes.oneOf(['dense', 'regular']),
}

export default InlineForm
