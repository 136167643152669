import React from 'react'
import _ from 'lodash'
import { Box, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { EnrollmentState, PatientProgramWeek } from 'src/graphql.generated'
import Authentication from 'src/legacy/components/Authentication'
import SearchParticipantForm from './SearchParticipantForm'
import ChipSelection from './ChipSelection'
import { PARTICIPANT_STATES } from './constants'
import { ParticipantFilter } from './useLoadParticipantsWithFilter'
import useLoadSources from './useLoadSources'
import SelectSource from './SelectSource'
import SelectProviders from './SelectProviders'
import useLoadProviders from './useLoadProviders'
import SelectStateOfResidence from './SelectStateOfResidence'
import SelectWeeks from './SelectWeeks'
import useLoadParticipantTags from './useLoadParticipantTags'

const useStyles = makeStyles((theme) => ({
  typography: {
    marginRight: theme.spacing(2),
  },
}))

export type Props = {
  filter: ParticipantFilter
  onChange: (updatedFilter: ParticipantFilter) => void
}

const ParticipantSearchFilters = (props: Props) => {
  const { onChange, filter } = props
  const {
    states: currentStates,
    sources: currentSources,
    providers: currentProviders,
    statesOfResidence: currentStatesofResidence,
    weeks: currentWeeks,
    tags: currentTags,
    eligibilityUnconfirmed: currentEligibilityUnconfirmed,
  } = filter
  const classes = useStyles()

  const sources = useLoadSources()
  const providers = useLoadProviders()
  const tags = useLoadParticipantTags()

  const handleOnQueryChange = (updatedQuery?: string) => {
    onChange({
      ...filter,
      query: updatedQuery,
    })
  }

  const handleOnStateSelect = (selectedState: EnrollmentState) => {
    onChange({
      ...filter,
      states: _.xor(currentStates, [selectedState]),
    })
  }

  const handleOnSourceSelect = (selectedSources: Array<string>) => {
    onChange({
      ...filter,
      sources: selectedSources,
    })
  }

  const handleOnProvidersSelect = (selectedProviders: Array<string>) => {
    onChange({
      ...filter,
      providers: selectedProviders,
    })
  }

  const handleOnStateOfResidenceSelect = (selectedStates: Array<string>) => {
    onChange({
      ...filter,
      statesOfResidence: selectedStates,
    })
  }

  const handleOnWeeksSelect = (selectedWeeks: Array<PatientProgramWeek>) => {
    onChange({
      ...filter,
      weeks: selectedWeeks,
    })
  }

  const handleOnTagsSelect = (selectedTag: string) => {
    onChange({
      ...filter,
      tags: _.xor(currentTags, [selectedTag]),
    })
  }

  const handleOnEligibilityUnconfirmedToggled = () =>
    onChange({ ...filter, eligibilityUnconfirmed: !currentEligibilityUnconfirmed })

  return (
    <Box m={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h3">What are you looking for?</Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchParticipantForm onChange={handleOnQueryChange} value={filter.query} />
        </Grid>

        <Grid item xs={3}>
          <SelectSource
            sources={sources}
            currentlySelectedSources={currentSources}
            selectSources={handleOnSourceSelect}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectProviders
            providers={providers}
            selectProviders={handleOnProvidersSelect}
            selectedProviders={currentProviders}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectStateOfResidence
            selectStates={handleOnStateOfResidenceSelect}
            selectedStates={currentStatesofResidence}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectWeeks selectWeeks={handleOnWeeksSelect} selectedWeeks={currentWeeks} />
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={1}>
            <Typography color="textSecondary" component="span" variant="body2">
              Filter by Status
            </Typography>
          </Grid>
          {PARTICIPANT_STATES.map(({ label, state }) => (
            <ChipSelection<EnrollmentState>
              key={state}
              currentSelections={currentStates}
              label={label}
              selected={state}
              onSelect={handleOnStateSelect}
            />
          ))}
          <Authentication allowManager>
            <ChipSelection<boolean | undefined>
              currentSelections={[currentEligibilityUnconfirmed]}
              // jsx-boolean-value disabled for clarity -- the selected value (true in this case)
              // is used to filter from the list of currentSelections
              selected={true} // eslint-disable-line react/jsx-boolean-value
              onSelect={handleOnEligibilityUnconfirmedToggled}
              label="Eligibility unconfirmed"
            />
          </Authentication>
        </Grid>

        <Grid item xs={12} container alignItems="center">
          <Grid item xs={1}>
            <Typography className={classes.typography} color="textSecondary" component="span" variant="body2">
              Filter by Tags
            </Typography>
          </Grid>

          {tags.map(({ name, id }) => (
            <ChipSelection<string>
              key={id}
              currentSelections={currentTags}
              label={name}
              selected={id}
              onSelect={handleOnTagsSelect}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ParticipantSearchFilters
