/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

const defaultOptions = {} as const
export type RescheduleIntakeCallMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  calendarOwnerEmail: Types.Scalars['String']['input']
  start: Types.Scalars['DateTime']['input']
}>

export type RescheduleIntakeCallMutation = { __typename?: 'Mutation'; rescheduleIntakeCall?: null | null }

export type SendParticipantBackToCallSchedulingMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  programID: Types.Scalars['String']['input']
  description: Types.Scalars['String']['input']
}>

export type SendParticipantBackToCallSchedulingMutation = {
  __typename?: 'Mutation'
  sendParticipantBackToCallScheduling: {
    __typename?: 'CreateEnrollmentAction'
    uid: string
    patient: {
      __typename?: 'Patient'
      intakeCallTime?: Date | null
      enrollment: { __typename?: 'Enrollment'; state: Types.EnrollmentState }
    }
  }
}

export const RescheduleIntakeCallDocument = gql`
  mutation rescheduleIntakeCall($uid: ID!, $calendarOwnerEmail: String!, $start: DateTime!) {
    rescheduleIntakeCall(uid: $uid, calendarOwnerEmail: $calendarOwnerEmail, start: $start)
  }
`
export type RescheduleIntakeCallMutationFn = Apollo.MutationFunction<
  RescheduleIntakeCallMutation,
  RescheduleIntakeCallMutationVariables
>

/**
 * __useRescheduleIntakeCallMutation__
 *
 * To run a mutation, you first call `useRescheduleIntakeCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleIntakeCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleIntakeCallMutation, { data, loading, error }] = useRescheduleIntakeCallMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      calendarOwnerEmail: // value for 'calendarOwnerEmail'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useRescheduleIntakeCallMutation(
  baseOptions?: Apollo.MutationHookOptions<RescheduleIntakeCallMutation, RescheduleIntakeCallMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RescheduleIntakeCallMutation, RescheduleIntakeCallMutationVariables>(
    RescheduleIntakeCallDocument,
    options
  )
}
export type RescheduleIntakeCallMutationHookResult = ReturnType<typeof useRescheduleIntakeCallMutation>
export type RescheduleIntakeCallMutationResult = Apollo.MutationResult<RescheduleIntakeCallMutation>
export type RescheduleIntakeCallMutationOptions = Apollo.BaseMutationOptions<
  RescheduleIntakeCallMutation,
  RescheduleIntakeCallMutationVariables
>
export const SendParticipantBackToCallSchedulingDocument = gql`
  mutation sendParticipantBackToCallScheduling($uid: ID!, $programID: String!, $description: String!) {
    sendParticipantBackToCallScheduling(uid: $uid, programID: $programID, description: $description) {
      uid
      patient {
        enrollment {
          state
        }
        intakeCallTime
      }
    }
  }
`
export type SendParticipantBackToCallSchedulingMutationFn = Apollo.MutationFunction<
  SendParticipantBackToCallSchedulingMutation,
  SendParticipantBackToCallSchedulingMutationVariables
>

/**
 * __useSendParticipantBackToCallSchedulingMutation__
 *
 * To run a mutation, you first call `useSendParticipantBackToCallSchedulingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendParticipantBackToCallSchedulingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendParticipantBackToCallSchedulingMutation, { data, loading, error }] = useSendParticipantBackToCallSchedulingMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      programID: // value for 'programID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useSendParticipantBackToCallSchedulingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendParticipantBackToCallSchedulingMutation,
    SendParticipantBackToCallSchedulingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SendParticipantBackToCallSchedulingMutation,
    SendParticipantBackToCallSchedulingMutationVariables
  >(SendParticipantBackToCallSchedulingDocument, options)
}
export type SendParticipantBackToCallSchedulingMutationHookResult = ReturnType<
  typeof useSendParticipantBackToCallSchedulingMutation
>
export type SendParticipantBackToCallSchedulingMutationResult =
  Apollo.MutationResult<SendParticipantBackToCallSchedulingMutation>
export type SendParticipantBackToCallSchedulingMutationOptions = Apollo.BaseMutationOptions<
  SendParticipantBackToCallSchedulingMutation,
  SendParticipantBackToCallSchedulingMutationVariables
>
