import { Field, Form, Formik } from 'formik'
import React from 'react'
import { CustomTextField, FormDatePicker } from 'src/form'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import _ from 'lodash'
import { Box } from '@mui/material'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import SubmitButton from './SubmitButton'

const INTAKE_NOTE_COACHING_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    notes: Yup.string().required(),
  })
  .required()

type IntakeNoteCoachingFormType = Yup.InferType<typeof INTAKE_NOTE_COACHING_FORM_SCHEMA>

const IntakeNoteCoachingForm = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<IntakeNoteCoachingFormType>
      validationSchema={INTAKE_NOTE_COACHING_FORM_SCHEMA}
      initialValues={INTAKE_NOTE_COACHING_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.IntakeNoteCoaching,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box>
            <Field component={FormDatePicker} label="Date of event" name="date" />
          </Box>
          <Box>
            <Field
              component={CustomTextField}
              label="Intake notes"
              fullWidth
              multiline
              name="notes"
              resizable
              minRows={20}
            />
          </Box>
          <Box>
            <SubmitButton isSubmitting={isSubmitting} />
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default IntakeNoteCoachingForm
