import React, { Fragment, ReactNode, useMemo } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { formatRelativeTime } from 'src/date'
import { Divider } from 'src/timeline'
import { getMessageDisplayingProps } from 'src/feed/ChatMessageItem/getMessageDisplayingProps'
import Message, { MessageContent } from './Message'

type MessageAreaProps<T extends MessageContent> = {
  messages: T[]
  isCareteamMessageCheck: (message: T) => boolean
  renderAuthorName?: (message: T) => ReactNode
  renderMenuItems?: (message: T) => ReactNode
}

export default function MessageArea<T extends MessageContent>({
  messages,
  isCareteamMessageCheck,
  renderAuthorName,
  renderMenuItems,
}: MessageAreaProps<T>) {
  const messagesWithRelativeTimes = useMemo(() => messages.map(withRelativeTime), [messages])
  const styles = useStyles()
  return (
    <div
      className={styles.messageArea}
      role="log"
      aria-live="polite"
      aria-atomic="false"
      aria-relevant="additions"
      aria-label="Chat"
    >
      {mapInPairs(messagesWithRelativeTimes, ({ relativeTime, message }, previous) => {
        const isCareteamMessage = isCareteamMessageCheck(message)
        const { direction } = getMessageDisplayingProps(isCareteamMessage)
        return (
          <Fragment key={`message-${message.id}`}>
            {(!previous || relativeTime !== previous.relativeTime) && <Divider>{relativeTime}</Divider>}
            <Message
              message={message}
              direction={direction}
              menuItems={renderMenuItems && renderMenuItems(message)}
              authorName={
                renderAuthorName && message.authorID !== previous?.message.authorID && renderAuthorName(message)
              }
            />
          </Fragment>
        )
      })}
    </div>
  )
}

const withRelativeTime = <T extends MessageContent>(message: T): { message: T; relativeTime: string } => ({
  relativeTime: formatRelativeTime(moment(message.updatedAt || message.createdAt)),
  message,
})

const useStyles = makeStyles((theme) => ({
  messageArea: {
    flex: 1,
    padding: theme.spacing(2),
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
}))

function mapInPairs<T, R>(arr: T[], fn: (current: T, prev: T | undefined, index: number) => R): R[] {
  return arr.map((current, index) => fn(current, arr[index - 1], index))
}
