import _ from 'lodash'
import { flow } from 'lodash/fp'
import { useState } from 'react'
import { Filters } from './ParticipantListFilters'
import { HomeViewParticipantFragment } from './searchParticipants.generated'

type Participant = HomeViewParticipantFragment

const getAvailableFilterTags = (participants: Participant[]) =>
  _.chain(participants)
    .flatMap((participant) => participant.tags?.map((tag) => tag.name) || [])
    .uniq()
    .value()

const participantHasTag = (participant: Participant, wantedTag: string) =>
  participant.tags?.find((tag) => tag.name === wantedTag)

const filterParticipantsByTags = (participants: Participant[], filters: Filters) => {
  if (filters.tags.length === 0) {
    return participants
  }
  return participants.filter((participant) => filters.tags.every((tag) => participantHasTag(participant, tag)))
}

const filterParticipantsByUnreads = (
  participants: Participant[],
  selectedUid: string | undefined,
  filters: Filters
) => {
  if (!filters.unreads) {
    return participants
  }
  return participants.filter((participant) => {
    const newMessageCount = participant.indicators?.newMessages?.count ?? 0
    // If participant is selected, show it even if it has no new messages, so that
    // the just-selected pt is not hidden
    return participant.uid === selectedUid || newMessageCount > 0
  })
}

const useParticipantFilters = (participants: Participant[], selectedUid?: string) => {
  const [filters, setFilters] = useState<Filters>({ tags: [], unreads: false })

  const updateFilters = (updatedFilters: Partial<Filters>) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...updatedFilters }))
  }

  return {
    availableFilterTags: getAvailableFilterTags(participants),
    filteredParticipants: flow(
      (pts: Participant[]) => filterParticipantsByTags(pts, filters),
      (pts: Participant[]) => filterParticipantsByUnreads(pts, selectedUid, filters)
    )(participants),
    filters,
    updateFilters,
  }
}

export default useParticipantFilters
