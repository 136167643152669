import React from 'react'
import { Menu, MenuItem, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import MoreVertIcon from '@mui/icons-material/MoreVert'

type Action = {
  label: string
  onClick: () => void
}
export type Props = {
  actions: Array<Action>
}

export default function CardActionsMenu({ actions }: Props) {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleClick = (event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const handleMenuItemClick = (callback: () => void) => (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    event.stopPropagation()
    callback()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton classes={{ root: classes.icon }} aria-haspopup="true" onClick={handleClick} size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose}>
        {actions.map((action) => (
          <MenuItem key={action.label} onClick={handleMenuItemClick(action.onClick)}>
            {action.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const useStyles = makeStyles({
  icon: {
    margin: 0,
    padding: 0,
  },
})
