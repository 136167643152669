import React from 'react'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

type ResponseCount = {
  date: Date
  count: number
}

type Props = {
  responses: Array<ResponseCount>
}

const RecentResponses: React.FC<Props> = ({ responses }) => (
  <>
    {responses.map((response) =>
      response.count > 0 ? (
        <FiberManualRecordIcon
          key={response.date.toString()}
          titleAccess="activity done"
          color="primary"
          style={{ fontSize: 16, paddingLeft: '2px', paddingRight: '2px' }}
        />
      ) : (
        <FiberManualRecordIcon
          key={response.date.toString()}
          color="disabled"
          titleAccess="no activity"
          style={{ fontSize: 16, paddingLeft: '2px', paddingRight: '2px' }}
        />
      )
    )}
  </>
)

export default RecentResponses
