import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, MenuItem, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Field } from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import { FormDateTimePicker, CustomTextField, GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { UpdateQueryOptions } from '@apollo/client'
import { ScheduledVideoCallReason } from 'src/graphql.generated'
import ParticipantTime from 'src/date/ParticipantTime'
import { trackCreateCheckinCallAppointment } from 'src/mixpanel/api'

import {
  useScheduleVideoCallMutation,
  GetParticipantVideoCallBookingInformationDocument,
} from '../videoCallBookingHeaderQueries.generated'

const DEFAULT_CALL_DURATION = 30

export const VIDEO_CALL_REASON_LABELS: Record<ScheduledVideoCallReason, string> = {
  ENGAGEMENT_ISSUE: 'Program engagement',
  ESCALATING_SYMPTOMS: 'Escalating symptoms',
  LIFE_ISSUE: 'Life event(s)',
  OTHER_REASON: 'Other',
  PATIENT_INITIATED: 'Patient initiated',
}

const useStyles = makeStyles((theme) => ({
  instructionText: {
    paddingTop: theme.spacing(2),
  },
}))

enum SchedulingView {
  SCHEDULE_TIME = 'SCHEDULE_TIME',
  CONFIRM_TIME = 'CONFIRM_TIME',
}

type ScheduleVideoCallForm = {
  start: number
  uid: string
  durationMinutes: number
  reason: ScheduledVideoCallReason
}

const ScheduleVideoCallSchema = (uid: string, durationMinutes: number) =>
  Yup.object({
    start: Yup.number().required(),
    uid: Yup.string().required().default(uid),
    durationMinutes: Yup.number().required().default(durationMinutes),
    reason: Yup.string().oneOf(Object.values(ScheduledVideoCallReason)).required(),
  }).required()

export type ScheduleInProgramVideoCallDialogProps = {
  uid: string
  firstname?: string | null
  lastname?: string | null
  open: boolean
  onClose: () => void
}

const VIDEO_CALL_REASONS_INPUT = Object.entries(VIDEO_CALL_REASON_LABELS).filter(
  ([reason]) => reason !== ScheduledVideoCallReason.PatientInitiated
)

const ScheduleInProgramVideoCallDialog = ({
  uid,
  firstname,
  lastname,
  onClose,
  open,
}: ScheduleInProgramVideoCallDialogProps) => {
  const [schedulingScreen, setSchedulingScreen] = useState(SchedulingView.SCHEDULE_TIME)

  const [scheduleVideoCall] = useScheduleVideoCallMutation({
    refetchQueries: [GetParticipantVideoCallBookingInformationDocument],
  })

  const classes = useStyles()

  const validation = ScheduleVideoCallSchema(uid, DEFAULT_CALL_DURATION)

  const handleSubmit = async ({ variables }: UpdateQueryOptions<ScheduleVideoCallForm>) => {
    const { start, ...queryVariables } = variables!
    const startTime = new Date(start)

    trackCreateCheckinCallAppointment(queryVariables.reason)
    await scheduleVideoCall({ variables: { ...queryVariables, startTime } })
  }

  const close = () => {
    onClose()
    setSchedulingScreen(SchedulingView.SCHEDULE_TIME)
  }

  return (
    <Dialog onClose={onClose} open={open}>
      <GraphQLForm
        initialErrors={{ reason: 'This is required' }} // Telling formik that the form is not initially valid
        onSubmit={handleSubmit}
        onSuccess={close}
        validationSchema={validation}
        render={({ values, isSubmitting, isValid }) => (
          <>
            <ClosableDialogTitle onClose={close}>Create a check-in call appointment</ClosableDialogTitle>

            <DialogContent hidden={schedulingScreen !== SchedulingView.SCHEDULE_TIME}>
              <Field
                component={FormDateTimePicker}
                fullWidth
                helperText={<ParticipantTime uid={uid} time={values.start} />}
                label="Select Date and Time"
                name="start"
              />

              <Typography classes={{ root: classes.instructionText }} variant="body1">
                Please select from the options below to describe the nature of this call.
              </Typography>

              <Field component={CustomTextField} label="Reason for call" name="reason" type="select">
                {VIDEO_CALL_REASONS_INPUT.map(([reason, reasonText]) => (
                  <MenuItem key={reason} value={reason}>
                    {reasonText}
                  </MenuItem>
                ))}
              </Field>
            </DialogContent>

            <DialogContent hidden={schedulingScreen !== SchedulingView.CONFIRM_TIME}>
              <Typography variant="body1">
                You are about to create a check-in call appointment with{' '}
                {firstname && lastname ? (
                  <strong>
                    {firstname} {lastname}
                  </strong>
                ) : (
                  'the participant'
                )}{' '}
                for {moment(values.start).format('LL')} at{' '}
                <strong>
                  {moment(values.start).format('hh:mm')}-
                  {moment(values.start).add({ minutes: values.durationMinutes }).format('hh:mm A')}{' '}
                  {moment.tz(moment.tz.guess()).zoneAbbr()}
                </strong>
                .
              </Typography>
            </DialogContent>

            <DialogActions>
              {schedulingScreen === SchedulingView.SCHEDULE_TIME && (
                <>
                  <Button color="inherit" onClick={close}>
                    Cancel
                  </Button>

                  <Button
                    color="primary"
                    disabled={!isValid}
                    onClick={() => setSchedulingScreen(SchedulingView.CONFIRM_TIME)}
                  >
                    Next
                  </Button>
                </>
              )}

              {schedulingScreen === SchedulingView.CONFIRM_TIME && (
                <>
                  <Button color="inherit" onClick={() => setSchedulingScreen(SchedulingView.SCHEDULE_TIME)}>
                    Back
                  </Button>

                  <Button color="primary" disabled={isSubmitting} type="submit">
                    Confirm
                  </Button>
                </>
              )}
            </DialogActions>
          </>
        )}
      />
    </Dialog>
  )
}

export default ScheduleInProgramVideoCallDialog
