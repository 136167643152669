/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

import { CommunityPostItemFragmentDoc } from '../communityPostFragment.generated'

const defaultOptions = {} as const
export type GetCommunityPostsItemsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetCommunityPostsItemsQuery = {
  __typename?: 'Query'
  communityPosts: {
    __typename: 'CommunityPostsResult'
    posts: Array<{
      __typename: 'CommunityPostQuestion'
      postID: string
      title: string
      label: string
      content?: string | null
      community: { __typename?: 'Community'; name: string }
      communityPostReplies: {
        __typename?: 'CommunityPostReplies'
        id: string
        replyingStatus: string
        numberOfPatientsReplied: number
      }
    }>
  }
}

export const GetCommunityPostsItemsDocument = gql`
  query getCommunityPostsItems {
    communityPosts {
      __typename
      posts {
        ...CommunityPostItem
      }
    }
  }
  ${CommunityPostItemFragmentDoc}
`

/**
 * __useGetCommunityPostsItemsQuery__
 *
 * To run a query within a React component, call `useGetCommunityPostsItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityPostsItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityPostsItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommunityPostsItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCommunityPostsItemsQuery, GetCommunityPostsItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommunityPostsItemsQuery, GetCommunityPostsItemsQueryVariables>(
    GetCommunityPostsItemsDocument,
    options
  )
}
export function useGetCommunityPostsItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityPostsItemsQuery, GetCommunityPostsItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCommunityPostsItemsQuery, GetCommunityPostsItemsQueryVariables>(
    GetCommunityPostsItemsDocument,
    options
  )
}
export type GetCommunityPostsItemsQueryHookResult = ReturnType<typeof useGetCommunityPostsItemsQuery>
export type GetCommunityPostsItemsLazyQueryHookResult = ReturnType<typeof useGetCommunityPostsItemsLazyQuery>
export type GetCommunityPostsItemsQueryResult = Apollo.QueryResult<
  GetCommunityPostsItemsQuery,
  GetCommunityPostsItemsQueryVariables
>
