import _ from 'lodash'
import { CoachProfiles, CoachProfile } from 'src/legacy/models/firebase'
import { useFirebase, isSuccessWithData } from 'src/firebase'

export interface CoachProfileWithCoachID extends CoachProfile {
  coachID: string
}

const orderCoachProfiles = (coachProfiles: CoachProfiles): Array<CoachProfileWithCoachID> =>
  _(coachProfiles)
    .map((coachProfile, coachID) => ({ coachID, ...coachProfile }))
    .reject('isSuspended')
    .orderBy(['firstname', 'lastname'])
    .value()

export default function useLoadCoachProfiles() {
  const request = useFirebase<CoachProfiles>('coachProfiles')

  return isSuccessWithData(request) ? orderCoachProfiles(request.data) : []
}
