import * as React from 'react'
import { useContext } from 'react'
import { ChatMessageDirection } from 'src/graphql.generated'
import { Message, ResponsePreview } from 'src/message'
import MessageBubble from 'src/message/MessageBubble'
import { EditMessageContext } from 'src/feed/EditChatMessage/EditMessageProvider'
import { CardActionsMenu } from 'src/designSystem'
import useCheckMessageEditable from '../EditChatMessage/useCheckMessageEditable'
import { FeedChatMessageItemFragment } from '../feedQueries.generated'
import { getMessageDisplayingProps } from './getMessageDisplayingProps'

type ChatMessageItemProps = {
  chatMessage: FeedChatMessageItemFragment
}

export default function ChatMessageItem({ chatMessage }: ChatMessageItemProps) {
  const { text, chatMessageDirection, messageID, coachID, feedID, createdAt, linkedResponse } = chatMessage
  const isCareteamMessage = chatMessageDirection === ChatMessageDirection.ToPatient
  const { bubblePaletteColor, direction } = getMessageDisplayingProps(isCareteamMessage)
  const cleanedText = (text || '').trimLeft()
  const { startEditingMessage } = useContext(EditMessageContext)
  const [showEdit, setShowEdit] = React.useState(false)

  useCheckMessageEditable(createdAt, (newEnough) => {
    setShowEdit(newEnough && isCareteamMessage)
  })

  const actions = showEdit && (
    <CardActionsMenu
      actions={[{ label: 'Edit', onClick: () => startEditingMessage({ uid: feedID, ...chatMessage }) }]}
    />
  )

  return (
    <Message
      message={{
        id: messageID,
        authorID: coachID || feedID,
        createdAt: createdAt.valueOf(),
      }}
      direction={direction}
      actions={actions}
    >
      <MessageBubble
        bubblePaletteColor={bubblePaletteColor}
        linkedTo={linkedResponse ? <ResponsePreview response={linkedResponse} /> : undefined}
      >
        {cleanedText}
      </MessageBubble>
    </Message>
  )
}
