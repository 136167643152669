/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react'
import { ApolloError } from '@apollo/client'
import { Formik, FormikProps } from 'formik'
import { useToasts } from 'react-toast-notifications'
import { ObjectSchema } from 'yup'
import FormComponent from './FormComponent'

type FormProps<T extends Record<string, any>> = {
  onSubmit: (values: T) => Promise<unknown> | void
  render: (props: FormikProps<T>) => React.ReactElement
  validationSchema: ObjectSchema<T>
  initialValues?: T
  isLoading?: boolean
  onSuccess?: (values: T) => void
  resetAfterSuccess?: boolean
}

function RequestForm<T extends Record<string, any>>({
  initialValues,
  isLoading,
  onSubmit,
  onSuccess,
  render,
  resetAfterSuccess,
  validationSchema,
}: FormProps<T>) {
  const { addToast } = useToasts()

  const onSubmitCallback = useCallback(
    async (formValues, { resetForm, setStatus, setSubmitting }) => {
      const castedValues = validationSchema.cast(formValues, { stripUnknown: true })
      setSubmitting(true)
      setStatus({
        errors: null,
        viewOnly: false,
      })
      try {
        await onSubmit(castedValues)
        if (resetAfterSuccess) {
          resetForm({ values: validationSchema.cast() })
        }
        setSubmitting(false)
        if (onSuccess) {
          onSuccess(castedValues)
        }
      } catch (error) {
        setSubmitting(false)
        setStatus({
          error,
          viewOnly: false,
        })

        if (error instanceof ApolloError && error.graphQLErrors[0].extensions.clientError) {
          addToast(error.message)
        } else {
          addToast('Something went wrong!')
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSubmit, onSuccess, resetAfterSuccess, validationSchema]
  )
  return (
    <Formik
      initialStatus={{ error: null, viewOnly: false, isLoading }}
      initialValues={initialValues || validationSchema.default()!}
      enableReinitialize
      onSubmit={onSubmitCallback}
      validationSchema={validationSchema}
    >
      {(props) => <FormComponent props={props} isLoading={isLoading} render={render} />}
    </Formik>
  )
}

RequestForm.defaultProps = {
  resetAfterSuccess: true,
}

export default RequestForm
