import { startCase, without } from 'lodash'
import React from 'react'
import { Box, Chip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  box: {
    marginTop: theme.spacing(-0.5),
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
}))

type Props = {
  value: Record<string, Record<string, boolean>>
}

const formatHealthplans = (healthplans: Record<string, boolean>) => {
  const keys = without(Object.keys(healthplans), 'general')
  return keys.length > 0 ? `(${keys.map(startCase).join(', ')})` : ''
}

const Regions: React.FunctionComponent<Props> = (props) => {
  const { value } = props
  const classes = useStyles()
  return (
    <Box className={classes.box}>
      {Object.entries(value).map(([state, healthplans]) => (
        <Chip key={state} className={classes.chip} label={`${state} ${formatHealthplans(healthplans)}`} size="small" />
      ))}
    </Box>
  )
}

export default Regions
