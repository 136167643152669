import { useEffect } from 'react'

const useBeforeUnload = (handler: (e: Event) => void) => {
  useEffect(() => {
    window.addEventListener('beforeunload', handler)

    return () => {
      window.removeEventListener('beforeunload', handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useBeforeUnload
