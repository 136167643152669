import React, { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import { ReactReduxFirebaseProvider as RRFProvider } from 'react-redux-firebase'

import firebaseApp from './firebaseApp'

const config = {}
const initializeAuth = process.env.NODE_ENV !== 'test'

const ReactReduxFirebaseProvider: FunctionComponent = ({ children }) => {
  const dispatch = useDispatch()
  return (
    <RRFProvider config={config} dispatch={dispatch} firebase={firebaseApp} initializeAuth={initializeAuth}>
      {children}
    </RRFProvider>
  )
}

export default ReactReduxFirebaseProvider
