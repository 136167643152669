export enum BillingCode {
  F10_10 = 'F10_10',
  F10_20 = 'F10_20',
  F10_21 = 'F10_21',
  F10_90 = 'F10_90',
  F11_20 = 'F11_20',
  F12_20 = 'F12_20',
  F20_9 = 'F20_9',
  F25_9 = 'F25_9',
  F31_2 = 'F31_2',
  F31_4 = 'F31_4',
  F31_9 = 'F31_9',
  F31_81 = 'F31_81',
  F32_0 = 'F32_0',
  F32_1 = 'F32_1',
  F32_2 = 'F32_2',
  F32_4 = 'F32_4',
  F32_5 = 'F32_5',
  F32_9 = 'F32_9',
  F33_0 = 'F33_0',
  F33_1 = 'F33_1',
  F33_2 = 'F33_2',
  F33_3 = 'F33_3',
  F33_9 = 'F33_9',
  F33_41 = 'F33_41',
  F33_42 = 'F33_42',
  F34_1 = 'F34_1',
  F41_0 = 'F41_0',
  F41_1 = 'F41_1',
  F41_9 = 'F41_9',
  F42_9 = 'F42_9',
  F43_0 = 'F43_0',
  F43_10 = 'F43_10',
  F43_20 = 'F43_20',
  F43_21 = 'F43_21',
  F43_22 = 'F43_22',
  F43_23 = 'F43_23',
  F50_9 = 'F50_9',
  F53_0 = 'F53_0',
  F60_9 = 'F60_9',
  F84_0 = 'F84_0',
  F84_5 = 'F84_5',
  F90_0 = 'F90_0',
  F90_1 = 'F90_1',
  F90_2 = 'F90_2',
}

type BillingCodeName = {
  short: string
  long: string
}

export const BILLING_CODE_NAMES: Record<BillingCode, BillingCodeName> = {
  F90_2: {
    short: 'adhd comb',
    long: 'Attention-deficit hyperactivity disorder, combined type',
  },
  F90_1: {
    short: 'adhd pre hyp',
    long: 'Attention-deficit hyperactivity disorder, predominantly hyperactive type',
  },
  F90_0: {
    short: 'adhd pre inat',
    long: 'Attention-deficit hyperactivity disorder, predominantly inattentive type',
  },
  F84_5: {
    short: 'asp syn',
    long: 'Asperger syndrome',
  },
  F84_0: {
    short: 'aut spe dis',
    long: 'Autism Spectrum Disorder',
  },
  F60_9: {
    short: 'per dis',
    long: 'Personality disorder not otherwise specified',
  },
  F53_0: {
    short: 'pos depr',
    long: 'Postpartum depression',
  },
  F50_9: {
    short: 'eat dis unspec',
    long: 'Eating disorder, unspecified',
  },
  F43_23: {
    short: 'adj dis w anx depr',
    long: 'Adjustment disorder with mixed anxiety and depressed mood',
  },
  F43_22: {
    short: 'adj dis w anx',
    long: 'Adjustment disorder with anxiety',
  },
  F43_21: {
    short: 'adj dis w depr',
    long: 'Adjustment disorder with depressed mood',
  },
  F43_20: {
    short: 'adj dis unspec',
    long: 'Adjustment Disorder, unspecified',
  },
  F43_10: {
    short: 'ptsd',
    long: 'Post-traumatic stress disorder',
  },
  F43_0: {
    short: 'act stress react',
    long: 'Acute stress reaction',
  },
  F42_9: {
    short: 'ocd unspec',
    long: 'Obsessive-Compulsive Disorder, unspecified',
  },
  F41_9: {
    short: 'anx dis unspec',
    long: 'Anxiety disorder, unspecified',
  },
  F41_1: {
    short: 'gen anx dis',
    long: 'Generalized anxiety disorder',
  },
  F41_0: {
    short: 'panic dis',
    long: 'Panic disorder',
  },
  F34_1: {
    short: 'dysthym dis',
    long: 'Dysthymic disorder',
  },
  F33_9: {
    short: 'mdd recur unspec',
    long: 'MDD, recurrent unspecified',
  },
  F33_42: {
    short: 'mdd recur full rem',
    long: 'MDD, recurrent, in full remission',
  },
  F33_41: {
    short: 'mdd recur part rem',
    long: 'MDD, recurrent, in partial remission',
  },
  F33_3: {
    short: 'mdd recur sev w psych sym',
    long: 'MDD, recurrent, severe w psych symptoms',
  },
  F33_2: {
    short: 'mdd recur sev',
    long: 'MDD, recurrent severe without psychotic features',
  },
  F33_1: {
    short: 'mdd recur mod',
    long: 'MDD, recurrent, moderate',
  },
  F33_0: {
    short: 'mdd recur mild',
    long: 'MDD, recurrent, mild',
  },
  F32_9: {
    short: 'mdd 1 ep unspec',
    long: 'MDD, single episode, unspecified',
  },
  F32_5: {
    short: 'mdd 1 ep full rem',
    long: 'MDD, single episode, in full remission',
  },
  F32_4: {
    short: 'mdd 1 ep part rem',
    long: 'MDD, single episode, in partial remission',
  },
  F32_2: {
    short: 'mdd 1 ep sev',
    long: 'MDD, single episode, severe without psychotic features',
  },
  F32_1: {
    short: 'mdd 1 ep mod',
    long: 'MDD, single episode, moderate',
  },
  F32_0: {
    short: 'mdd 1 ep mild',
    long: 'MDD, single episode, mild',
  },
  F31_9: {
    short: 'bp unspec',
    long: 'Bipolar disorder, unspecified',
  },
  F31_81: {
    short: 'bp 2',
    long: 'Bipolar II disorder',
  },
  F31_4: {
    short: 'bp sev dep',
    long: 'Bipolar disorder, current episode severe depression without psychotic symptoms',
  },
  F31_2: {
    short: 'bp manic',
    long: 'Bipolar disorder, current episode manic with psychotic symptoms',
  },
  F25_9: {
    short: 'schizoaf dis unspec',
    long: 'Schizoaffective disorder, unspecified',
  },
  F20_9: {
    short: 'schizophr unspec',
    long: 'Schizophrenia, unspecified',
  },
  F12_20: {
    short: 'canna dep uncomp',
    long: 'Cannabis Dependence, uncomplicated',
  },
  F11_20: {
    short: 'opio dep uncomp',
    long: 'Opiod Dependence, uncomplicated',
  },
  F10_90: {
    short: 'alco use unspec',
    long: 'Alcohol Use, unspecified',
  },
  F10_21: {
    short: 'alco dep in rem',
    long: 'Alcohol Dependence, in remission',
  },
  F10_20: {
    short: 'alco dep uncomp',
    long: 'Alcohol Dependence, uncomplicated',
  },
  F10_10: {
    short: 'alco abuse unspec',
    long: 'Alcohol Abuse, unspecified',
  },
} as const
