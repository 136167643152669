import _ from 'lodash'
import React from 'react'

const getDifference = (participantHistory: Array<GraphParticipantHistory>, type: AllowedType) => {
  const currentScore = _.last(participantHistory)!.value[`${type}_SCORE`]
  const initialScore = _.first(participantHistory)!.value[`${type}_SCORE`]
  const diff = Math.round((currentScore / initialScore - 1) * 100)
  return _.isFinite(diff) ? `${diff}%` : 'N/A'
}

const getInitialValue = (participantHistory: Array<GraphParticipantHistory>, type: AllowedType) =>
  _.first(participantHistory)!.value[`${type}_SCORE`]

type GraphParticipantHistory = {
  value: Record<'PHQ9_SCORE' | 'GAD7_SCORE', number>
}

type AllowedType = 'PHQ9' | 'GAD7'

type Props = {
  participantHistory: Array<GraphParticipantHistory>
  type: AllowedType
}

const ParticipantHistoryScoreChange = ({ participantHistory, type }: Props) => {
  if (!participantHistory || participantHistory.length === 0) {
    return null
  }
  const value =
    participantHistory.length > 1 ? getDifference(participantHistory, type) : getInitialValue(participantHistory, type)
  return <span>{value}</span>
}

export default ParticipantHistoryScoreChange
