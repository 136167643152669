import * as React from 'react'
import { ItemWithSideInfo } from 'src/timeline'
import { Box, Typography } from '@mui/material'
import { VideoCallCancelledBy } from 'src/graphql.generated'
import { FeedVideoCallItemFragment } from '../feedQueries.generated'
import { FeedItemCard } from '../FeedComponents'
import VideoCallHeader from './VideoCallHeader'
import formatDate from './formatDate'

type Props = {
  videoCall: FeedVideoCallItemFragment
}

export default function VideoCallItem({ videoCall }: Props) {
  const header = <VideoCallHeader videoCall={videoCall} />

  return (
    <ItemWithSideInfo displayedTime={videoCall.createdAt} alignItem="left" stickToTop>
      <FeedItemCard variant="outlined" header={header} isCompleted isExpandable>
        <Box px={2} pb={1}>
          <Typography variant="body2">
            <VideoCallCardContent videoCall={videoCall} />
          </Typography>
        </Box>
      </FeedItemCard>
    </ItemWithSideInfo>
  )
}

function VideoCallCardContent({ videoCall }: { videoCall: FeedVideoCallItemFragment }) {
  const { cancelledAt, cancelledBy, coach, startTime, endTime } = videoCall
  const { email: coachEmail } = coach
  const now = new Date()
  const callIsFinished = endTime <= now

  if (cancelledAt) {
    return <VideoCallCancelledCardContent cancelledAt={cancelledAt} cancelledBy={cancelledBy} coachEmail={coachEmail} />
  }

  const coachContent = (
    <>
      Call with <strong>{coachEmail}</strong>
    </>
  )

  if (callIsFinished) {
    return (
      <>
        {coachContent} was had on {formatDate(startTime)}
      </>
    )
  }

  return (
    <>
      {coachContent} is scheduled for {formatDate(startTime)}
    </>
  )
}

function VideoCallCancelledCardContent({
  cancelledAt,
  cancelledBy,
  coachEmail,
}: {
  cancelledAt: Date
  cancelledBy?: VideoCallCancelledBy | null
  coachEmail: string
}) {
  const cancelledAtContent = (
    <>
      Call with <strong>{coachEmail}</strong> was cancelled on {formatDate(cancelledAt)}
    </>
  )

  if (!cancelledBy) {
    return cancelledAtContent
  }

  const cancelledByContent =
    cancelledBy === VideoCallCancelledBy.Participant ? 'by the participant' : 'by the care team'

  return (
    <>
      {cancelledAtContent} {cancelledByContent}
    </>
  )
}
