import { useFormikContext } from 'formik'
import { Button } from '@mui/material'
import React from 'react'

const fullyCoveredCostEstimate = {
  intakeCostEstimate: 0,
  month1CostEstimate: 0,
  month2CostEstimate: 0,
  month3CostEstimate: 0,
}

const PrefillFullyCoveredPlanButton = () => {
  const { setFieldValue } = useFormikContext()

  const handleFullyCoveredCostEstimate = () => {
    setFieldValue('value.costEstimate', fullyCoveredCostEstimate)
    setFieldValue('value.description', `Eligible, no cost`)
  }

  return (
    <Button variant="outlined" onClick={handleFullyCoveredCostEstimate}>
      Fully covered
    </Button>
  )
}

export default PrefillFullyCoveredPlanButton
