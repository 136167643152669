import * as Yup from 'yup'
import { DateTime } from 'luxon'

const isRequiredWhenTrue = { is: true, then: (schema: Yup.NumberSchema) => schema.required() }

const isRequiredWhenGreaterThanZero = {
  is: (value: number) => value > 0,
  then: (schema: Yup.NumberSchema) => schema.required(),
}

const WPAIHoursSchema = Yup.number().min(0)

const WPAIQuestionSchema = Yup.number().min(0).max(10)

export const WPAI_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    WPAI_QUESTION_1: Yup.boolean().default(false).required(),
    WPAI_QUESTION_2: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_3: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_4: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_5: WPAIQuestionSchema.when('WPAI_QUESTION_4', isRequiredWhenGreaterThanZero),
    WPAI_QUESTION_6: WPAIQuestionSchema.required(),
    WPAI_SCORE_1: Yup.number(),
    WPAI_SCORE_2: Yup.number(),
    WPAI_SCORE_3: Yup.number(),
    WPAI_SCORE_4: Yup.number(),
  })
  .required()

export type WPAIFormType = Yup.InferType<typeof WPAI_FORM_SCHEMA>
