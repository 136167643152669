import React from 'react'
import { Field, Form, Formik } from 'formik'
import { CustomTextField } from 'src/form'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button } from '@mui/material'
import { useToasts } from 'react-toast-notifications'
import { VideoCallException } from 'src/graphql.generated'
import { useUpdateCallNotesMutation } from './callNotesMutations.generated'
import CallExceptionSelect from '../CallExceptionSelect/CallExceptionSelect'
import CallNotesSubmitButton from './CallNotesSubmitButton'

type CallNotesFormData = { callNotes: string }

type Props = {
  callID: string
  startTime: Date
  callException?: VideoCallException | null
  notes?: string | null
}

const CallNotesForm = ({ callID, callException, notes, startTime }: Props) => {
  const styles = useStyles()

  const [updateCallNotes, { loading: loadingOfUpdateCallNotes }] = useUpdateCallNotesMutation()

  const { addToast } = useToasts()

  return (
    <Formik<CallNotesFormData>
      initialValues={{ callNotes: notes || '' }}
      onSubmit={async (values) => {
        try {
          await updateCallNotes({ variables: { callID, notes: values.callNotes } })
          addToast('Call notes saved.', {
            appearance: 'success',
          })
        } catch (e) {
          addToast('Update call notes failed.')
        }
      }}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <Field
            component={CustomTextField}
            multiline
            minRows={4}
            resizable
            fullWidth
            label="Write call notes here"
            name="callNotes"
            variant="outlined"
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" pt={1}>
            <Box px={2}>
              <CallExceptionSelect callID={callID} callException={callException} />
            </Box>
            <Box className={styles.buttons}>
              <Button
                type="submit"
                color="primary"
                variant="outlined"
                className={styles.saveDraftButton}
                disabled={isSubmitting || loadingOfUpdateCallNotes}
              >
                Save draft
              </Button>
              <CallNotesSubmitButton
                callID={callID}
                callException={callException}
                callNotes={values.callNotes}
                startTime={startTime}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  saveDraftButton: {
    backgroundColor: '#FFF',
  },
}))

export default CallNotesForm
