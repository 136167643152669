import React from 'react'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { CareTeam, ParticipantWithCareTeam } from '../useLoadSetCareTeamData'
import { CoachProfileWithCoachID } from '../useLoadCoachProfiles'
import CareTeamChanges from './CareTeamChanges'
import CombineNamesWithThreshold from './CombineNamesWithThreshold'

const useStyles = makeStyles((theme) => ({
  topPaddedParticipantName: { paddingTop: theme.spacing(2) },
}))

const NAME_LENGTH_THRESHOLD = 40

type GroupedParticipantsWithCareTeam = {
  key: string
  oldCareTeam: CareTeam
  names: Array<string>
}

const groupParticipantsWithSameOldCareTeam = (oldParticipantCareTeams: Array<ParticipantWithCareTeam>) =>
  oldParticipantCareTeams.reduce(
    (participantGroupedCareTeams: Record<string, GroupedParticipantsWithCareTeam>, participantWithCareTeam) => {
      const { primaryCoachID, careteamCoachIDs } = participantWithCareTeam.oldCareTeam
      // make a key out of old care team coach IDs and use it to group identical changes
      const key = `${primaryCoachID}-${careteamCoachIDs.join('-')}`

      if (!participantGroupedCareTeams[key]) {
        return {
          ...participantGroupedCareTeams,
          [key]: {
            key,
            oldCareTeam: participantWithCareTeam.oldCareTeam,
            names: [`${participantWithCareTeam.firstname} ${participantWithCareTeam.lastname}`],
          },
        }
      }

      participantGroupedCareTeams[key].names.push(
        `${participantWithCareTeam.firstname} ${participantWithCareTeam.lastname}`
      )

      return participantGroupedCareTeams
    },
    {}
  )

type Props = {
  close: () => void
  handleReturn: () => void
  newCareTeam: CareTeam
  oldParticipantCareTeams: Array<ParticipantWithCareTeam>
  coachProfiles: Array<CoachProfileWithCoachID>
  isSubmitting: boolean
}

const ConfirmCareTeamChange: React.FC<Props> = ({
  close,
  handleReturn,
  newCareTeam,
  oldParticipantCareTeams,
  coachProfiles,
  isSubmitting,
}) => {
  const classes = useStyles()

  const groupedParticipantCareTeams = Object.values(groupParticipantsWithSameOldCareTeam(oldParticipantCareTeams))

  return (
    <>
      <ClosableDialogTitle onClose={close}>Summary of Changes</ClosableDialogTitle>
      <DialogContent>
        {groupedParticipantCareTeams.map((participantWithCareteam) => {
          const { key, names, oldCareTeam } = participantWithCareteam
          return (
            <div key={key}>
              <Grid container alignContent="center" spacing={2}>
                <Grid item xs={12}>
                  <CombineNamesWithThreshold
                    styling={classes.topPaddedParticipantName}
                    names={names}
                    threshold={NAME_LENGTH_THRESHOLD}
                    variant="h4"
                  />
                </Grid>
              </Grid>
              <Grid container alignContent="center" spacing={2}>
                <Grid item xs={6}>
                  <CareTeamChanges
                    coachType="primary"
                    coachProfiles={coachProfiles}
                    careTeamID={oldCareTeam.primaryCoachID}
                    newCareTeamID={newCareTeam.primaryCoachID}
                  />
                </Grid>
                <Grid item xs={6}>
                  <CareTeamChanges
                    coachType="other"
                    coachProfiles={coachProfiles}
                    careTeamID={oldCareTeam.careteamCoachIDs}
                    newCareTeamID={newCareTeam.careteamCoachIDs}
                  />
                </Grid>
              </Grid>
            </div>
          )
        })}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleReturn}>
          Back
        </Button>
        <Button color="primary" type="submit" disabled={isSubmitting}>
          Approve
        </Button>
      </DialogActions>
    </>
  )
}

export default ConfirmCareTeamChange
