import React from 'react'
import { Box } from '@mui/material'

const Dash = () => (
  <Box component="span" mx={1 / 2}>
    &ndash;
  </Box>
)

export default Dash
