/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, StoreEnhancer } from 'redux'
import setupReducers, { AppState } from 'src/legacy/reducers'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__?: () => StoreEnhancer
  }
}

export default function configureStore(initialState?: Partial<AppState>) {
  const devToolsEnhancer =
    process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : undefined

  const store = createStore(setupReducers(), initialState, devToolsEnhancer)

  if (process.env.NODE_ENV === 'development' && module.hot) {
    // Enable webpack hot module replacement for reducers
    module.hot.accept('src/legacy/reducers', () => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
      store.replaceReducer(require('src/legacy/reducers').default())
    })
  }

  return store
}
