import React from 'react'
import { Box, Chip } from '@mui/material'

const EmptyPlaceholder = () => (
  <Box alignItems="center" display="flex" height="100%" justifyContent="center" my={2}>
    <Chip label="No entries found" />
  </Box>
)

export default EmptyPlaceholder
