import { useGetFullPatientQuery, FullPatientFragment } from './patientQueries.generated'

type LoadAllParticipantDataResult =
  | {
      status: 'loading'
      patient: FullPatientFragment | undefined
    }
  | {
      status: 'success'
      patient: FullPatientFragment
    }
  | {
      status: 'failure'
      error: Error
      patient: undefined
    }

export default function useLoadAllParticipantData(uid: string): LoadAllParticipantDataResult {
  const { loading, data } = useGetFullPatientQuery({
    variables: {
      uid,
    },
  })
  if (loading) {
    return {
      status: 'loading',
      patient: data?.patient,
    }
  }
  if (!data?.patient) {
    return {
      status: 'failure',
      error: new Error('Could not fetch participant'),
      patient: undefined,
    }
  }
  return {
    status: 'success',
    patient: data.patient,
  }
}
