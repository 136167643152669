import _ from 'lodash'
import { EnrollmentState } from 'src/graphql.generated'
import MeruDate from 'src/legacy/lib/MeruDate'
import { HomeViewParticipantFragment } from './searchParticipants.generated'

export const HOME_VIEW_ENROLLMENT_STATES = [
  { enrollmentState: EnrollmentState.IntakeRegistered, label: 'Registered' },
  { enrollmentState: EnrollmentState.IntakeLoggedIn, label: 'App Logged in' },
  { enrollmentState: EnrollmentState.IntakeCallScheduled, label: 'Call Scheduled' },
  { enrollmentState: EnrollmentState.InProgram, label: 'In Program' },
]

const getWeekNbr = (programStartDate: Date | undefined | null) => MeruDate.now().formatWeekNumber(programStartDate)
const participantName = (participant: HomeViewParticipantFragment) => {
  const { firstname, lastname } = participant
  return `${firstname} ${lastname}`
}
const participantIntakeCallTime = (participant: HomeViewParticipantFragment) => {
  const { intakeCallTime } = participant
  return intakeCallTime
}
const sortByProgramStartDate = (participants: Array<HomeViewParticipantFragment>) => {
  const participantsByWeekNumber = _.groupBy(participants, (participant) =>
    getWeekNbr(participant.enrollment.programStartDate)
  )

  return _.chain(Object.entries(participantsByWeekNumber))
    .sortBy(([weekNbr]) => Number(weekNbr))
    .map(([weekNbr, participantsOnWeekNumber]) => ({
      key: weekNbr,
      label: `Week ${weekNbr}`,
      participants: _.sortBy(participantsOnWeekNumber, participantName),
    }))
    .value()
}

const sortByEnrollmentState = (participants: Array<HomeViewParticipantFragment>) => {
  const participantsByEnrollmentState = _.groupBy(participants, (participant) => participant.enrollment.state)

  return _.chain(Object.entries(participantsByEnrollmentState))
    .sortBy(([enrollmentState]) =>
      HOME_VIEW_ENROLLMENT_STATES.findIndex((entry) => entry.enrollmentState === enrollmentState)
    )
    .flatMap(([enrollmentState, participantsOfEnrollmentState]) => {
      if (enrollmentState !== EnrollmentState.InProgram) {
        const label = HOME_VIEW_ENROLLMENT_STATES.find((entry) => entry.enrollmentState === enrollmentState)?.label
        return {
          key: enrollmentState,
          label: label ?? 'Unknown',
          participants:
            enrollmentState === EnrollmentState.IntakeCallScheduled
              ? _.sortBy(participantsOfEnrollmentState, participantIntakeCallTime)
              : _.sortBy(participantsOfEnrollmentState, participantName),
        }
      }

      return sortByProgramStartDate(participantsOfEnrollmentState)
    })
    .value()
}

const sortParticipants = (participants: Array<HomeViewParticipantFragment>) => sortByEnrollmentState(participants)

export default sortParticipants
