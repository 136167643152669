import { useFormikContext } from 'formik'
import { fromJSDate } from 'src/date/DateString'
import {
  FullPatientFinancialEligibilityCheckFragment,
  useGetEligibilityInputsQuery,
} from './financialEligibilityHealthPlanQueries.generated'
import { formatUTCYearMonthDate } from './formatUTCYearMonthDate'

export function useInitializeInsuranceSubscriberFields(
  uid: string,
  onCompleted: (result: FullPatientFinancialEligibilityCheckFragment) => void
) {
  const { setFieldValue, status } = useFormikContext()
  const viewOnly = status?.viewOnly ?? false

  const onResponse = (result: FullPatientFinancialEligibilityCheckFragment) => {
    const { coverageActive, subscriber } = result
    setFieldValue('value.insuranceSubscriber', {
      insuranceSubscriberProvided: true,
      coverageActive,
      memberID: subscriber.id,
      groupDescription: subscriber.groupDescription,
      groupNumber: subscriber.groupNumber,
      firstname: subscriber.firstName,
      lastname: subscriber.lastName,
      birthDate: subscriber.birthDate ? formatUTCYearMonthDate(subscriber.birthDate) : undefined,
    })
    onCompleted(result)
  }

  const eligibilityInputsQuery = useGetEligibilityInputsQuery({
    variables: { uid },
    skip: viewOnly,
    onCompleted: (data) => {
      if (data.patient.financialEligibilityCheck) {
        onResponse(data.patient.financialEligibilityCheck)
      } else {
        const insuranceDetailsRequired = Boolean(data.patient.credentialingHealthPlan)

        const birthDate = data.patient.birthDate ? fromJSDate(data.patient.birthDate) : undefined
        setFieldValue('value.insuranceSubscriber', {
          insuranceSubscriberProvided: insuranceDetailsRequired,
          coverageActive: false,
          memberID: '',
          groupDescription: null,
          groupNumber: null,
          firstname: data.patient.firstname ?? '',
          lastname: data.patient.lastname ?? '',
          birthDate,
        })
      }
    },
  })

  return { eligibilityInputsQuery, setInsuranceSubscriberValue: onResponse }
}
