/* eslint-disable max-len */

import React from 'react'
import { FastField, Field, Form, Formik } from 'formik'
import { CustomCheckbox, CustomTextField, FormDatePicker } from 'src/form'
import { PatientHistoryType } from 'src/graphql.generated'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import _ from 'lodash'
import RecalculateScores from './RecalculateScores'
import { WPAIFormType, WPAI_FORM_SCHEMA } from './types'
import SubmitButton from '../SubmitButton'

const WPAI_QUESTIONS = {
  WPAI_QUESTION_1: 'Are you currently employed (working for pay)?',
  WPAI_QUESTION_2:
    'During the past seven days, how many hours did you miss from work because of problems associated with your depression or burnout?',
  WPAI_QUESTION_3:
    'During the past seven days, how many hours did you miss from work because of any other reason, such as vacation, holidays, time off to participate in this study?',
  WPAI_QUESTION_4: 'During the past seven days, how many hours did you actually work?',
  WPAI_QUESTION_5:
    'During the past seven days, how much did your depression or burnout affect your productivity while you were working?',
  WPAI_QUESTION_6:
    'During the past seven days, how much did your depression or burnout affect your ability to do your regular daily activities, other than work at a job?',
  WPAI_SCORE_1: 'Percent work time missed due to health',
  WPAI_SCORE_2: 'Percent impairment while working due to health',
  WPAI_SCORE_3: 'Percent overall work impairment due to health',
  WPAI_SCORE_4: 'Percent activity impairment due to health',
}

const DYNAMIC_QUESTION_FIELDS: Array<keyof typeof WPAI_QUESTIONS> = [
  'WPAI_QUESTION_2',
  'WPAI_QUESTION_3',
  'WPAI_QUESTION_4',
]

const SCORE_FIELDS: Array<keyof typeof WPAI_QUESTIONS> = [
  'WPAI_SCORE_1',
  'WPAI_SCORE_2',
  'WPAI_SCORE_3',
  'WPAI_SCORE_4',
]

const WPAIForm = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<WPAIFormType>
      validationSchema={WPAI_FORM_SCHEMA}
      initialValues={WPAI_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.Wpai,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />
          <FastField
            component={CustomCheckbox}
            fullWidth
            label={WPAI_QUESTIONS.WPAI_QUESTION_1}
            name="WPAI_QUESTION_1"
          />
          {values.WPAI_QUESTION_1 &&
            DYNAMIC_QUESTION_FIELDS.map((key) => (
              <Field
                component={CustomTextField}
                label={WPAI_QUESTIONS[key]}
                key={key}
                labelPosition="left"
                name={key}
                type="number"
              />
            ))}
          {values.WPAI_QUESTION_1 && (
            <Field
              component={CustomTextField}
              disabled={!(values.WPAI_QUESTION_4 && values.WPAI_QUESTION_4 > 0)}
              label={WPAI_QUESTIONS.WPAI_QUESTION_5}
              labelPosition="left"
              name="WPAI_QUESTION_5"
              placeholder="0-10"
              type="number"
            />
          )}
          <FastField
            component={CustomTextField}
            label={WPAI_QUESTIONS.WPAI_QUESTION_6}
            labelPosition="left"
            name="WPAI_QUESTION_6"
            placeholder="0-10"
            type="number"
          />
          {SCORE_FIELDS.map((key) => (
            <FastField
              component={CustomTextField}
              disabled
              label={WPAI_QUESTIONS[key]}
              key={key}
              labelPosition="left"
              name={key}
              type="number"
            />
          ))}
          <RecalculateScores />
          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default WPAIForm
