import { useFormikContext } from 'formik'
import { useToasts } from 'react-toast-notifications'
import { Button } from '@mui/material'
import React from 'react'
import getCostEstimateValuesFromClipboard from './getCostEstimateValuesFromClipboard'

const PasteCostEstimateButton = () => {
  const { setFieldValue } = useFormikContext()
  const { addToast } = useToasts()

  const handlePasteFromClipboard = async () => {
    const costEstimate = await getCostEstimateValuesFromClipboard()

    if (!costEstimate) {
      addToast(
        'Could not read cost estimation values from the clipboard. Make sure that you have copied the values to the clipboard',
        { appearance: 'error' }
      )
      return
    }

    setFieldValue('value.costEstimate', costEstimate)
    setFieldValue(
      'value.description',
      `Intake cost: ${costEstimate.intakeCostEstimate}$ \nMonth 1 cost: ${costEstimate.month1CostEstimate}$ \nMonth 2 cost: ${costEstimate.month2CostEstimate}$ \nMonth 3 cost: ${costEstimate.month3CostEstimate}$`
    )
  }

  return (
    <Button variant="outlined" onClick={handlePasteFromClipboard}>
      Paste from clipboard
    </Button>
  )
}

export default PasteCostEstimateButton
