/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql.generated'

import { InProgramVideoCallFragmentDoc } from '../../videoCallBookingHeaderQueries.generated'

const defaultOptions = {} as const
export type UpdateCallNotesMutationVariables = Types.Exact<{
  callID: Types.Scalars['ID']['input']
  notes: Types.Scalars['String']['input']
}>

export type UpdateCallNotesMutation = {
  __typename?: 'Mutation'
  updateCallNotes?: {
    __typename?: 'VideoCall'
    notes?: string | null
    callException?: Types.VideoCallException | null
    id: string
    startTime: Date
    endTime: Date
    zoomMeetingID: string
    callType: Types.VideoCallType
    joinUrl?: string | null
  } | null
}

export type SubmitCallNotesMutationVariables = Types.Exact<{
  callID: Types.Scalars['ID']['input']
  notes: Types.Scalars['String']['input']
}>

export type SubmitCallNotesMutation = { __typename?: 'Mutation'; submitCallNotes?: null | null }

export const UpdateCallNotesDocument = gql`
  mutation updateCallNotes($callID: ID!, $notes: String!) {
    updateCallNotes(callID: $callID, notes: $notes) {
      ...InProgramVideoCall
    }
  }
  ${InProgramVideoCallFragmentDoc}
`
export type UpdateCallNotesMutationFn = Apollo.MutationFunction<
  UpdateCallNotesMutation,
  UpdateCallNotesMutationVariables
>

/**
 * __useUpdateCallNotesMutation__
 *
 * To run a mutation, you first call `useUpdateCallNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCallNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCallNotesMutation, { data, loading, error }] = useUpdateCallNotesMutation({
 *   variables: {
 *      callID: // value for 'callID'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCallNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCallNotesMutation, UpdateCallNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateCallNotesMutation, UpdateCallNotesMutationVariables>(UpdateCallNotesDocument, options)
}
export type UpdateCallNotesMutationHookResult = ReturnType<typeof useUpdateCallNotesMutation>
export type UpdateCallNotesMutationResult = Apollo.MutationResult<UpdateCallNotesMutation>
export type UpdateCallNotesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCallNotesMutation,
  UpdateCallNotesMutationVariables
>
export const SubmitCallNotesDocument = gql`
  mutation submitCallNotes($callID: ID!, $notes: String!) {
    submitCallNotes(callID: $callID, notes: $notes)
  }
`
export type SubmitCallNotesMutationFn = Apollo.MutationFunction<
  SubmitCallNotesMutation,
  SubmitCallNotesMutationVariables
>

/**
 * __useSubmitCallNotesMutation__
 *
 * To run a mutation, you first call `useSubmitCallNotesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitCallNotesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitCallNotesMutation, { data, loading, error }] = useSubmitCallNotesMutation({
 *   variables: {
 *      callID: // value for 'callID'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useSubmitCallNotesMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitCallNotesMutation, SubmitCallNotesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SubmitCallNotesMutation, SubmitCallNotesMutationVariables>(SubmitCallNotesDocument, options)
}
export type SubmitCallNotesMutationHookResult = ReturnType<typeof useSubmitCallNotesMutation>
export type SubmitCallNotesMutationResult = Apollo.MutationResult<SubmitCallNotesMutation>
export type SubmitCallNotesMutationOptions = Apollo.BaseMutationOptions<
  SubmitCallNotesMutation,
  SubmitCallNotesMutationVariables
>
