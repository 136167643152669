import React from 'react'
import { TableRow, TableCell, Typography } from '@mui/material'
import { isNil } from 'lodash'

type Props = {
  label: string
  content?: JSX.Element | number | string | null
  helperText?: JSX.Element | string | null
}

const ParticipantResultsTextRow: React.FunctionComponent<Props> = ({ label, content, helperText }) => (
  <TableRow>
    <TableCell>{label}</TableCell>
    <TableCell>
      <Typography variant="inherit">{isNil(content) ? 'n/a' : content}</Typography>
    </TableCell>
    <TableCell>
      <Typography color="textSecondary" variant="inherit">
        {helperText}
      </Typography>
    </TableCell>
  </TableRow>
)

export default ParticipantResultsTextRow
