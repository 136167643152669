import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as Yup from 'yup'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { CustomRadio, CustomRadioGroup } from 'src/form'
import { Field, Form, Formik } from 'formik'
import RescheduleCallForm from 'src/participant/CallScheduled/RescheduleCallForm'
import ConfirmParticipantReschedule from 'src/participant/CallScheduled/ConfirmParticipantReschedule'

enum MenuOption {
  COACH_MANUAL_RESCHEDULE = 'COACH_MANUAL_RESCHEDULE',
  PARTICIPANT_INAPP_RESCHEDULE = 'PARTICIPANT_INAPP_RESCHEDULE',
}

const ModifyScheduledCallSchema = Yup.object()
  .required()
  .shape({
    value: Yup.string().oneOf(Object.keys(MenuOption)),
  })

type Props = {
  close: () => void
  open: boolean
  programID?: string
  uid: string
}

const useStyles = makeStyles({
  radioGroup: {
    marginTop: 0,
  },
})

const ModifyScheduledCallModal: React.FunctionComponent<Props> = (props) => {
  const { close, open, uid, programID } = props
  const [renderComponent, setRenderComponent] = useState<MenuOption | null>(null)
  const styles = useStyles()

  return (
    <Dialog fullWidth maxWidth="xs" onClose={close} open={open}>
      {!renderComponent && (
        <Formik
          onSubmit={(values) => setRenderComponent(values.value)}
          validationSchema={ModifyScheduledCallSchema}
          initialValues={{ value: renderComponent }}
        >
          <Form>
            <ClosableDialogTitle onClose={close}>How would you like to modify this call?</ClosableDialogTitle>
            <DialogContent>
              <Field component={CustomRadioGroup} name="value" type="string" className={styles.radioGroup}>
                <CustomRadio label="Reschedule manually" value={MenuOption.COACH_MANUAL_RESCHEDULE} />
                <CustomRadio
                  label="Cancel call and enable participant to reschedule in app"
                  value={MenuOption.PARTICIPANT_INAPP_RESCHEDULE}
                />
              </Field>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={close}>
                Cancel
              </Button>
              <Button color="primary" disabled={false} type="submit">
                Next
              </Button>
            </DialogActions>
          </Form>
        </Formik>
      )}
      {renderComponent === MenuOption.COACH_MANUAL_RESCHEDULE && (
        <RescheduleCallForm close={() => setRenderComponent(null)} uid={uid} onSuccess={close} />
      )}
      {renderComponent === MenuOption.PARTICIPANT_INAPP_RESCHEDULE && programID && (
        <ConfirmParticipantReschedule
          close={() => setRenderComponent(null)}
          uid={uid}
          programID={programID}
          onSuccess={close}
        />
      )}
    </Dialog>
  )
}

export default ModifyScheduledCallModal
