import React, { createContext, useEffect, useState } from 'react'
import useCheckMessageEditable from 'src/feed/EditChatMessage/useCheckMessageEditable'
import { useToasts } from 'react-toast-notifications'

type EditMessage = {
  text: string
  messageID: string
  uid: string
  createdAt: Date
}

export type EditMessageContextValue = {
  messageToEdit: EditMessage | null
  stopEditingMessage: () => void
  startEditingMessage: (msg: EditMessage) => void
}

export const EditMessageContext = createContext<EditMessageContextValue>(undefined!)

export default function EditMessageProvider({ feedID, children }: { feedID: string; children: React.ReactNode }) {
  const [messageToEdit, setMessageToEdit] = useState<EditMessageContextValue['messageToEdit']>(null)
  const { addToast } = useToasts()

  const stopEditingMessage = () => {
    setMessageToEdit(null)
  }

  const startEditingMessage = (msg: EditMessage) => {
    setMessageToEdit({
      uid: msg.uid,
      text: msg.text,
      messageID: msg.messageID,
      createdAt: msg.createdAt,
    })
  }

  useCheckMessageEditable(messageToEdit?.createdAt, (newEnough) => {
    if (!newEnough && messageToEdit) {
      stopEditingMessage()
      addToast(
        'Messages can only be edited for 5 minutes after sending. If you want, it can be sent as a new message.',
        { appearance: 'info', autoDismiss: false }
      )
    }
  })

  useEffect(() => {
    if (feedID !== messageToEdit?.uid) {
      stopEditingMessage()
    }
  }, [feedID, messageToEdit?.uid])

  return (
    <EditMessageContext.Provider value={{ messageToEdit, stopEditingMessage, startEditingMessage }}>
      {children}
    </EditMessageContext.Provider>
  )
}
