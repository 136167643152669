import React, { useState } from 'react'
import { Button, DialogActions, DialogContent, RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { SchedulingView } from './types'

type Props = {
  onClose: () => void
  onConfirmSchedulingView: (view: SchedulingView) => void
}

const OptionsView = ({ onClose, onConfirmSchedulingView }: Props) => {
  const [selectedSchedulingViewOption, setSelectedSchedulingViewOption] = useState<SchedulingView>()

  return (
    <>
      <ClosableDialogTitle onClose={onClose}>How would you like to modify this call?</ClosableDialogTitle>

      <DialogContent>
        <RadioGroup onChange={(event) => setSelectedSchedulingViewOption(event.target.value as SchedulingView)}>
          <FormControlLabel
            value={SchedulingView.Reschedule}
            control={<Radio color="primary" />}
            label="Reschedule manually"
          />
          <FormControlLabel value={SchedulingView.Cancel} control={<Radio color="primary" />} label="Cancel call" />
        </RadioGroup>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onClose}>
          Dismiss
        </Button>

        <Button
          color="inherit"
          onClick={() => onConfirmSchedulingView(selectedSchedulingViewOption!)}
          disabled={!selectedSchedulingViewOption}
        >
          Next
        </Button>
      </DialogActions>
    </>
  )
}

export default OptionsView
