import { useFormikContext } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import ScoreFields from './ScoreFields'

const GAD7_QUESTIONS = [
  {
    key: 'GAD7_QUESTION_1',
    label: 'Feeling nervous, anxious or on edge?',
  },
  {
    key: 'GAD7_QUESTION_2',
    label: 'Not being able to stop or control worrying?',
  },
  {
    key: 'GAD7_QUESTION_3',
    label: 'Worrying too much about different things?',
  },
  {
    key: 'GAD7_QUESTION_4',
    label: 'Trouble relaxing?',
  },
  {
    key: 'GAD7_QUESTION_5',
    label: 'Being so restless that it is hard to sit still?',
  },
  {
    key: 'GAD7_QUESTION_6',
    label: 'Becoming easily annoyed or irritable?',
  },
  {
    key: 'GAD7_QUESTION_7',
    label: 'Feeling afraid as if something awful might happen?',
  },
  {
    key: 'GAD7_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 3,
}

const ScoreValueSchema = Yup.number().required().min(SCORE_RANGE.VALUE_MIN).max(SCORE_RANGE.VALUE_MAX)

export const GAD7_SCHEMA = Yup.object()
  .shape({
    GAD7_QUESTION_1: ScoreValueSchema,
    GAD7_QUESTION_2: ScoreValueSchema,
    GAD7_QUESTION_3: ScoreValueSchema,
    GAD7_QUESTION_4: ScoreValueSchema,
    GAD7_QUESTION_5: ScoreValueSchema,
    GAD7_QUESTION_6: ScoreValueSchema,
    GAD7_QUESTION_7: ScoreValueSchema,
    GAD7_SCORE: Yup.number().required().min(0).max(21),
  })
  .default({})
  .required()

export type GAD7Schema = Yup.InferType<typeof GAD7_SCHEMA>

const GAD7Fields = () => {
  const { setFieldValue, values } = useFormikContext<{ value: GAD7Schema }>()
  return <ScoreFields type="GAD7" values={values} setFieldValue={setFieldValue} questions={GAD7_QUESTIONS} />
}

export default GAD7Fields
