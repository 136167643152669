import * as Yup from 'yup'
import { PatientAbTest } from 'src/graphql.generated'

export const EMPTY_VALUE = '@@EMPTY_VALUE@@'

export const getABTestVariablesSchema = (abTests?: Array<Pick<PatientAbTest, 'name' | 'possibleGroups'>> | null) => {
  const abTestVariablesSchema: Record<string, Yup.Schema<string | null>> = {}
  abTests?.forEach((test) => {
    abTestVariablesSchema[test.name] = Yup.lazy((value) => {
      if (value === EMPTY_VALUE || value === null) {
        return Yup.string()
          .transform(() => null)
          .nullable()
      }
      return Yup.string().oneOf(test.possibleGroups).required()
    })
  })

  return Yup.object()
    .shape({
      patientABTestsByName: Yup.object().shape(abTestVariablesSchema),
    })
    .required()
}
