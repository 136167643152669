import _ from 'lodash'
import React from 'react'
import { Box , Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'


import CommaSeparatedList from 'src/legacy/components/CommaSeparatedList'
import UidShorthand from 'src/legacy/components/UidShorthand'
import { isSuccess, useFirebase } from 'src/firebase'
import { CoachProfiles } from 'src/legacy/models/firebase'
import { CareTeamMember } from 'src/graphql.generated'

const orderCareTeam = (careTeam?: Array<CareTeamMember> | null) => _(careTeam).orderBy('isPrimary', 'desc').value()

type CoachNameProps = {
  coachID: string
  coachProfiles?: CoachProfiles | null
}

const CoachName = ({ coachID, coachProfiles }: CoachNameProps) => {
  const coachProfile = _.get(coachProfiles, coachID)
  if (coachProfile) {
    return <>{`${coachProfile.firstname} ${_.first(coachProfile.lastname)}.`}</>
  }
  return <UidShorthand uid={coachID} />
}

const useStyles = makeStyles({
  root: {
    minWidth: 60,
  },
})

type Props = {
  careTeam?: Array<CareTeamMember> | null
}

const CareTeam = ({ careTeam }: Props) => {
  const coachProfilesRequest = useFirebase<CoachProfiles>('coachProfiles')

  const classes = useStyles()

  if (!isSuccess(coachProfilesRequest)) {
    return <Skeleton classes={classes} />
  }

  const coachProfiles = coachProfilesRequest.data

  return (
    <CommaSeparatedList>
      {orderCareTeam(careTeam).map(({ coachID, isPrimary }) => (
        <Box key={coachID} color={isPrimary ? 'text.primary' : 'text.secondary'} component="span">
          <CoachName coachID={coachID} coachProfiles={coachProfiles} />
        </Box>
      ))}
    </CommaSeparatedList>
  )
}

export default CareTeam
