import React, { Fragment } from 'react'
import { isObjectLike } from 'lodash'
import { Table, TableBody, TableCell, TableRow, TableCellProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Value from './Value' // eslint-disable-line import/no-cycle
import FormattedValue from './FormattedValue'

type ValueTableProps = {
  rows: Array<[string, unknown]>
}

export default function ValueTable({ rows }: ValueTableProps) {
  const classes = useTableStyles({})
  return (
    <Table
      classes={{
        root: classes.root,
      }}
      size="small"
      component="table"
    >
      <TableBody>{rows.map(renderTableRows)}</TableBody>
    </Table>
  )
}

const renderTableRows = ([key, value]: [string, unknown]) => {
  if (isObjectLike(value)) {
    return (
      <Fragment key={key}>
        <TableRow>
          <TableCellBorderless>
            <FormattedValue content={key} />
          </TableCellBorderless>
        </TableRow>
        {/* eslint-disable-next-line @typescript-eslint/ban-types */}
        {Object.entries(value as object).map((entry) => (
          <TableRowContent key={entry[0]} content={entry} indent />
        ))}
      </Fragment>
    )
  }
  return <TableRowContent key={key} content={[key, value]} />
}

type TableRowContentProps = {
  content: [string, unknown]
  indent?: boolean
}

function TableRowContent({ content, indent }: TableRowContentProps) {
  const [key, value] = content
  return (
    <TableRow>
      <TableCellBorderless indent={indent}>
        <FormattedValue content={key} />
      </TableCellBorderless>
      <TableCellBorderless>
        <Value value={value} />
      </TableCellBorderless>
    </TableRow>
  )
}

type TableCellBorderlessProps = TableCellProps & {
  indent?: boolean
}

function TableCellBorderless(props: TableCellBorderlessProps) {
  const { indent: _, ...rest } = props
  return (
    <TableCell
      {...rest}
      classes={{
        root: useTableStyles(props).cellRoot,
      }}
    />
  )
}

const useTableStyles = makeStyles((theme) => ({
  root: {
    width: 'auto',
  },
  cellRoot: {
    border: 'none',
    fontWeight: 'bold',
    paddingLeft: (props: TableCellBorderlessProps) => (props.indent ? theme.spacing(5) : undefined),
  },
}))
