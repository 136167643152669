/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

const defaultOptions = {} as const
export type ChangeAccessLevelMutationVariables = Types.Exact<{
  level: Types.AccessLevel
}>

export type ChangeAccessLevelMutation = {
  __typename?: 'Mutation'
  changeAccessLevel: {
    __typename?: 'Session'
    firebaseToken?: string | null
    isAdmin?: boolean | null
    isCoach?: boolean | null
    isManager?: boolean | null
    isCareCoordinator?: boolean | null
    isCommunityFacilitator?: boolean | null
    refreshAt?: number | null
    validUntil?: number | null
  }
}

export type GetSessionQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetSessionQuery = {
  __typename?: 'Query'
  session?: {
    __typename?: 'Session'
    uid: string
    isAdmin?: boolean | null
    isCoach?: boolean | null
    isManager?: boolean | null
    isCareCoordinator?: boolean | null
    isCommunityFacilitator?: boolean | null
    refreshAt?: number | null
    validUntil?: number | null
  } | null
}

export type LoginMutationVariables = Types.Exact<{
  idToken: Types.Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'Session'
    uid: string
    email?: string | null
    firebaseToken?: string | null
    isAdmin?: boolean | null
    isCoach?: boolean | null
    isManager?: boolean | null
    isCareCoordinator?: boolean | null
    isCommunityFacilitator?: boolean | null
    name?: string | null
    refreshAt?: number | null
    validUntil?: number | null
  } | null
}

export type LogoutMutationVariables = Types.Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout?: null | null }

export type RefreshAccessTokenMutationVariables = Types.Exact<{ [key: string]: never }>

export type RefreshAccessTokenMutation = {
  __typename?: 'Mutation'
  refreshAccessToken: {
    __typename?: 'Session'
    uid: string
    firebaseToken?: string | null
    isAdmin?: boolean | null
    isCoach?: boolean | null
    isManager?: boolean | null
    isCareCoordinator?: boolean | null
    isCommunityFacilitator?: boolean | null
    refreshAt?: number | null
    validUntil?: number | null
  }
}

export const ChangeAccessLevelDocument = gql`
  mutation changeAccessLevel($level: AccessLevel!) {
    changeAccessLevel(level: $level) {
      firebaseToken
      isAdmin
      isCoach
      isManager
      isCareCoordinator
      isCommunityFacilitator
      refreshAt
      validUntil
    }
  }
`
export type ChangeAccessLevelMutationFn = Apollo.MutationFunction<
  ChangeAccessLevelMutation,
  ChangeAccessLevelMutationVariables
>

/**
 * __useChangeAccessLevelMutation__
 *
 * To run a mutation, you first call `useChangeAccessLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAccessLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAccessLevelMutation, { data, loading, error }] = useChangeAccessLevelMutation({
 *   variables: {
 *      level: // value for 'level'
 *   },
 * });
 */
export function useChangeAccessLevelMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeAccessLevelMutation, ChangeAccessLevelMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangeAccessLevelMutation, ChangeAccessLevelMutationVariables>(
    ChangeAccessLevelDocument,
    options
  )
}
export type ChangeAccessLevelMutationHookResult = ReturnType<typeof useChangeAccessLevelMutation>
export type ChangeAccessLevelMutationResult = Apollo.MutationResult<ChangeAccessLevelMutation>
export type ChangeAccessLevelMutationOptions = Apollo.BaseMutationOptions<
  ChangeAccessLevelMutation,
  ChangeAccessLevelMutationVariables
>
export const GetSessionDocument = gql`
  query getSession {
    session {
      uid
      isAdmin
      isCoach
      isManager
      isCareCoordinator
      isCommunityFacilitator
      refreshAt
      validUntil
    }
  }
`

/**
 * __useGetSessionQuery__
 *
 * To run a query within a React component, call `useGetSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetSessionQuery, GetSessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options)
}
export function useGetSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSessionQuery, GetSessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetSessionQuery, GetSessionQueryVariables>(GetSessionDocument, options)
}
export type GetSessionQueryHookResult = ReturnType<typeof useGetSessionQuery>
export type GetSessionLazyQueryHookResult = ReturnType<typeof useGetSessionLazyQuery>
export type GetSessionQueryResult = Apollo.QueryResult<GetSessionQuery, GetSessionQueryVariables>
export const LoginDocument = gql`
  mutation login($idToken: String!) {
    login(idToken: $idToken) {
      uid
      email
      firebaseToken
      isAdmin
      isCoach
      isManager
      isCareCoordinator
      isCommunityFacilitator
      name
      refreshAt
      validUntil
    }
  }
`
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options)
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>
export const LogoutDocument = gql`
  mutation logout {
    logout
  }
`
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options)
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>
export const RefreshAccessTokenDocument = gql`
  mutation refreshAccessToken {
    refreshAccessToken {
      uid
      firebaseToken
      isAdmin
      isCoach
      isManager
      isCareCoordinator
      isCommunityFacilitator
      refreshAt
      validUntil
    }
  }
`
export type RefreshAccessTokenMutationFn = Apollo.MutationFunction<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>

/**
 * __useRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAccessTokenMutation, { data, loading, error }] = useRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RefreshAccessTokenMutation, RefreshAccessTokenMutationVariables>(
    RefreshAccessTokenDocument,
    options
  )
}
export type RefreshAccessTokenMutationHookResult = ReturnType<typeof useRefreshAccessTokenMutation>
export type RefreshAccessTokenMutationResult = Apollo.MutationResult<RefreshAccessTokenMutation>
export type RefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshAccessTokenMutation,
  RefreshAccessTokenMutationVariables
>
