import React from 'react'
import { Button } from '@mui/material'
import * as Yup from 'yup'

import { RequestForm } from 'src/form'
import { InlineForm } from 'src/legacy/components/mui'
import QueryField from './QueryField'

const SearchParticipantSchema: Yup.ObjectSchema<{
  query?: string
}> = Yup.object({
  query: Yup.string(),
}).required()

type Props = {
  value?: string
  onChange: (query?: string) => void
}
const SearchParticipantForm = (props: Props) => (
  <RequestForm
    onSubmit={({ query }) => {
      props.onChange(query)
    }}
    initialValues={{ query: props.value }}
    resetAfterSuccess={false}
    validationSchema={SearchParticipantSchema}
    render={({ isSubmitting }) => (
      <InlineForm variant="dense">
        <QueryField />
        <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
          Search
        </Button>
      </InlineForm>
    )}
  />
)

export default SearchParticipantForm
