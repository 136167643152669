import { TypePolicies } from '@apollo/client'

export const communityTypePolicies: TypePolicies = {
  Query: {
    fields: {
      communityPost: {
        keyArgs: ['postID'],
        read: (post, { args, toReference }) =>
          post ?? toReference({ __typename: 'CommunityPost', postID: args?.postID }),
      },
    },
  },
  CommunityPost: {
    keyFields: (post) => `CommunityPost:${post.postID}`,
    merge: true,
    fields: {
      communityPostReplies: { merge: true },
    },
  },
  CommunityPostReply: {
    keyFields: ['replyID'],
    merge: true,
  },
  CommunityPostReplyQuestionAnswer: {
    keyFields: ['replyID'],
    merge: true,
  },
  CommunityPostReplies: {
    fields: {
      replies: {
        merge: false,
      },
    },
  },
}
