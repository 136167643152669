import React from 'react'
import { Field } from 'formik'
import { MenuItem } from '@mui/material'

import { PROGRAM_IDS, TEST_PROGRAM_IDS } from 'src/config'
import { CustomTextField } from 'src/form'

type Props = {
  test?: boolean
}

const ProgramIDField: React.FunctionComponent<Props> = ({ test }) => {
  const PROGRAMS = test ? TEST_PROGRAM_IDS : PROGRAM_IDS
  return (
    <Field component={CustomTextField} fullWidth label="Program ID" name="programID" type="select">
      {PROGRAMS.map((program) => (
        <MenuItem key={program} value={program}>
          {program}
        </MenuItem>
      ))}
    </Field>
  )
}

export default ProgramIDField
