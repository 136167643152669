import { useCallback, useContext, useEffect, useState } from 'react'
import { clearItem, getItem, setItem } from 'src/legacy/lib/localStorageWithTTL'
import { EditMessageContext } from 'src/feed/EditChatMessage/EditMessageProvider'
import _ from 'lodash'
import prefixUID from './messageLocalStoragePrefixUID'

export const DRAFT_MESSAGE_EVENT_NAME = 'DraftChanged'

const dispatchDraftMessageEvent = _.debounce((eventName: string) => {
  window.dispatchEvent(new Event(eventName))
}, 500)

const useMessage = (uid: string) => {
  const { messageToEdit } = useContext(EditMessageContext)
  const [message, setMessage] = useState<string>('')
  const [draftSaved, setDraftSaved] = useState<boolean>(false)

  useEffect(() => {
    if (messageToEdit?.text && messageToEdit.uid === uid) {
      setMessage(messageToEdit.text)
    }
  }, [messageToEdit, uid])

  useEffect(() => {
    const storedMessage = getItem(prefixUID(uid))
    setMessage(storedMessage ?? '')
    setDraftSaved(!!storedMessage)
  }, [uid])

  const set = useCallback(
    (newMessage: string) => {
      setMessage(newMessage)
      if (newMessage === '') {
        clearItem(prefixUID(uid))
        setDraftSaved(false)
      } else if (!messageToEdit) {
        setItem(prefixUID(uid), newMessage)
        setDraftSaved(true)
      }
      dispatchDraftMessageEvent(`${DRAFT_MESSAGE_EVENT_NAME}_${uid}`)
    },
    [messageToEdit, uid]
  )

  return { draftSaved, message, setMessage: set }
}

export default useMessage
