import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { EmptyPlaceholder } from 'src/legacy/components/mui'
import useLoadSources from 'src/participantsView/useLoadSources'

const Sources: React.FunctionComponent = () => {
  const sources = useLoadSources()

  if (!sources.length) {
    return <EmptyPlaceholder />
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Source</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {sources.map((source) => (
          <TableRow key={source}>
            <TableCell>{source}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default Sources
