import React, { useContext, useState } from 'react'
import { ResponsePreview } from 'src/message'
import { EditMessageContext } from 'src/feed/EditChatMessage/EditMessageProvider'
import { IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClearIcon from '@mui/icons-material/Clear'
import { useToasts } from 'react-toast-notifications'
import { MessageSubmitDisplayType, MessageType } from '../useChatDisplayRules'
import ChatTypingField from './ChatTypingField'
import { useMentionResponse } from '../mentionResponse'
import SmsConsentStorer from './SmsConsentStorer'
import { hasGivenSmsSendingConsent } from './smsConsentHandler'
import useSubmit from './useSubmit'
import useMessage from './useMessage'

// https://support.twilio.com/hc/en-us/articles/360033806753-Maximum-Message-Length-with-Twilio-Programmable-Messaging
const SMS_MAX_CHARACTERS = 1600

type Props = {
  feedID: string
  messageSubmitDisplayType: MessageSubmitDisplayType
  messageCreatedCallback: () => void
}

export default function ChatMessageSendingSection(props: Props) {
  const { feedID, messageCreatedCallback, messageSubmitDisplayType } = props
  const { addToast } = useToasts()
  const styles = useStyles()

  const [displayConsentDialog, setDisplayConsentDialog] = useState(false)
  const closeDialog = () => setDisplayConsentDialog(false)

  const { response, removeResponse } = useMentionResponse()

  const { messageToEdit, stopEditingMessage } = useContext(EditMessageContext)
  const { draftSaved, message, setMessage } = useMessage(feedID)
  const { createMessage, editMessage, isSendingMessage } = useSubmit(messageCreatedCallback)

  const onSubmit = (messageType: MessageType) => {
    if (messageType === MessageType.SMS && message.length > SMS_MAX_CHARACTERS) {
      addToast(`SMS message is too long. Maximum limit: ${SMS_MAX_CHARACTERS} characters.`)
      return
    }

    if (messageType === MessageType.SMS && !hasGivenSmsSendingConsent()) {
      setDisplayConsentDialog(true)
      return
    }

    if (message.trim() === '') {
      if (messageToEdit) {
        addToast('Message cannot be empty')
      }
      return
    }

    if (messageToEdit) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      editMessage(messageToEdit.uid, messageToEdit.messageID, message)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      createMessage(messageType, feedID, message, response)
    }
    removeResponse()
    setMessage('')
  }

  const stopEditingClearMessage = () => {
    stopEditingMessage()
    setMessage('')
  }

  return (
    <>
      <SmsConsentStorer closeDialog={closeDialog} displayConsentDialog={displayConsentDialog} />
      {messageToEdit?.messageID && (
        <div className={styles.editingMessageContainer}>
          <Typography color="textSecondary" classes={{ root: styles.editingMessage }}>
            Editing message (available for 5 minutes)
          </Typography>
          <IconButton
            className={styles.closeEdit}
            onClick={stopEditingClearMessage}
            aria-label="Cancel message editing"
          >
            <ClearIcon />
          </IconButton>
        </div>
      )}
      <ChatTypingField
        draftSaved={draftSaved}
        // focus input when there is a response or when editing a message
        grabFocus={Boolean(response) || !!messageToEdit}
        linkedContent={response && <ResponsePreview response={response} onRemoveResponse={removeResponse} />}
        value={message}
        onSubmit={onSubmit}
        onTyping={setMessage}
        disabled={isSendingMessage}
        messageSubmitDisplayType={messageSubmitDisplayType}
      />
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  editingMessage: {
    fontSize: '0.75rem',
    color: theme.palette.text.secondary,
  },
  editingMessageContainer: {
    padding: theme.spacing(0.5, 1),
    background: theme.palette.common.white,
    display: 'flex',
    placeItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeEdit: {
    margin: 0,
    padding: 0,
  },
}))
