/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

import { CommunityPostReplyFragmentFragmentDoc } from './communityPostReplyFragment.generated'

const defaultOptions = {} as const
export type HideCommunityPostReplyMutationVariables = Types.Exact<{
  replyID: Types.Scalars['ID']['input']
}>

export type HideCommunityPostReplyMutation = {
  __typename?: 'Mutation'
  hideCommunityPostReply: {
    __typename?: 'CommunityPostReplyHidden'
    reply: {
      __typename: 'CommunityPostReplyQuestionAnswer'
      replyID: string
      createdAt: Date
      isHidden: boolean
      message: string
      replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
    }
  }
}

export type UnhideCommunityPostReplyMutationVariables = Types.Exact<{
  replyID: Types.Scalars['ID']['input']
}>

export type UnhideCommunityPostReplyMutation = {
  __typename?: 'Mutation'
  unhideCommunityPostReply: {
    __typename?: 'CommunityPostReplyUnhidden'
    reply: {
      __typename: 'CommunityPostReplyQuestionAnswer'
      replyID: string
      createdAt: Date
      isHidden: boolean
      message: string
      replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
    }
  }
}

export const HideCommunityPostReplyDocument = gql`
  mutation hideCommunityPostReply($replyID: ID!) {
    hideCommunityPostReply(replyID: $replyID) {
      reply {
        ...CommunityPostReplyFragment
      }
    }
  }
  ${CommunityPostReplyFragmentFragmentDoc}
`
export type HideCommunityPostReplyMutationFn = Apollo.MutationFunction<
  HideCommunityPostReplyMutation,
  HideCommunityPostReplyMutationVariables
>

/**
 * __useHideCommunityPostReplyMutation__
 *
 * To run a mutation, you first call `useHideCommunityPostReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideCommunityPostReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideCommunityPostReplyMutation, { data, loading, error }] = useHideCommunityPostReplyMutation({
 *   variables: {
 *      replyID: // value for 'replyID'
 *   },
 * });
 */
export function useHideCommunityPostReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<HideCommunityPostReplyMutation, HideCommunityPostReplyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<HideCommunityPostReplyMutation, HideCommunityPostReplyMutationVariables>(
    HideCommunityPostReplyDocument,
    options
  )
}
export type HideCommunityPostReplyMutationHookResult = ReturnType<typeof useHideCommunityPostReplyMutation>
export type HideCommunityPostReplyMutationResult = Apollo.MutationResult<HideCommunityPostReplyMutation>
export type HideCommunityPostReplyMutationOptions = Apollo.BaseMutationOptions<
  HideCommunityPostReplyMutation,
  HideCommunityPostReplyMutationVariables
>
export const UnhideCommunityPostReplyDocument = gql`
  mutation unhideCommunityPostReply($replyID: ID!) {
    unhideCommunityPostReply(replyID: $replyID) {
      reply {
        ...CommunityPostReplyFragment
      }
    }
  }
  ${CommunityPostReplyFragmentFragmentDoc}
`
export type UnhideCommunityPostReplyMutationFn = Apollo.MutationFunction<
  UnhideCommunityPostReplyMutation,
  UnhideCommunityPostReplyMutationVariables
>

/**
 * __useUnhideCommunityPostReplyMutation__
 *
 * To run a mutation, you first call `useUnhideCommunityPostReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnhideCommunityPostReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unhideCommunityPostReplyMutation, { data, loading, error }] = useUnhideCommunityPostReplyMutation({
 *   variables: {
 *      replyID: // value for 'replyID'
 *   },
 * });
 */
export function useUnhideCommunityPostReplyMutation(
  baseOptions?: Apollo.MutationHookOptions<UnhideCommunityPostReplyMutation, UnhideCommunityPostReplyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UnhideCommunityPostReplyMutation, UnhideCommunityPostReplyMutationVariables>(
    UnhideCommunityPostReplyDocument,
    options
  )
}
export type UnhideCommunityPostReplyMutationHookResult = ReturnType<typeof useUnhideCommunityPostReplyMutation>
export type UnhideCommunityPostReplyMutationResult = Apollo.MutationResult<UnhideCommunityPostReplyMutation>
export type UnhideCommunityPostReplyMutationOptions = Apollo.BaseMutationOptions<
  UnhideCommunityPostReplyMutation,
  UnhideCommunityPostReplyMutationVariables
>
