import { DateTime } from 'luxon'
import { useEffect } from 'react'

const EDIT_DURATION_MINUTES = 5

export default function useCheckMessageEditable(createdAt?: Date, callback?: (newEnough: boolean) => void) {
  const messageTooOldDate = createdAt && DateTime.fromJSDate(createdAt).plus({ minutes: EDIT_DURATION_MINUTES })

  useEffect(() => {
    if (!messageTooOldDate) {
      callback?.(false)
      return undefined
    }

    function check() {
      const msgNewEnough =
        createdAt && DateTime.now().diff(DateTime.fromJSDate(createdAt)).as('minutes') < EDIT_DURATION_MINUTES
      callback?.(!!msgNewEnough)
    }

    check()
    const timeout = setTimeout(check, messageTooOldDate.toMillis() - Date.now())

    return () => clearTimeout(timeout)
  }, [callback, createdAt, messageTooOldDate])
}
