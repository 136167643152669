import { useEffect, useState, RefObject, DependencyList, useRef, useLayoutEffect } from 'react'
import { debounce } from 'lodash'

export type ScrollLocation = 'top' | 'bottom'

export type ScrollLocationOptions = {
  ref: RefObject<HTMLElement>
  topTreshold?: number
  bottomTreshold?: number
  scrollDebounceWait?: number
}

export function useScroll(ref: RefObject<HTMLElement>, wait = 30) {
  const [scroll, setScroll] = useState<null | number>(null)
  useEffect(() => {
    const { current: element } = ref
    if (!element) {
      return undefined
    }
    const listener = debounce(() => {
      setScroll(element.scrollTop)
    }, wait)
    // @todo add browser check for passive events
    element.addEventListener('scroll', listener, { passive: true })
    return () => {
      element.removeEventListener('scroll', listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, wait])

  return scroll
}

export function useScrollLocation({
  ref,
  topTreshold = 0,
  bottomTreshold = 0,
  scrollDebounceWait,
}: ScrollLocationOptions): ScrollLocation[] {
  const [locations, setLocations] = useState<ScrollLocation[]>([])
  const scroll = useScroll(ref, scrollDebounceWait)
  useEffect((): void => {
    const { current: area } = ref
    if (!area || scroll === null) return
    const hitsTop = scroll - topTreshold <= 0
    const hitsBottom = scroll >= area.scrollHeight - area.offsetHeight - bottomTreshold
    const activeLocations: ScrollLocation[] = []
    if (hitsTop) {
      activeLocations.push('top')
    }
    if (hitsBottom) {
      activeLocations.push('bottom')
    }
    setLocations(activeLocations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, scroll])

  return locations
}

export function useKeepTheScroll(ref: RefObject<HTMLElement>, deps: DependencyList) {
  const prevScrollHeight = useRef<number>(0)
  useEffect(() => {
    if (ref.current) {
      prevScrollHeight.current = ref.current.scrollHeight
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  useLayoutEffect(() => {
    const { current: element } = ref
    if (element && prevScrollHeight.current > 0) {
      element.scrollTop = element.scrollTop + element.scrollHeight - prevScrollHeight.current
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export function useAutoScrollToTarget(
  targetRef: RefObject<HTMLElement>,
  initiallyEnabled: boolean,
  deps: DependencyList
) {
  const [enabled, setAutoScrollEnabled] = useState<boolean>(initiallyEnabled)
  const initialScroll = useRef<boolean>(true)
  useEffect((): void => {
    const { current: target } = targetRef
    if (!enabled || !target || !target.scrollIntoView) return
    if (initialScroll.current) {
      target.scrollIntoView({ behavior: 'auto' })
      initialScroll.current = false
    } else {
      target.scrollIntoView({ behavior: 'smooth' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  return setAutoScrollEnabled
}
