import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { MenuItem } from '@mui/material'

import { CustomTextField } from 'src/form'
import { EmailTemplate } from 'src/graphql.generated'
import { SendEmailFormType } from './type'

const SelectTemplateField = ({ templates }: { templates: EmailTemplate[] }) => {
  const { setFieldValue, values } = useFormikContext<SendEmailFormType>()

  useEffect(() => {
    const template = templates.find(({ id }) => id === values.templateID)
    if (template) {
      const { description, subject, title } = template
      setFieldValue('description', description)
      setFieldValue('subject', subject)
      setFieldValue('title', title)
    }
  }, [values.templateID, templates, setFieldValue])

  return (
    <Field component={CustomTextField} fullWidth label="Template" name="templateID" type="select">
      {templates.map(({ id, title }) => (
        <MenuItem key={id} value={id}>
          {title}
        </MenuItem>
      ))}
    </Field>
  )
}

export default SelectTemplateField
