import React from 'react'
import { Button, Checkbox, DialogActions, DialogContent, MenuItem } from '@mui/material'

import { Field } from 'formik'
import CustomTextField from 'src/form/CustomTextField'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { CareTeam } from './useLoadSetCareTeamData'
import { CoachProfileWithCoachID } from './useLoadCoachProfiles'

const joinAllCoachNamesTogether = (coachIDs: Array<string>, coachProfiles: Array<CoachProfileWithCoachID>): string =>
  coachIDs
    .map((coachID) => coachProfiles.find((coach) => coach.coachID === coachID))
    .map((coach) => `${coach?.firstname} ${coach?.lastname}`)
    .join(', ')

const createCareteamHelperText = (nbrOfMembers: number) =>
  `${nbrOfMembers} careteam member${nbrOfMembers !== 1 ? 's' : ''} selected`

type SelectPrimaryTherapistProps = {
  coachProfiles: Array<CoachProfileWithCoachID>
}

const SelectPrimaryTherapist: React.FC<SelectPrimaryTherapistProps> = ({ coachProfiles }) => (
  <Field
    component={CustomTextField}
    fullWidth
    label="Primary Provider"
    name="primaryCoachID"
    type="select"
    unregisterOnUnmount={false}
  >
    {coachProfiles.map(({ coachID, firstname, lastname }) => (
      <MenuItem key={coachID} value={coachID}>
        {firstname} {lastname}
      </MenuItem>
    ))}
  </Field>
)

type SelectOtherCoachesProps = {
  careTeam: CareTeam
  coachProfiles: Array<CoachProfileWithCoachID>
}

const SelectOtherCoaches: React.FC<SelectOtherCoachesProps> = ({ careTeam, coachProfiles }) => (
  <Field
    component={CustomTextField}
    fullWidth
    label="Other Careteam Members"
    name="careteamCoachIDs"
    multiple
    helperText={createCareteamHelperText(careTeam.careteamCoachIDs.length)}
    renderValue={(careteamCoachIDs: Array<string>) => joinAllCoachNamesTogether(careteamCoachIDs, coachProfiles)}
    type="select"
    unregisterOnUnmount={false}
  >
    {coachProfiles.map(({ coachID, firstname, lastname }) => (
      <MenuItem key={coachID} value={coachID} disabled={careTeam.primaryCoachID === coachID}>
        <Checkbox
          checked={careTeam.careteamCoachIDs.includes(coachID) || careTeam.primaryCoachID === coachID}
          disabled={careTeam.primaryCoachID === coachID}
          color="primary"
        />
        {firstname} {lastname}
      </MenuItem>
    ))}
  </Field>
)

type Props = {
  careTeam: CareTeam
  close: () => void
  coachProfiles: Array<CoachProfileWithCoachID>
  handleToReview: () => void
}

const SelectCareTeam: React.FC<Props> = ({ careTeam, close, coachProfiles, handleToReview }) => (
  <>
    <ClosableDialogTitle onClose={close}>Change Careteam</ClosableDialogTitle>
    <DialogContent>
      <SelectPrimaryTherapist coachProfiles={coachProfiles} />
      <SelectOtherCoaches careTeam={careTeam} coachProfiles={coachProfiles} />
    </DialogContent>
    <DialogActions>
      <Button color="inherit" onClick={close}>
        Cancel
      </Button>
      <Button disabled={!careTeam.primaryCoachID} color="primary" onClick={handleToReview}>
        Review Changes
      </Button>
    </DialogActions>
  </>
)

export default SelectCareTeam
