import { Box, Divider, List } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'
import { useSessionUID } from 'src/session/useSession'
import { useFetchParticipantsForHomeViewQuery } from './searchParticipants.generated'
import getDateRangeForRecentResponses from './getDateRangeForRecentResponses'
import sortParticipants, { HOME_VIEW_ENROLLMENT_STATES } from './sortParticipants'
import { Participants } from './Participants'
import ParticipantListFilters from './ParticipantListFilters'
import useParticipantFilters from './useParticipantFilters'

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    paddingBottom: theme.spacing(1),
  },
}))

type Props = {
  selectedUid?: string
}

const ParticipantList: FC<Props> = ({ selectedUid }) => {
  const coachID = useSessionUID()
  const { data } = useFetchParticipantsForHomeViewQuery({
    variables: {
      coachIDs: [coachID],
      states: HOME_VIEW_ENROLLMENT_STATES.map((entry) => entry.enrollmentState),
      ...getDateRangeForRecentResponses(),
    },
  })

  const allParticipants = data?.searchPatientsV2.patients || []

  const { availableFilterTags, filteredParticipants, filters, updateFilters } = useParticipantFilters(
    allParticipants,
    selectedUid
  )

  const sorted = sortParticipants(filteredParticipants)

  const styles = useStyles()

  return (
    <Box display="flex" style={{ height: '100%' }} flexDirection="column" alignContent="center" mt={1}>
      <Box className={styles.filterContainer}>
        <ParticipantListFilters filters={filters} tags={availableFilterTags} updateFilters={updateFilters} />
      </Box>
      <Divider />
      <Box overflow="auto" whiteSpace="nowrap">
        <List disablePadding>
          {sorted.map((participantsByEnrollmentState) => {
            const { key, label, participants } = participantsByEnrollmentState
            return (
              <React.Fragment key={key}>
                <Participants participants={participants} label={label} selectedUid={selectedUid} />
              </React.Fragment>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

export default ParticipantList
