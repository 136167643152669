import { Field, Form, Formik } from 'formik'
import React from 'react'
import { FormDatePicker } from 'src/form'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import _ from 'lodash'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import ScoreFields, { getScoreValueSchema } from './ScoreFields'
import SubmitButton from './SubmitButton'

const GAD7_QUESTIONS = [
  {
    key: 'GAD7_QUESTION_1',
    label: 'Feeling nervous, anxious or on edge?',
  },
  {
    key: 'GAD7_QUESTION_2',
    label: 'Not being able to stop or control worrying?',
  },
  {
    key: 'GAD7_QUESTION_3',
    label: 'Worrying too much about different things?',
  },
  {
    key: 'GAD7_QUESTION_4',
    label: 'Trouble relaxing?',
  },
  {
    key: 'GAD7_QUESTION_5',
    label: 'Being so restless that it is hard to sit still?',
  },
  {
    key: 'GAD7_QUESTION_6',
    label: 'Becoming easily annoyed or irritable?',
  },
  {
    key: 'GAD7_QUESTION_7',
    label: 'Feeling afraid as if something awful might happen?',
  },
  {
    key: 'GAD7_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 3,
}
const ScoreValueSchema = getScoreValueSchema(SCORE_RANGE)

const GAD7_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    GAD7_QUESTION_1: ScoreValueSchema,
    GAD7_QUESTION_2: ScoreValueSchema,
    GAD7_QUESTION_3: ScoreValueSchema,
    GAD7_QUESTION_4: ScoreValueSchema,
    GAD7_QUESTION_5: ScoreValueSchema,
    GAD7_QUESTION_6: ScoreValueSchema,
    GAD7_QUESTION_7: ScoreValueSchema,
    GAD7_SCORE: Yup.number().required().min(0).max(21),
  })
  .required()
type GAD7FormType = Yup.InferType<typeof GAD7_FORM_SCHEMA>

const GAD7Form = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<GAD7FormType>
      validationSchema={GAD7_FORM_SCHEMA}
      initialValues={GAD7_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.Gad7,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />

          <ScoreFields
            type="GAD7"
            values={_.omit(values, ['date'])}
            setFieldValue={setFieldValue}
            questions={GAD7_QUESTIONS}
          />

          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default GAD7Form
