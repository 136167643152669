import { FormikProps, Form } from 'formik'
import React, { useEffect } from 'react'

const FormComponent = <T extends Record<string, unknown>>({
  props,
  isLoading,
  render,
}: {
  props: FormikProps<T>
  isLoading: boolean | undefined
  render: (props: FormikProps<T>) => React.ReactElement
}) => {
  const { status, setStatus } = props
  useEffect(() => {
    setStatus({ ...status, isLoading })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])
  return <Form>{render(props)}</Form>
}

export default FormComponent
