import { EnrollmentState } from 'src/graphql.generated'

export const PARTICIPANT_STATES = [
  { label: 'Pre-registered', state: EnrollmentState.IntakePreRegistered },
  { label: 'Registered', state: EnrollmentState.IntakeRegistered },
  { label: 'Logged In', state: EnrollmentState.IntakeLoggedIn },
  { label: 'Call Scheduled', state: EnrollmentState.IntakeCallScheduled },
  { label: 'In Program', state: EnrollmentState.InProgram },
  { label: 'Cancellation Pending', state: EnrollmentState.CancellationPending },
  { label: 'Post Program', state: EnrollmentState.PostProgram },
  { label: 'Did Not Start', state: EnrollmentState.DidNotStart },
  { label: 'Test', state: EnrollmentState.Test },
]

export enum ParticipantTableColumn {
  name = 'name',
  source = 'source',
  careteam = 'careteam',
  status = 'status',
  lastStateTransitionAt = 'lastStateTransitionAt',
  callTime = 'callTime',
  programStartDate = 'programStartDate',
  financialEligibility = 'financialEligibility',
}
