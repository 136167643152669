import React, { KeyboardEvent, FormEvent, useEffect, useRef } from 'react'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Textarea, { TextareaAutosizeProps } from 'react-textarea-autosize'
import { trackChatInputFocus, trackChatSend } from 'src/mixpanel'
import { MessageSubmitDisplayType, MessageType } from '../useChatDisplayRules'
import SubmitButtonGroup from './SubmitButtonGroup'

export type TypingFieldProps = Omit<TextareaAutosizeProps, 'value' | 'disabled' | 'onSubmit'> & {
  value: string
  onSubmit: (messageType: MessageType) => void
  onTyping: (value: string) => void
  grabFocus?: boolean
  disabled: boolean
  draftSaved?: boolean
  linkedContent?: React.ReactNode
  messageSubmitDisplayType: MessageSubmitDisplayType
}

export default function ChatTypingField({
  draftSaved,
  grabFocus,
  value,
  onSubmit,
  onTyping,
  disabled,
  messageSubmitDisplayType,
  linkedContent,
  ...extraTextAreaProps
}: TypingFieldProps) {
  const inputRef = useRef<HTMLTextAreaElement | null>(null)
  const submitRef = useRef<HTMLDivElement | null>(null)
  const styles = useStyles({ width: submitRef.current?.offsetWidth })

  const defaultMessageType =
    messageSubmitDisplayType === MessageSubmitDisplayType.ONE_BUTTON ? MessageType.SMS : MessageType.IN_APP

  const handleDefaultSubmit = (event?: FormEvent) => {
    event?.preventDefault()
    onSubmit(defaultMessageType)
    trackChatSend()
  }
  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    // Send with RETURN and add new line with SHIFT + RETURN
    const { key, shiftKey } = event.nativeEvent
    if (key === 'Enter' && !shiftKey) {
      event.preventDefault()
      if (!disabled) {
        handleDefaultSubmit()
      }
    }
  }

  const submitComponent =
    messageSubmitDisplayType === MessageSubmitDisplayType.ONE_BUTTON ? (
      <Button type="submit" color="primary" variant="contained" disabled={disabled}>
        Send SMS
      </Button>
    ) : (
      <SubmitButtonGroup disabled={disabled} onSubmit={onSubmit} />
    )

  useEffect(() => {
    if (grabFocus && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [grabFocus])
  return (
    <div className={styles.container}>
      {linkedContent}
      <form onSubmit={handleDefaultSubmit} className={styles.formContent}>
        <Textarea
          ref={inputRef}
          minRows={1}
          maxRows={6}
          className={styles.textarea}
          onChange={(event) => onTyping(event.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={() => trackChatInputFocus(true)}
          onBlur={() => trackChatInputFocus(false)}
          placeholder="Type a message..."
          value={value}
          {...extraTextAreaProps}
        />

        {draftSaved && (
          <Typography color="textSecondary" classes={{ root: styles.draftIcon }} variant="caption">
            Draft saved
          </Typography>
        )}
        {/* TODO: You should be able to set ref on Box in mui 5, so fix this when upgrading mui version */}
        <div className={styles.controlsWrapper} ref={submitRef}>
          <Box className={styles.controls}>{submitComponent}</Box>
        </div>
      </form>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    background: theme.palette.common.white,
  },
  formContent: {
    display: 'flex',
  },
  textarea: {
    flex: 1,
    ...theme.typography.body1,
    padding: theme.spacing(2, 3),
    background: 'transparent',
    border: 'none',
    resize: 'none',
    outline: 'none',
  },
  controlsWrapper: {
    alignSelf: 'flex-end',
  },
  controls: {
    padding: theme.spacing(1, 3),
  },
  draftIcon: ({ width }: { width?: number }) => ({
    position: 'absolute',
    bottom: 0,
    right: width,
    zIndex: theme.zIndex.drawer,
    fontStyle: 'italic',
  }),
}))
