type CoachRole = {
  coachRole: 'therapist'
  isPrimary: boolean
}

export type Careteam = Record<string, CoachRole>

export type CoachProfile = {
  email: string
  firstname: string
  lastname: string
  isSuspended?: boolean
  photoURL?: string
  regions?: Record<string, Record<string, boolean>>
  title?: string
  videoUrlSD?: string
}

export type CoachProfiles = Record<string, CoachProfile>

export type DeviceData = {
  appVersion: string
  deviceModel: string
  osVersion: string
  platform: string
  updatedAt: number
}

export type DevicesData = {
  devices?: Record<string, DeviceData>
}

export type Group = {
  careteam?: Record<string, string>
  coachID: string
  members?: Record<string, boolean>
  name: string
  programStartDate: string
  isGroupEnabled?: true
  isOrganizingGroup?: null | boolean
  isTruePatientGroupCreatedAt?: null | number
}

export type Groups = Record<string, Group>

type Device = Record<string, number | string>

export type MetaUserData = {
  devices?: Record<string, Device>
}

export type ConnectionData = {
  currentRoute: string
  lastActivity: number
}

export type OnlineStatus = {
  connections?: Record<string, ConnectionData>
  lastSeenAt: number
}

export type PrivateUserData = {
  careteam?: Careteam
  coachID?: string
  createdAt: number
  firstTimeLoginAt?: number
  groupID?: string
  isFreeRider?: boolean
  programID: string
  timezone?: string
  variables?: Record<string, string>
}

type ProgramWeek = {
  description: string
  title: string
}

export type ReferralType = {
  title: string
}

export type ReferralTypes = Record<string, ReferralType>

type QuestionType = 'SEX' | 'DATA_OBJECT' | 'MEDITATION' | 'SELECTED_MEDITATIONS'

type LessonQuestion = {
  annotation?: string
  question?: string
  image?: string
  variableProfile?: string
  tag?: string
  type?: QuestionType
  name?: string
  placeholder?: string
  value?: number
  variableTask?: string
  questions?: Array<{ id: string; question: string; annotation?: string }>
}

export type LessonCategory =
  | 'CBT'
  | 'GROUP_REFLECTION'
  | 'HEALTH_QUESTIONNAIRE'
  | 'MEDITATION_HRV'
  | 'MEDITATION_MINDFULNESS'
  | 'PERSONAL_REFLECTION'
  | 'SETUP'
  | 'VIDEO_INTRODUCTION'
  | 'VIDEO_RECAP'
  | 'VIDEO_TIPS'
  | 'DEFAULT'

export type Lesson = {
  activityType: string
  category: LessonCategory
  title: string
  duration?: number
  questions?: {
    [questionID: string]: LessonQuestion
  }
  tags?: Array<LessonTags>
}

export enum LessonTags {
  CALL_SCHEDULING = 'CALL_SCHEDULING',
}

export enum PatientHistoryType {
  ACCEPTED = 'ACCEPTED',
  ANONYMOUS_NOTE = 'ANONYMOUS_NOTE',
  BURNOUT = 'BURNOUT',
  CUSTOM = 'CUSTOM',
  DECLINED = 'DECLINED',
  FINANCIAL_ELIGIBILITY_CONFIRMED = 'FINANCIAL_ELIGIBILITY_CONFIRMED',
  FINANCIAL_ELIGIBILITY_HEALTH_PLAN = 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN',
  GAD7 = 'GAD7',
  INTAKE_NOTE = 'INTAKE_NOTE',
  MISSED_INTAKE_CALL = 'MISSED_INTAKE_CALL',
  NOTE = 'NOTE',
  PHONE_CALL = 'PHONE_CALL',
  PHQ9 = 'PHQ9',
  PROGRAM_END = 'PROGRAM_END',
  SMS = 'SMS',
  WPAI = 'WPAI',
  WHO5 = 'WHO5',
  INTAKE_NOTE_COACHING = 'INTAKE_NOTE_COACHING',
  TREATMENT_PLAN = 'TREATMENT_PLAN',
}

export type Program = {
  isHrvProgram: boolean
  providerRole: 'coach' | 'therapist'
  patientHistoryTypes: Array<PatientHistoryType>
  programEndMetrics: Array<PatientHistoryType>
  acceptanceCriteria: Array<PatientHistoryType>
  lastWeekNbr: number
  weeks: Record<string, ProgramWeek>
  lessons: {
    [lessonID: string]: Lesson
  }
  meditations: {
    [meditationID: string]: {
      title: string
    }
  }
}

export interface Root {
  coachProfiles: CoachProfile
  metaUserData: MetaUserData
  privateUserData: PrivateUserData
  referralTypes: ReferralType
  response: Response
}
