import React, { useState } from 'react'
import { PatientHistoryType } from 'src/graphql.generated'
import { Card, CardContent, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useLoadPatientHistoryTypes } from 'src/program'
import { PatientHistoryFormType, PATIENT_HISTORY_TYPE_NAMES } from 'src/participantHistory/constants'
import Form from './form/Form'
import { getFormFromLocalStorage } from './form/persistFormHelpers'

const ParticipantHistoryForm = ({ uid, programID }: { uid: string; programID: string | null }) => {
  const patientHistoryTypes = useLoadPatientHistoryTypes(programID)
  const [formType, setFormType] = useState<PatientHistoryFormType>(() => getDefaultFormType(uid))

  const handleTypeChange = (event: SelectChangeEvent<PatientHistoryType | 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN'>) => {
    const type = event.target.value
    setFormType(type as PatientHistoryFormType)
  }

  return (
    <Card>
      <CardContent>
        <FormControl>
          <InputLabel id="participant-history-select-label">Type</InputLabel>
          <Select
            labelId="participant-history-select-label"
            value={formType}
            label="Type"
            onChange={handleTypeChange}
            size="small"
          >
            {patientHistoryTypes.map((field) => (
              <MenuItem key={field} value={field}>
                {PATIENT_HISTORY_TYPE_NAMES[field]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Form uid={uid} formType={formType} setFormType={setFormType} />
      </CardContent>
    </Card>
  )
}

const getDefaultFormType = (uid: string) => {
  const intakeNoteSavedInLocalStorage = getFormFromLocalStorage(uid, PatientHistoryType.IntakeNote)
  return intakeNoteSavedInLocalStorage ? PatientHistoryType.IntakeNote : PatientHistoryType.Note
}

export default ParticipantHistoryForm
