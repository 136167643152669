import React from 'react'
import MultiSelectField from './MultiSelectField'

type Provider = {
  id: string
  firstname: string
  lastname: string
}

export type Props = {
  selectedProviders: Array<string>
  providers: Array<Provider>
  selectProviders: (providerIDs: Array<string>) => void
}

const SelectProviders = (props: Props) => {
  const { selectProviders, selectedProviders, providers } = props

  return (
    <MultiSelectField
      label="Providers"
      name="providers"
      onSelect={selectProviders}
      identifier={(value) => value.id}
      renderLabel={({ firstname, lastname }) => `${firstname} ${lastname}`}
      values={providers}
      selected={selectedProviders}
    />
  )
}

export default SelectProviders
