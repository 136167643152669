import React from 'react'
import { PatientHistoryFormType } from 'src/participantHistory/constants'
import LegacyParticipantHistoryForm from 'src/legacy/components/ParticipantRecords/ParticipantHistory/ParticipantHistoryForm'
import { PatientHistoryType } from 'src/graphql.generated'
import MissedIntakeCallForm from './MissedIntakeCallForm'
import NoteForm from './NoteForm'
import PHQ9Form from './PHQ9Form'
import GAD7Form from './GAD7Form'
import WHO5Form from './WHO5Form'
import BurnoutForm from './BurnoutForm'
import IntakeNoteCoachingForm from './IntakeNoteCoachingForm'
import WPAIForm from './wpai/WPAIForm'
import TreatmentPlanForm from './TreatmentPlanForm'

const Form = ({
  uid,
  formType,
  setFormType,
}: {
  uid: string
  formType: PatientHistoryFormType
  setFormType: (formType: PatientHistoryFormType) => void
}) => {
  switch (formType) {
    case PatientHistoryType.MissedIntakeCall:
      return <MissedIntakeCallForm uid={uid} />
    case PatientHistoryType.FinancialEligibilityConfirmed:
    case PatientHistoryType.Note:
    case PatientHistoryType.PhoneCall:
    case PatientHistoryType.Sms:
      return <NoteForm uid={uid} formType={formType} />
    case PatientHistoryType.Phq9:
      return <PHQ9Form uid={uid} />
    case PatientHistoryType.Gad7:
      return <GAD7Form uid={uid} />
    case PatientHistoryType.Who5:
      return <WHO5Form uid={uid} />
    case PatientHistoryType.Burnout:
      return <BurnoutForm uid={uid} />
    case PatientHistoryType.IntakeNoteCoaching:
      return <IntakeNoteCoachingForm uid={uid} />
    case PatientHistoryType.Wpai:
      return <WPAIForm uid={uid} />
    case PatientHistoryType.TreatmentPlan:
      return <TreatmentPlanForm uid={uid} />
    default:
      return (
        <LegacyParticipantHistoryForm
          uid={uid}
          formType={formType}
          afterSubmittedCallback={() => {
            setFormType(PatientHistoryType.Note)
          }}
        />
      )
  }
}

export default Form
