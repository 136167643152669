import { toPairs, isNil } from 'lodash'
import React from 'react'
import FormattedValue from './FormattedValue'
import ValueTable from './ValueTable' // eslint-disable-line import/no-cycle

type ValueProps = {
  value?: unknown
}

const Value = ({ value }: ValueProps) => {
  if (isNil(value)) {
    return <FormattedValue content="-" />
  }

  switch (typeof value) {
    case 'boolean':
    case 'number':
    case 'string':
      return <FormattedValue content={value} />
    case 'object':
      return <ValueTable rows={toPairs(value!)} />
    default:
      return null
  }
}

export default Value
