import React, { FunctionComponent, createContext, useContext, useState } from 'react'
import { omit } from 'lodash'
import { ChatMessageLinkedResponseFragment } from 'src/feed/feedQueries.generated'

export type Context = {
  response: ChatMessageLinkedResponseFragment | undefined
  removeResponse: () => void
  setResponse: (response: ChatMessageLinkedResponseFragment) => void
}

const useResponse = (feedID: string) => {
  const [responses, setResponses] = useState<Record<string, ChatMessageLinkedResponseFragment>>({})
  const removeResponse = () => setResponses(omit({ ...responses }, feedID))
  const setResponse = (response: ChatMessageLinkedResponseFragment) =>
    setResponses({
      ...responses,
      [feedID]: response,
    })
  return {
    response: responses[feedID],
    removeResponse,
    setResponse,
  }
}

const MentionResponseContext = createContext<Context>({} as ReturnType<typeof useResponse>)

type Props = {
  feedID: string
}

export const MentionResponseContextProvider: FunctionComponent<Props> = ({ children, feedID }) => {
  const responseContext = useResponse(feedID)
  return <MentionResponseContext.Provider value={responseContext}>{children}</MentionResponseContext.Provider>
}

export const useMentionResponse = () => useContext(MentionResponseContext)
