import React from 'react'
import { useToasts } from 'react-toast-notifications'

import { VideoCallException } from 'src/graphql.generated'
import TextButtonSelect, { SelectOption } from '../../TextButtonSelect'
import { useSetCallExceptionMutation } from './callExceptionMutationts.generated'

type Props = {
  callID: string
  callException?: VideoCallException | null
}

const NO_EXCEPTIONS_VALUE = 'CALL_HAD'

export type CallExceptionOptionValue = VideoCallException | typeof NO_EXCEPTIONS_VALUE

const CALL_EXCEPTION_OPTIONS: SelectOption<CallExceptionOptionValue>[] = [
  {
    value: NO_EXCEPTIONS_VALUE,
    label: 'Call Had',
  },
  {
    value: VideoCallException.NoShow,
    label: 'No Show',
  },
  {
    value: VideoCallException.LateCancellation,
    label: 'Late Cancellation',
  },
  {
    value: VideoCallException.ProviderIssue,
    label: 'Provider Issue',
  },
  {
    value: VideoCallException.OtherException,
    label: 'Other Exception',
  },
]

const CallExceptionSelect = ({ callID, callException }: Props) => {
  const { addToast } = useToasts()
  const [setCallException, { loading }] = useSetCallExceptionMutation()

  const onSelectValueChange = async (selectedValue: CallExceptionOptionValue) => {
    try {
      await setCallException({
        variables: { callID, callException: selectedValue === NO_EXCEPTIONS_VALUE ? null : selectedValue },
      })

      addToast('The call exception has been updated successfully', { appearance: 'success' })
    } catch (error) {
      addToast('Failed to update the call exception', { appearance: 'error' })
    }
  }

  return (
    <TextButtonSelect
      disabled={loading}
      value={callException ?? NO_EXCEPTIONS_VALUE}
      options={CALL_EXCEPTION_OPTIONS}
      onChange={onSelectValueChange}
      tooltipTitle="Report an exception if there is an issue with this call"
      tooltipPlacement="top"
    />
  )
}

export default CallExceptionSelect
