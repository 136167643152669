import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, TableCell, TableRow } from '@mui/material'
import { Check } from '@mui/icons-material'

import { FromNow, FormatDateTime } from 'src/date'
import Authentication from 'src/legacy/components/Authentication'
import { DeleteButton, SelectableItemCheckbox, TableCellIcon } from 'src/legacy/components/mui'
import { MedicalNotesIcon } from 'src/legacy/components/Icon'
import { useDeletePatientMutation } from 'src/legacy/lib/graphql/queries.generated'
import CareTeam from 'src/legacy/components/CareTeam/CareTeam'
import MeruDate from 'src/legacy/lib/MeruDate'
import { PatientSearchFieldsFragment } from './searchPatients.generated'
import FormattedState from './FormattedState'
import { ParticipantTableColumn } from './constants'
import HideableTableCell from './HideableTableCell'

type Props = {
  participants: Array<PatientSearchFieldsFragment>
  onDeleteSuccess: () => void
}

type ParticipantRowProps = {
  participant: PatientSearchFieldsFragment
  onDeleteSuccess: () => void
}

const ParticipantRow = (props: ParticipantRowProps) => {
  const { participant, onDeleteSuccess } = props
  const {
    uid,
    email,
    firstname,
    lastname,
    details,
    careTeam,
    indicators,
    intakeCallTime,
    enrollment: { state = null, lastStateTransitionAt, programStartDate },
  } = participant

  const [deletePatientMutation] = useDeletePatientMutation({ variables: { uid }, onCompleted: onDeleteSuccess })

  const { source = undefined } = details || {}

  return (
    <TableRow key={participant.uid}>
      <TableCell padding="checkbox">
        <SelectableItemCheckbox value={participant.uid} />
      </TableCell>
      <TableCellIcon>
        <IconButton color="primary" component={Link} to={`/participant/${participant.uid}/records`} size="large">
          <MedicalNotesIcon />
        </IconButton>
      </TableCellIcon>
      <HideableTableCell id={ParticipantTableColumn.name}>
        {firstname && lastname ? `${firstname} ${lastname}` : email}
      </HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.source}>{source}</HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.careteam}>
        <CareTeam careTeam={careTeam} />
      </HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.status}>
        <FormattedState state={state} />
      </HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.lastStateTransitionAt}>
        <FromNow value={lastStateTransitionAt} />
      </HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.callTime}>
        <FormatDateTime value={intakeCallTime} />
      </HideableTableCell>
      <HideableTableCell id={ParticipantTableColumn.programStartDate}>
        {programStartDate ? <>Week {MeruDate.now().formatWeekNumber(programStartDate)}</> : null}
      </HideableTableCell>
      <Authentication allowManager>
        <HideableTableCell align="center" id={ParticipantTableColumn.financialEligibility}>
          {indicators?.financialEligibilityConfirmed === true ? (
            <Check fontSize="small" titleAccess="Financial eligibility checked" />
          ) : undefined}
        </HideableTableCell>
      </Authentication>
      <TableCellIcon>
        <Authentication
          render={(allowAdmin) =>
            allowAdmin ? <DeleteButton onDelete={deletePatientMutation} title="Delete Participant" /> : null
          }
        />
      </TableCellIcon>
    </TableRow>
  )
}

const ParticipantRows = (props: Props) => {
  const { participants, onDeleteSuccess } = props
  return (
    <>
      {participants.map((participant) => (
        <ParticipantRow participant={participant} key={participant.uid} onDeleteSuccess={onDeleteSuccess} />
      ))}
    </>
  )
}

export default ParticipantRows
