import React from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import { Check, PanoramaFishEye } from '@mui/icons-material'
import { useLoadProgram } from 'src/program'
import { useTaskRecommendationsForPatientQuery } from './taskRecommendationQueries.generated'
import DailyTasksSkeleton from './DailyTasksSkeleton'

export type Props = {
  uid: string
  programID: string
}

const DailyTasks = ({ uid, programID }: Props) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const programRequest = useLoadProgram(programID)
  const recommendations = useTaskRecommendationsForPatientQuery({
    variables: {
      uid,
      timezone,
    },
  })
  if (recommendations.loading || !recommendations.data || !programRequest.data) {
    return <DailyTasksSkeleton />
  }

  const {
    taskRecommendationsForPatient: { taskRecommendations },
  } = recommendations.data
  const { data: program } = programRequest

  return (
    <Box bgcolor="lightestGrey" borderRadius="10px" mt={2} pt={2} px={1}>
      {taskRecommendations.length === 0 ? (
        <Box ml={2} pb={2}>
          <Typography color="textSecondary" variant="subtitle1">
            No recommendations for today
          </Typography>
        </Box>
      ) : (
        <>
          <Box ml={2}>
            <Typography color="textPrimary" variant="h6">
              Today&apos;s recommendation
            </Typography>
          </Box>
          <List>
            {taskRecommendations.map((task) => {
              const lesson = program.lessons[task.lessonID] || {}
              const itemTitle = lesson.title || task.lessonID
              return (
                <ListItem key={task.taskID} dense disabled={task.isDisabled} value={itemTitle}>
                  <ListItemIcon>{task.completedAt ? <Check /> : <PanoramaFishEye />}</ListItemIcon>
                  <ListItemText primary={itemTitle} />
                </ListItem>
              )
            })}
          </List>
        </>
      )}
    </Box>
  )
}

export default DailyTasks
