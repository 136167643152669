import React, { useEffect, useState } from 'react'
import ignoreException from 'src/exceptionHandler/ignoreException'
import { FullScreenLoader } from 'src/designSystem'
import useIsInPast from './useIsInPast'
import useLastActive from './useLastActive'
import { useLogout, usePollSession, useRefreshSession } from '../useSessionQueries'
import { useSession } from '../useSession'
import SessionTimeoutWarning from './SessionTimeoutWarning'

const POLL_INTERVAL = 60 * 1000

const isGreaterOrEquals = (startedAt: number | undefined, lastActive: number) =>
  startedAt ? lastActive >= startedAt : false

export default function SessionManager() {
  const session = useSession()
  const pollInterval = session.isLoggedIn ? POLL_INTERVAL : undefined
  const { loading } = usePollSession(pollInterval)

  const lastActive = useLastActive()
  const logout = useLogout()
  const refreshSession = useRefreshSession()
  const shouldLogout = useIsInPast(session.validUntil)
  const shouldRefreshSession = useIsInPast(session.refreshAt)
  const [hasBeenActiveDuringSession, setHasBeenActiveDuringSession] = useState(
    isGreaterOrEquals(session.startedAt, lastActive)
  )

  useEffect(() => {
    if (!shouldRefreshSession) {
      setHasBeenActiveDuringSession(isGreaterOrEquals(session.startedAt, lastActive))
    }
  }, [shouldRefreshSession, session.startedAt, lastActive])

  useEffect(() => {
    if (shouldLogout) {
      logout().catch(ignoreException)
    }
  }, [logout, shouldLogout])

  useEffect(() => {
    if (hasBeenActiveDuringSession && shouldRefreshSession) {
      refreshSession().catch(ignoreException)
    }
  }, [hasBeenActiveDuringSession, refreshSession, shouldRefreshSession])

  if (loading) {
    return <FullScreenLoader />
  }

  if (shouldRefreshSession && !hasBeenActiveDuringSession) {
    return <SessionTimeoutWarning onContinue={refreshSession} onLogout={logout} />
  }

  return null
}
