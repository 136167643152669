import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import { SolidOverlayDialog } from 'src/designSystem'

type Props = {
  onContinue: () => void
  onLogout: () => void
}

export default function SessionTimeoutWarning({ onContinue, onLogout }: Props) {
  const [isDisabled, setIsDisabled] = useState(false)

  const noCallback = useCallback(() => {
    setIsDisabled(true)
    onLogout()
  }, [onLogout, setIsDisabled])
  const yesCallback = useCallback(() => {
    setIsDisabled(true)
    onContinue()
  }, [onContinue, setIsDisabled])

  const actions = [
    <Button color="inherit" key="no" disabled={isDisabled} onClick={noCallback}>
      No
    </Button>,
    <Button color="primary" disabled={isDisabled} key="yes" onClick={yesCallback}>
      Yes
    </Button>,
  ]

  return (
    <SolidOverlayDialog actions={actions} open>
      You are about to be logged out. Would you like to continue using the dashboard?
    </SolidOverlayDialog>
  )
}
