import React from 'react'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { CustomTextField, FormDatePicker } from 'src/form'
import _ from 'lodash'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import { PatientHistoryFormType } from 'src/participantHistory/constants'
import SubmitButton from './SubmitButton'

const NOTE_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    description: Yup.string().required(),
  })
  .required()

type NoteFormType = Yup.InferType<typeof NOTE_FORM_SCHEMA>

const NoteForm = ({ uid, formType }: { uid: string; formType: PatientHistoryFormType }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()

  return (
    <Formik<NoteFormType>
      validationSchema={NOTE_FORM_SCHEMA}
      initialValues={NOTE_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: formType,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />
          <Field component={CustomTextField} label="Description" fullWidth multiline name="description" minRows={4} />
          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default NoteForm
