import React, { useEffect } from 'react'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { Field, useFormikContext } from 'formik'

import { CustomTextField } from 'src/form'

const QueryField = () => {
  const location = useLocation()

  const { setFieldValue } = useFormikContext()

  const { q } = qs.parse(location.search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (q) {
      setFieldValue('query', q)
    }
  }, [q])

  return (
    <Field fullWidth component={CustomTextField} placeholder="Search for Name, Email or Phone Number" name="query" />
  )
}

export default QueryField
