import { useCallback, useEffect } from 'react'
import { useFormikContext } from 'formik'
import { debounce } from 'debounce-promise-with-cancel'
import ignoreException from 'src/exceptionHandler/ignoreException'

type Props = {
  debounceMs: number
}

export default function Autosave({ debounceMs }: Props) {
  const { submitForm, values, dirty, status, submitCount } = useFormikContext()
  const enabled = (dirty && !status?.isLoading) || submitCount > 0

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(async () => {
      await submitForm()
    }, debounceMs),
    [debounceMs, submitForm]
  )

  useEffect(() => {
    if (enabled) {
      debouncedSubmit().catch(ignoreException)
    }
    return () => {
      debouncedSubmit.cancel()
    }
  }, [enabled, debouncedSubmit, values])

  return null
}
