import React, { useContext, useCallback } from 'react'
import { ParticipantTableColumn } from './constants'

type ParticipantTableContext = {
  disabledColumns?: Set<ParticipantTableColumn>
}

export const Context = React.createContext<ParticipantTableContext>({})

export const { Provider } = Context

const useParticipantTableContext = () => {
  const { disabledColumns } = useContext(Context)

  const isDisabled = useCallback(
    (columnName: ParticipantTableColumn) => disabledColumns && disabledColumns.has(columnName),
    [disabledColumns]
  )

  return isDisabled
}

export default useParticipantTableContext
