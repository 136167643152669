import _ from 'lodash'
import React from 'react'
import { Field, useFormikContext } from 'formik'

import { CustomTextField } from 'src/form'

const hasDuplicateKeyError = (errors: string[]) => _.includes(errors, 'DUPLICATE_KEY')

const EmailField = () => {
  const { status } = useFormikContext()
  return (
    <Field
      component={CustomTextField}
      errorText={hasDuplicateKeyError(status.errors) ? 'Email address is already in use' : null}
      fullWidth
      label="Email"
      name="email"
    />
  )
}

export default EmailField
