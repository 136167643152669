import { InMemoryCache } from '@apollo/client'
import { mergeAll } from 'lodash/fp'
import { communityTypePolicies } from 'src/communities'
import { feedFieldTypePolicies } from 'src/feed'
import { patientTypePolicies } from 'src/participant/patientTypePolicies'
import introspection from './introspection.generated'

export default function createCache() {
  return new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    typePolicies: mergeAll([feedFieldTypePolicies, communityTypePolicies, patientTypePolicies]),
  })
}
