import React from 'react'

export default function useStickyPositionObserver(enabled: boolean | undefined, targetRef: React.RefObject<Element>) {
  const [isSticky, setIsSticky] = React.useState(false)

  React.useEffect(() => {
    const { current: target } = targetRef
    if (!enabled || !target) {
      return undefined
    }
    const observer = new IntersectionObserver(
      (entries) => {
        // no intersection with screen
        if (entries[0].intersectionRatio === 0) setIsSticky(true)
        // fully intersects with screen
        else if (entries[0].intersectionRatio === 1) setIsSticky(false)
      },
      { threshold: [0, 1] }
    )
    observer.observe(target)
    return () => {
      observer.unobserve(target)
    }
  }, [enabled, targetRef])

  return isSticky
}
