import { get, map, orderBy, pipe, reject, toPairs } from 'lodash/fp'

export const getCoachProfiles = pipe(
  get(['firebase', 'data', 'coachProfiles']),
  toPairs,
  map(([coachID, value]) => ({ ...value, coachID })),
  orderBy(['firstname', 'lastname'], ['asc', 'asc'])
)

export const getActiveCoachProfiles = pipe(getCoachProfiles, reject('isSuspended'))
