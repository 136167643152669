import React from 'react'
import { Field } from 'formik'
import { Button } from '@mui/material'
import * as Yup from 'yup'

import { CustomTextField, RequestForm } from 'src/form'
import { InlineForm } from 'src/legacy/components/mui'

const AddCoachFormSchema = Yup.object().required().shape({
  email: Yup.string().email().required(),
})

type Props = {
  onSubmit: (email: string) => void
}

const AddCoachForm: React.FunctionComponent<Props> = (props) => {
  const { onSubmit } = props
  return (
    <RequestForm
      onSubmit={({ email }) => onSubmit(email)}
      validationSchema={AddCoachFormSchema}
      render={({ isSubmitting }) => (
        <InlineForm variant="dense">
          <Field component={CustomTextField} label="Email" name="email" />
          <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
            Add
          </Button>
        </InlineForm>
      )}
    />
  )
}

export default AddCoachForm
