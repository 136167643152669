import React, { useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { TypingField } from 'src/message'
import useReplyTocommunityPost from './useReplyToCommunityPost'

export type Props = {
  postID: string
  disabled?: boolean
}

export default function CommunityPostFacilitatorReplyInput(props: Props) {
  const { postID, disabled } = props
  const [message, setMessage] = useState('')
  const [isSending, setIsSending] = useState(false)
  const { addToast } = useToasts()

  const replyToCommmunityPost = useReplyTocommunityPost(postID)

  const onSubmit = () => {
    setIsSending(true)
    replyToCommmunityPost(message)
      .then(() => {
        setMessage('')
      })
      .catch((e) => {
        addToast(`Replying to post failed: ${e.message}`)
      })
      .finally(() => {
        setIsSending(false)
      })
  }
  return (
    <TypingField
      onTyping={setMessage}
      onSubmit={onSubmit}
      value={message}
      disabled={disabled || isSending || !message.trim()}
      variant="dark"
    />
  )
}
