import { Box, Typography } from '@mui/material'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'

const parseDate = (date: string | Date) => moment(date, 'YYYY-MM-DD', true)

const calculateResponsesPerWeek = (responses: Array<CompletedResponse>, programStartDate: string) => {
  if (!parseDate(programStartDate).isValid()) {
    return {}
  }

  return _.chain(responses)
    .groupBy(({ date }) => parseDate(date).diff(programStartDate, 'weeks') + 1)
    .mapValues((values) => _.chain(values).map('count').sum().value())
    .value()
}

type CompletedResponse = {
  count: number
  date: Date
}

type Props = {
  lastWeekNbr?: number
  programStartDate: string
  completedResponses: Array<CompletedResponse>
}

const PracticesPerWeek: React.FC<Props> = ({ lastWeekNbr = 8, completedResponses, programStartDate }) => {
  const responsesPerWeek = calculateResponsesPerWeek(completedResponses, programStartDate)

  return (
    <Box display="flex" justifyContent="space-around">
      {_.times(lastWeekNbr).map((week) => (
        <Box display="block" textAlign="center" paddingLeft="4px" paddingRight="4px" key={week}>
          <Typography color="textSecondary" variant="caption">
            {week + 1}
          </Typography>
          <Typography color="primary" variant="h4">
            {responsesPerWeek[week + 1] >= 0 ? responsesPerWeek[week + 1] : '-'}
          </Typography>
        </Box>
      ))}
    </Box>
  )
}

export default PracticesPerWeek
