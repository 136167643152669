import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import CheckIcon from '@mui/icons-material/Check'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-flex',
    position: 'absolute',
    bottom: 0,
    right: theme.spacing(1),
    verticalAlign: 'middle',
  },
}))

type Props = {
  hasSaved: boolean
  isSaving: boolean
}

const AutosaveIcon = ({ hasSaved, isSaving }: Props) => {
  const classes = useStyles()
  const [showIcon, setShowIcon] = useState(isSaving || hasSaved)

  useEffect(() => {
    if (isSaving) {
      setShowIcon(true)
      return undefined
    }
    if (hasSaved) {
      const timeout = setTimeout(() => setShowIcon(false), 3000)
      return () => clearTimeout(timeout)
    }
    setShowIcon(false)
    return undefined
  }, [hasSaved, isSaving])

  if (showIcon) {
    return (
      <Typography classes={classes} color={isSaving ? 'initial' : 'primary'} variant="caption">
        {!isSaving && <CheckIcon fontSize="small" color="primary" />}
        {isSaving ? 'saving...' : 'saved'}
      </Typography>
    )
  }
  return null
}

export default AutosaveIcon
