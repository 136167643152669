import React from 'react'
import { identity } from 'lodash/fp'
import MultiSelectField from './MultiSelectField'

type Props = {
  currentlySelectedSources: Array<string>
  sources: Array<string>
  selectSources: (sources: Array<string>) => void
}

const SelectSource = (props: Props) => {
  const { currentlySelectedSources: selectedSources, selectSources, sources } = props

  return (
    <MultiSelectField
      label="Sources"
      name="sources"
      identifier={identity}
      renderLabel={identity}
      onSelect={selectSources}
      selected={selectedSources}
      values={sources}
    />
  )
}

export default SelectSource
