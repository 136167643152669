import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  root: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
})

const ButtonNavigationLinks = ({ actions }) => {
  const classes = useStyles()
  const location = useLocation()
  return (
    <Box position="absolute" bottom={0} left={0} right={0}>
      <Paper>
        <BottomNavigation showLabels value={location.pathname}>
          {actions.map(({ label, icon, to }) => (
            <BottomNavigationAction
              classes={classes}
              component={Link}
              key={to}
              icon={icon}
              label={label}
              selected
              to={to}
              value={to}
            />
          ))}
        </BottomNavigation>
      </Paper>
    </Box>
  )
}

ButtonNavigationLinks.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      to: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default ButtonNavigationLinks
