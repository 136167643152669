import { FieldPolicy, Reference, TypePolicies } from '@apollo/client'
import { orderBy, unionBy } from 'lodash'
import { GetFeedItemsQuery } from './feedQueries.generated'

export const feedFieldPolicy: FieldPolicy<GetFeedItemsQuery['feed']> = {
  keyArgs: ['uid'],
  merge: true,
}

export const feedItemsFieldPolicy: FieldPolicy<readonly Reference[], GetFeedItemsQuery['feed']['items']> = {
  merge: (existingItemRefs, incomingItems, { toReference, readField }) => {
    const incomingItemRefs = incomingItems.map((item) => toReference(item, true)!)
    const mergedItemRefs = unionBy(existingItemRefs, incomingItemRefs, '__ref')
    const sortedItemRefs = orderBy(mergedItemRefs, (item) => readField<string>('createdAt', item), 'asc')
    return sortedItemRefs
  },
}

export const feedFieldTypePolicies: TypePolicies = {
  Query: {
    fields: {
      feed: feedFieldPolicy,
    },
  },
  FeedSuccess: {
    fields: {
      items: feedItemsFieldPolicy,
    },
  },
  ChatMessage: {
    keyFields: ['messageID'],
  },
  SmsMessage: {
    keyFields: ['twilioMessageID'],
  },
  PatientResponse: {
    keyFields: ['responseID'],
  },
}
