import React, { useEffect } from 'react'
import { FullScreenLoader } from 'src/designSystem'
import ignoreException from 'src/exceptionHandler/ignoreException'
import { useLogout } from './useSessionQueries'

export default function Logout() {
  const logout = useLogout()

  useEffect(() => {
    logout().catch(ignoreException)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <FullScreenLoader />
}
