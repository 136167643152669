import { useGetPatientAbTestsQuery } from 'src/participant/patientQueries.generated'
import React from 'react'
import { Autosave, CustomTextField, RequestForm } from 'src/form'
import { Box, Card, CardContent, Grid, MenuItem, Typography } from '@mui/material'
import { Field } from 'formik'
import { AppearanceTypes, useToasts } from 'react-toast-notifications'
import useUpdateABTestGroups from 'src/legacy/components/ParticipantRecords/ParticipantRecord/ABTestGroups/useUpdateABTestGroups'
import {
  EMPTY_VALUE,
  getABTestVariablesSchema,
} from 'src/legacy/components/ParticipantRecords/ParticipantRecord/ABTestGroups/getABTestVariablesSchema'
import { PatientAbTest } from 'src/graphql.generated'

type Props = {
  uid: string
}

export default function ABTestGroupsForm({ uid }: Props) {
  const { data } = useGetPatientAbTestsQuery({ variables: { uid }, fetchPolicy: 'cache-and-network' })
  const updateABTestVariables = useUpdateABTestGroups(uid)
  const { addToast } = useToasts()

  const disableEditing = shouldDisableEditing()

  const initialValues = apiValueToFormValue(data?.patient.abTests)
  const validationSchema = getABTestVariablesSchema(data?.patient.abTests)

  const onSuccess = () => {
    const successMessage = 'Participant AB test variables updated successfully'
    const toastOptions = {
      // type assertion used, as library typing is out of date. autoDismissTimeout option works, but library typings do not support it in the Options type, thus hacking around it with the "as" assertion
      appearance: 'success' as AppearanceTypes,
      autoDismissTimeout: 3000,
    }
    addToast(successMessage, toastOptions)
  }

  return (
    <RequestForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ patientABTestsByName }) => {
        if (!patientABTestsByName) return undefined

        const abTests = formValueToApiValue(patientABTestsByName)
        return updateABTestVariables(abTests)
      }}
      onSuccess={onSuccess}
      render={() => (
        <Card style={{ marginTop: 10 }}>
          <Autosave debounceMs={100} />
          <CardContent>
            <Box mb={1} mt={3}>
              <Typography variant="h3">AB Tests</Typography>
            </Box>

            {data?.patient.abTests?.length === 0 && <Typography variant="body1">No active AB tests</Typography>}

            {data?.patient.abTests?.map((test) => (
              <Grid container spacing={2} key={test.name}>
                <Grid item xs={12} md={6}>
                  <Field
                    name={`patientABTestsByName.${test.name}`}
                    label={test.name}
                    component={CustomTextField}
                    fullWidth
                    type="select"
                    disabled={disableEditing}
                  >
                    <MenuItem value={EMPTY_VALUE}>
                      <em>(no value)</em>
                    </MenuItem>
                    {test.possibleGroups.map((group) => (
                      <MenuItem key={group} value={group}>
                        {group}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
            ))}
          </CardContent>
        </Card>
      )}
    />
  )
}

function shouldDisableEditing() {
  const editEnabled = process.env.AB_TEST_VARIABLE_EDIT_ENABLED === 'true'
  return !editEnabled
}

function apiValueToFormValue(abTests?: Array<PatientAbTest> | null) {
  if (!abTests) return { patientABTestsByName: {} }

  const formValue: Record<string, string> = {}
  abTests.forEach((test) => {
    formValue[test.name] = test.assignedGroup ?? EMPTY_VALUE
  })

  return { patientABTestsByName: formValue }
}

function formValueToApiValue(patientABTestsByName: Record<string, string | null>) {
  return Object.entries(patientABTestsByName).map(([name, assignedGroup]) => ({
    name,
    assignedGroup,
  }))
}
