import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import firebaseApp from 'src/firebase'
import { Bugsnag } from 'src/bugsnag'
import { trackLogin, trackLogout } from 'src/mixpanel'
import { AccessLevel } from 'src/graphql.generated'
import ignoreException from 'src/exceptionHandler/ignoreException'
import * as actions from './actions'
import {
  useChangeAccessLevelMutation,
  useGetSessionQuery,
  useLoginMutation,
  useLogoutMutation,
  useRefreshAccessTokenMutation,
} from './sessionQueries.generated'

export function useChangeAccessLevel() {
  const dispatch = useDispatch()
  const [changeAccessLevel] = useChangeAccessLevelMutation()

  return useCallback(
    async (level: AccessLevel) => {
      const { data } = await changeAccessLevel({ variables: { level } })
      if (data?.changeAccessLevel.firebaseToken) {
        await firebaseApp.auth().signInWithCustomToken(data.changeAccessLevel.firebaseToken)
        window.location.reload()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeAccessLevel, dispatch]
  )
}

export function useLogin() {
  const dispatch = useDispatch()
  const [login] = useLoginMutation()

  return useCallback(
    async (idToken: string) => {
      const { data } = await login({ variables: { idToken } })
      if (data?.login?.firebaseToken) {
        Bugsnag.setUser(data.login.uid, data.login.email!, data.login.name!)
        const [userCredential] = await Promise.all([
          firebaseApp.auth().signInWithCustomToken(data.login.firebaseToken),
          trackLogin(data.login),
        ])
        dispatch(actions.setSession(data.login))
        return userCredential.user
      }
      return null
    },
    [dispatch, login]
  )
}

export function useLogout() {
  const [logout] = useLogoutMutation()

  return useCallback(async () => {
    await Promise.all([firebaseApp.auth().signOut(), logout(), trackLogout()])
    window.location.assign('/login')
  }, [logout])
}

export function usePollSession(pollInterval?: number) {
  const dispatch = useDispatch()
  const { data, loading } = useGetSessionQuery({ pollInterval })
  const logout = useLogout()

  useEffect(() => {
    if (data?.session) {
      dispatch(actions.setSession(data.session))
    } else if (pollInterval) {
      logout().catch(ignoreException)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, data, logout])

  return { loading }
}

export function useRefreshSession() {
  const dispatch = useDispatch()
  const [refreshAccessToken] = useRefreshAccessTokenMutation()

  return useCallback(async () => {
    const { data } = await refreshAccessToken()
    if (data?.refreshAccessToken) {
      dispatch(actions.setSession(data.refreshAccessToken))
    }
  }, [dispatch, refreshAccessToken])
}
