import { FeedPatientResponseItemFragment } from '../../feedQueries.generated'

type HrvPractiseMetricsValue = {
  durationInSec: number
}

export function getMeditationMinutes(response: FeedPatientResponseItemFragment) {
  const { sections } = response

  const hrvPracticeSection = sections.find((section) => section.type === 'CARD:HRV_PRACTICE')
  if (!hrvPracticeSection) {
    return null
  }

  const hrvTags = new Set(['HRV_BIOFEEDBACK_METRICS', 'HRV_RESTING_METRICS'])
  const hrvPractiseMetricsEntry = hrvPracticeSection.entries.find((entry) => hrvTags.has(entry.tag || ''))
  if (!hrvPractiseMetricsEntry || !hrvPractiseMetricsEntry.rawValue) {
    return null
  }

  const { durationInSec } = hrvPractiseMetricsEntry.rawValue as HrvPractiseMetricsValue
  if (durationInSec === undefined) {
    return null
  }

  return Math.round(durationInSec / 60)
}
