/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'
import { getOr } from 'lodash/fp'
import { useSelector } from 'react-redux'
import { useFirebaseConnect } from 'react-redux-firebase'
import { AppState } from 'src/legacy/reducers'

export enum FirebaseRequestStatus {
  REQUESTING = 'REQUESTING',
  SUCCESS = 'SUCCESS',
}

export type FirebaseRequesting = {
  status: FirebaseRequestStatus.REQUESTING
  data?: undefined
}

export type FirebaseSuccess<T> = {
  status: FirebaseRequestStatus.SUCCESS
  data: T | null
}

export type FirebaseRequest<T> = FirebaseRequesting | FirebaseSuccess<T>

export function isRequesting<T>(request: FirebaseRequest<T>): request is FirebaseRequesting {
  return request.status === FirebaseRequestStatus.REQUESTING
}

export function isSuccess<T>(request: FirebaseRequest<T>): request is FirebaseSuccess<T> {
  return request.status === FirebaseRequestStatus.SUCCESS
}

type ContainsData<T> = T extends FirebaseSuccess<infer D> ? T & { data: Exclude<D, null | undefined> } : never

export function isSuccessWithData<T>(request: FirebaseRequest<T>): request is ContainsData<FirebaseSuccess<T>> {
  return isSuccess(request) && request.data != null
}

export type Selector<T> = (state: AppState) => T | null

function createDefaultSelector<T>(path: string): Selector<T> {
  return getOr<AppState, any, T | null>(null, ['firebase', 'data', ...path.split('/')])
}

/**
 * useFirebase
 *
 * @param path to the firebase resource or null to disable hook
 * @param selector to select parts of the resource
 */
export function useFirebase<T>(path: string | null, selector?: Selector<T>): FirebaseRequest<T> {
  useFirebaseConnect(path || [])
  const dataSelector = useMemo(() => (path ? selector || createDefaultSelector<T>(path) : () => null), [path, selector])
  const data = useSelector<AppState, T | null>(dataSelector)
  const getRequesting = useMemo(
    // When we don't have path yet, act as REQUESTING to support dependent fetching
    () => (path ? getOr<AppState, any, boolean>(true, ['firebase', 'requesting', path]) : () => true),
    [path]
  )
  const requesting = useSelector<AppState, boolean | null>(getRequesting)
  return useMemo(() => {
    if (requesting) {
      return { status: FirebaseRequestStatus.REQUESTING }
    }
    return { status: FirebaseRequestStatus.SUCCESS, data }
  }, [data, requesting])
}
