import React from 'react'
import moment from 'moment'
import { Chip, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Header } from 'src/designSystem'
import { FeedPatientResponseItemFragment } from '../feedQueries.generated'
import { getHealthQuestionnaireScores } from './helpers/hqScoresHelpers'
import { getMeditationMinutes } from './helpers/meditationMinutesHelpers'

type ResponseHeaderProps = {
  response: FeedPatientResponseItemFragment
  isResumed?: boolean
}

export default function ResponseHeader({ response, isResumed }: ResponseHeaderProps) {
  const classes = useStyles()
  const title = response.title || response.lessonID
  const responseSummary = getResponseSummary(response)
  const resumedSummary = isResumed && (
    <Tooltip title={getResumedTooltip(response)} className={classes.tooltip}>
      <Chip disabled={false} label="continued" size="small" />
    </Tooltip>
  )
  const summary = (
    <div>
      {responseSummary} {resumedSummary}
    </div>
  )
  return <Header title={title} iconCategory={response.category} summary={summary} />
}

const getResponseSummary = (response: FeedPatientResponseItemFragment) => {
  if (!response.isCompleted) {
    return 'Incomplete practice'
  }
  switch (response.category) {
    case 'MEDITATION_HRV':
    case 'MEDITATION_MINDFULNESS': {
      const duration = getMeditationMinutes(response) || response.duration
      return duration ? `${duration} min meditation` : ''
    }
    case 'HEALTH_QUESTIONNAIRE': {
      const scores = getHealthQuestionnaireScores(response)
      if (!scores) {
        return ''
      }
      return scores.map((score) => `${score.label} ${score.value}`).join(' - ')
    }
    case 'CBT':
    case 'VIDEO_RECAP':
    case 'VIDEO_TIPS':
    case 'VIDEO_INTRODUCTION': {
      return `${response.duration} min`
    }
    default: {
      return ''
    }
  }
}

const getResumedTooltip = ({ createdAt }: FeedPatientResponseItemFragment) =>
  `Started at ${moment(createdAt).format('LLL')}`

const useStyles = makeStyles((theme) => ({
  tooltip: {
    margin: theme.spacing(0, 1),
    pointerEvents: 'all',
  },
}))
