import React from 'react'
import { Box, Grid } from '@mui/material'
import { Patient } from 'src/graphql.generated'
import MessageIndicator from './MessageIndicator'
import SuicidalityIndicator from './SuicidalityIndicator'

type IndicatorsProps = {
  indicators?: Patient['indicators']
}

export default function Indicators({ indicators }: IndicatorsProps) {
  const count = indicators?.newMessages?.count
  const suicidal = indicators?.suicidal

  return (
    <>
      {suicidal && (
        <Grid item>
          <Box display="flex">
            <SuicidalityIndicator />
          </Box>
        </Grid>
      )}
      {count !== undefined && (
        <Grid item>
          <Box display="flex">
            <MessageIndicator count={count} />
          </Box>
        </Grid>
      )}
    </>
  )
}
