import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  code: {
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.primary,
    fontFamily: 'IBM Plex Mono, monospace',
    padding: theme.spacing(1) / 2,
  },
}))

const Code = ({ children }) => {
  const classes = useStyles()
  return <code className={classes.code}>{children}</code>
}

Code.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Code
