import { EnrollmentState } from 'src/graphql.generated'
import {
  useGetPatientsWithCareTeamQuery,
  useGetPatientWithEnrollmentQuery,
} from 'src/participant/patientQueries.generated'
import { useSessionUID } from 'src/session'

export enum MessageType {
  SMS,
  IN_APP,
}

export enum MessageSubmitDisplayType {
  ONE_BUTTON,
  BUTTON_GROUP,
}
interface ChatDisplayRules {
  disableChat: boolean
  messageSubmitDisplayType: MessageSubmitDisplayType
}

const useIsInTreatmentOrTest = (uid: string) => {
  const participantQueryResponse = useGetPatientWithEnrollmentQuery({
    variables: {
      uid,
    },
  })
  const participantState = participantQueryResponse.data?.patient?.enrollment.state
  return participantState === EnrollmentState.InProgram || participantState === EnrollmentState.Test
}

const useDisableChat = (uid: string) => {
  const coachID = useSessionUID()
  const participantsWithCareTeam = useGetPatientsWithCareTeamQuery({
    variables: {
      uids: [uid],
    },
  })
  const participantCareTeam = participantsWithCareTeam.data?.patients?.[0]?.careTeam
  const isPrimaryCoach =
    participantCareTeam?.find((careTeam) => careTeam.coachID === coachID && careTeam.isPrimary === true) !== undefined
  return !isPrimaryCoach
}

const useGetMessageSubmitDisplayType = (uid: string): MessageSubmitDisplayType => {
  const isInTreatmentOrTest = useIsInTreatmentOrTest(uid)
  return !isInTreatmentOrTest ? MessageSubmitDisplayType.ONE_BUTTON : MessageSubmitDisplayType.BUTTON_GROUP
}

const useChatDisplayRules = (uid: string): ChatDisplayRules => ({
  disableChat: useDisableChat(uid),
  messageSubmitDisplayType: useGetMessageSubmitDisplayType(uid),
})

export default useChatDisplayRules
