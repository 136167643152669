import React from 'react'
import { MomentInput } from 'moment'
import { Box } from '@mui/material'
import MeruDate from 'src/legacy/lib/MeruDate'
import FormatDate from './FormatDate'

const calculateWeekNbr = (programStartDate: MomentInput, recordDate?: MomentInput) => {
  if (!recordDate) return MeruDate.now().formatWeekNumber(programStartDate, true)
  return new MeruDate(recordDate).formatWeekNumber(programStartDate, true)
}

type Props = {
  dateColor?: 'text.primary' | 'text.secondary'
  programStartDate: MomentInput
  recordDate?: MomentInput
}

const FormatDateAndWeek: React.FunctionComponent<Props> = (props) => {
  const { programStartDate, recordDate, dateColor = 'text.primary' } = props
  return (
    <>
      <Box color={dateColor} component="span">
        <FormatDate value={recordDate || programStartDate} />
      </Box>
      <Box color="text.secondary" ml={1} component="span">
        {`(week ${calculateWeekNbr(programStartDate, recordDate)})`}
      </Box>
    </>
  )
}

export default FormatDateAndWeek
