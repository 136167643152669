import mixpanel, { Dict } from 'mixpanel-browser'
import { MIXPANEL_TOKEN } from 'src/config'
import { ScheduledVideoCallReason } from 'src/graphql.generated'

export enum Event {
  LOGGED_IN = 'logged_in',
  LOGGED_OUT = 'logged_out',
  NAVIGATION = 'navigation',
  TAB_ACTIVITY = 'tab_activity',
  TAB_CLOSED = 'tab_closed',
  USER_ACTIVITY_IN_TAB = 'user_activity_in_tab',
  CHAT_INPUT_FOCUS = 'chat_input_focus',
  CHAT_SEND = 'chat_send',
  TAG_ADDED = 'tag_added',
  TAG_REMOVED = 'tag_removed',
  UPDATE_PROGRAM_START_DATE = 'update_program_start_date',
  TOGGLE_WEEK_VIEW = 'toggle_week_view',
  TOGGLE_GROUP_VIEW = 'toggle_group_view',
  RESTART_PROGRAM = 'restart_program',
  TRIGGER_CHECKIN_CALL_FLOW = 'trigger_checkin_call_flow',
  CREATE_CHECKIN_CALL_APPOINTMENT = 'create_checkin_call_appointment',
  START_INTAKE_CALL = 'start_intake_call',
  START_CHECK_IN_CALL = 'start_check_in_call',
  CANCEL_CHECK_IN_CALL = 'cancel_check_in_call',
  ENABLE_DISABLE_CALL_BOOKING = 'enable_disable_call_booking',
}

const track = (event: Event, props?: Dict) =>
  mixpanel.track(event, {
    ...props,
    host: window.location.host,
  })

export const init = (tabID: string) => {
  mixpanel.init(MIXPANEL_TOKEN, {
    // eslint-disable-next-line camelcase
    property_blacklist: [
      '$current_url', // blacklist because url might contain user data (name, email) in the search query
    ],
  })
  mixpanel.register({
    tabID,
  })
}

type LoginData = {
  uid: string
  email?: string | null
  isAdmin?: boolean | null
  isCoach?: boolean | null
  isManager?: boolean | null
  isCareCoordinator?: boolean | null
  isCommunityFacilitator?: boolean | null
  name?: string | null
}

export const trackLogin = (loginData: LoginData) => {
  const { uid, email, isAdmin, isCareCoordinator, isCoach, isCommunityFacilitator, isManager, name } = loginData
  mixpanel.register({
    coachID: uid,
  })
  mixpanel.identify()
  mixpanel.people.set({
    coachID: uid,
    isAdmin,
    isCareCoordinator,
    isCoach,
    isCommunityFacilitator,
    isManager,
    email,
    name,
  })
  track(Event.LOGGED_IN)
}

export const trackLogout = () => track(Event.LOGGED_OUT)

export const trackNavigation = (pathname: string) => track(Event.NAVIGATION, { pathname })

export const trackTabActivity = (isTabActive: boolean) => track(Event.TAB_ACTIVITY, { isTabActive })

export const trackTabClosed = () => track(Event.TAB_CLOSED)

export const trackUserActivityInTab = (isUserActiveInTab: boolean) =>
  track(Event.USER_ACTIVITY_IN_TAB, { isUserActiveInTab })

export const trackChatInputFocus = (isChatInputFocused: boolean) =>
  track(Event.CHAT_INPUT_FOCUS, { isChatInputFocused })

export const trackChatSend = () => track(Event.CHAT_SEND)

export const trackAddParticipantTag = () => track(Event.TAG_ADDED)

export const trackRemoveParticipantTag = () => track(Event.TAG_REMOVED)

export const trackProgrmaStartDateUpdate = (weeksMoved: number) =>
  track(Event.UPDATE_PROGRAM_START_DATE, { weeksMoved })

export const trackToggleView = (view: Event.TOGGLE_WEEK_VIEW | Event.TOGGLE_GROUP_VIEW) => track(view)

export const trackRestartProgram = (restartedOnWeek: number | null) => track(Event.RESTART_PROGRAM, { restartedOnWeek })

export const trackTriggerCheckinCallFlow = () => track(Event.TRIGGER_CHECKIN_CALL_FLOW)

export const trackCreateCheckinCallAppointment = (callReason: ScheduledVideoCallReason) =>
  track(Event.CREATE_CHECKIN_CALL_APPOINTMENT, { callReason })

export const trackStartIntakeCall = (zoomMeetingID: string) => track(Event.START_INTAKE_CALL, { zoomMeetingID })

export const trackStartCheckInCall = (zoomMeetingID: string) => track(Event.START_CHECK_IN_CALL, { zoomMeetingID })

export const trackCancelCheckInCall = (zoomMeetingID: string) => track(Event.CANCEL_CHECK_IN_CALL, { zoomMeetingID })

export const trackEnableOrDisableInProgramCallBooking = (props: {
  action: 'enable' | 'disable'
  email: string
  name: string
}) => track(Event.ENABLE_DISABLE_CALL_BOOKING, props)
