import React, { useCallback } from 'react'
import { isNil } from 'lodash'
import { Button, DialogActions, DialogContent, Box, Table, TableBody } from '@mui/material'
import { Warning } from '@mui/icons-material'
import { UpdateQueryOptions } from '@apollo/client'
import * as Yup from 'yup'
import { EngagementType, PatientEngagementIndicator } from 'src/graphql.generated'
import { GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { FormatDate } from 'src/date'
import { GetPatientHistoryDocument, useGetPatientHistoryQuery } from 'src/legacy/lib/graphql/queries.generated'
import Baseline from './Baseline'
import Engagement from './Engagement'
import ProgramEndResult from './ProgramEndResult'
import PercentageChange from './PercentageChange'
import { ParticipantResultsHeader, ParticipantResultsTextRow } from './ParticipantResultsTable'
import { GetFullPatientDocument, useSetPatientProgramEndMutation } from '../../patientQueries.generated'
import { ProgramEndMetrics, ProgramEndMetricsSchema } from './programEndMetricSchemas'

type ProgramEndForm = {
  uid: string
  engagement: EngagementType
  programEndMetrics: ProgramEndMetrics
}

const ProgramEndSchema = (uid: string): Yup.ObjectSchema<ProgramEndForm> =>
  Yup.object({
    uid: Yup.string().default(uid).required(),
    engagement: Yup.string()
      .oneOf([EngagementType.Completer, EngagementType.Dropout, EngagementType.NoShow])
      .required(),
    programEndMetrics: ProgramEndMetricsSchema.required().default({}),
  }).required()

export type Props = {
  close: () => void
  cancel: () => void
  engagement: PatientEngagementIndicator
  lastWeekNbr: number
  programStartDate: string
  programEndMetrics: Array<keyof ProgramEndMetrics>
  uid: string
}

const MissingProgramEndMetrics = () => (
  <>
    <ParticipantResultsHeader label="No Program Metrics For Program" />
    <ParticipantResultsTextRow
      label="Please try reloading the page and contact support if the problem persists"
      content={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Warning fontSize="small" color="error" />
        </Box>
      }
    />
  </>
)

const baselinesForAllMetricsAvailable = (endMetricsInProgram: ProgramEndMetrics) =>
  Object.keys(endMetricsInProgram)
    .map((metricKey) => {
      const metricValues = endMetricsInProgram[metricKey as keyof ProgramEndMetrics]!
      return metricValues.baseline
    })
    .every((value) => !isNil(value))

const isDisabled = (endMetricsInProgram: ProgramEndMetrics) =>
  !(baselinesForAllMetricsAvailable(endMetricsInProgram) && Object.keys(endMetricsInProgram).length)

const EndProgram = ({ cancel, close, engagement, uid, programStartDate, lastWeekNbr, programEndMetrics }: Props) => {
  const { data, loading } = useGetPatientHistoryQuery({ variables: { uid } })

  const { patientHistory } = data || {}

  const [setPatientProgramEnd] = useSetPatientProgramEndMutation({
    refetchQueries: [GetFullPatientDocument, GetPatientHistoryDocument],
  })
  const handleSubmit = useCallback(
    async (opts: UpdateQueryOptions<ProgramEndForm>): Promise<void> => {
      const { variables } = opts!

      await setPatientProgramEnd({ variables })
    },
    [setPatientProgramEnd]
  )

  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      onSuccess={close}
      validationSchema={ProgramEndSchema(uid)}
      render={({ isSubmitting, values }) => (
        <>
          <ClosableDialogTitle onClose={close}>Confirm Program Results</ClosableDialogTitle>
          <DialogContent>
            <Table size="small">
              <TableBody>
                <ParticipantResultsHeader label="Program" />
                <ParticipantResultsTextRow label="Start" content={<FormatDate value={programStartDate} />} />
                <Engagement
                  type={engagement.type}
                  completedIntros={engagement.completedIntros}
                  lastWeekNbr={lastWeekNbr}
                />
                {!programEndMetrics.length && <MissingProgramEndMetrics />}
                {programEndMetrics.length > 0 &&
                  programEndMetrics.map((metric) => (
                    <React.Fragment key={metric}>
                      <ParticipantResultsHeader label={metric} />
                      <Baseline type={metric} data={{ patientHistory, loading }} programStartDate={programStartDate} />
                      <ProgramEndResult
                        type={metric}
                        data={{ patientHistory, loading }}
                        programStartDate={programStartDate}
                        lastWeekNbr={lastWeekNbr}
                      />
                      <PercentageChange
                        isLoading={loading}
                        baseline={values.programEndMetrics[metric]?.baseline}
                        programEndResult={values.programEndMetrics[metric]?.programEnd}
                      />
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>

            <Box pt={3} display="flex" justifyContent="center">
              Note: The results will be used in billing and reporting.
            </Box>
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={cancel}>
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={loading || isSubmitting || isDisabled(values.programEndMetrics)}
              type="submit"
            >
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default EndProgram
