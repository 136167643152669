import React from 'react'
import { Box } from '@mui/material'
import {
  angryMoodIcon,
  neutralMoodIcon,
  happyMoodIcon,
  laughingMoodIcon,
  unhappyMoodIcon,
} from 'src/designSystem/icons'

export enum Mood {
  Angry = 'CRYING', // Notice that we are using the name CRYING for angry smiley
  Unhappy = 'UNHAPPY',
  Indifferent = 'INDIFFERENT',
  Happy = 'HAPPY',
  Laughing = 'LAUGHING',
}

export const isValidMoodValue = (value?: unknown): value is Mood =>
  typeof value === 'string' && Object.values(Mood).includes(value as Mood)

const moodIconsByResponse = {
  [Mood.Angry]: angryMoodIcon,
  [Mood.Unhappy]: unhappyMoodIcon,
  [Mood.Indifferent]: neutralMoodIcon,
  [Mood.Happy]: happyMoodIcon,
  [Mood.Laughing]: laughingMoodIcon,
}

type Props = {
  value: Mood
}

export default function MoodValue({ value }: Props) {
  const moodIcon = moodIconsByResponse[value]

  return (
    <Box>
      <img src={moodIcon} height={20} alt={value} />
    </Box>
  )
}
