import React, { useCallback } from 'react'
import { Box, Button, DialogActions, DialogContent, Alert } from '@mui/material'

import * as Yup from 'yup'
import { CustomCheckbox, FormDatePicker, GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { PatientHistoryType } from 'src/legacy/models/firebase'
import { UpdateQueryOptions } from '@apollo/client'
import { Field } from 'formik'
import { PATIENT_HISTORY_TYPE_NAMES } from 'src/participantHistory/constants'
import { GetPatientHistoryDocument, useGetPatientHistoryQuery } from 'src/legacy/lib/graphql/queries.generated'
import { Moment } from 'moment'
import PreselectedSourceField from './PreselectedSourceField'
import {
  GetFullPatientDocument,
  useGetEarliestProgramStartDateQuery,
  useSetPatientAcceptedMutation,
} from '../../patientQueries.generated'
import { isStartDateDisabled } from '../dateHelpers'

type ParticipantAcceptForm = {
  uid: string
  programStartDate: string
  source: string
  shipHRVDeviceToPatient: boolean
}

const ParticipantAcceptedSchema = (uid: string): Yup.ObjectSchema<ParticipantAcceptForm> =>
  Yup.object({
    uid: Yup.string().required().default(uid),
    programStartDate: Yup.string().required(),
    source: Yup.string().required(),
    shipHRVDeviceToPatient: Yup.boolean().required().default(true),
  }).required()

type Props = {
  close: () => void
  uid: string
  acceptanceCriteria?: Array<PatientHistoryType>
  isHrvProgram?: boolean
}

const SetParticipantAcceptedForm = ({ close, uid, acceptanceCriteria, isHrvProgram = true }: Props) => {
  const { data: patientHistoryData } = useGetPatientHistoryQuery({ variables: { uid } })
  const { data: earliestProgramStartDateData } = useGetEarliestProgramStartDateQuery({ variables: { uid } })

  const [setPatientAccepted] = useSetPatientAcceptedMutation({
    refetchQueries: [GetFullPatientDocument, GetPatientHistoryDocument],
  })

  const handleSubmit = useCallback(
    async (opts: UpdateQueryOptions<ParticipantAcceptForm>): Promise<void> => {
      const variables = isHrvProgram ? opts.variables! : { ...opts.variables!, shipHRVDeviceToPatient: false }

      await setPatientAccepted({
        variables,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setPatientAccepted]
  )

  if (!acceptanceCriteria) {
    return (
      <>
        <ClosableDialogTitle onClose={close}>Missing Program Acceptance Info</ClosableDialogTitle>
        <DialogContent>
          <Alert color="error" icon={false}>
            We were unable to fetch acceptance criteria for the program. There was likely a connection error, please try
            again and contact #support in slack if the problem persists.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={close}>
            Close
          </Button>
        </DialogActions>
      </>
    )
  }

  const missingCriteria = acceptanceCriteria.filter(
    (criterion) =>
      !patientHistoryData?.patientHistory.find((patientHistoryItem) => patientHistoryItem.type?.valueOf() === criterion)
  )

  const shouldDisableDate = (date: Moment) =>
    missingCriteria.length > 0 ||
    isStartDateDisabled(date, earliestProgramStartDateData?.patient.earliestProgramStartDate)

  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      onSuccess={close}
      validationSchema={ParticipantAcceptedSchema(uid)}
      render={({ isSubmitting }) => (
        <>
          <ClosableDialogTitle onClose={close}>Accept Participant Into Program</ClosableDialogTitle>
          <DialogContent>
            {missingCriteria.map((MissingCriterionType) => (
              <Alert key={MissingCriterionType} color="error" icon={false}>
                {PATIENT_HISTORY_TYPE_NAMES[MissingCriterionType]} record needs to be completed.
              </Alert>
            ))}
            <Field
              component={FormDatePicker}
              label="Start date"
              fullWidth
              name="programStartDate"
              shouldDisableDate={shouldDisableDate}
            />
            <PreselectedSourceField uid={uid} />
            <Box mt={1} ml={1}>
              The <em>source</em> above will be used in billing.
            </Box>
            {isHrvProgram && (
              <Field
                component={CustomCheckbox}
                fullWidth
                label="Ship an HRV device to the participant"
                name="shipHRVDeviceToPatient"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" disabled={isSubmitting || missingCriteria.length > 0} type="submit">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default SetParticipantAcceptedForm
