import { useFormikContext } from 'formik'
import { useToasts } from 'react-toast-notifications'
import { Button } from '@mui/material'
import React from 'react'
import { CostEstimateLookupSuccess } from 'src/graphql.generated'
import { useCostEstimateLookupLazyQuery } from './financialEligibilityHealthPlanQueries.generated'
import { centsToDollars } from './centsToDollars'

type Props = {
  uid: string
}

const GetCostEstimateButton = ({ uid }: Props) => {
  const [costEstimateLookup, costEstimateLookupStatus] = useCostEstimateLookupLazyQuery()
  const { setFieldValue } = useFormikContext()
  const { addToast } = useToasts()

  const handleGetCostEstimate = async () => {
    const costEstimateResult = await costEstimateLookup({ variables: { uid } })

    if (!costEstimateResult.data || costEstimateResult.error) {
      addToast(`Cost estimation failed`, { appearance: 'error' })
      return
    }

    if (costEstimateResult.data?.costEstimateLookup.__typename === 'CostEstimateLookupFailure') {
      addToast(`Cost estimation failed: ${costEstimateResult.data.costEstimateLookup.reason}`, {
        appearance: 'warning',
      })
      return
    }

    addToast(`Cost estimation succeeded`, {
      appearance: 'success',
    })

    const costEstimate = toCostEstimateInDollars(costEstimateResult.data.costEstimateLookup)

    setFieldValue('value.costEstimate', costEstimate)
    setFieldValue(
      'value.description',
      [
        `Intake cost: ${costEstimate.intakeCostEstimate}$`,
        `Month 1 cost: ${costEstimate.month1CostEstimate}$`,
        `Month 2 cost: ${costEstimate.month2CostEstimate}$`,
        `Month 3 cost: ${costEstimate.month3CostEstimate}$`,
      ].join('\n')
    )
  }

  return (
    <Button variant="outlined" onClick={handleGetCostEstimate} disabled={costEstimateLookupStatus.loading}>
      Get cost estimate
    </Button>
  )
}

function toCostEstimateInDollars(estimate: CostEstimateLookupSuccess) {
  return {
    intakeCostEstimate: centsToDollars(estimate.intakeCostEstimateInCents),
    month1CostEstimate: centsToDollars(estimate.month1CostEstimateInCents),
    month2CostEstimate: centsToDollars(estimate.month2CostEstimateInCents),
    month3CostEstimate: centsToDollars(estimate.month3CostEstimateInCents),
  }
}

export default GetCostEstimateButton
