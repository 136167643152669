import { useEffect } from 'react'
import moment from 'moment'

export default () => {
  useEffect(() => {
    const today3AM = moment().startOf('day').add(3, 'hours')
    const next3AM = today3AM.isBefore(moment()) ? today3AM.add(1, 'day') : today3AM

    const interval = setInterval(() => {
      if (!moment().isBefore(next3AM)) {
        window.location.reload()
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })
}
