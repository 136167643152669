import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { getIn, FastField, FormikHelpers } from 'formik'

import { CustomTextField } from 'src/form'

type Props<Values extends Record<string, number>> = {
  type: 'GAD7' | 'PHQ9' | 'WHO5'
  values: { value: Values }
  setFieldValue: FormikHelpers<Values>['setFieldValue']
  questions: Array<{ key: string; label: string }>
}

const ScoreFields = <Values extends Record<string, number>>({
  type,
  values,
  setFieldValue,
  questions,
}: Props<Values>) => {
  const scoreField = `${type}_SCORE`
  const scorePath = `value.${scoreField}`
  const scoreFieldValue = getIn(values, scorePath)

  const calculatedScore = useMemo(() => {
    const scoreQuestions = questions.map(({ key }) => key)
    const scoreValues = _.pick(values.value, scoreQuestions)

    return _.reduce(
      scoreValues,
      (acc, value, key) => {
        if (key !== scoreField && typeof value === 'number') {
          return acc + value
        }
        return acc
      },
      0
    )
  }, [scoreField, values.value, questions])

  useEffect(() => {
    if (calculatedScore !== scoreFieldValue) {
      setFieldValue(scorePath, calculatedScore)
    }
  }, [calculatedScore, scoreFieldValue, scorePath, setFieldValue])

  return (
    <>
      {questions.map(({ key, label }) => {
        const isDisabled = _.endsWith(key, 'SCORE')
        return (
          <FastField
            component={CustomTextField}
            disabled={isDisabled}
            label={label}
            key={key}
            labelPosition="left"
            name={`value.${key}`}
            type="number"
          />
        )
      })}
    </>
  )
}

export default ScoreFields
