import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { Redirect } from 'react-router'

import ScrollContainer from 'src/legacy/components/ScrollContainer'
import { useLoadAllParticipantData } from 'src/participant'
import { useIsAdmin } from 'src/session'
import ABTestGroupsForm from 'src/legacy/components/ParticipantRecords/ParticipantRecord/ABTestGroups/ABTestGroupsForm'
import ParticipantHistory from '../../../participantHistory/ParticipantHistory'
import ParticipantRecordForm from './ParticipantRecord/ParticipantRecordForm'

type Props = {
  uid: string
}
const ParticipantRecords = ({ uid }: Props) => {
  const result = useLoadAllParticipantData(uid)
  const { addToast } = useToasts()

  const isAdmin = useIsAdmin()

  const [initialLoadDoneUid, setInitialLoadDoneUid] = useState<string | null>(null)

  useEffect(() => {
    if (result.status === 'failure') {
      addToast(
        "Failed to retrieve the participant's information. Make sure that you have the required access for this participant",
        {
          appearance: 'error',
        }
      )
    }
  }, [result.status, addToast])

  useEffect(() => {
    if (result.status === 'success') {
      setInitialLoadDoneUid(uid)
    }
    if (result.status === 'loading' && initialLoadDoneUid !== uid) {
      setInitialLoadDoneUid(null)
    }
  }, [initialLoadDoneUid, result.status, uid])

  if (result.status === 'failure') {
    return <Redirect to="/" />
  }

  return (
    <ScrollContainer>
      <ParticipantRecordForm uid={uid} isLoading={initialLoadDoneUid !== uid} patient={result.patient} />
      {isAdmin && <ABTestGroupsForm uid={uid} />}
      <ParticipantHistory uid={uid} programID={result.patient?.enrollment.programID || null} />
    </ScrollContainer>
  )
}

export default ParticipantRecords
