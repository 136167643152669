import React from 'react'
import { Field } from 'formik'
import * as Yup from 'yup'

import { CustomTextField } from 'src/form'

export type IntakeNoteCoachingSchema = {
  notes: string
}

export const INTAKE_NOTE_COACHING_SCHEMA = Yup.object<IntakeNoteCoachingSchema>({
  notes: Yup.string().required(),
})

const IntakeNoteCoachingFields = () => (
  <Field
    component={CustomTextField}
    label="Intake notes"
    fullWidth
    multiline
    name="value.notes"
    resizable
    minRows={20}
  />
)

export default IntakeNoteCoachingFields
