import React from 'react'
import cx from 'classnames'
import { Drawer, Paper, ButtonProps, ButtonBase } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ArrowRight from '@mui/icons-material/ArrowRight'
import ArrowLeft from '@mui/icons-material/ArrowLeft'

type Alignment = 'left' | 'right'

type Props = StyleProps & {
  children: React.ReactNode
  onTogglerClick: () => void
  open: boolean
}

type StyleProps = {
  alignment: Alignment
  width: number
}

export default function FoldableDrawer({ open, width, alignment, onTogglerClick, children }: Props) {
  const styles = useDrawerStyles({ width, alignment })
  return (
    <>
      <Toggler open={open} alignment={alignment} width={width} onClick={onTogglerClick} />
      <Drawer
        variant="persistent"
        anchor={alignment}
        open={open}
        className={styles.drawer}
        SlideProps={{ mountOnEnter: false }}
        classes={{ paper: styles.drawerPaper }}
      >
        {children}
      </Drawer>
    </>
  )
}

type TogglerProps = StyleProps & ButtonProps & { open: boolean }

function Toggler({ open, width, alignment, onClick }: TogglerProps) {
  const styles = useTogglerStyles({ width, alignment })
  const [OpenArrow, ClosedArrow] = alignment === 'left' ? [ArrowLeft, ArrowRight] : [ArrowRight, ArrowLeft]
  return (
    <Paper square elevation={4} className={cx(styles.toggler, { [styles.togglerOpen]: open })}>
      <ButtonBase focusRipple onClick={onClick}>
        {open ? (
          <OpenArrow titleAccess={`${alignment} open`} color="action" />
        ) : (
          <ClosedArrow titleAccess={`${alignment} closed`} color="action" />
        )}
      </ButtonBase>
    </Paper>
  )
}

const marginProp = (alignment: Alignment) => (alignment === 'left' ? 'marginLeft' : 'marginRight')

/**
 * These styles can be applied to the content element to make it
 * swift when drawer is open. This way it won't stay behind it.
 */
export const useContentStyles = makeStyles((theme) => ({
  content: (props: StyleProps) => ({
    transition: theme.transitions.create(marginProp(props.alignment), {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [marginProp(props.alignment)]: -props.width,
  }),
  contentSwift: (props: StyleProps) => ({
    transition: theme.transitions.create(marginProp(props.alignment), {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [marginProp(props.alignment)]: 0,
  }),
}))

const useDrawerStyles = makeStyles((theme) => ({
  drawer: (props: StyleProps) => ({
    color: theme.palette.background.paper,
    width: props.width,
    flexShrink: 0,
  }),
  drawerPaper: (props: StyleProps) => ({
    width: props.width,
    paddingTop: 56,
    paddingBottom: 15,
    overflow: 'hidden',
  }),
}))

const useTogglerStyles = makeStyles((theme) => ({
  toggler: (props: StyleProps) => ({
    position: 'absolute',
    zIndex: theme.zIndex.drawer - 1,
    top: '50%',
    [props.alignment]: 0,
    background: theme.palette.background.paper,
    transition: theme.transitions.create(props.alignment, {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
  togglerOpen: (props: StyleProps) => ({
    transition: theme.transitions.create(props.alignment, {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [props.alignment]: props.width,
  }),
}))
