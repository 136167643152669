import '@mui/styles'

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { common } from '@mui/material/colors'
import { createTheme, alpha, Theme } from '@mui/material/styles'

const PRIMARY_COLOR_FADE = 0.08

const defaultTheme = createTheme()

declare module '@mui/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    lightGrey: string
    lightestGrey: string
  }
  interface PaletteOptions {
    lightGrey: string
    lightestGrey: string
  }
}

const palette = {
  primary: {
    main: '#57ad6e',
    contrastText: common.white,
  },
  secondary: {
    main: '#4ca0e0',
    contrastText: common.white,
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
  },
  background: {
    default: '#f7faf8',
    paper: common.white,
  },
  warning: {
    main: '#f0c518',
    contrastText: common.black,
  },
  lightGrey: '#dee0df',
  lightestGrey: '#f2f5f3',
}

const typography = {
  fontFamily: 'IBM Plex Sans, sans-serif',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 600,
  body2: {
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  h1: {
    fontSize: '1.625rem',
    fontWeight: 500,
    lineHeight: '1.875rem',
  },
  h2: {
    fontSize: '1.375rem',
    fontWeight: 500,
    lineHeight: '1.625rem',
  },
  h3: {
    fontSize: '1.125rem',
    fontWeight: 500,
    lineHeight: '1.375rem',
  },
  h4: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.25rem',
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.25rem',
  },
  h6: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.125rem',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.125rem',
  },
  subtitle2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1rem',
  },
}

const theme = createTheme({
  typography,
  palette,
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          '& + &': {
            marginTop: defaultTheme.spacing(1),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            color: 'white',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(2),
          paddingTop: 0,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '.MuiCardHeader-root + &': {
            paddingTop: 0,
          },
          '&:first-of-type': {
            paddingTop: 0,
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(3),
          paddingTop: defaultTheme.spacing(1),
          paddingBottom: defaultTheme.spacing(2),
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorDockedLeft: {
          borderRight: `1px solid ${alpha(palette.primary.main, PRIMARY_COLOR_FADE * 2)}`,
        },
        paperAnchorDockedRight: {
          borderLeft: `1px solid ${alpha(palette.primary.main, PRIMARY_COLOR_FADE * 2)}`,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(2),
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.MuiInputLabel-shrink': {
            color: defaultTheme.palette.text.primary,
          },
          '&.Mui-focused': {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          width: 256,
        },
        fullWidth: {
          width: '100%',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderColor: `${defaultTheme.palette.text.primary} !important`,
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRight: `${defaultTheme.spacing(0.5)} solid transparent`,
          '&.Mui-selected': {
            backgroundColor: alpha(palette.primary.main, PRIMARY_COLOR_FADE),
            borderRight: `${defaultTheme.spacing(0.5)} solid ${palette.primary.main}`,
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        text: {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          height: 56,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: defaultTheme.typography.body2.fontSize,
          lineHeight: defaultTheme.typography.body2.lineHeight,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '.MuiToolbar-root + &': {
            marginTop: defaultTheme.spacing(-2),
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          fontWeight: 'bold',
        },
      },
    },
  },
})

const CustomMuiThemeProvider = ({ children }: { children: React.ReactNode }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyledEngineProvider>
)

CustomMuiThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CustomMuiThemeProvider
