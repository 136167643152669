import React, { useRef, useState } from 'react'
import { Box, Chip, IconButton, Popover, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { LocalOfferOutlined } from '@mui/icons-material'
import { TagFragment, useGetTagsLazyQuery } from 'src/legacy/components/Admin/Tags/tagQueries.generated'
import { Loader } from 'src/designSystem'
import { trackAddParticipantTag, trackRemoveParticipantTag } from 'src/mixpanel/api'
import {
  GetPatientWithTagsDocument,
  useAddTagToParticipantMutation,
  useGetPatientWithTagsQuery,
  useRemoveTagFromParticipantMutation,
} from '../patientQueries.generated'

const useStyles = makeStyles((theme) => ({
  popover: ({ width }: { width?: number }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    width,
  }),
  chipSpacing: {
    padding: theme.spacing(0.5),
  },
}))

type Props = {
  uid: string
}

const isParticipantTag = (participantTags: Array<TagFragment>, tag: TagFragment) =>
  participantTags.find((participantTag) => participantTag.id === tag.id)

const ParticipantTags = ({ uid }: Props) => {
  const { data: participantTagsData, loading } = useGetPatientWithTagsQuery({ variables: { uid } })
  const [getTags, { data: allTagsData, loading: allTagsLoading }] = useGetTagsLazyQuery()

  const [open, setOpen] = useState(false)

  const [addTagToParticipant] = useAddTagToParticipantMutation()
  const [removeTagFromParticipant] = useRemoveTagFromParticipantMutation()

  const anchorRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const styles = useStyles({ width: parentRef.current?.offsetWidth })

  const handleClick = async () => {
    setOpen((prevOpen) => !prevOpen)
    await getTags()
  }

  const handleClose = () => {
    setOpen(false)
  }

  if (loading && !open) {
    return <Loader size="large" />
  }

  const participantTags = participantTagsData?.patient?.tags || []
  const allAvailableTags = allTagsData?.patientTags || []

  const handleSelect = (tag: TagFragment) => async () => {
    if (!isParticipantTag(participantTags, tag)) {
      trackAddParticipantTag()
      await addTagToParticipant({ variables: { tagID: tag.id, uid }, refetchQueries: [GetPatientWithTagsDocument] })
    } else {
      trackRemoveParticipantTag()
      await removeTagFromParticipant({
        variables: { tagID: tag.id, uid },
        refetchQueries: [GetPatientWithTagsDocument],
      })
    }
  }

  return (
    // TODO: You should be able to set ref on Box in mui 5
    <div ref={parentRef}>
      <Box display="inline-flex" alignItems="center" flexWrap="wrap">
        <Typography ref={anchorRef}>
          Edit tags
          <IconButton onClick={handleClick} size="small">
            <LocalOfferOutlined />
          </IconButton>
        </Typography>
        {participantTags.map((tag) => (
          <Box className={styles.chipSpacing} key={tag.id}>
            <Chip color="primary" label={tag.name} />
          </Box>
        ))}
        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box className={styles.popover}>
            {(!allTagsLoading &&
              allAvailableTags.map((tag) => (
                <Box className={styles.chipSpacing} key={tag.id}>
                  <Chip
                    clickable
                    color={isParticipantTag(participantTags, tag) ? 'primary' : undefined}
                    label={tag.name}
                    variant={isParticipantTag(participantTags, tag) ? undefined : 'outlined'}
                    onClick={handleSelect(tag)}
                  />
                </Box>
              ))) || <Loader size="large" />}
          </Box>
        </Popover>
      </Box>
    </div>
  )
}

export default ParticipantTags
