import _ from 'lodash'
import React from 'react'
import { FieldProps } from 'formik'
import { FormControl, FormHelperText, SelectProps, FormControlLabel, Checkbox, Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import { GenericFormProps } from './helpers'
import CustomFormHelperText from './CustomFormHelperText'

type Props = {
  fullWidth?: boolean
  helperText?: JSX.Element | string
  label: string
  multiline?: boolean
  renderValue?: SelectProps['renderValue']
  rows?: number
  isCheckbox?: boolean
  condensed?: boolean
}

const INPUT_FIELD_HEIGHT = 40
const INPUT_ROW_HEIGHT = 16

const useStyles = makeStyles({
  root: {
    marginTop: (props: Props) => (props.condensed ? 0 : undefined),
  },
})

const PROP_PICK_LIST = [
  'disabled',
  'error',
  'fullWidth',
  'focused',
  'hiddenLabel',
  'margin',
  'required',
  'size',
  'variant',
  'color',
]

const LoadingFieldIndicator: React.FunctionComponent<Props & GenericFormProps & FieldProps> = (props) => {
  const controlProps = _.pick(props, PROP_PICK_LIST)

  const { multiline = false, rows = 1, helperText, label, fullWidth } = props
  const additionalRowCount = (multiline ? rows : 1) - 1
  const height = INPUT_FIELD_HEIGHT + additionalRowCount * INPUT_ROW_HEIGHT

  const classes = useStyles(props)

  if (props.isCheckbox) {
    return (
      <FormControl {...controlProps} role="progressbar" margin="none" classes={classes}>
        <FormControlLabel label={label} control={<Checkbox disabled />} />
        {helperText && <CustomFormHelperText>{helperText}</CustomFormHelperText>}
      </FormControl>
    )
  }
  return (
    <FormControl {...controlProps} role="progressbar" margin="none" classes={classes}>
      <Skeleton height={height} width={fullWidth ? '100%' : 256} />
      {helperText && (
        <FormHelperText margin="dense" variant="filled">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default LoadingFieldIndicator
