import React, { useCallback, useState } from 'react'
import { Box, Card, Tab, Tabs, Toolbar } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { Cards } from 'src/legacy/components/mui'
import { Container, Loader } from 'src/designSystem'
import ScrollContainer from 'src/legacy/components/ScrollContainer'
import { PatientSortField, PatientSortOrder } from 'src/graphql.generated'
import ParticipantsTable from './ParticipantsTable'
import ParticipantForm from '../legacy/components/addParticipantForms/ParticipantForm'
import TestParticipantForm from '../legacy/components/addParticipantForms/TestParticipantForm'
import ParticipantSearchFilters from './ParticipantSearchFilters'
import useLoadParticipantsWithFilter, { ParticipantFilter } from './useLoadParticipantsWithFilter'
import useParseSearchQuery, { updateQueryString } from './useParseSearchQuery'
import useDisableColumnsForStates from './useDisableColumnsForStates'

const DEFAULT_SORT_FIELD = PatientSortField.LastStateTransitionAt
const DEFAULT_SORT_ORDER = PatientSortOrder.Desc

const ParticipantsView = () => {
  const location = useLocation()
  const history = useHistory()

  const params = useParseSearchQuery()
  const { filter, pagination, sort } = params
  const { page, pageSize } = pagination
  const { sortBy = DEFAULT_SORT_FIELD, sortOrder = DEFAULT_SORT_ORDER } = sort

  const disabledColumns = useDisableColumnsForStates(filter.states)
  const result = useLoadParticipantsWithFilter(filter, pagination, sort)

  const onFilterChange = useCallback(
    (updatedFilter: ParticipantFilter) => {
      const query = updateQueryString(params, {
        filter: updatedFilter,
        pagination: { page: 0 },
      })
      history.push(`${location.pathname}?${query}`)
    },
    [params, history, location]
  )

  const onPageChange = useCallback(
    (newPage: number) => {
      const query = updateQueryString(params, {
        pagination: { page: newPage },
      })
      history.push(`${location.pathname}?${query}`)
    },
    [params, history, location]
  )

  const onChangeRowsPerPage = useCallback(
    (newRows: number) => {
      const query = updateQueryString(params, {
        pagination: { page: 0, pageSize: newRows },
      })
      history.push(`${location.pathname}?${query}`)
    },
    [params, history, location]
  )

  const onSortChange = useCallback(
    (sortField: PatientSortField, newSortOrder: PatientSortOrder) => {
      const query = updateQueryString(params, {
        sort: { sortBy: sortField, sortOrder: newSortOrder },
      })
      history.push(`${location.pathname}?${query}`)
    },
    [params, history, location]
  )

  const [selected, setSelected] = useState('participant_form')
  return (
    <Container>
      <ScrollContainer>
        <Cards>
          <Card>
            <Toolbar disableGutters variant="dense">
              <Tabs indicatorColor="primary" onChange={(_event, value) => setSelected(value)} value={selected}>
                <Tab label="Add Participant" value="participant_form" />
                <Tab label="Add Test Participant" value="test_participant_form" />
              </Tabs>
            </Toolbar>
            <Box borderTop={1} borderColor="divider">
              {selected === 'participant_form' && <ParticipantForm onAddParticipantSuccess={result.refetch} />}
              {selected === 'test_participant_form' && (
                <TestParticipantForm onAddTestParticipantSuccess={result.refetch} />
              )}
            </Box>
          </Card>
          <Card>
            <ParticipantSearchFilters filter={filter} onChange={onFilterChange} />
            {result.loading && <Loader size="large" />}
            {!result.loading && result.data && (
              <ParticipantsTable
                participants={result.data.searchPatientsV2.patients}
                rowCount={result.data.searchPatientsV2.patientCount}
                page={page}
                pageSize={pageSize}
                filter={filter}
                sortBy={sortBy}
                sortOrder={sortOrder}
                onSortChange={onSortChange}
                onPageChange={onPageChange}
                onChangeRowsPerPage={onChangeRowsPerPage}
                disabledColumns={disabledColumns}
                onContentChange={result.refetch}
              />
            )}
          </Card>
        </Cards>
      </ScrollContainer>
    </Container>
  )
}

export default ParticipantsView
