/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'

export type CommunityPostReplyFragmentFragment = {
  __typename: 'CommunityPostReplyQuestionAnswer'
  replyID: string
  createdAt: Date
  isHidden: boolean
  message: string
  replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
}

export type CommunityPostReplyQuestionAnswerFragmentFragment = {
  __typename: 'CommunityPostReplyQuestionAnswer'
  replyID: string
  createdAt: Date
  isHidden: boolean
  message: string
  replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
}

export const CommunityPostReplyQuestionAnswerFragmentFragmentDoc = gql`
  fragment CommunityPostReplyQuestionAnswerFragment on CommunityPostReplyQuestionAnswer {
    __typename
    replyID
    createdAt
    isHidden
    message
    replyAuthor {
      ... on CommunityFacilitator {
        name
      }
    }
  }
`
export const CommunityPostReplyFragmentFragmentDoc = gql`
  fragment CommunityPostReplyFragment on CommunityPostReply {
    __typename
    replyID
    ... on CommunityPostReplyQuestionAnswer {
      ...CommunityPostReplyQuestionAnswerFragment
    }
  }
  ${CommunityPostReplyQuestionAnswerFragmentFragmentDoc}
`
