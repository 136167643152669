import React from 'react'
import { FieldProps } from 'formik'
import DateTimePicker from 'src/designSystem/DateTimePicker'
import { GenericFormProps, getError, hasError, isViewOnly, useUnregisterFieldOnUnmount } from './helpers'

type Props = {
  disabled: boolean
  fullWidth: boolean
  helperText?: string
  label: string
  labelPosition: 'left' | 'top'
  shouldDisableDate?: (date: string) => boolean
}

const FormDateTimePicker: React.FC<Props & GenericFormProps & FieldProps> = (props) => {
  const {
    disabled,
    field: { name, value },
    form: { setFieldValue },
    fullWidth,
    helperText,
    label,
    labelPosition,
    shouldDisableDate,
  } = props

  useUnregisterFieldOnUnmount(props)
  const viewOnly = isViewOnly(props)
  const error = hasError(props)
  const errorText = error ? getError(props) : helperText

  return (
    <DateTimePicker
      name={name}
      value={value}
      setValue={setFieldValue}
      viewOnly={viewOnly}
      error={error}
      helperText={errorText}
      shouldDisableDate={shouldDisableDate}
      disabled={disabled}
      fullWidth={fullWidth}
      label={label}
      labelPosition={labelPosition}
    />
  )
}

FormDateTimePicker.defaultProps = {
  disabled: false,
  errorText: undefined,
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  labelPosition: 'top',
  unregisterOnUnmount: true,
}

export default FormDateTimePicker
