import cx from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import useDrawersAutohiding from './useDrawersAutohiding'
import FoldableDrawer, { useContentStyles } from './FoldableDrawer'

type Props = {
  sidebar?: JSX.Element
  children: React.ReactNode
  infoarea?: JSX.Element
  sidebarWidth?: number
  infoareaWidth?: number
  forceShowSidebar?: boolean
}

const Container = ({
  children,
  infoarea,
  sidebar,
  sidebarWidth = 370,
  infoareaWidth = 370,
  forceShowSidebar,
}: Props) => {
  const sidebarStyleProps = useMemo(() => ({ alignment: 'left' as const, width: sidebarWidth }), [sidebarWidth])
  const infoareaStyleProps = useMemo(() => ({ alignment: 'right' as const, width: infoareaWidth }), [infoareaWidth])

  const sidebarContentStyles = useContentStyles(sidebarStyleProps)
  const infoareaContentStyles = useContentStyles(infoareaStyleProps)

  const hasInfoArea = Boolean(infoarea)
  const hasSidebar = Boolean(sidebar)
  const [sidebarOpen, setSidebarOpen] = useState(hasSidebar)
  const [infoareaOpen, setInfoAreaOpen] = useState(hasInfoArea)

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
  const toggleInfoarea = () => setInfoAreaOpen(!infoareaOpen)

  useDrawersAutohiding({
    setSidebarOpen,
    setInfoAreaOpen,
    sidebarWidth,
    infoareaWidth,
    minContentWidth: 370,
    hasInfoArea,
  })

  // whenever we go from no-infoarea to having an infoarea, we want to show it
  useEffect(() => {
    setInfoAreaOpen(hasInfoArea)
  }, [hasInfoArea])

  return (
    <Box height="100%" display="flex">
      {sidebar && (
        <FoldableDrawer open={forceShowSidebar || sidebarOpen} onTogglerClick={toggleSidebar} {...sidebarStyleProps}>
          {sidebar}
        </FoldableDrawer>
      )}
      <Box
        flexGrow={1}
        position="relative"
        className={cx({
          [sidebarContentStyles.content]: hasSidebar,
          [infoareaContentStyles.content]: hasInfoArea,
          [sidebarContentStyles.contentSwift]: sidebarOpen,
          [infoareaContentStyles.contentSwift]: infoareaOpen,
        })}
      >
        {children}
      </Box>
      {infoarea && (
        <FoldableDrawer open={infoareaOpen} onTogglerClick={toggleInfoarea} {...infoareaStyleProps}>
          {infoarea}
        </FoldableDrawer>
      )}
    </Box>
  )
}

export default Container
