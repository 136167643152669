import React, { useCallback, useState } from 'react'
import { IconButton, IconButtonProps } from '@mui/material'
import { DeleteForever } from '@mui/icons-material'
import { useToasts } from 'react-toast-notifications'

import ConfirmationDialog from './ConfirmationDialog'

type Props = {
  color?: IconButtonProps['color']
  onDelete: () => void | Promise<unknown>
  size?: IconButtonProps['size']
  title: string
}

const DeleteButton = ({ color = 'default', onDelete, size = 'medium', title }: Props) => {
  const { addToast } = useToasts()

  const [disabled, setDisabled] = useState(false)

  const onProceedCallback = useCallback(async () => {
    setDisabled(true)
    try {
      await onDelete()
    } catch (error) {
      setDisabled(false)
      addToast('Something went wrong! Please try again.')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onDelete])

  return (
    <ConfirmationDialog onProceed={onProceedCallback} title={title}>
      {(open) => (
        <IconButton color={color} disabled={disabled} onClick={open} size={size}>
          <DeleteForever titleAccess="delete" />
        </IconButton>
      )}
    </ConfirmationDialog>
  )
}

export default DeleteButton
