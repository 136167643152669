/* eslint-disable react/no-unused-prop-types */
import * as React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment, { Moment } from 'moment'

type Props = {
  alignItem: 'left' | 'right'
  displayedTime?: Date | Moment
  children: React.ReactNode
  metadata?: React.ReactNode
  stickToTop?: boolean
  actions?: React.ReactNode
}

export default function ItemWithSideInfo(props: Props) {
  const styles = useStyles(props)
  const { displayedTime, children, metadata, actions } = props

  return (
    <Box className={styles.itemWithTimeRow}>
      {children}
      <Box className={styles.metadata}>
        <div className={styles.metadataContent}>
          <div>
            {metadata}
            {displayedTime && (
              <Typography variant="caption" component="div" className={styles.metadataText}>
                {moment(displayedTime).format('h:mm a')}
              </Typography>
            )}
          </div>
          <div className={styles.actions}>{actions}</div>
        </div>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  itemWithTimeRow: {
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: (props: Props) => (props.alignItem === 'right' ? 'row-reverse' : 'row'),
    alignItems: 'stretch',
    '&:hover $actions': {
      display: 'block',
    },
  },
  metadata: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props: Props) => (props.alignItem === 'right' ? 'flex-end' : 'flex-start'),
    textAlign: (props: Props) => props.alignItem,
    alignItems: (props: Props) => (props.stickToTop ? 'flex-start' : 'flex-end'),
    whiteSpace: 'nowrap',
    minWidth: '25%',
    color: theme.palette.grey[500],
    padding: (props: Props) => theme.spacing(props.metadata ? 3 : 2.5, 2),
  },
  metadataContent: {
    height: 0,
    display: 'flex',
    flexDirection: (props: Props) => (props.alignItem === 'left' ? 'row' : 'row-reverse'),
    alignItems: 'center',
  },
  metadataText: {
    lineHeight: (props: Props) => (props.stickToTop ? 'normal' : 1),
  },
  actions: {
    display: 'none',
    margin: theme.spacing(0, 1),
  },
}))
