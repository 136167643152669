import React from 'react'
import * as Yup from 'yup'
import { useToasts } from 'react-toast-notifications'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import ClosableDialogTitle from 'src/legacy/components/mui/ClosableDialogTitle'
import { Formik, Form, Field } from 'formik'
import { CustomTextField } from 'src/form'
import { useSetInProgramCallBookingEnabledMutation } from '../../videoCallBookingHeaderQueries.generated'
import useTrackToogleBookingEnabledMixpanelEvent from '../useTrackToogleBookingEnabledMixpanelEvent'

type Props = {
  uid: string
  open: boolean
  onClose: () => void
}

const DisableBookingFormSchema = Yup.object()
  .shape({
    description: Yup.string().required(),
  })
  .required()

type DisableBookingFormType = Yup.InferType<typeof DisableBookingFormSchema>

const DisableBookingModal = ({ onClose, open, uid }: Props) => {
  const { addToast } = useToasts()
  const [setInProgramCallBookingEnabled, { loading }] = useSetInProgramCallBookingEnabledMutation()

  const trackMixpanelEvent = useTrackToogleBookingEnabledMixpanelEvent()
  const disableBooking = async (description: string) => {
    trackMixpanelEvent('disable')
    try {
      await setInProgramCallBookingEnabled({
        variables: { uid, enabled: false, description },
      })

      addToast('The participant can no longer book check-in calls from the app', { appearance: 'success' })
      handleClose()
    } catch (error) {
      addToast(`Failed to disable check-in call booking from the app for the participant`)
    }
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <ClosableDialogTitle onClose={onClose}>Describe the reason for disabling check-in call</ClosableDialogTitle>
      <Formik<DisableBookingFormType>
        onSubmit={({ description }) => disableBooking(description)}
        validationSchema={DisableBookingFormSchema}
        initialValues={DisableBookingFormSchema.default()}
        validateOnMount
      >
        {({ isValid }) => (
          <Form>
            <DialogContent>
              <Field
                component={CustomTextField}
                placeholder="Describe why check-in call booking will be disabled for this participant"
                label="Description"
                name="description"
                fullWidth
                multiline
                minRows={4}
              />
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={onClose}>
                Cancel
              </Button>
              <Button color="primary" disabled={!isValid || loading} type="submit">
                Confirm
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default DisableBookingModal
