import _ from 'lodash'
import { hasError, isViewOnly } from './helpers'

export const errorStyle = (style) => (props) => (hasError(props) ? style : null)

const labelPositionLeftStyle = (style) => (props) => (props.labelPosition === 'left' ? style : null)

export const mergeStyles = (...styles) => (props) =>
  _(styles)
    .map((style) => style(props))
    .reduce(_.merge, {})

export const viewOnlyStyle = (style) => (props) => (isViewOnly(props) ? style : null)

export const resizableFieldStyle = (style) => (props) => (props.resizable ? style : null)

export const commonCheckboxStyles = (theme) => ({
  label: mergeStyles(
    errorStyle({
      color: theme.palette.error.main,
    }),
    viewOnlyStyle({
      color: `${theme.palette.text.primary} !important`,
    })
  ),
})

export const commonTextFieldStyles = (theme) => ({
  formHelperTextRoot: labelPositionLeftStyle({
    textAlign: 'right',
    width: '100%',
  }),
  input: mergeStyles(
    labelPositionLeftStyle({
      textAlign: 'right',
    }),
    resizableFieldStyle({
      resize: 'vertical',
    })
  ),
  inputRoot: mergeStyles(
    viewOnlyStyle({
      color: `${theme.palette.text.primary} !important`,
    }),
    labelPositionLeftStyle({
      width: 256,
    })
  ),
  labelRoot: mergeStyles(
    viewOnlyStyle({
      color: `${theme.palette.text.primary} !important`,
    }),
    labelPositionLeftStyle({
      flex: 1,
      pointerEvents: 'auto',
      position: 'relative',
      transform: 'none !important',
    })
  ),
  root: labelPositionLeftStyle({
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  }),
})
