type FirebaseConfig = {
  clientId: string
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId?: string
  appId?: string
}

export type ProgramName = string

const databaseURL = process.env.FIREBASE_DATABASE_URL!
const testDatabase = `meru-health-test-${Date.now()}-${Math.floor(Math.random() * 1000000)}`

export const GRAPHQL_URL = process.env.GRAPHQL_URL!
export const SERVER_URL = process.env.SERVER_URL!
export const BUGSNAG_KEY = 'd245067a1d38968a768d61e3ae0085c1'
export const FIREBASE_CONFIG: FirebaseConfig = {
  clientId: process.env.FIREBASE_CLIENT_ID!,
  apiKey: process.env.FIREBASE_API_KEY!,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN!,
  databaseURL:
    process.env.NODE_ENV === 'test' ? `${databaseURL.replace(/\?ns.*/, `?ns=${testDatabase}`)}` : databaseURL,
  appId: process.env.FIREBASE_APP_ID,
  projectId: process.env.FIREBASE_PROJECT_ID,
}
export const PROGRAM_IDS = process.env.PROGRAM_IDS!.split(',') as Array<ProgramName>
export const REGIONS = process.env.REGIONS!.split(',') as Array<string>
export const TEST_PROGRAM_IDS = process.env.TEST_PROGRAM_IDS!.split(',') as Array<ProgramName>
export const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN!
export const TREATMENT_PLAN_ENABLED = process.env.TREATMENT_PLAN_ENABLED!
