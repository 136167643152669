/* eslint-disable react/no-array-index-key */
import _ from 'lodash'
import React, { Children, FC, Fragment, ReactNode } from 'react'
import { Divider } from '@mui/material'

const toArrayWithoutEmptyChildren = (children: ReactNode) => _.compact(Children.toArray(children))

const CardContents: FC = ({ children }) => {
  const compactedChildren = toArrayWithoutEmptyChildren(children)
  if (compactedChildren.length === 0) {
    return null
  }
  return (
    <>
      {compactedChildren.map((child, i) => (
        <Fragment key={i}>
          {i > 0 && <Divider variant="middle" />}
          {child}
        </Fragment>
      ))}
    </>
  )
}

export default CardContents
