import { Box, Typography, Chip , Skeleton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import moment from 'moment'
import React from 'react'
import CommunityPostReplyActions from './CommunityPostReplyActions'
import { CommunityPostReplyQuestionAnswerFragmentFragment } from './communityPostReplyFragment.generated'
import ReplyItem from './ReplyItem'

type Props = {
  reply: CommunityPostReplyQuestionAnswerFragmentFragment
}

type AuthorProps = {
  authorName?: string
}

function Author(props: AuthorProps) {
  const { authorName } = props
  const styles = useStyles()
  return (
    <>
      {authorName && (
        <Typography className={styles.authorLabel} color="primary">
          Community facilitator • {authorName}
        </Typography>
      )}
    </>
  )
}

export default function CommunityPostReply(props: Props) {
  const styles = useStyles()
  const { reply } = props
  switch (reply.__typename) {
    case 'CommunityPostReplyQuestionAnswer':
      return (
        <ReplyItem key={reply.replyID} className={styles.replyItemContainer}>
          <Box className={styles.replyItemHeader}>
            <Box style={{ display: 'flex' }}>
              <Author authorName={reply?.replyAuthor?.name} />
              <Typography variant="caption" component="div" className={styles.timeLabel}>
                {moment(reply.createdAt).format('h:mm a')}
              </Typography>
              {reply.isHidden && <Chip className={styles.hiddenIndicator} size="small" label="Hidden" />}
            </Box>
            <Box className={styles.actions}>
              <CommunityPostReplyActions reply={reply} />
            </Box>
          </Box>
          <Typography variant="body1" className={reply.isHidden ? styles.hiddenMessageText : undefined}>
            {reply.message}
          </Typography>
        </ReplyItem>
      )
    default:
      return null
  }
}

const skeletonWidthSteps = ['40%', '80%', '60%', '30%', '70%']

export function CommunityPostReplySkeleton(props: { index: number }) {
  return (
    <ReplyItem>
      <Skeleton
        variant="text"
        width={skeletonWidthSteps[props.index % skeletonWidthSteps.length]}
        data-testid="skeleton"
      />
    </ReplyItem>
  )
}

const useStyles = makeStyles((theme) => ({
  replyItemContainer: {
    '&:hover $actions': {
      display: 'block',
    },
  },
  hiddenMessageText: {
    color: '#959997',
  },
  replyItemHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '26px',
  },
  actions: {
    display: 'none',
    margin: theme.spacing(0, 1),
  },
  hiddenIndicator: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    marginLeft: theme.spacing(2),
  },
  timeLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  authorLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 700,
  },
}))
