import React from 'react'
import { isObject, keys } from 'lodash'
import { Skeleton } from '@mui/material'
import { isSuccess, useFirebase } from 'src/firebase'
import { Program } from 'src/legacy/models/firebase'
import CommaSeparatedList from 'src/legacy/components/CommaSeparatedList'
import { Value } from '../../../FeedComponents'

type MeditationValueProps = {
  programID: string
  value: string[]
}

export default function MeditationValue({ programID, value }: MeditationValueProps) {
  const request = useFirebase<Program>(`programs/${programID}`)

  if (!isSuccess(request)) {
    return <Skeleton />
  }

  if (isObject(value)) {
    return (
      <CommaSeparatedList>
        {keys(value).map((meditationID) => (
          <Value key={meditationID} value={request.data?.meditations[meditationID]?.title} />
        ))}
      </CommaSeparatedList>
    )
  }

  return <Value value={request.data?.meditations[value]?.title} />
}
