import React from 'react'
import { Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

type FormattedValueProps = {
  content: boolean | number | string
}

export default function FormattedValue({ content }: FormattedValueProps) {
  const styles = useStyles()
  const contentToRender = getContentToRender(content)

  return (
    <Typography variant="caption" className={styles.boldedMultiLineCaption}>
      {contentToRender}
    </Typography>
  )
}

const getContentToRender = (content: boolean | number | string): string | number => {
  switch (typeof content) {
    case 'boolean':
      return content ? 'Yes' : 'No'
    case 'number':
      return content
    case 'string':
      return content.trim() === '' ? '-' : content
    default:
      return '-'
  }
}

const useStyles = makeStyles({
  boldedMultiLineCaption: {
    lineHeight: 1.3,
    fontWeight: 'bold',
  },
})
