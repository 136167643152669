import React from 'react'
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom'
import { AppRefresher, useNightlyRefresh } from 'src/appRefresher'
import { Communities } from 'src/communities'
import { AppBars } from 'src/designSystem'
import { ForceChromeBrowser } from 'src/forceBrowser'
import { NavigationAndTabEvents } from 'src/mixpanel'
import { ParticipantsView } from 'src/participantsView'
import { Login, Logout, RedirectFromLogin, RedirectToLogin, SessionManager, useSession } from 'src/session'

// Legacy components
import Admin from 'src/legacy/components/Admin'
import MainView from './legacy/containers/MainView'
import useLocalStorageCleanUp from './legacy/lib/useLocalStorageCleanUp'

const App = () => {
  const { isLoggedIn } = useSession()
  useNightlyRefresh()
  useLocalStorageCleanUp()

  return (
    <Router>
      {isLoggedIn ? <RedirectFromLogin /> : <RedirectToLogin />}

      {isLoggedIn && (
        <AppBars>
          <Route exact path="/" render={() => <Redirect to="/participant" />} />
          <Route path="/user/:uid" render={({ match }) => <Redirect to={`/participant/${match.params.uid}`} />} />
          <Route component={MainView} path={['/participant/:uid/', '/participant']} />
          <Route component={ParticipantsView} path="/participants" />
          <Route component={Communities} path="/communities/:postID?" />
          <Route component={Admin} path="/admin" />
        </AppBars>
      )}

      <Route component={Login} path="/login" />
      <Route component={Logout} path="/logout" />

      <AppRefresher refreshInterval={5 * 60 * 1000} />
      <ForceChromeBrowser />
      <NavigationAndTabEvents />
      <SessionManager />
    </Router>
  )
}

export default App
