/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

const defaultOptions = {} as const
export type GetIntakeNotesQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetIntakeNotesQuery = {
  __typename?: 'Query'
  patientHistory: Array<
    | {
        __typename: 'CommonPatientHistory'
        date?: string | null
        value?: Types.JSONObject | null
        entryID: string
        type?: Types.PatientHistoryType | null
      }
    | { __typename?: 'FinancialEligibilityPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
    | { __typename?: 'VideoCallPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
  >
}

export type GetTreatmentPlansQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetTreatmentPlansQuery = {
  __typename?: 'Query'
  patientHistory: Array<
    | {
        __typename: 'CommonPatientHistory'
        date?: string | null
        value?: Types.JSONObject | null
        entryID: string
        type?: Types.PatientHistoryType | null
      }
    | { __typename?: 'FinancialEligibilityPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
    | { __typename?: 'VideoCallPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
  >
}

export type DeletePatientHistoryMutationVariables = Types.Exact<{
  entryID: Types.Scalars['ID']['input']
}>

export type DeletePatientHistoryMutation = {
  __typename?: 'Mutation'
  deletePatientHistory?:
    | { __typename: 'CommonPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
    | { __typename: 'FinancialEligibilityPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
    | { __typename: 'VideoCallPatientHistory'; entryID: string; type?: Types.PatientHistoryType | null }
    | null
}

export type AddPatientHistoryMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  date: Types.Scalars['String']['input']
  type: Types.PatientHistoryType
  value: Types.Scalars['JSONObject']['input']
}>

export type AddPatientHistoryMutation = {
  __typename?: 'Mutation'
  addPatientHistory:
    | {
        __typename: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'FinancialEligibilityPatientHistory'
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
}

export type AddFinancialEligibilityPatientHistoryMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  date: Types.Scalars['Date']['input']
  costEstimate?: Types.InputMaybe<Types.FinancialEligibilityCostEstimateInput>
  insuranceSubscriber?: Types.InputMaybe<Types.FinancialEligibilityInsuranceSubscriberInput>
  description: Types.Scalars['String']['input']
}>

export type AddFinancialEligibilityPatientHistoryMutation = {
  __typename?: 'Mutation'
  addFinancialEligibilityPatientHistory:
    | {
        __typename: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'FinancialEligibilityPatientHistory'
        description: string
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
        costEstimate?: {
          __typename?: 'FinancialEligibilityCostEstimate'
          intakeCostEstimateInCents: number
          month1CostEstimateInCents: number
          month2CostEstimateInCents: number
          month3CostEstimateInCents: number
        } | null
      }
    | {
        __typename: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
        videoCall: {
          __typename?: 'VideoCall'
          id: string
          reason?: Types.ScheduledVideoCallReason | null
          activeDurationInMS?: number | null
          numberOfParticipants?: number | null
        }
      }
}

export type PatientHistoryEntry_CommonPatientHistory_Fragment = {
  __typename: 'CommonPatientHistory'
  value?: Types.JSONObject | null
  entryID: string
  coachID?: string | null
  createdAt: Date
  date?: string | null
  source?: string | null
  type?: Types.PatientHistoryType | null
}

export type PatientHistoryEntry_FinancialEligibilityPatientHistory_Fragment = {
  __typename: 'FinancialEligibilityPatientHistory'
  description: string
  entryID: string
  coachID?: string | null
  createdAt: Date
  date?: string | null
  source?: string | null
  type?: Types.PatientHistoryType | null
  costEstimate?: {
    __typename?: 'FinancialEligibilityCostEstimate'
    intakeCostEstimateInCents: number
    month1CostEstimateInCents: number
    month2CostEstimateInCents: number
    month3CostEstimateInCents: number
  } | null
}

export type PatientHistoryEntry_VideoCallPatientHistory_Fragment = {
  __typename: 'VideoCallPatientHistory'
  value?: Types.JSONObject | null
  entryID: string
  coachID?: string | null
  createdAt: Date
  date?: string | null
  source?: string | null
  type?: Types.PatientHistoryType | null
  videoCall: {
    __typename?: 'VideoCall'
    id: string
    reason?: Types.ScheduledVideoCallReason | null
    activeDurationInMS?: number | null
    numberOfParticipants?: number | null
  }
}

export type PatientHistoryEntryFragment =
  | PatientHistoryEntry_CommonPatientHistory_Fragment
  | PatientHistoryEntry_FinancialEligibilityPatientHistory_Fragment
  | PatientHistoryEntry_VideoCallPatientHistory_Fragment

export type GetPatientHistoryAndProgramStartDateQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientHistoryAndProgramStartDateQuery = {
  __typename?: 'Query'
  patient: { __typename?: 'Patient'; enrollment: { __typename?: 'Enrollment'; programStartDate?: Date | null } }
  patientHistory: Array<
    | {
        __typename: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'FinancialEligibilityPatientHistory'
        description: string
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
        costEstimate?: {
          __typename?: 'FinancialEligibilityCostEstimate'
          intakeCostEstimateInCents: number
          month1CostEstimateInCents: number
          month2CostEstimateInCents: number
          month3CostEstimateInCents: number
        } | null
      }
    | {
        __typename: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
        videoCall: {
          __typename?: 'VideoCall'
          id: string
          reason?: Types.ScheduledVideoCallReason | null
          activeDurationInMS?: number | null
          numberOfParticipants?: number | null
        }
      }
  >
}

export const PatientHistoryEntryFragmentDoc = gql`
  fragment PatientHistoryEntry on PatientHistory {
    __typename
    entryID
    coachID
    createdAt
    date
    source
    type
    ... on CommonPatientHistory {
      value
    }
    ... on VideoCallPatientHistory {
      value
      videoCall {
        id
        reason
        activeDurationInMS
        numberOfParticipants
      }
    }
    ... on FinancialEligibilityPatientHistory {
      description
      costEstimate {
        intakeCostEstimateInCents
        month1CostEstimateInCents
        month2CostEstimateInCents
        month3CostEstimateInCents
      }
    }
  }
`
export const GetIntakeNotesDocument = gql`
  query getIntakeNotes($uid: ID!) {
    patientHistory(uid: $uid, type: INTAKE_NOTE, orderBy: date_DESC) {
      entryID
      type
      ... on CommonPatientHistory {
        __typename
        date
        value
      }
    }
  }
`

/**
 * __useGetIntakeNotesQuery__
 *
 * To run a query within a React component, call `useGetIntakeNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntakeNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntakeNotesQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetIntakeNotesQuery(
  baseOptions: Apollo.QueryHookOptions<GetIntakeNotesQuery, GetIntakeNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetIntakeNotesQuery, GetIntakeNotesQueryVariables>(GetIntakeNotesDocument, options)
}
export function useGetIntakeNotesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIntakeNotesQuery, GetIntakeNotesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetIntakeNotesQuery, GetIntakeNotesQueryVariables>(GetIntakeNotesDocument, options)
}
export type GetIntakeNotesQueryHookResult = ReturnType<typeof useGetIntakeNotesQuery>
export type GetIntakeNotesLazyQueryHookResult = ReturnType<typeof useGetIntakeNotesLazyQuery>
export type GetIntakeNotesQueryResult = Apollo.QueryResult<GetIntakeNotesQuery, GetIntakeNotesQueryVariables>
export const GetTreatmentPlansDocument = gql`
  query getTreatmentPlans($uid: ID!) {
    patientHistory(uid: $uid, type: TREATMENT_PLAN, orderBy: date_DESC) {
      entryID
      type
      ... on CommonPatientHistory {
        __typename
        date
        value
      }
    }
  }
`

/**
 * __useGetTreatmentPlansQuery__
 *
 * To run a query within a React component, call `useGetTreatmentPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTreatmentPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTreatmentPlansQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetTreatmentPlansQuery(
  baseOptions: Apollo.QueryHookOptions<GetTreatmentPlansQuery, GetTreatmentPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetTreatmentPlansQuery, GetTreatmentPlansQueryVariables>(GetTreatmentPlansDocument, options)
}
export function useGetTreatmentPlansLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTreatmentPlansQuery, GetTreatmentPlansQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetTreatmentPlansQuery, GetTreatmentPlansQueryVariables>(
    GetTreatmentPlansDocument,
    options
  )
}
export type GetTreatmentPlansQueryHookResult = ReturnType<typeof useGetTreatmentPlansQuery>
export type GetTreatmentPlansLazyQueryHookResult = ReturnType<typeof useGetTreatmentPlansLazyQuery>
export type GetTreatmentPlansQueryResult = Apollo.QueryResult<GetTreatmentPlansQuery, GetTreatmentPlansQueryVariables>
export const DeletePatientHistoryDocument = gql`
  mutation deletePatientHistory($entryID: ID!) {
    deletePatientHistory(entryID: $entryID) {
      __typename
      entryID
      type
    }
  }
`
export type DeletePatientHistoryMutationFn = Apollo.MutationFunction<
  DeletePatientHistoryMutation,
  DeletePatientHistoryMutationVariables
>

/**
 * __useDeletePatientHistoryMutation__
 *
 * To run a mutation, you first call `useDeletePatientHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientHistoryMutation, { data, loading, error }] = useDeletePatientHistoryMutation({
 *   variables: {
 *      entryID: // value for 'entryID'
 *   },
 * });
 */
export function useDeletePatientHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePatientHistoryMutation, DeletePatientHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePatientHistoryMutation, DeletePatientHistoryMutationVariables>(
    DeletePatientHistoryDocument,
    options
  )
}
export type DeletePatientHistoryMutationHookResult = ReturnType<typeof useDeletePatientHistoryMutation>
export type DeletePatientHistoryMutationResult = Apollo.MutationResult<DeletePatientHistoryMutation>
export type DeletePatientHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientHistoryMutation,
  DeletePatientHistoryMutationVariables
>
export const AddPatientHistoryDocument = gql`
  mutation addPatientHistory($uid: ID!, $date: String!, $type: PatientHistoryType!, $value: JSONObject!) {
    addPatientHistory(uid: $uid, date: $date, type: $type, value: $value) {
      __typename
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`
export type AddPatientHistoryMutationFn = Apollo.MutationFunction<
  AddPatientHistoryMutation,
  AddPatientHistoryMutationVariables
>

/**
 * __useAddPatientHistoryMutation__
 *
 * To run a mutation, you first call `useAddPatientHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientHistoryMutation, { data, loading, error }] = useAddPatientHistoryMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      date: // value for 'date'
 *      type: // value for 'type'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useAddPatientHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPatientHistoryMutation, AddPatientHistoryMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddPatientHistoryMutation, AddPatientHistoryMutationVariables>(
    AddPatientHistoryDocument,
    options
  )
}
export type AddPatientHistoryMutationHookResult = ReturnType<typeof useAddPatientHistoryMutation>
export type AddPatientHistoryMutationResult = Apollo.MutationResult<AddPatientHistoryMutation>
export type AddPatientHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddPatientHistoryMutation,
  AddPatientHistoryMutationVariables
>
export const AddFinancialEligibilityPatientHistoryDocument = gql`
  mutation addFinancialEligibilityPatientHistory(
    $uid: ID!
    $date: Date!
    $costEstimate: FinancialEligibilityCostEstimateInput
    $insuranceSubscriber: FinancialEligibilityInsuranceSubscriberInput
    $description: String!
  ) {
    addFinancialEligibilityPatientHistory(
      uid: $uid
      date: $date
      costEstimate: $costEstimate
      insuranceSubscriber: $insuranceSubscriber
      description: $description
    ) {
      ...PatientHistoryEntry
    }
  }
  ${PatientHistoryEntryFragmentDoc}
`
export type AddFinancialEligibilityPatientHistoryMutationFn = Apollo.MutationFunction<
  AddFinancialEligibilityPatientHistoryMutation,
  AddFinancialEligibilityPatientHistoryMutationVariables
>

/**
 * __useAddFinancialEligibilityPatientHistoryMutation__
 *
 * To run a mutation, you first call `useAddFinancialEligibilityPatientHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFinancialEligibilityPatientHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFinancialEligibilityPatientHistoryMutation, { data, loading, error }] = useAddFinancialEligibilityPatientHistoryMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      date: // value for 'date'
 *      costEstimate: // value for 'costEstimate'
 *      insuranceSubscriber: // value for 'insuranceSubscriber'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddFinancialEligibilityPatientHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFinancialEligibilityPatientHistoryMutation,
    AddFinancialEligibilityPatientHistoryMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddFinancialEligibilityPatientHistoryMutation,
    AddFinancialEligibilityPatientHistoryMutationVariables
  >(AddFinancialEligibilityPatientHistoryDocument, options)
}
export type AddFinancialEligibilityPatientHistoryMutationHookResult = ReturnType<
  typeof useAddFinancialEligibilityPatientHistoryMutation
>
export type AddFinancialEligibilityPatientHistoryMutationResult =
  Apollo.MutationResult<AddFinancialEligibilityPatientHistoryMutation>
export type AddFinancialEligibilityPatientHistoryMutationOptions = Apollo.BaseMutationOptions<
  AddFinancialEligibilityPatientHistoryMutation,
  AddFinancialEligibilityPatientHistoryMutationVariables
>
export const GetPatientHistoryAndProgramStartDateDocument = gql`
  query getPatientHistoryAndProgramStartDate($uid: ID!) {
    patient(uid: $uid) {
      enrollment {
        programStartDate
      }
    }
    patientHistory(uid: $uid, orderBy: date_DESC) {
      ...PatientHistoryEntry
    }
  }
  ${PatientHistoryEntryFragmentDoc}
`

/**
 * __useGetPatientHistoryAndProgramStartDateQuery__
 *
 * To run a query within a React component, call `useGetPatientHistoryAndProgramStartDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientHistoryAndProgramStartDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientHistoryAndProgramStartDateQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientHistoryAndProgramStartDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPatientHistoryAndProgramStartDateQuery,
    GetPatientHistoryAndProgramStartDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientHistoryAndProgramStartDateQuery, GetPatientHistoryAndProgramStartDateQueryVariables>(
    GetPatientHistoryAndProgramStartDateDocument,
    options
  )
}
export function useGetPatientHistoryAndProgramStartDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPatientHistoryAndProgramStartDateQuery,
    GetPatientHistoryAndProgramStartDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetPatientHistoryAndProgramStartDateQuery,
    GetPatientHistoryAndProgramStartDateQueryVariables
  >(GetPatientHistoryAndProgramStartDateDocument, options)
}
export type GetPatientHistoryAndProgramStartDateQueryHookResult = ReturnType<
  typeof useGetPatientHistoryAndProgramStartDateQuery
>
export type GetPatientHistoryAndProgramStartDateLazyQueryHookResult = ReturnType<
  typeof useGetPatientHistoryAndProgramStartDateLazyQuery
>
export type GetPatientHistoryAndProgramStartDateQueryResult = Apollo.QueryResult<
  GetPatientHistoryAndProgramStartDateQuery,
  GetPatientHistoryAndProgramStartDateQueryVariables
>
