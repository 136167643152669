/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'

export type CommunityPostItemFragment = {
  __typename: 'CommunityPostQuestion'
  postID: string
  title: string
  label: string
  content?: string | null
  community: { __typename?: 'Community'; name: string }
  communityPostReplies: {
    __typename?: 'CommunityPostReplies'
    id: string
    replyingStatus: string
    numberOfPatientsReplied: number
  }
}

export const CommunityPostItemFragmentDoc = gql`
  fragment CommunityPostItem on CommunityPost {
    __typename
    postID
    title
    label
    content
    community {
      name
    }
    communityPostReplies(showHidden: true) {
      id
      replyingStatus
      numberOfPatientsReplied
    }
  }
`
