import moment from 'moment'

const smsConsentKey = 'sms-consent-expiration'

const consentDuration = 90

const isInFuture = (date: Date) => date.getTime() > Date.now()

export const hasGivenSmsSendingConsent = () => {
  const consentExpirationDate = window.localStorage.getItem(smsConsentKey)

  if (consentExpirationDate && isInFuture(new Date(consentExpirationDate))) {
    return true
  }

  return false
}

export const storeSmsSendingConsent = () => {
  const consentExpirationDate = moment().add(consentDuration, 'days').toISOString()

  window.localStorage.setItem(smsConsentKey, consentExpirationDate)
}
