import { ApolloClient, ApolloLink, DefaultOptions } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { GRAPHQL_URL } from 'src/config'
import createCache from './cache'
import customScalarsLink from './customScalarLink'

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
  },
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

export default new ApolloClient({
  cache: createCache(),
  defaultOptions,
  link: ApolloLink.from([
    customScalarsLink(),
    errorLink,
    new BatchHttpLink({
      uri: GRAPHQL_URL,
      credentials: 'include',
    }),
  ]),
})
