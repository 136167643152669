import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { FullPatientFinancialEligibilityCheckFragment } from '../financialEligibilityHealthPlanQueries.generated'
import { HealthPlanLookupForm } from './HealthPlanLookupForm'
import { AperoEligibilityResponseView } from './AperoEligibilityResponseView'

type FormResponse = FullPatientFinancialEligibilityCheckFragment

type DialogProps = {
  uid: string
  sources: string[]
  open: boolean
  onClose: () => void
  onCompleted: (info: FullPatientFinancialEligibilityCheckFragment) => void
}

export function HealthPlanLookupDialog(props: DialogProps) {
  const { uid, sources, open, onClose, onCompleted } = props
  const [response, setResponse] = useState<FormResponse | undefined>(undefined)

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>Apero lookup tool</DialogTitle>
      <Content uid={uid} sources={sources} onResponse={(res) => setResponse(res)} />
      {response && <AperoEligibilityResponseView eligibilityCheck={response} />}
      <Actions disabled={!response} onCancel={onClose} onSubmit={response ? () => onCompleted(response) : undefined} />
    </Dialog>
  )
}

type ContentProps = {
  uid: string
  sources: string[]
  onResponse: (response: FullPatientFinancialEligibilityCheckFragment) => void
}

function Content(props: ContentProps) {
  const { uid, sources, onResponse } = props
  return (
    <DialogContent>
      <DialogContentText>
        These values are only used for the lookup itself. Press &quot;Fill form&quot; to use these values for the
        eligibility record.
      </DialogContentText>
      <HealthPlanLookupForm uid={uid} sources={sources} onResponse={onResponse} />
    </DialogContent>
  )
}

type ActionsProps = {
  disabled: boolean
  onCancel: () => void
  onSubmit?: () => void
}

function Actions({ onSubmit, onCancel, disabled }: ActionsProps) {
  return (
    <DialogActions>
      <Button onClick={onCancel}>Cancel</Button>
      <Button disabled={disabled} onClick={onSubmit}>
        Fill form
      </Button>
    </DialogActions>
  )
}
