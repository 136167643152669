import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { CustomRadio, CustomRadioGroup } from 'src/form'
import { MISSED_INTAKE_CALL_REASONS } from './constants'

export const MISSED_INTAKE_CALL_SCHEMA = Yup.object()
  .shape({
    reason: Yup.string().required(),
  })
  .required()

export type MissedIntakeCallSchema = Yup.InferType<typeof MISSED_INTAKE_CALL_SCHEMA>

const MissedIntakeCallFields = () => (
  <FastField component={CustomRadioGroup} name="value.reason" type="string">
    {_.map(MISSED_INTAKE_CALL_REASONS, (label, value) => (
      <CustomRadio key={value} label={label} value={value} />
    ))}
  </FastField>
)

export default MissedIntakeCallFields
