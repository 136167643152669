import React from 'react'
import moment, { MomentInput } from 'moment-timezone'

type Props = {
  timezone?: string
  value?: MomentInput
}

const FormatTime: React.FunctionComponent<Props> = (props) => {
  const { timezone, value } = props
  if (!value) {
    return null
  }
  if (timezone) {
    return <>{moment.tz(value, timezone).format('LT z')}</>
  }
  return <>{moment(value).format('LT')}</>
}

export default FormatTime
