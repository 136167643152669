import { Button } from '@mui/material'
import React from 'react'

const SubmitButton = ({ isSubmitting }: { isSubmitting: boolean }) => (
  <Button color="primary" disabled={isSubmitting} type="submit" variant="contained" style={{ marginTop: '16px' }}>
    Add
  </Button>
)

export default SubmitButton
