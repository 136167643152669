import { useFormikContext } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import ScoreFields from './ScoreFields'

const WHO5_QUESTIONS = [
  {
    key: 'WHO5_QUESTION_1',
    label: 'I have felt cheerful and in good spirits.',
  },
  {
    key: 'WHO5_QUESTION_2',
    label: 'I have felt calm and relaxed.',
  },
  {
    key: 'WHO5_QUESTION_3',
    label: 'I have felt active and vigorous.',
  },
  {
    key: 'WHO5_QUESTION_4',
    label: 'I woke up feeling fresh and rested.',
  },
  {
    key: 'WHO5_QUESTION_5',
    label: 'My daily life has been filled with things that interest me.',
  },
  {
    key: 'WHO5_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 5,
}

const ScoreValueSchema = Yup.number().required().min(SCORE_RANGE.VALUE_MIN).max(SCORE_RANGE.VALUE_MAX)

export const WHO5_SCHEMA = Yup.object()
  .shape({
    WHO5_QUESTION_1: ScoreValueSchema,
    WHO5_QUESTION_2: ScoreValueSchema,
    WHO5_QUESTION_3: ScoreValueSchema,
    WHO5_QUESTION_4: ScoreValueSchema,
    WHO5_QUESTION_5: ScoreValueSchema,
    WHO5_SCORE: Yup.number().required().min(0).max(25),
  })
  .default({})
  .required()

export type WHO5Schema = Yup.InferType<typeof WHO5_SCHEMA>

const WHO5Fields = () => {
  const { setFieldValue, values } = useFormikContext<{ value: WHO5Schema }>()
  return <ScoreFields type="WHO5" values={values} setFieldValue={setFieldValue} questions={WHO5_QUESTIONS} />
}

export default WHO5Fields
