/* eslint-disable camelcase */
import React, { useLayoutEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { FIREBASE_CONFIG } from 'src/config'
import useGoogleSignInClient from './useGoogleSignInClient'

type Props = {
  onSuccess: (idToken: string) => Promise<void>
}

export default function GoogleSignIn({ onSuccess }: Props) {
  const loginElement = useRef(null)
  const { loaded } = useGoogleSignInClient()

  useLayoutEffect(() => {
    if (loaded) {
      google.accounts.id.initialize({
        callback: async (response: google.accounts.id.CredentialResponse) => {
          await onSuccess(response.credential)
        },
        client_id: FIREBASE_CONFIG.clientId,
      })
      google.accounts.id.renderButton(loginElement.current!, {
        text: 'continue_with',
        theme: 'filled_blue',
        type: 'standard',
      })
    }
  }, [loaded, onSuccess])

  return (
    <Box alignItems="center" display="flex" height="100%" justifyContent="center" width="100%">
      <div>
        <Typography gutterBottom variant="h1">
          Login
        </Typography>
        <div ref={loginElement} />
      </div>
    </Box>
  )
}
