import { FeedPatientResponseItemFragment, FeedPatientResponseItemSectionFragment } from '../../feedQueries.generated'

export type Score = {
  label: string
  type: string
  value: number
}

export function getHealthQuestionnaireScores(response: FeedPatientResponseItemFragment): Score[] {
  return response.sections.map(calculateScore).filter(notNullScore).sort(sortScore)
}

function calculateScore(section: FeedPatientResponseItemSectionFragment) {
  if (section.type === 'BURNOUT_QUESTIONNAIRE') {
    const value = calculateBurnoutScore(section)
    return value === null ? null : { label: 'Burnout', type: section.type, value }
  }
  if (section.type === 'GAD-7_QUESTIONNAIRE') {
    const value = extractHQScoreValue(section, 'GAD7_SCORE')
    return value === null ? null : { label: 'Anxiety', type: section.type, value }
  }
  if (section.type === 'PHQ-9_QUESTIONNAIRE') {
    const value = extractHQScoreValue(section, 'PHQ9_SCORE')
    return value === null ? null : { label: 'Depression', type: section.type, value }
  }
  return null
}

function calculateBurnoutScore(section: FeedPatientResponseItemSectionFragment) {
  const trueValueIndex = section.entries.findIndex((entry) => entry.rawValue === true)
  if (trueValueIndex < 0) {
    return null
  }
  return trueValueIndex + 1
}

function extractHQScoreValue(section: FeedPatientResponseItemSectionFragment, scoreEntryTag: string) {
  const entryWithScore = section.entries.find((entry) => entry.tag === scoreEntryTag)
  if (!entryWithScore) {
    return null
  }
  const value = Number(entryWithScore.rawValue)
  return !Number.isNaN(value) ? value : null
}

function notNullScore(score: Score | null): score is Score {
  return score !== null
}

const SORT_ORDER = ['GAD-7_QUESTIONNAIRE', 'PHQ-9_QUESTIONNAIRE', 'BURNOUT_QUESTIONNAIRE']

function sortScore(a: Score, b: Score) {
  const aTypeIndex = SORT_ORDER.indexOf(a.type)
  const bTypeIndex = SORT_ORDER.indexOf(b.type)
  return aTypeIndex - bTypeIndex
}
