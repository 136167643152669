import * as Yup from 'yup'
import { PatientHistoryType, ProgramEndMetricInput } from 'src/graphql.generated'

const Phq9ProgramEndSchema = Yup.object<ProgramEndMetricInput>({
  baseline: Yup.number().integer().min(0).max(27).required(),
  programEnd: Yup.number().integer().min(0).max(27).nullable(),
})

const Gad7ProgramEndSchema = Yup.object<ProgramEndMetricInput>({
  baseline: Yup.number().integer().min(0).max(21).required(),
  programEnd: Yup.number().integer().min(0).max(21).nullable(),
})

const Who5ProgramEndSchema = Yup.object<ProgramEndMetricInput>({
  baseline: Yup.number().integer().min(0).max(25).required(),
  programEnd: Yup.number().integer().min(0).max(25).nullable(),
})

export const availableProgramEndMetrics = {
  GAD7: PatientHistoryType.Gad7,
  PHQ9: PatientHistoryType.Phq9,
  WHO5: PatientHistoryType.Who5,
}

export const ProgramEndMetricsSchema = Yup.object({
  [PatientHistoryType.Gad7]: Gad7ProgramEndSchema.default(undefined),
  [PatientHistoryType.Phq9]: Phq9ProgramEndSchema.default(undefined),
  [PatientHistoryType.Who5]: Who5ProgramEndSchema.default(undefined),
}).required()

export type ProgramEndMetrics = Yup.InferType<typeof ProgramEndMetricsSchema>
