import React from 'react'
import { Button, DialogActions, DialogContent } from '@mui/material'
import { Field } from 'formik'
import * as Yup from 'yup'
import { UpdateQueryOptions } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'

import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { FormDateTimePicker, GraphQLForm } from 'src/form'
import ParticipantTime from '../../../../date/ParticipantTime'

import {
  CallBookingVideoCallFragment,
  GetParticipantVideoCallBookingInformationDocument,
} from '../../videoCallBookingHeaderQueries.generated'

import { useRescheduleInProgramCallByCoachMutation } from './modifyInProgramCallMutations.generated'

type RescheduleFormValues = {
  start: number
}

const RescheduleFormValidationSchema: Yup.ObjectSchema<RescheduleFormValues> = Yup.object().required().shape({
  start: Yup.number().required(),
})

export type RescheduleViewProps = {
  uid: string
  videoCall: CallBookingVideoCallFragment
  onClose: () => void
  onBack: () => void
}

const RescheduleView = ({ uid, videoCall, onClose, onBack }: RescheduleViewProps) => {
  const [rescheduleCall, { loading }] = useRescheduleInProgramCallByCoachMutation({
    refetchQueries: [GetParticipantVideoCallBookingInformationDocument],
  })

  const { addToast } = useToasts()

  const handleSubmit = async (values: UpdateQueryOptions<RescheduleFormValues>) => {
    const { start } = values.variables!

    try {
      await rescheduleCall({ variables: { callID: videoCall.id, start: new Date(start) } })
      onClose()
      addToast('The check-in call has been successfully rescheduled', { appearance: 'success' })
    } catch (error) {
      addToast('Failed to reschedule the check-in call', { appearance: 'error' })
    }
  }

  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      validationSchema={RescheduleFormValidationSchema}
      render={({ isSubmitting, values }) => (
        <>
          <ClosableDialogTitle onClose={onClose}>Reschedule call</ClosableDialogTitle>
          <DialogContent>
            <Field
              component={FormDateTimePicker}
              fullWidth
              helperText={<ParticipantTime uid={uid} time={values.start} />}
              label="Select Date and Time"
              name="start"
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={onBack}>
              Back
            </Button>
            <Button color="primary" disabled={isSubmitting || loading} type="submit">
              Reschedule
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default RescheduleView
