import React from 'react'
import { Avatar, Box, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'
import { OndemandVideo } from '@mui/icons-material'

import { CoachProfile } from 'src/legacy/models/firebase'
import { isSuccess, useFirebase } from 'src/firebase'
import { getActiveCoachProfiles } from 'src/legacy/selectors/coachProfiles'
import { Loader } from 'src/designSystem'
import { EmptyPlaceholder, TableCellIcon } from 'src/legacy/components/mui'
import AddCoachForm from './AddCoachForm'
import Regions from './Regions'

type Props = {
  addFn: (email: string) => void
}

interface CoachProfileWithCoachID extends CoachProfile {
  coachID: string
}

const Coaches: React.FunctionComponent<Props> = (props) => {
  const { addFn } = props
  const request = useFirebase<CoachProfileWithCoachID[]>('coachProfiles', getActiveCoachProfiles)
  if (!isSuccess(request)) {
    return <Loader size="large" />
  }
  if (!request.data) {
    return <EmptyPlaceholder />
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Coach</TableCell>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Title</TableCell>
          <TableCell>Licenses</TableCell>
          <TableCellIcon>Video</TableCellIcon>
        </TableRow>
      </TableHead>
      <TableBody>
        {request.data.map((coach) => (
          <TableRow key={coach.coachID}>
            <TableCell>
              <Avatar src={coach.photoURL}>
                {coach.firstname[0]}
                {coach.lastname[0]}
              </Avatar>
            </TableCell>
            <TableCell>{coach.firstname}</TableCell>
            <TableCell>{coach.lastname}</TableCell>
            <TableCell>{coach.email}</TableCell>
            <TableCell>{coach.title}</TableCell>
            <TableCell>{coach.regions && <Regions value={coach.regions} />}</TableCell>
            <TableCellIcon>
              {coach.videoUrlSD && (
                <IconButton component="a" href={coach.videoUrlSD} size="small" target="_blank">
                  <OndemandVideo titleAccess="Video URL" />
                </IconButton>
              )}
            </TableCellIcon>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={9}>
            <Box display="flex" justifyContent="flex-end">
              <AddCoachForm onSubmit={addFn} />
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default Coaches
