import React, { ReactElement, ReactFragment } from 'react'
import { Box, BoxProps, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Palette, PaletteColor } from '@mui/material/styles'
import Linkify from 'react-linkify'

export type MessageBubbleProps = BoxProps & {
  children: string
  bubblePaletteColor: 'primary' | 'secondary'
  linkedTo?: ReactElement | ReactFragment
  disableTextLinkParsing?: boolean
}

export type PaletteColorKey = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never
}[keyof Palette]

export default function MessageBubble({
  children,
  linkedTo,
  disableTextLinkParsing,
  bubblePaletteColor,
  ...boxProps
}: MessageBubbleProps) {
  const bubbleStyle = useBubbleStyle({ bubblePaletteColor, hasLinkedContent: Boolean(linkedTo) })
  return (
    <Box className={bubbleStyle.root} {...boxProps}>
      {linkedTo && <Box className={bubbleStyle.linkedToWrapper}>{linkedTo}</Box>}
      <Typography className={bubbleStyle.message}>
        {disableTextLinkParsing ? children : <Linkify>{children}</Linkify>}
      </Typography>
    </Box>
  )
}

type BubbleStyleProps = {
  bubblePaletteColor: PaletteColorKey
  hasLinkedContent?: boolean
}

const useBubbleStyle = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    background: (props: BubbleStyleProps) => theme.palette[props.bubblePaletteColor].main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1.75),
    paddingBottom: theme.spacing(1.75),
    borderRadius: '20px',
  },
  message: {
    // Negative margin takes care of extra visual space
    // introduced by line height
    margin: `-0.25rem 0`,
    color: (props: BubbleStyleProps) => theme.palette[props.bubblePaletteColor].contrastText,
    whiteSpace: 'pre-wrap',
  },
  linkedToWrapper: {
    margin: -theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))
