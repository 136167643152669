import React, { useCallback } from 'react'
import { Button, ButtonGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ConfirmationDialog from 'src/legacy/components/mui/ConfirmationDialog'
import { useRestartIntakeMutation } from '../patientQueries.generated'

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
  },
})

export type Props = {
  uid: string
}

const RestartIntake = (props: Props) => {
  const { uid } = props
  const classes = useStyles()
  const [restartIntake] = useRestartIntakeMutation()

  const handleRestartIntake = useCallback(async () => {
    await restartIntake({ variables: { uid } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restartIntake])

  return (
    <ConfirmationDialog title="Restart participant intake" onProceed={handleRestartIntake}>
      {(open) => (
        <ButtonGroup fullWidth>
          <Button classes={classes} color="primary" fullWidth onClick={open}>
            Restart intake
          </Button>
        </ButtonGroup>
      )}
    </ConfirmationDialog>
  )
}

export default RestartIntake
