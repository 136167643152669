import React from 'react'
import Authentication from 'src/legacy/components/Authentication'
import { SelectableItems, SelectableItemsToolbar, TableCellIcon } from 'src/legacy/components/mui'
import ParticipantActionBar from 'src/legacy/components/ParticipantActionBar'
import { Table, TableBody, TableCell, TableHead, TableRow, TablePagination, TableSortLabel } from '@mui/material'

import { PatientSortOrder, PatientSortField } from 'src/graphql.generated'
import { PatientSearchFieldsFragment } from './searchPatients.generated'
import { ParticipantTableColumn } from './constants'
import { Provider as TableContextProvider } from './useParticipantTableContext'
import ParticipantRows from './ParticipantRows'
import HideableTableCell from './HideableTableCell'
import { ParticipantFilter } from './useLoadParticipantsWithFilter'

export type Props = {
  participants: Array<PatientSearchFieldsFragment>
  rowCount: number
  page: number
  pageSize: number
  sortBy: PatientSortField
  sortOrder: PatientSortOrder
  filter: ParticipantFilter
  onPageChange: (page: number) => void
  onSortChange: (column: PatientSortField, direction: PatientSortOrder) => void
  onChangeRowsPerPage: (rows: number) => void
  onContentChange: () => void
  disabledColumns?: Set<ParticipantTableColumn>
}

const DEFAULT_DIRECTIONS: Record<PatientSortField, PatientSortOrder> = {
  [PatientSortField.FirstName]: PatientSortOrder.Asc,
  [PatientSortField.LastName]: PatientSortOrder.Asc,
  [PatientSortField.LastStateTransitionAt]: PatientSortOrder.Desc,
  [PatientSortField.CallScheduledAt]: PatientSortOrder.Desc,
}

const ParticipantsTable = (props: Props) => {
  const {
    rowCount,
    page,
    pageSize,
    filter,
    participants,
    onSortChange,
    onPageChange,
    onContentChange,
    disabledColumns,
    sortBy,
    sortOrder,
  } = props

  const createSortHandler = (property: PatientSortField) => () => {
    if (property === sortBy) {
      onSortChange(property, sortOrder === PatientSortOrder.Asc ? PatientSortOrder.Desc : PatientSortOrder.Asc)
    } else {
      onSortChange(property, DEFAULT_DIRECTIONS[property])
    }
  }

  const orderDirection = sortOrder === PatientSortOrder.Desc ? 'desc' : 'asc'
  return (
    <SelectableItems>
      <TableContextProvider value={{ disabledColumns }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCellIcon />
              <HideableTableCell id={ParticipantTableColumn.name}>Name</HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.source}>Source</HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.careteam}>Careteam</HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.status}>Status</HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.lastStateTransitionAt}>
                <TableSortLabel
                  active={sortBy === PatientSortField.LastStateTransitionAt}
                  direction={orderDirection}
                  onClick={createSortHandler(PatientSortField.LastStateTransitionAt)}
                >
                  Status Changed
                </TableSortLabel>
              </HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.callTime}>
                <TableSortLabel
                  active={sortBy === PatientSortField.CallScheduledAt}
                  direction={orderDirection}
                  onClick={createSortHandler(PatientSortField.CallScheduledAt)}
                >
                  Call time
                </TableSortLabel>
              </HideableTableCell>
              <HideableTableCell id={ParticipantTableColumn.programStartDate}>Program Week</HideableTableCell>
              <Authentication allowManager>
                <HideableTableCell align="center" id={ParticipantTableColumn.financialEligibility}>
                  Eligibility
                </HideableTableCell>
              </Authentication>
              <TableCellIcon>Delete</TableCellIcon>
            </TableRow>
          </TableHead>
          <TableBody>
            <ParticipantRows participants={participants} onDeleteSuccess={onContentChange} />
          </TableBody>
        </Table>
        <SelectableItemsToolbar filter={filter}>
          {({ clearSelection, selected }) => (
            <ParticipantActionBar
              onSuccess={() => {
                clearSelection()
                onContentChange()
              }}
              uids={selected}
            />
          )}
        </SelectableItemsToolbar>
        <TablePagination
          component="div"
          count={rowCount}
          page={page}
          rowsPerPage={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={(_event, value) => {
            onPageChange(value)
          }}
          onRowsPerPageChange={(event) => {
            props.onChangeRowsPerPage(Number(event.target.value))
          }}
        />
      </TableContextProvider>
    </SelectableItems>
  )
}

export default ParticipantsTable
