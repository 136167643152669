import React from 'react'
import { Field } from 'formik'
import { Grid } from '@mui/material'
import { CustomCheckbox, CustomTextField, FormDatePicker } from 'src/form'

const GRID_ITEM_WIDTH = 4

type Props = {
  name?: string
}

const InsuranceSubscriberFields = ({ name = 'insuranceSubscriber' }: Props) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Field fullWidth name={`${name}.coverageActive`} label="Coverage active" component={CustomCheckbox} />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={`${name}.memberID`} label="Member ID" component={CustomTextField} />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field
        fullWidth
        name={`${name}.groupDescription`}
        label="Group description"
        component={CustomTextField}
        disabled
      />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={`${name}.groupNumber`} label="Group number" component={CustomTextField} />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={`${name}.firstname`} label="First name" component={CustomTextField} />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={`${name}.lastname`} label="Last name" component={CustomTextField} />
    </Grid>
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={`${name}.birthDate`} label="Birthdate" component={FormDatePicker} />
    </Grid>
  </Grid>
)

export default InsuranceSubscriberFields
