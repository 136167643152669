/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import { CommunityPostReplyFragmentFragmentDoc } from './communityPostReplyFragment.generated'

export type CommunityPostRepliesItemFragment = {
  __typename: 'CommunityPostReplies'
  id: string
  numberOfPatientsReplied: number
  canReplyToPost: boolean
  replyingStatus: string
  repliedMyself: boolean
  replies?: Array<{
    __typename: 'CommunityPostReplyQuestionAnswer'
    replyID: string
    createdAt: Date
    isHidden: boolean
    message: string
    replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
  }> | null
}

export const CommunityPostRepliesItemFragmentDoc = gql`
  fragment CommunityPostRepliesItem on CommunityPostReplies {
    __typename
    id
    numberOfPatientsReplied
    canReplyToPost
    replyingStatus
    repliedMyself
    replies {
      ...CommunityPostReplyFragment
    }
  }
  ${CommunityPostReplyFragmentFragmentDoc}
`
