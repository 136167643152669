import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Dialog } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'
import MeruDate from 'src/legacy/lib/MeruDate'
import { EnrollmentState, PatientEngagementIndicator } from 'src/graphql.generated'
import { PatientHistoryType } from 'src/legacy/models/firebase'
import { useIsManager } from 'src/session'
import RestartIntake from './RestartIntake'
import SetParticipantAcceptedForm from './SetParticipantAcceptedForm'
import SetParticipantDeclinedForm from './SetParticipantDeclinedForm'
import SetProgramEndForm from './SetProgramEndForm'
import { availableProgramEndMetrics, ProgramEndMetrics } from './SetProgramEndForm/programEndMetricSchemas'
import UpdateProgramMenu from './UpdateProgramMenu'

const useStyles = makeStyles({
  root: {
    whiteSpace: 'nowrap',
  },
})

export type Props = {
  uid: string
  acceptanceCriteria?: Array<PatientHistoryType>
  engagement?: PatientEngagementIndicator | null
  isHrvProgram?: boolean
  lastWeekNbr: number
  programStartDate?: string
  programEndMetrics?: Array<PatientHistoryType>
  state: EnrollmentState
}

const programIsEnding = (lastWeekNbr: number, programStartDate?: string) => {
  const daysFromProgramEnd = MeruDate.now().daysFromProgramEnd(programStartDate, lastWeekNbr)
  return daysFromProgramEnd !== null && daysFromProgramEnd > -5
}

const AFTER_REGISTRATION_COMPLETED_STATES = new Set([
  EnrollmentState.IntakeCallScheduled,
  EnrollmentState.IntakeLoggedIn,
  EnrollmentState.IntakeRegistered,
])

const ParticipantActions = ({
  engagement,
  acceptanceCriteria,
  isHrvProgram,
  lastWeekNbr,
  programStartDate,
  programEndMetrics: firebaseProgramEndMetrics = [],
  state,
  uid,
}: Props) => {
  const classes = useStyles()
  const [openDialog, setOpenDialog] = useState<string | null>(null)
  const onClose = () => setOpenDialog(null)

  const isAfterRegistrationCompleted = AFTER_REGISTRATION_COMPLETED_STATES.has(state)
  const isInProgram = state === EnrollmentState.InProgram
  const programHasStarted = programStartDate && new Date() > new Date(programStartDate)
  const didNotStart = state === EnrollmentState.DidNotStart
  const programEndMetrics: Array<keyof ProgramEndMetrics> = firebaseProgramEndMetrics
    .map((programMetric) => _.get(availableProgramEndMetrics, [programMetric]))
    .filter(Boolean)

  const isManager = useIsManager()

  const showUpdateMenu = (isInProgram && programHasStarted && isManager) || (isInProgram && !programHasStarted)

  return (
    <>
      {isAfterRegistrationCompleted && (
        <Box mt={2}>
          <ButtonGroup fullWidth>
            <Button classes={classes} color="primary" fullWidth onClick={() => setOpenDialog('ACCEPT_INTO_PROGRAM')}>
              Accept
            </Button>

            <Button classes={classes} fullWidth onClick={() => setOpenDialog('PARTICIPANT_DECLINE')}>
              Decline
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {isInProgram && programHasStarted && !isManager && (
        <Box mt={2}>
          <ButtonGroup fullWidth>
            <Button classes={classes} color="primary" fullWidth onClick={() => setOpenDialog('PROGRAM_END')}>
              {programIsEnding(lastWeekNbr, programStartDate) ? 'Complete Program' : 'Terminate Program'}
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {showUpdateMenu && (
        <Box mt={2}>
          <ButtonGroup fullWidth>
            <Button classes={classes} color="primary" fullWidth onClick={() => setOpenDialog('PROGRAM_UPDATE_MENU')}>
              Update program
            </Button>
          </ButtonGroup>
        </Box>
      )}
      {didNotStart && (
        <Box mt={2}>
          <RestartIntake uid={uid} />
        </Box>
      )}
      <Dialog
        fullWidth
        maxWidth={openDialog === 'PROGRAM_END' ? 'sm' : 'xs'}
        onClose={onClose}
        open={Boolean(openDialog)}
      >
        {openDialog === 'ACCEPT_INTO_PROGRAM' && (
          <SetParticipantAcceptedForm
            acceptanceCriteria={acceptanceCriteria}
            isHrvProgram={isHrvProgram}
            close={onClose}
            uid={uid}
          />
        )}
        {openDialog === 'PARTICIPANT_DECLINE' && <SetParticipantDeclinedForm close={onClose} uid={uid} />}
        {openDialog === 'PROGRAM_UPDATE_MENU' && (
          <UpdateProgramMenu
            close={onClose}
            uid={uid}
            engagement={engagement!}
            lastWeekNbr={lastWeekNbr}
            programStartDate={programStartDate!}
            programEndMetrics={programEndMetrics}
          />
        )}
        {openDialog === 'PROGRAM_END' && (
          <SetProgramEndForm
            close={onClose}
            cancel={onClose}
            uid={uid}
            engagement={engagement!}
            lastWeekNbr={lastWeekNbr}
            programStartDate={programStartDate!}
            programEndMetrics={programEndMetrics}
          />
        )}
      </Dialog>
    </>
  )
}

export default ParticipantActions
