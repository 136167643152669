import React, { useCallback } from 'react'
import { Field } from 'formik'
import { Button, DialogActions, DialogContent, MenuItem, ListSubheader, Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

import * as Yup from 'yup'

import { CustomTextField, GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { UpdateQueryOptions } from '@apollo/client'
import { PatientDeclinedReasonV2 } from 'src/graphql.generated'
import { GetPatientHistoryDocument } from 'src/legacy/lib/graphql/queries.generated'
import { GetFullPatientDocument, useSetPatientDeclinedV2Mutation } from '../patientQueries.generated'
import { DECLINE_REASON_NAMES } from '../participantConstants'

type ParticipantDeclinedForm = {
  uid: string
  reasons: PatientDeclinedReasonV2
  description?: string
}

const PERSONAL_REASONS = [
  PatientDeclinedReasonV2.PersonalPrefersTraditionalOneToOneTherapy,
  PatientDeclinedReasonV2.PersonalNotInterested,
  PatientDeclinedReasonV2.PersonalNotResponding,
  PatientDeclinedReasonV2.PersonalLifeEvent,
  PatientDeclinedReasonV2.PersonalPrefersOtherLanguage,
  PatientDeclinedReasonV2.PersonalOther,
  PatientDeclinedReasonV2.PersonalFoundAlternativeCare,
  PatientDeclinedReasonV2.PersonalFrequentTravel,
  PatientDeclinedReasonV2.PersonalTimeCommitment,
  PatientDeclinedReasonV2.PersonalFeelsBetter,
]

const OPERATIONAL_REASONS = [
  PatientDeclinedReasonV2.OperationalSwitchedOrLostInsurance,
  PatientDeclinedReasonV2.OperationalOutsideGeographicCoverage,
  PatientDeclinedReasonV2.OperationalTechnicalIssue,
  PatientDeclinedReasonV2.OperationalDuplicateAccount,
  PatientDeclinedReasonV2.OperationalUnder_18,
  PatientDeclinedReasonV2.OperationalInsuranceOutOfNetwork,
  PatientDeclinedReasonV2.OperationalInsuranceMedicaidOrMedicare,
  PatientDeclinedReasonV2.OperationalInsuranceCostShareTooHigh,
  PatientDeclinedReasonV2.OperationalNoProvidersWithRequiredCredentialing,
]

const CLINICAL_REASONS = [
  PatientDeclinedReasonV2.ClinicalHighAcuity,
  PatientDeclinedReasonV2.ClinicalLowAcuity,
  PatientDeclinedReasonV2.ClinicalOther,
]

export const DECLINED_REASONS_WITH_REQUIRED_DESCRIPTION = [...CLINICAL_REASONS, PatientDeclinedReasonV2.PersonalOther]

const descriptionIsRequiredForDeclinedReason = (reason: PatientDeclinedReasonV2) =>
  DECLINED_REASONS_WITH_REQUIRED_DESCRIPTION.includes(reason)

const getDescriptionHelperText = (reason?: PatientDeclinedReasonV2 | null) => {
  if (!reason) {
    return 'Description is required for certain reasons'
  }

  return descriptionIsRequiredForDeclinedReason(reason)
    ? 'Description is required for the selected reason'
    : 'Description is optional for the selected reason'
}

const ParticipantDeclinedSchema = (uid: string): Yup.ObjectSchema<ParticipantDeclinedForm> =>
  Yup.object({
    uid: Yup.string().required().default(uid),
    reasons: Yup.mixed<PatientDeclinedReasonV2>().oneOf(Object.values(PatientDeclinedReasonV2)).required(),
    description: Yup.string().when('reasons', {
      is: descriptionIsRequiredForDeclinedReason,
      then: (schema: Yup.StringSchema) => schema.required('Description is required for the selected reason'),
    }),
  }).required()

type Props = {
  close: () => void
  uid: string
}

const SetParticipantDeclinedForm = ({ close, uid }: Props) => {
  const styles = useStyles()
  const [setPatientDeclined] = useSetPatientDeclinedV2Mutation({
    refetchQueries: [GetFullPatientDocument, GetPatientHistoryDocument],
  })
  const handleSubmit = useCallback(
    async (opts: UpdateQueryOptions<ParticipantDeclinedForm>): Promise<void> => {
      const variables = opts.variables!
      await setPatientDeclined({
        variables,
      })
    },
    [setPatientDeclined]
  )
  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      onSuccess={close}
      validationSchema={ParticipantDeclinedSchema(uid)}
      render={({ isSubmitting, values }) => (
        <>
          <ClosableDialogTitle onClose={close}>Participant Declined</ClosableDialogTitle>
          <DialogContent>
            <Alert color="info" icon={false}>
              Choose the main reason why you are declining the participant
            </Alert>
            <Field
              component={CustomTextField}
              fullWidth
              label="Choose reason"
              name="reasons"
              type="select"
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    horizontal: 'left',
                    vertical: 'bottom',
                  },
                },
              }}
            >
              <ListSubheader className={styles.categoryLabel} disableSticky>
                Personal reasons
              </ListSubheader>
              {PERSONAL_REASONS.map((value) => (
                <MenuItem key={value} value={value}>
                  {DECLINE_REASON_NAMES.get(value)}
                </MenuItem>
              ))}
              <ListSubheader className={styles.categoryLabel} disableSticky>
                Operational reasons
              </ListSubheader>
              {OPERATIONAL_REASONS.map((value) => (
                <MenuItem key={value} value={value}>
                  {DECLINE_REASON_NAMES.get(value)}
                </MenuItem>
              ))}
              <ListSubheader className={styles.categoryLabel} disableSticky>
                Clinical reasons
              </ListSubheader>
              {CLINICAL_REASONS.map((value) => (
                <MenuItem key={value} value={value}>
                  {DECLINE_REASON_NAMES.get(value)}
                </MenuItem>
              ))}
            </Field>
            <Field
              component={CustomTextField}
              fullWidth
              label="Description"
              helperText={getDescriptionHelperText(values.reasons)}
              multiline
              name="description"
              minRows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={close}>
              Cancel
            </Button>
            <Button color="primary" disabled={isSubmitting} type="submit">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

// TODO: Remove this code when material ui updates to v5.0.0
// see new feature
// https://github.com/mui-org/material-ui/pull/25567
const useStyles = makeStyles({
  categoryLabel: {
    'pointer-events': 'none',
  },
})

export default SetParticipantDeclinedForm
