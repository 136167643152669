import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/database'

import { FIREBASE_CONFIG } from 'src/config'

export default firebase.initializeApp(FIREBASE_CONFIG)

// Hide auth emulator warnings in tests
if (FIREBASE_CONFIG.authDomain.startsWith('localhost') && process.env.NODE_ENV !== 'test') {
  // @ts-expect-error Wrong types in firebase auth
  firebase.auth().useEmulator('http://localhost:9099', { disableWarnings: true })
}
if (FIREBASE_CONFIG.databaseURL.startsWith('http://localhost')) {
  firebase.database().useEmulator('localhost', 9000)
}
