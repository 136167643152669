import React from 'react'
import { List, ListItem, ListItemIcon, ListItemText , Skeleton } from '@mui/material'
import { PanoramaFishEye } from '@mui/icons-material'


const DailyTasksSkeleton = () => (
  <List>
    <ListItem dense>
      <ListItemIcon>
        <PanoramaFishEye />
      </ListItemIcon>
      <ListItemText primary={<Skeleton />} />
    </ListItem>
    <ListItem dense>
      <ListItemIcon>
        <PanoramaFishEye />
      </ListItemIcon>
      <ListItemText primary={<Skeleton />} />
    </ListItem>
    <ListItem dense>
      <ListItemIcon>
        <PanoramaFishEye />
      </ListItemIcon>
      <ListItemText primary={<Skeleton />} />
    </ListItem>
  </List>
)

export default DailyTasksSkeleton
