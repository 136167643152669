import React, { useState } from 'react'
import { Box, Button, Dialog } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import SetCareTeamForm from 'src/participant/SetCareTeamForm'
import SendEmailForm from '../SendEmailForm/SendEmailForm'

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
  },
}))

type Props = {
  onSuccess: () => void
  uids: Array<string>
}

const ParticipantActionBar = ({ onSuccess, uids }: Props) => {
  const classes = useStyles()
  const [open, setOpen] = useState<string | boolean>(false)
  const maxWidth = open === 'email' ? 'sm' : 'xs'
  const onClose = () => setOpen(false)
  return (
    <>
      <Box color="primary.contrastText" mr={1}>
        {uids.length} participant(s) selected
      </Box>
      <Button color="inherit" classes={classes} onClick={() => setOpen('careteam')} variant="outlined">
        Change Careteam
      </Button>
      <Button color="inherit" classes={classes} onClick={() => setOpen('email')} variant="outlined">
        Send Email
      </Button>
      <Dialog fullWidth maxWidth={maxWidth} onClose={onClose} open={Boolean(open)}>
        {open === 'careteam' && <SetCareTeamForm close={onClose} onSuccess={onSuccess} uids={uids} />}
        {open === 'email' && <SendEmailForm close={onClose} onSuccess={onSuccess} uids={uids} />}
      </Dialog>
    </>
  )
}

export default ParticipantActionBar
