import moment, { Moment } from 'moment'

export const isRestartDateDisabled = (date: Moment) => date?.day() !== 1 || date?.isBefore(moment())

export const isStartDateDisabled = (date: Moment, earliestStartDate: string | null | undefined) => {
  if (!earliestStartDate) return true

  const earliestStartDateMoment = moment(earliestStartDate)

  return date?.day() !== 1 || date?.isBefore(earliestStartDateMoment)
}

export const getProgramStartDateSuggestion = () => {
  const nextMonday = moment().add(1, 'weeks').isoWeekday('Monday')

  return moment(nextMonday).diff(moment(), 'days') < 7 ? nextMonday.add(1, 'week') : nextMonday
}
