/* eslint-disable max-len */
import _ from 'lodash'
import React, { useEffect } from 'react'
import { FastField, Field, useFormikContext } from 'formik'
import { calculateWpaiScores } from '@meru/shared/helpers/calculations'
import * as Yup from 'yup'

import { CustomCheckbox, CustomTextField } from 'src/form'

const WPAI_QUESTIONS = {
  WPAI_QUESTION_1: 'Are you currently employed (working for pay)?',
  WPAI_QUESTION_2:
    'During the past seven days, how many hours did you miss from work because of problems associated with your depression or burnout?',
  WPAI_QUESTION_3:
    'During the past seven days, how many hours did you miss from work because of any other reason, such as vacation, holidays, time off to participate in this study?',
  WPAI_QUESTION_4: 'During the past seven days, how many hours did you actually work?',
  WPAI_QUESTION_5:
    'During the past seven days, how much did your depression or burnout affect your productivity while you were working?',
  WPAI_QUESTION_6:
    'During the past seven days, how much did your depression or burnout affect your ability to do your regular daily activities, other than work at a job?',
  WPAI_SCORE_1: 'Percent work time missed due to health',
  WPAI_SCORE_2: 'Percent impairment while working due to health',
  WPAI_SCORE_3: 'Percent overall work impairment due to health',
  WPAI_SCORE_4: 'Percent activity impairment due to health',
}

const DYNAMIC_QUESTION_FIELDS: Array<keyof typeof WPAI_QUESTIONS> = [
  'WPAI_QUESTION_2',
  'WPAI_QUESTION_3',
  'WPAI_QUESTION_4',
]

const SCORE_FIELDS: Array<keyof typeof WPAI_QUESTIONS> = [
  'WPAI_SCORE_1',
  'WPAI_SCORE_2',
  'WPAI_SCORE_3',
  'WPAI_SCORE_4',
]

const isRequiredWhenTrue = { is: true, then: (schema: Yup.NumberSchema) => schema.required() }

const isRequiredWhenGreaterThanZero = {
  is: (value: number) => value > 0,
  then: (schema: Yup.NumberSchema) => schema.required(),
}

const WPAIHoursSchema = Yup.number().min(0)

const WPAIQuestionSchema = Yup.number().min(0).max(10)

export const WPAI_SCHEMA = Yup.object()
  .shape({
    WPAI_QUESTION_1: Yup.boolean().required(),
    WPAI_QUESTION_2: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_3: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_4: WPAIHoursSchema.when('WPAI_QUESTION_1', isRequiredWhenTrue),
    WPAI_QUESTION_5: WPAIQuestionSchema.when('WPAI_QUESTION_4', isRequiredWhenGreaterThanZero),
    WPAI_QUESTION_6: WPAIQuestionSchema.required(),
    WPAI_SCORE_1: Yup.number(),
    WPAI_SCORE_2: Yup.number(),
    WPAI_SCORE_3: Yup.number(),
    WPAI_SCORE_4: Yup.number(),
  })
  .default({})
  .required()

export type WPAISchema = Yup.InferType<typeof WPAI_SCHEMA>

const clearValues = (value: WPAISchema) => {
  const omit = ['WPAI_SCORE_1', 'WPAI_SCORE_2', 'WPAI_SCORE_3', 'WPAI_SCORE_4'] // scores are always re-calculated
  if (!value.WPAI_QUESTION_1) {
    omit.push('WPAI_QUESTION_2', 'WPAI_QUESTION_3', 'WPAI_QUESTION_4', 'WPAI_QUESTION_5')
  }
  if (!value.WPAI_QUESTION_4) {
    omit.push('WPAI_QUESTION_5')
  }
  return _.omit(value, omit)
}

const WPAIFields = () => {
  const { setFieldValue, values } = useFormikContext<{ value: WPAISchema }>()

  useEffect(() => {
    const clearedValue = clearValues(values.value)
    const value = {
      ...clearedValue,
      ..._.omitBy(calculateWpaiScores(clearedValue), _.isNull),
    }
    if (!_.isEqual(values.value, value)) {
      setFieldValue('value', value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.value])

  return (
    <>
      <FastField
        component={CustomCheckbox}
        fullWidth
        label={WPAI_QUESTIONS.WPAI_QUESTION_1}
        name="value.WPAI_QUESTION_1"
      />
      {values.value.WPAI_QUESTION_1 &&
        DYNAMIC_QUESTION_FIELDS.map((key) => (
          <Field
            component={CustomTextField}
            label={WPAI_QUESTIONS[key]}
            key={key}
            labelPosition="left"
            name={`value.${key}`}
            type="number"
          />
        ))}
      {values.value.WPAI_QUESTION_1 && (
        <Field
          component={CustomTextField}
          disabled={!(values.value.WPAI_QUESTION_4 && values.value.WPAI_QUESTION_4 > 0)}
          label={WPAI_QUESTIONS.WPAI_QUESTION_5}
          labelPosition="left"
          name="value.WPAI_QUESTION_5"
          placeholder="0-10"
          type="number"
        />
      )}
      <FastField
        component={CustomTextField}
        label={WPAI_QUESTIONS.WPAI_QUESTION_6}
        labelPosition="left"
        name="value.WPAI_QUESTION_6"
        placeholder="0-10"
        type="number"
      />
      {SCORE_FIELDS.map((key) => (
        <FastField
          component={CustomTextField}
          disabled
          label={WPAI_QUESTIONS[key]}
          key={key}
          labelPosition="left"
          name={`value.${key}`}
          type="number"
        />
      ))}
    </>
  )
}

export default WPAIFields
