import _ from 'lodash'
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'

import { isSuccess, useFirebase } from 'src/firebase'
import UidShorthand from 'src/legacy/components/UidShorthand'

const useStyles = makeStyles({
  root: {
    minWidth: 60,
  },
})

type Props = {
  coachID: string
}

const CoachName = ({ coachID }: Props) => {
  const coachProfilesRequest = useFirebase('coachProfiles')

  const classes = useStyles()

  if (!isSuccess(coachProfilesRequest)) {
    return <Skeleton classes={classes} />
  }

  const coachProfile = _.get(coachProfilesRequest.data, coachID)
  if (coachProfile) {
    const title = coachProfile.title?.replace(/,\sLicensed.*/, '') ?? ''
    const nameAndTitle = [`${coachProfile.firstname} ${coachProfile.lastname}`, title]
      .filter((item) => item.length > 0)
      .join(', ')
    return <>{nameAndTitle}</>
  }
  return <UidShorthand uid={coachID} />
}

export default CoachName
