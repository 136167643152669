import React from 'react'
import moment, { MomentInput } from 'moment'

type Props = {
  value?: MomentInput
}

const FormatDate: React.FunctionComponent<Props> = (props) => {
  const { value } = props
  if (!value) {
    return null
  }
  return <>{moment(value).format('l')}</>
}

export default FormatDate
