import _ from 'lodash'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { UpdateQueryOptions } from '@apollo/client'

import { GraphQLForm } from 'src/form'
import { SetCareteamMutationFn, useSetCareteamMutation } from 'src/legacy/lib/graphql/queries.generated'
import useLoadCoachProfiles from './useLoadCoachProfiles'
import ConfirmCareTeamChange from './ConfirmCareTeamChange'
import SelectCareTeam from './SelectCareTeam'
import { useLoadSetCareTeamData } from './useLoadSetCareTeamData'

type CareTeam = {
  primaryCoachID: string
  careteamCoachIDs: Array<string>
}

const setCareteamForUids = (
  setCareteam: SetCareteamMutationFn,
  uids: Array<string>,
  { primaryCoachID, careteamCoachIDs }: CareTeam
) =>
  Promise.all(
    uids.map((uid) =>
      setCareteam({
        variables: {
          uid,
          primaryCoachID,
          coachIDs: _.union(careteamCoachIDs, [primaryCoachID]),
        },
      })
    )
  )

const CareteamValidationSchema: Yup.ObjectSchema<CareTeam> = Yup.object({
  careteamCoachIDs: Yup.array().of(Yup.string().required()).default([]).required(),
  primaryCoachID: Yup.string().required(),
}).required()

export type Props = {
  close: () => void
  onSuccess: () => void
  uids: Array<string>
}

const SetCareTeamForm: React.FC<Props> = ({ close, onSuccess, uids }) => {
  const coachProfiles = useLoadCoachProfiles()

  const { participantsWithCareTeams } = useLoadSetCareTeamData(uids)

  const [setCareteamMutation] = useSetCareteamMutation()

  const [showConfirmation, setShowConfirmation] = useState(false)

  const handleChangeDialog = () => setShowConfirmation(!showConfirmation)

  return (
    <GraphQLForm
      validationSchema={CareteamValidationSchema}
      onSubmit={async ({ variables }: UpdateQueryOptions<CareTeam>) => {
        await setCareteamForUids(setCareteamMutation, uids, variables!)
      }}
      onSuccess={() => {
        close()
        onSuccess()
      }}
      render={({ values, isSubmitting }) => (
        <>
          {!showConfirmation && (
            <SelectCareTeam
              careTeam={values}
              close={close}
              coachProfiles={coachProfiles}
              handleToReview={handleChangeDialog}
            />
          )}
          {showConfirmation && (
            <ConfirmCareTeamChange
              close={close}
              handleReturn={handleChangeDialog}
              newCareTeam={values}
              oldParticipantCareTeams={participantsWithCareTeams}
              coachProfiles={coachProfiles}
              isSubmitting={isSubmitting}
            />
          )}
        </>
      )}
    />
  )
}

export default SetCareTeamForm
