import { useCallback } from 'react'
import { useReplyToCommunityPostMutation } from './replyToCommunityPostMutation.generated'

export default function useReplyTocommunityPost(postID: string) {
  const [replyToCommunityPost] = useReplyToCommunityPostMutation()

  return useCallback(
    (message: string) =>
      replyToCommunityPost({
        variables: {
          postID,
          reply: {
            questionAnswer: {
              message,
            },
          },
        },
      }),
    [postID, replyToCommunityPost]
  )
}
