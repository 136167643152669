import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

type Props = {
  title: string
  actions: React.ReactNode
}

const FinancialEligibilityFormSection = ({ title, actions, children }: React.PropsWithChildren<Props>) => (
  <Box display="flex" flexDirection="column">
    <Typography variant="h2">{title}</Typography>

    {actions && (
      <Stack
        direction="row"
        spacing={2}
        my={2}
        sx={{
          borderColor: (theme) => theme.palette.grey[400],
          borderRadius: '10px',
          borderWidth: '1px',
          borderStyle: 'dashed',
          padding: (theme) => theme.spacing(2),
        }}
      >
        {actions}
      </Stack>
    )}

    {children}
  </Box>
)

export default FinancialEligibilityFormSection
