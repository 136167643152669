import { useGetPatientsWithCareTeamQuery } from 'src/participant/patientQueries.generated'
import { useSessionUID } from 'src/session'

const useIsPrimaryCoachOfPatient = (uid: string) => {
  const coachID = useSessionUID()

  const participantsWithCareTeam = useGetPatientsWithCareTeamQuery({
    variables: {
      uids: [uid],
    },
  })
  const participantCareTeam = participantsWithCareTeam.data?.patients?.[0]?.careTeam
  const isPrimaryCoach =
    participantCareTeam?.find((careTeam) => careTeam.coachID === coachID && careTeam.isPrimary === true) !== undefined

  return isPrimaryCoach
}

export default useIsPrimaryCoachOfPatient
