import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Card, CardHeader, Collapse, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ExpandMore } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  cardHeaderRoot: {
    cursor: 'pointer',
  },
  expandIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  closeIcon: {
    transform: 'rotate(180deg)',
  },
}))

const ExpandableCard = ({ children, title, subheader, subheaderTypographyProps }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  return (
    <Card>
      <CardHeader
        action={
          <IconButton className={classNames(classes.expandIcon, { [classes.closeIcon]: open })} size="large">
            <ExpandMore />
          </IconButton>
        }
        classes={{ root: classes.cardHeaderRoot }}
        onClick={() => setOpen(!open)}
        subheader={subheader}
        subheaderTypographyProps={subheaderTypographyProps}
        title={title}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Card>
  )
}

ExpandableCard.defaultProps = {
  subheader: null,
  subheaderTypographyProps: null,
}

ExpandableCard.propTypes = {
  children: PropTypes.node.isRequired,
  subheader: PropTypes.node,
  subheaderTypographyProps: PropTypes.shape({}),
  title: PropTypes.node.isRequired,
}

export default ExpandableCard
