import React from 'react'
import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isNil } from 'lodash'

type Props = {
  content?: string | null
}

const Question: React.FunctionComponent<Props> = ({ content }) => {
  const styles = useStyles()
  if (isNil(content)) {
    return null
  }
  return (
    <Typography variant="caption" className={styles.multiLineCaption} color="textSecondary">
      {content}
    </Typography>
  )
}

export default Question

const useStyles = makeStyles({
  multiLineCaption: {
    lineHeight: 1.3,
  },
})
