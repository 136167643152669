import { PatientDeclinedReasonV2 } from 'src/graphql.generated'

export const DECLINE_REASON_NAMES = new Map([
  [PatientDeclinedReasonV2.PersonalPrefersTraditionalOneToOneTherapy, 'Prefers traditional 1:1 therapy'],
  [PatientDeclinedReasonV2.PersonalNotInterested, 'No longer interested / seeking care'],
  [PatientDeclinedReasonV2.PersonalNotResponding, 'Not responding'],
  [PatientDeclinedReasonV2.PersonalOther, 'Other personal reasons'],
  [PatientDeclinedReasonV2.PersonalLifeEvent, 'Life event / emergency'],
  [PatientDeclinedReasonV2.PersonalPrefersOtherLanguage, 'Prefers language other than English'],
  [PatientDeclinedReasonV2.PersonalFoundAlternativeCare, 'Found alternative care'],
  [PatientDeclinedReasonV2.PersonalFrequentTravel, 'Frequent travel'],
  [PatientDeclinedReasonV2.PersonalTimeCommitment, 'Time commitment'],
  [PatientDeclinedReasonV2.PersonalFeelsBetter, 'Feels better / no longer seeking care'],
  [PatientDeclinedReasonV2.OperationalOutsideGeographicCoverage, 'Geographic location outside of Meru coverage'],
  [PatientDeclinedReasonV2.OperationalSwitchedOrLostInsurance, 'Switched or lost job / insurance'],
  [PatientDeclinedReasonV2.OperationalTechnicalIssue, 'Technical issue'],
  [PatientDeclinedReasonV2.OperationalDuplicateAccount, 'Duplicate account'],
  [PatientDeclinedReasonV2.OperationalUnder_18, 'Under 18'],
  [PatientDeclinedReasonV2.OperationalInsuranceOutOfNetwork, 'Insurance: out of network'],
  [PatientDeclinedReasonV2.OperationalInsuranceMedicaidOrMedicare, 'Insurance: medicaid / medicare'],
  [PatientDeclinedReasonV2.OperationalInsuranceCostShareTooHigh, 'Insurance: cost share too high'],
  [PatientDeclinedReasonV2.OperationalNoProvidersWithRequiredCredentialing, 'No providers with required credentialing'],
  [PatientDeclinedReasonV2.ClinicalHighAcuity, 'High acuity'],
  [PatientDeclinedReasonV2.ClinicalLowAcuity, 'Low acuity'],
  [PatientDeclinedReasonV2.ClinicalOther, 'Other clinical reasons'],
])

type USState = {
  name: string
  code: string
}

export const US_STATES: Array<USState> = [
  { name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'District of Columbia', code: 'DC' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Maine', code: 'ME' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesota', code: 'MN' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Montana', code: 'MT' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'New York', code: 'NY' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregon', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
]
export const UNKNOWN_STATE = { name: 'Unknown', code: '-' }
export const ALL_STATES = [...US_STATES, { name: 'Meru Employees', code: 'XX' }, UNKNOWN_STATE]
