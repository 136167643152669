import { FullPatientFragment } from 'src/participant/patientQueries.generated'
import { PatientDetailsInput, PatientInput, PrivateUserDataInput } from 'src/graphql.generated'
import { fromJSDate } from 'src/date/DateString'
import { PatientSchema } from './PatientSchema'
import { convertPhoneNumberToE164, convertPhoneNumberToNational } from './formatPhoneNumber'
import { PATIENT_RECORD_SCHEMA } from './patient-record-schema'

type ApiValue = {
  patient: PatientInput & {
    details: PatientDetailsInput
  }
  privateUserData: PrivateUserDataInput
}

export const fromFormValueToApiValue = (values: PatientSchema): ApiValue => {
  const details = values.patient?.details ?? {}
  const { groupID: insuranceGroupID, memberID: insuranceMemberID, ...otherDetails } = details

  return {
    patient: {
      ...values.patient,
      phone: convertPhoneNumberToE164(values.patient?.phone),
      stateOfResidence: values.patient?.stateOfResidence === '-' ? null : values.patient?.stateOfResidence,
      details: {
        ...otherDetails,
        insuranceGroupID,
        insuranceMemberID,
      },
    },
    privateUserData: values.privateUserData ?? {},
  }
}

export const fromApiValueToFormValue = (patient?: FullPatientFragment): PatientSchema => {
  const { privateUserData, details } = patient ?? {}

  return PATIENT_RECORD_SCHEMA.cast({
    patient: {
      ...patient,
      birthDate: patient?.birthDate ? fromJSDate(patient.birthDate) : undefined,
      stateOfResidence: patient?.stateOfResidence || '-',
      details: details ?? {},
      phone: convertPhoneNumberToNational(patient?.phone),
    },
    privateUserData,
  })
}
