import _ from 'lodash'
import React from 'react'
import { Field } from 'formik'
import { MenuItem } from '@mui/material'

import { ReferralTypes } from 'src/legacy/models/firebase'
import { isSuccess, useFirebase } from 'src/firebase'
import { CustomTextField } from 'src/form'

const orderReferralTypes = (referralTypes?: ReferralTypes | null) => _(referralTypes).map('title').sort().value()

const ReferralTypeField: React.FunctionComponent = () => {
  const request = useFirebase<ReferralTypes>('referralTypes')
  const titles = isSuccess(request) ? orderReferralTypes(request.data) : []
  return (
    <Field
      component={CustomTextField}
      fullWidth
      label="Referral type"
      name="patient.details.referralType"
      type="select"
    >
      {titles.map((title) => (
        <MenuItem key={title} value={title}>
          {title}
        </MenuItem>
      ))}
    </Field>
  )
}

export default ReferralTypeField
