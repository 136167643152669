import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

import CustomMuiThemeProvider from 'src/designSystem/CustomMuiThemeProvider'
import ClosableDialogTitle from './ClosableDialogTitle'

const ConfirmationDialog = ({ children, onProceed, title }) => {
  const [open, setOpen] = useState(false)
  const onClose = () => setOpen(false)
  return (
    <>
      {children(() => setOpen(true))}
      <CustomMuiThemeProvider>
        <Dialog fullWidth maxWidth="xs" onClose={onClose} open={open}>
          <ClosableDialogTitle onClose={onClose}>{title}</ClosableDialogTitle>
          <DialogContent>
            <DialogContentText color="textPrimary">Are you sure you want to continue?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              color="primary"
              onClick={() => {
                onProceed()
                setOpen(false)
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </CustomMuiThemeProvider>
    </>
  )
}

ConfirmationDialog.propTypes = {
  children: PropTypes.func.isRequired,
  onProceed: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default ConfirmationDialog
