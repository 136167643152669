import React from 'react'
import { Header } from 'src/designSystem'
import { VideoCallCancelledBy, VideoCallType } from 'src/graphql.generated'
import { FeedVideoCallItemFragment } from '../feedQueries.generated'
import formatDate from './formatDate'

type Props = {
  videoCall: FeedVideoCallItemFragment
}

export default function VideoCallHeader({ videoCall }: Props) {
  return <Header title={getTitle(videoCall)} iconCategory="VIDEO_CALL_SCHEDULED" summary={getSummary(videoCall)} />
}

function getTitle(videoCall: FeedVideoCallItemFragment) {
  const callTypeLabel = videoCall.callType === VideoCallType.Intake ? 'Intake' : 'Check-in'

  return `${callTypeLabel} call scheduled`
}

function getCancelledSummary(cancelledBy: VideoCallCancelledBy | null | undefined) {
  if (!cancelledBy) {
    return 'Cancelled'
  }

  return cancelledBy === VideoCallCancelledBy.Coach ? 'Cancelled by the care team' : 'Cancelled by the participant'
}

function getSummary(videoCall: FeedVideoCallItemFragment) {
  const { cancelledAt, cancelledBy, startTime, endTime } = videoCall
  const now = new Date()
  const callIsFinished = endTime <= now

  if (cancelledAt) {
    return getCancelledSummary(cancelledBy)
  }

  if (callIsFinished) {
    return `Had on ${formatDate(startTime)}`
  }

  return `Scheduled for ${formatDate(startTime)}`
}
