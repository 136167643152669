import React from 'react'
import { Field } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { UpdateQueryOptions } from '@apollo/client'
import { FormDatePicker, GraphQLForm } from 'src/form'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { trackProgrmaStartDateUpdate } from 'src/mixpanel/api'
import { useUpdateProgramStartDateMutation } from '../patientQueries.generated'
import { getProgramStartDateSuggestion, isRestartDateDisabled } from './dateHelpers'

type Props = {
  programStartDate: string
  uid: string
  close: () => void
  cancel: () => void
}

const SetProgramStartDateSchema = Yup.object({
  uid: Yup.string().required(),
  programStartDate: Yup.string().required(),
}).required()

type ProgramStartDateForm = Yup.InferType<typeof SetProgramStartDateSchema>

const SetProgramStartDateForm = ({ programStartDate, uid, close, cancel }: Props) => {
  const [updateProgramStartDate] = useUpdateProgramStartDateMutation()

  const handleSubmit = async ({ variables }: UpdateQueryOptions<ProgramStartDateForm>) => {
    await updateProgramStartDate({ variables })
    trackProgrmaStartDateUpdate(moment(variables?.programStartDate).diff(programStartDate, 'weeks'))
  }

  const suggestedStartDate = getProgramStartDateSuggestion()

  return (
    <GraphQLForm
      initialValues={{ uid, programStartDate: suggestedStartDate.format('YYYY-MM-DD') }}
      onSubmit={handleSubmit}
      onSuccess={close}
      validationSchema={SetProgramStartDateSchema}
      render={({ isSubmitting }) => (
        <>
          <ClosableDialogTitle onClose={close}>Change program start date</ClosableDialogTitle>
          <DialogContent>
            <Typography>Current start date: {moment(programStartDate).format('M/D/YYYY')}</Typography>
            <Field
              component={FormDatePicker}
              label="Start date"
              fullWidth
              name="programStartDate"
              shouldDisableDate={isRestartDateDisabled}
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" onClick={cancel}>
              Cancel
            </Button>
            <Button color="primary" disabled={isSubmitting} type="submit">
              Confirm
            </Button>
          </DialogActions>
        </>
      )}
    />
  )
}

export default SetProgramStartDateForm
