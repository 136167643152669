import { TREATMENT_PLAN_ENABLED } from 'src/config'
import { useFirebase } from 'src/firebase'
import { Program, PatientHistoryType } from 'src/legacy/models/firebase'

const EMPTY_RESPONSE: Array<PatientHistoryType> = []

export default function useLoadPatientHistoryTypes(programID: string | null) {
  const programPath = programID ? `programs/${programID}` : null
  const { data } = useFirebase<Program>(programPath)

  // filter out treatment plan from patient history types if TREATMENT_PLAN_ENABLED ff is false
  const patientHistoryTypes =
    TREATMENT_PLAN_ENABLED === 'true'
      ? data?.patientHistoryTypes
      : data?.patientHistoryTypes?.filter((type) => type !== PatientHistoryType.TREATMENT_PLAN)

  return patientHistoryTypes || EMPTY_RESPONSE
}
