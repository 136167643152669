import React from 'react'
import { FormControlLabel, Radio } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { commonCheckboxStyles, errorStyle } from './styles'

const useStyles = makeStyles((theme) => ({
  ...commonCheckboxStyles(theme),
  radio: errorStyle({
    color: theme.palette.error.main,
  }),
}))

type Props = {
  label: string
  value: string | number
}

const CustomRadio: React.FunctionComponent<Props> = (props) => {
  const { label, value } = props
  const classes = useStyles(props)
  return (
    <FormControlLabel
      classes={{
        label: classes.label,
      }}
      control={<Radio className={classes.radio} color="primary" />}
      label={label}
      value={String(value)}
    />
  )
}

export default CustomRadio
