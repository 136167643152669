import React from 'react'
import { JSONObject } from 'src/graphql.generated'
import ValueTable from '../feed/FeedComponents/Value/ValueTable'

const ParticipantReEnrollmentTable = ({ value }: { value: JSONObject | null }) => {
  const rows = value ? Object.entries(value) : []
  return <ValueTable rows={rows} />
}

export default ParticipantReEnrollmentTable
