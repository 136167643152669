import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

export default function RedirectToLogin() {
  const location = useLocation()
  const redirect = `${location.pathname}${location.search}`
  if (location.pathname === '/login' || location.pathname === '/logout') {
    return null
  }
  if (redirect !== '/') {
    return <Redirect to={`/login?redirect=${encodeURIComponent(redirect)}`} />
  }
  return <Redirect to="/login" />
}
