import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Link, Typography } from '@mui/material'
import React from 'react'
import { ClosableDialogTitle } from 'src/legacy/components/mui'
import { storeSmsSendingConsent } from './smsConsentHandler'

type Props = {
  closeDialog: () => void
  displayConsentDialog: boolean
}

const giveConsent = (closeDialog: () => void) => {
  storeSmsSendingConsent()
  closeDialog()
}

const SmsConsentStorer = ({ closeDialog, displayConsentDialog }: Props) => (
  <Dialog fullWidth maxWidth="sm" onClose={closeDialog} open={displayConsentDialog}>
    <ClosableDialogTitle onClose={closeDialog}>Please do not send any PHI through SMS</ClosableDialogTitle>

    <DialogContent>
      <DialogContentText color="textPrimary">
        For more information, please read the following page for an explanation on what you can and cannot include in an
        SMS message to participants.
      </DialogContentText>

      <Link
        href="https://www.notion.so/meruhealth/SMS-in-the-Dashboard-e5750031f8224501978260ac6d78c4b6"
        target="_blank"
      >
        <Typography variant="body1">SMS in the Dashboard</Typography>
      </Link>
    </DialogContent>

    <DialogActions>
      <Button color="inherit" onClick={closeDialog}>
        Cancel
      </Button>
      <Button color="primary" onClick={() => giveConsent(closeDialog)}>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
)

export default SmsConsentStorer
