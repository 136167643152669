import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { trackStartIntakeCall } from 'src/mixpanel/api'
import ModifyScheduledCallModal from '../ModifyScheduledCallModal'
import JoinCallButton from '../JoinCallButton'
import VideoCallBookingInformation from '../VideoCallBookingInformation'

import {
  CallBookingParticipantFragment,
  IntakeVideoCallBookingInformationFragment,
} from '../videoCallBookingHeaderQueries.generated'

type Props = {
  bookingInformation: IntakeVideoCallBookingInformationFragment
  participant: CallBookingParticipantFragment
}

const IntakeVideoCallBookingContainer = ({ participant, bookingInformation }: Props) => {
  const {
    uid,
    enrollment: { programID },
  } = participant

  const { videoCall } = bookingInformation

  const [modifyDialogOpened, setModifyDialogOpened] = useState(false)
  const closeModifyDialog = () => setModifyDialogOpened(false)
  const styles = useStyles()

  if (!videoCall) {
    return <Typography variant="body2">Intake call not complete</Typography>
  }

  return (
    <Box>
      <VideoCallBookingInformation
        videoCall={videoCall}
        callActions={
          <>
            <Button
              color="primary"
              variant="outlined"
              className={styles.modifyButton}
              onClick={() => setModifyDialogOpened(true)}
            >
              MODIFY
            </Button>

            <JoinCallButton videoCall={videoCall} mixpanelTrackingEvent={trackStartIntakeCall} />
          </>
        }
      />
      <ModifyScheduledCallModal close={closeModifyDialog} open={modifyDialogOpened} uid={uid} programID={programID} />
    </Box>
  )
}

export default IntakeVideoCallBookingContainer

const useStyles = makeStyles((theme) => ({
  modifyButton: {
    marginRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
}))
