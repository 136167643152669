import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isNil } from 'lodash'

type Props = {
  content: string
  score?: number
}

const SectionTitle: React.FunctionComponent<Props> = ({ content, score }) => {
  const styles = useStyles()
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="caption" className={styles.sectionTitle}>
        {content}
      </Typography>
      {!isNil(score) && (
        <Typography variant="caption" className={styles.sectionTitle}>
          Score: {score}
        </Typography>
      )}
    </Box>
  )
}

export default SectionTitle

const useStyles = makeStyles({
  sectionTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
})
