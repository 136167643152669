/* eslint-disable camelcase */
import React, { useMemo } from 'react'
import { Field, Formik } from 'formik'
import _ from 'lodash'
import * as Yup from 'yup'
import { Button, Grid, MenuItem, Alert, Stack } from '@mui/material'
import { ApolloError } from '@apollo/client'

import { JSONObject } from 'src/graphql.generated'

import { CustomTextField, FormDatePicker } from 'src/form'
import { fromJSDate } from 'src/date/DateString'
import {
  FinancialEligibilityLookupMutationVariables,
  FullPatientFinancialEligibilityCheckFragment,
  GetEligibilityInputsQuery,
  useFinancialEligibilityLookupMutation,
  useGetEligibilityInputsQuery,
} from '../financialEligibilityHealthPlanQueries.generated'
import { formatUTCYearMonthDate } from '../formatUTCYearMonthDate'

const GRID_ITEM_WIDTH = 4

type FinancialEligibilityLookupForm = {
  birthDate?: string | null
  firstname?: string | null
  lastname?: string | null
  memberID?: string | null
  groupNumber?: string | null
  source?: string
}

const lookupSchema = Yup.object({
  birthDate: Yup.string().required(),
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  memberID: Yup.string().required(),
  groupNumber: Yup.string().nullable(),
  source: Yup.string().required(),
})

type Props = {
  uid: string
  sources: string[]
  onResponse: (response: FullPatientFinancialEligibilityCheckFragment) => void
}

export function HealthPlanLookupForm(props: Props) {
  const { uid, sources, onResponse } = props

  const [financialEligibilityLookup, { loading, error }] = useFinancialEligibilityLookupMutation({
    onCompleted: (data) => {
      onResponse(data.financialEligibilityLookup)
    },
  })

  const { data } = useGetEligibilityInputsQuery({
    variables: { uid },
    onCompleted: ({ patient }) => {
      const { financialEligibilityCheck } = patient
      if (financialEligibilityCheck) {
        onResponse(financialEligibilityCheck)
      }
    },
  })

  const initialValues = useInitialValues(data)

  async function onSubmit(values: FinancialEligibilityLookupForm) {
    const variables = { uid, ..._.omitBy(values, _.isNil) } as FinancialEligibilityLookupMutationVariables
    try {
      await financialEligibilityLookup({ variables })
    } catch {
      // prevent error from being thrown up the component tree
    }
  }

  return (
    <Formik<FinancialEligibilityLookupForm>
      initialValues={initialValues}
      enableReinitialize
      validationSchema={lookupSchema}
      onSubmit={onSubmit}
    >
      {({ submitForm, isValid }) => (
        <Grid container spacing={2}>
          <FormField name="source" label="Source" type="select" component={CustomTextField}>
            <MenuItem value="">Source</MenuItem>
            {sources.map((source) => (
              <MenuItem key={source} value={source}>
                {source}
              </MenuItem>
            ))}
          </FormField>
          <FormField name="memberID" label="Member ID" component={CustomTextField} />
          <FormField name="groupNumber" label="Group number" component={CustomTextField} />
          <FormField name="firstname" label="First name" component={CustomTextField} />
          <FormField name="lastname" label="Last name" component={CustomTextField} />
          <FormField name="birthDate" label="Birthdate" component={FormDatePicker} />
          <LookupButton disabled={!isValid || loading} onClick={submitForm} />
          <ErrorMessage error={error} />
        </Grid>
      )}
    </Formik>
  )
}

type PatientDetails = {
  source?: string
  memberID?: string | null
  groupID?: string | null
  birthDate?: string | null
  insuranceSubscriber?: JSONObject | null
}

function useInitialValues(inputs?: GetEligibilityInputsQuery): FinancialEligibilityLookupForm {
  return useMemo(() => {
    if (!inputs) {
      return {}
    }
    const {
      patient: { firstname, lastname, birthDate, details, financialEligibilityCheck },
    } = inputs

    const birthDateString = birthDate ? fromJSDate(birthDate) : undefined
    const { memberID, groupID, insuranceSubscriber, source } = details as PatientDetails

    if (financialEligibilityCheck) {
      const { subscriber } = financialEligibilityCheck
      return {
        source,
        birthDate: subscriber.birthDate ? formatUTCYearMonthDate(subscriber.birthDate) : birthDateString,
        groupNumber: subscriber.groupNumber,
        firstname: subscriber.firstName,
        lastname: subscriber.lastName,
        memberID: subscriber.id,
      }
    }

    return _.defaults(
      {
        ...(insuranceSubscriber ?? {}),
        source,
      },
      {
        birthDate: birthDateString,
        firstname,
        lastname,
        memberID,
        groupNumber: groupID,
      }
    )
  }, [inputs])
}

type FormFieldProps = {
  name: string
  label: string
  type?: string
  component: React.ReactNode
  children?: React.ReactNode
}

function FormField(props: FormFieldProps) {
  const { name, label, type, component, children } = props
  return (
    <Grid item xs={GRID_ITEM_WIDTH}>
      <Field fullWidth name={name} label={label} type={type} component={component}>
        {children}
      </Field>
    </Grid>
  )
}

type LookupButtonProps = {
  disabled: boolean
  onClick: () => Promise<void>
}

function LookupButton({ disabled, onClick }: LookupButtonProps) {
  return (
    <Stack direction="row" sx={{ mx: 2, mt: 2 }} alignItems="center" spacing={2}>
      <Button color="primary" onClick={onClick} disabled={disabled} type="button" variant="outlined">
        Perform lookup
      </Button>
    </Stack>
  )
}

type ErrorMessageProps = {
  error?: ApolloError
}

function ErrorMessage({ error }: ErrorMessageProps) {
  const message = useMemo(() => {
    if (!error) {
      return undefined
    }
    if (error.graphQLErrors.length > 0) {
      return error.graphQLErrors[0].message
    }
    if (error.networkError) {
      return 'Network error'
    }
    return 'Unknown error'
  }, [error])

  if (message) {
    return (
      <Grid item xs={6}>
        <Alert severity="error">{message}</Alert>
      </Grid>
    )
  }
  return null
}
