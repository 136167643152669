import React, { useRef, useEffect } from 'react'
import { first, last } from 'lodash'
import makeStyles from '@mui/styles/makeStyles'
import { useScrollLocation, useKeepTheScroll, useAutoScrollToTarget } from './scrollHandlingHooks'

type ItemWithIdentifier = {
  itemID: string
}

type ScrollableFeedContainerProps = {
  items: ItemWithIdentifier[]
  children: React.ReactNode
}

const scrollLockTreshold = 120

export default function ScrollableFeedContainer({ items, children }: ScrollableFeedContainerProps) {
  const areaRef = useRef<HTMLDivElement>(null)
  const chatBottom = useRef<HTMLDivElement>(null)
  const scrollLocation = useScrollLocation({
    ref: areaRef,
    topTreshold: scrollLockTreshold,
    bottomTreshold: scrollLockTreshold,
  })

  // Keep the scroll when older messages get loaded
  useKeepTheScroll(areaRef, [first(items)?.itemID])

  // Automatic scrolling to bottom when new messages
  const setAutoScrollEnabled = useAutoScrollToTarget(chatBottom, true, [last(items)?.itemID])

  const scrolledToBottom = scrollLocation.includes('bottom')
  useEffect(() => {
    setAutoScrollEnabled(scrolledToBottom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrolledToBottom])

  const styles = useStyles()

  return (
    <div ref={areaRef} className={styles.scrollableFeedContainer}>
      {children}
      <div ref={chatBottom} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  scrollableFeedContainer: {
    flex: 1,
    padding: theme.spacing(3),
    overflowX: 'hidden',
  },
}))
