import React from 'react'
import { FeedPatientResponseItemSectionEntryFragment } from '../../../feedQueries.generated'
import { Value } from '../../../FeedComponents'
import HRVMetricsTable, { HRVSessionResult } from './HRVMetricsTable'
import MeditationValue from './MeditationValue'
import MoodValue, { isValidMoodValue } from './MoodValue'

type Props = {
  entry: FeedPatientResponseItemSectionEntryFragment
  isCompleted?: boolean
  programID: string
}

export default function SectionEntryValue({ entry, isCompleted, programID }: Props) {
  const hrvTags = new Set(['HRV_BIOFEEDBACK_METRICS', 'HRV_BIOFEEDBACK_TUTORIAL', 'HRV_RESTING_METRICS'])
  if (hrvTags.has(entry.tag || '')) {
    return <HRVMetricsTable value={entry.rawValue as HRVSessionResult} isCompleted={isCompleted} />
  }

  if (entry.tag === 'CURRENT_MEDITATION') {
    return <MeditationValue programID={programID} value={entry.rawValue as string[]} />
  }

  if (entry.tag === 'SMILEY' && isValidMoodValue(entry.rawValue)) {
    return <MoodValue value={entry.rawValue} />
  }

  return <Value value={entry.rawValue} />
}
