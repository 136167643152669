import { useCallback } from 'react'
import { useUpdateAbTestGroupsMutation } from 'src/participant/patientQueries.generated'
import { PatientAbTestInput } from 'src/graphql.generated'

const useUpdateABTestGroups = (uid: string) => {
  const [updateABTestGroups] = useUpdateAbTestGroupsMutation()

  const handleUpdate = useCallback(
    (abTests: PatientAbTestInput | PatientAbTestInput[]) => updateABTestGroups({ variables: { uid, abTests } }),
    [uid, updateABTestGroups]
  )

  return handleUpdate
}

export default useUpdateABTestGroups
