import React, { useCallback, useEffect, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import { Button } from '@mui/material'

import { SolidOverlayDialog } from 'src/designSystem'

const parseResponse = (response: AxiosResponse) => {
  const parser = new DOMParser()
  return parser.parseFromString(response.data, 'text/html')
}

const parseHasNewScripts = (doc: Document) => {
  const oldScripts = Array.from(document.getElementsByTagName('script'))
  const newScripts = Array.from(doc.getElementsByTagName('script'))
  return !newScripts.every((newScript) => oldScripts.some((oldScript) => oldScript.src === newScript.src))
}

const fetchHasNewScripts = () => {
  const config = {
    headers: {
      'Cache-Control': 'no-cache',
    },
  }
  const url = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`
  return axios
    .get(url, config)
    .then(parseResponse)
    .then(parseHasNewScripts)
    .catch(() => false)
}

type Props = {
  refreshInterval: number
}

const AppRefresher = ({ refreshInterval }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const close = useCallback(() => setIsOpen(false), [setIsOpen])
  const reload = useCallback(() => window.location.reload(), [])
  const poll = useCallback(async () => {
    const hasNewScripts = await fetchHasNewScripts()
    setIsOpen(hasNewScripts)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshInterval])

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const interval = window.setInterval(poll, refreshInterval)
      return () => {
        clearInterval(interval)
      }
    }
    return undefined
  }, [poll, refreshInterval])

  const actions = [
    <Button color="inherit" key="no" onClick={close}>
      No
    </Button>,
    <Button color="primary" key="yes" onClick={reload}>
      Yes
    </Button>,
  ]
  return (
    <SolidOverlayDialog actions={actions} open={isOpen}>
      A new version of dashboard is available! Would you like to refresh the dashboard?
    </SolidOverlayDialog>
  )
}

export default AppRefresher
