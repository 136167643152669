import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { Box, Typography, Button, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { trackTriggerCheckinCallFlow } from 'src/mixpanel/api'
import ScheduleInProgramVideoCallDialog from './ScheduleInProgramVideoCallDialog'
import BookingEnabledSelect from './BookingEnabledSelect'

import {
  InProgramVideoCallBookingInformationFragment,
  CallBookingParticipantFragment,
} from '../videoCallBookingHeaderQueries.generated'
import InProgramVideoCallBookingInformation from './InProgramVideoCallBookingInformation'

export const getCallsHadLabel = (numberOfCallsHad?: number | null) => {
  if (!numberOfCallsHad) {
    return 'No check-in calls had'
  }

  if (numberOfCallsHad === 1) {
    return '1 check-in call had'
  }

  return `${numberOfCallsHad} check-in calls had`
}

const getLastCallLabel = (endTime: Date) => {
  const relativeEndTime = DateTime.fromJSDate(endTime).toRelative({ locale: 'en-US' })

  return `Last check-in call ${relativeEndTime}`
}

export type InProgramVideoCallBookingContainerProps = {
  bookingInformation: InProgramVideoCallBookingInformationFragment
  participant: CallBookingParticipantFragment
}

const InProgramVideoCallBookingContainer = ({
  bookingInformation,
  participant,
}: InProgramVideoCallBookingContainerProps) => {
  const { firstname, lastname, uid } = participant
  const { numberOfCallsHad, latestCallHad, callBookingEnabled, videoCalls } = bookingInformation
  const [bookingDialogOpen, setBookingDialogOpen] = useState<boolean>(false)
  const styles = useStyles()

  const handleOpenBookingDialog = () => {
    setBookingDialogOpen(true)
    trackTriggerCheckinCallFlow()
  }

  return (
    <>
      <ScheduleInProgramVideoCallDialog
        firstname={firstname}
        lastname={lastname}
        uid={uid}
        open={bookingDialogOpen}
        onClose={() => setBookingDialogOpen(false)}
      />
      <Grid container justifyContent="space-between" alignItems="center" px={1}>
        <Grid item>
          <Typography variant="body2">{getCallsHadLabel(numberOfCallsHad)}</Typography>
          {latestCallHad && <Typography variant="body2">{getLastCallLabel(latestCallHad.endTime)}</Typography>}
        </Grid>
        <Grid item>
          <BookingEnabledSelect bookingEnabled={callBookingEnabled} uid={uid} />
          <Button
            color="primary"
            variant="outlined"
            className={styles.bookCallButton}
            onClick={handleOpenBookingDialog}
          >
            Book call
          </Button>
        </Grid>
      </Grid>

      {videoCalls.map((videoCall) => (
        <Box mt={1} key={videoCall.id}>
          <InProgramVideoCallBookingInformation videoCall={videoCall} uid={uid} />
        </Box>
      ))}
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  bookCallButton: {
    marginLeft: theme.spacing(1),
  },
}))

export default InProgramVideoCallBookingContainer
