import { getErrorBoundary } from 'src/bugsnag'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { ApolloProvider } from '@apollo/client'
import { Provider } from 'react-redux'
import { CustomMuiThemeProvider, GlobalStyles } from 'src/designSystem'
import { ReactReduxFirebaseProvider } from 'src/firebase'
import { ToastProvider } from 'src/toasts'
import graphQLClient from 'src/legacy/lib/graphql/client'
import App from './App'

import configureStore from './store'

const ErrorBoundary = getErrorBoundary()
const store = configureStore()

const render = (Component: React.FunctionComponent) => {
  const container = document.getElementById('app')
  const root = createRoot(container!)
  root.render(
    <ErrorBoundary>
      <CustomMuiThemeProvider>
        <>
          <GlobalStyles />
          <ApolloProvider client={graphQLClient}>
            <Provider store={store}>
              <ReactReduxFirebaseProvider>
                <ToastProvider>
                  <Component />
                </ToastProvider>
              </ReactReduxFirebaseProvider>
            </Provider>
          </ApolloProvider>
        </>
      </CustomMuiThemeProvider>
    </ErrorBoundary>
  )
}

render(App)

if (module.hot) {
  module.hot.accept('src/App', () => {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const nextApp = require('src/App').default
    render(nextApp)
  })
}
