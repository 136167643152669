import { calculateWpaiScores } from '@meru/shared/helpers/calculations'
import _ from 'lodash'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { WPAIFormType } from './types'

const getFormValuesToKeep = (formValues: WPAIFormType) => {
  if (!formValues.WPAI_QUESTION_1) {
    return _.pick(formValues, ['date', 'WPAI_QUESTION_1', 'WPAI_QUESTION_6'])
  }
  if (!formValues.WPAI_QUESTION_4) {
    return _.pick(formValues, [
      'date',
      'WPAI_QUESTION_1',
      'WPAI_QUESTION_2',
      'WPAI_QUESTION_3',
      'WPAI_QUESTION_4',
      'WPAI_QUESTION_6',
    ])
  }
  return _.pick(formValues, [
    'date',
    'WPAI_QUESTION_1',
    'WPAI_QUESTION_2',
    'WPAI_QUESTION_3',
    'WPAI_QUESTION_4',
    'WPAI_QUESTION_5',
    'WPAI_QUESTION_6',
  ])
}

const RecalculateScores = () => {
  const { values: currentFormValues, setValues } = useFormikContext<WPAIFormType>()
  useEffect(() => {
    const formValuesToKeep = getFormValuesToKeep(currentFormValues)
    const calculatedScores = calculateWpaiScores(formValuesToKeep)
    const newFormValues = {
      ...formValuesToKeep,
      ..._.omitBy(calculatedScores, _.isNull),
    } as WPAIFormType

    if (!_.isEqual(currentFormValues, newFormValues)) {
      setValues({
        ...newFormValues,
      })
    }
  }, [currentFormValues, setValues])

  return null
}
export default RecalculateScores
