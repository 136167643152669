import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import qs from 'qs'

export default function RedirectFromLogin() {
  const location = useLocation()
  if (location.pathname !== '/login') {
    return null
  }
  const { redirect = '/' } = qs.parse(location.search, { ignoreQueryPrefix: true })
  return <Redirect to={decodeURIComponent(redirect as string)} />
}
