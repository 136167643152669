import { Field, Form, Formik } from 'formik'
import React from 'react'
import { FormDatePicker } from 'src/form'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import _ from 'lodash'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import ScoreFields, { getScoreValueSchema } from './ScoreFields'
import SubmitButton from './SubmitButton'

const PHQ9_QUESTIONS = [
  {
    key: 'PHQ9_QUESTION_1',
    label: 'Little interest or pleasure in doing things',
  },
  {
    key: 'PHQ9_QUESTION_2',
    label: 'Feeling down, depressed, or hopeless',
  },
  {
    key: 'PHQ9_QUESTION_3',
    label: 'Trouble falling or staying asleep, or sleeping too much',
  },
  {
    key: 'PHQ9_QUESTION_4',
    label: 'Feeling tired or having little energy',
  },
  {
    key: 'PHQ9_QUESTION_5',
    label: 'Poor appetite or overeating',
  },
  {
    key: 'PHQ9_QUESTION_6',
    label: 'Feeling bad about yourself, or that you are a failure, or have let yourself or your family down',
  },
  {
    key: 'PHQ9_QUESTION_7',
    label: 'Trouble concentrating on things, such as reading the newspaper or watching television',
  },
  {
    key: 'PHQ9_QUESTION_8',
    label:
      'Moving or speaking so slowly that other people could have noticed? Or the opposite, being so fidgety or restless that you have been moving around a lot more than usual',
  },
  {
    key: 'PHQ9_QUESTION_9',
    label: 'Thoughts that you would be better off dead or of hurting yourself in some way',
  },
  {
    key: 'PHQ9_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 3,
}
const ScoreValueSchema = getScoreValueSchema(SCORE_RANGE)

const PHQ9_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    PHQ9_QUESTION_1: ScoreValueSchema,
    PHQ9_QUESTION_2: ScoreValueSchema,
    PHQ9_QUESTION_3: ScoreValueSchema,
    PHQ9_QUESTION_4: ScoreValueSchema,
    PHQ9_QUESTION_5: ScoreValueSchema,
    PHQ9_QUESTION_6: ScoreValueSchema,
    PHQ9_QUESTION_7: ScoreValueSchema,
    PHQ9_QUESTION_8: ScoreValueSchema,
    PHQ9_QUESTION_9: ScoreValueSchema,
    PHQ9_SCORE: Yup.number().required().min(0).max(27),
  })
  .required()
type PHQ9FormType = Yup.InferType<typeof PHQ9_FORM_SCHEMA>

const PHQ9Form = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<PHQ9FormType>
      validationSchema={PHQ9_FORM_SCHEMA}
      initialValues={PHQ9_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.Phq9,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />

          <ScoreFields
            type="PHQ9"
            values={_.omit(values, ['date'])}
            setFieldValue={setFieldValue}
            questions={PHQ9_QUESTIONS}
          />

          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default PHQ9Form
