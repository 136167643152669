import { chain, get, isNumber } from 'lodash'
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Box, Skeleton } from '@mui/material'

import { Warning } from '@mui/icons-material'
import { FormatDateAndWeek } from 'src/date'
import MeruDate from 'src/legacy/lib/MeruDate'
import { CommonPatientHistory, PatientHistory } from 'src/graphql.generated'
import { ProgramEndMetrics } from './programEndMetricSchemas'
import { ParticipantResultsTextRow } from './ParticipantResultsTable'

const MAX_WEEK_FOR_SCORES = 1

export const rejectScoresDuringProgram = (programStartDate: string) => (record: PatientHistory) => {
  const scoreWeek = new MeruDate(record.date).formatWeekNumber(programStartDate, true)
  return !scoreWeek || scoreWeek <= MAX_WEEK_FOR_SCORES
}

const getBaseline = (record: CommonPatientHistory) => {
  if (!record) return null
  return chain(record)
    .get('value')
    .find((_value, key) => key.indexOf('_SCORE') !== -1)
    .value()
}

type Props = {
  type: keyof ProgramEndMetrics
  data: {
    loading: boolean
    patientHistory?: PatientHistory[]
  }
  programStartDate: string
}

const Baseline = ({ type, data, programStartDate }: Props) => {
  const fieldName = `programEndMetrics.${type}.baseline`
  const baselineRecord = chain(data.patientHistory)
    .filter((historyEntryType) => type.valueOf() === historyEntryType.type)
    .filter(rejectScoresDuringProgram(programStartDate))
    .first()
    .value()

  const { setFieldValue, values } = useFormikContext<{
    programEndMetrics: ProgramEndMetrics
  }>()
  useEffect(() => {
    const baseline = getBaseline(baselineRecord)
    setFieldValue(fieldName, baseline)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.patientHistory])

  const baselineValue = values.programEndMetrics[type]?.baseline
  const helperText = isNumber(baselineValue) ? (
    <FormatDateAndWeek
      recordDate={get(baselineRecord, 'date')}
      programStartDate={programStartDate}
      dateColor="text.secondary"
    />
  ) : (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      {`No score from week ${MAX_WEEK_FOR_SCORES} or earlier`}
      <Warning fontSize="small" color="error" />
    </Box>
  )

  return (
    <ParticipantResultsTextRow
      label="Baseline"
      content={data.loading ? <Skeleton /> : baselineValue}
      helperText={data.loading ? undefined : helperText}
    />
  )
}

export default Baseline
