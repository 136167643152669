import React, { useState } from 'react'
import { Button } from '@mui/material'
import { detect } from 'detect-browser'
import { SolidOverlayDialog } from 'src/designSystem'

const ForceChromeBrowser = () => {
  const browser = detect()
  const isChrome = browser?.name === 'chrome'
  const [open, setOpen] = useState(!isChrome)
  const actions = [
    <Button color="inherit" key="dismiss" onClick={() => setOpen(false)}>
      Dismiss
    </Button>,
    <Button color="primary" key="download" href="https://www.google.com/chrome/">
      Download
    </Button>,
  ]
  return (
    <SolidOverlayDialog actions={actions} open={open}>
      Meru Health Dashboard supports Chrome browser only. Please consider downloading Chrome.
    </SolidOverlayDialog>
  )
}

export default ForceChromeBrowser
