import React from 'react'
import { Box, CircularProgress } from '@mui/material'

const FullScreenLoader = () => (
  <Box
    alignItems="center"
    bgcolor="background.default"
    display="flex"
    justifyContent="center"
    position="fixed"
    bottom={0}
    left={0}
    right={0}
    top={0}
    zIndex="tooltip"
  >
    <CircularProgress />
  </Box>
)

export default FullScreenLoader
