import { useEffect, useState } from 'react'
import { throttle } from 'lodash'

export default function useLastActive() {
  const [lastActive, setLastActive] = useState(Date.now())

  useEffect(() => {
    const updateLastActive = throttle(() => setLastActive(Date.now()), 1000)
    document.body.addEventListener('click', updateLastActive, true)
    document.body.addEventListener('mousemove', updateLastActive, true)
    document.body.addEventListener('keydown', updateLastActive, true)
    return () => {
      document.body.removeEventListener('click', updateLastActive, true)
      document.body.removeEventListener('mousemove', updateLastActive, true)
      document.body.removeEventListener('keydown', updateLastActive, true)
    }
  }, [])

  return lastActive
}
