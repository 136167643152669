import React from 'react'
import { TableRow, TableCell, Box } from '@mui/material'

type Props = {
  label: string
}

const ParticipantResultsHeader = ({ label }: Props) => (
  <TableRow>
    <TableCell variant="head" colSpan={3}>
      <Box pt={3}>{label}</Box>
    </TableCell>
  </TableRow>
)

export default ParticipantResultsHeader
