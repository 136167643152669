/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

const defaultOptions = {} as const
export type GetParticipantQuickNoteQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetParticipantQuickNoteQuery = {
  __typename?: 'Query'
  patient: { __typename?: 'Patient'; uid: string; quickNote?: string | null }
}

export type UpdateParticipantQuickNoteMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  quickNote: Types.Scalars['String']['input']
}>

export type UpdateParticipantQuickNoteMutation = {
  __typename?: 'Mutation'
  updatePatientQuickNote: { __typename?: 'Patient'; uid: string }
}

export const GetParticipantQuickNoteDocument = gql`
  query getParticipantQuickNote($uid: ID!) {
    patient(uid: $uid) {
      uid
      quickNote
    }
  }
`

/**
 * __useGetParticipantQuickNoteQuery__
 *
 * To run a query within a React component, call `useGetParticipantQuickNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantQuickNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantQuickNoteQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetParticipantQuickNoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetParticipantQuickNoteQuery, GetParticipantQuickNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetParticipantQuickNoteQuery, GetParticipantQuickNoteQueryVariables>(
    GetParticipantQuickNoteDocument,
    options
  )
}
export function useGetParticipantQuickNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantQuickNoteQuery, GetParticipantQuickNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetParticipantQuickNoteQuery, GetParticipantQuickNoteQueryVariables>(
    GetParticipantQuickNoteDocument,
    options
  )
}
export type GetParticipantQuickNoteQueryHookResult = ReturnType<typeof useGetParticipantQuickNoteQuery>
export type GetParticipantQuickNoteLazyQueryHookResult = ReturnType<typeof useGetParticipantQuickNoteLazyQuery>
export type GetParticipantQuickNoteQueryResult = Apollo.QueryResult<
  GetParticipantQuickNoteQuery,
  GetParticipantQuickNoteQueryVariables
>
export const UpdateParticipantQuickNoteDocument = gql`
  mutation updateParticipantQuickNote($uid: ID!, $quickNote: String!) {
    updatePatientQuickNote(uid: $uid, quickNote: $quickNote) {
      uid
    }
  }
`
export type UpdateParticipantQuickNoteMutationFn = Apollo.MutationFunction<
  UpdateParticipantQuickNoteMutation,
  UpdateParticipantQuickNoteMutationVariables
>

/**
 * __useUpdateParticipantQuickNoteMutation__
 *
 * To run a mutation, you first call `useUpdateParticipantQuickNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateParticipantQuickNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateParticipantQuickNoteMutation, { data, loading, error }] = useUpdateParticipantQuickNoteMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      quickNote: // value for 'quickNote'
 *   },
 * });
 */
export function useUpdateParticipantQuickNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateParticipantQuickNoteMutation,
    UpdateParticipantQuickNoteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateParticipantQuickNoteMutation, UpdateParticipantQuickNoteMutationVariables>(
    UpdateParticipantQuickNoteDocument,
    options
  )
}
export type UpdateParticipantQuickNoteMutationHookResult = ReturnType<typeof useUpdateParticipantQuickNoteMutation>
export type UpdateParticipantQuickNoteMutationResult = Apollo.MutationResult<UpdateParticipantQuickNoteMutation>
export type UpdateParticipantQuickNoteMutationOptions = Apollo.BaseMutationOptions<
  UpdateParticipantQuickNoteMutation,
  UpdateParticipantQuickNoteMutationVariables
>
