import React from 'react'
import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
import { useToasts } from 'react-toast-notifications'

import { trackCancelCheckInCall } from 'src/mixpanel/api'
import { ClosableDialogTitle } from 'src/legacy/components/mui'

import {
  CallBookingVideoCallFragment,
  GetParticipantVideoCallBookingInformationDocument,
} from '../../videoCallBookingHeaderQueries.generated'

import { useCancelInProgramCallByCoachMutation } from './modifyInProgramCallMutations.generated'

type Props = {
  videoCall: CallBookingVideoCallFragment
  onClose: () => void
  onBack: () => void
}

const CancelView = ({ onClose, onBack, videoCall }: Props) => {
  const { zoomMeetingID } = videoCall
  const { addToast } = useToasts()

  const [cancelCall, { loading }] = useCancelInProgramCallByCoachMutation({
    refetchQueries: [GetParticipantVideoCallBookingInformationDocument],
  })

  const handleCancelCall = async () => {
    try {
      await cancelCall({ variables: { zoomMeetingID } })
      trackCancelCheckInCall(zoomMeetingID)
      onClose()
      addToast('The check-in call has been successfully cancelled', { appearance: 'success' })
    } catch (error) {
      addToast('Failed to cancel the check-in call', { appearance: 'error' })
    }
  }

  return (
    <>
      <ClosableDialogTitle onClose={onClose}>Please note</ClosableDialogTitle>

      <DialogContent>
        <Typography>This cancels the participant&apos;s booked check-in call.</Typography>
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={onBack}>
          Back
        </Button>
        <Button color="primary" onClick={handleCancelCall} disabled={loading}>
          Cancel call
        </Button>
      </DialogActions>
    </>
  )
}

export default CancelView
