import * as React from 'react'
import { Button, Box, CircularProgress, Fade } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

type FeedLoadingProps = {
  isFetchingOlder: boolean
  fetchMoreOlder(): Promise<void>
}

export default function SeeMoreButton({ isFetchingOlder, fetchMoreOlder }: FeedLoadingProps) {
  const styles = useStyles()

  return (
    <Box className={styles.container} my={1}>
      <Fade in={isFetchingOlder} style={{ transitionDelay: isFetchingOlder ? `800ms` : '0ms' }}>
        <Box p={1}>
          <CircularProgress disableShrink color="primary" />
        </Box>
      </Fade>
      <Button onClick={fetchMoreOlder} disabled={isFetchingOlder} variant="outlined" color="primary">
        See more
      </Button>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
})
