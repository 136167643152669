import React from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { alpha } from '@mui/material/styles'
import ArrowDropUp from '@mui/icons-material/ArrowDropUp'
import classNames from 'classnames'
import useStickyPositionObserver from './useStickyPositionObserver'

type NewItemsIndicatorProps = {
  showArrow: boolean
  isSticky: boolean
}

function NewItemsIndicator({ showArrow, isSticky }: NewItemsIndicatorProps) {
  const styles = useNewItemIndicatorStyles()
  return (
    <Box className={classNames(styles.container, { [styles.withBackground]: isSticky })}>
      {showArrow && <ArrowDropUp className={styles.arrow} color="error" />}
      <Typography className={styles.text} variant="caption" component="div">
        New
      </Typography>
    </Box>
  )
}

type DividerProps = {
  isOnTop?: boolean
  children?: string
  isHighlighted?: boolean
  showNewIndicator?: boolean
}

export default function Divider({ isHighlighted, isOnTop, children, showNewIndicator }: DividerProps) {
  const { divider, sticky, line, hidable, hidden, highlighted, caption } = useDividerStyles()

  // This is used to detect when the divider is on sticky position to apply hidden styles to other than highlighter elements
  // https://usefulangle.com/post/108/javascript-detecting-element-gets-fixed-in-css-position-sticky
  const stickyPositionHelperRef = React.useRef<HTMLDivElement>(null)

  const isSticky = useStickyPositionObserver(showNewIndicator, stickyPositionHelperRef)

  return (
    <>
      {showNewIndicator && <div ref={stickyPositionHelperRef} />}
      <div className={classNames(divider, { [sticky]: showNewIndicator })}>
        <div
          className={classNames(line, hidable, { [hidden]: isSticky, [highlighted]: isHighlighted })}
          role="separator"
        />
        {children && (
          <Typography
            className={classNames(caption, hidable, { [hidden]: isSticky })}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {children}
          </Typography>
        )}
        <div className={classNames(line, hidable, { [hidden]: isSticky, [highlighted]: isHighlighted })} />
        {showNewIndicator && <NewItemsIndicator showArrow={isOnTop || isSticky} isSticky={isSticky} />}
      </div>
    </>
  )
}

const useDividerStyles = makeStyles((theme) => ({
  divider: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  caption: {
    padding: theme.spacing(1),
  },
  line: {
    backgroundColor: theme.palette.divider,
    flexGrow: 1,
    height: 1,
  },
  highlighted: {
    backgroundColor: theme.palette.error.main,
  },
  sticky: {
    position: 'sticky',
    top: 0,
  },
  hidable: {
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
  },
  hidden: {
    opacity: '0 !important',
  },
}))

const HIGHLIGHTER_CONTAINER_WIDTH = 5.5
const useNewItemIndicatorStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: theme.spacing(-HIGHLIGHTER_CONTAINER_WIDTH / 2),
    width: theme.spacing(HIGHLIGHTER_CONTAINER_WIDTH),
    height: theme.spacing(HIGHLIGHTER_CONTAINER_WIDTH),
  },
  text: {
    color: theme.palette.error.main,
  },
  arrow: {
    marginTop: theme.spacing(-0.5),
    marginBottom: theme.spacing(-1.25),
  },
  withBackground: {
    backgroundColor: alpha(theme.palette.background.default, 0.87),
    borderRadius: '50%',
    backdropFilter: 'blur(2px)',
  },
}))
