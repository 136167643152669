import React from 'react' // eslint-disable-line no-unused-vars
import moment, { MomentInput } from 'moment'

type Props = {
  value?: MomentInput
}

const FromNow: React.FunctionComponent<Props> = (props) => {
  const { value } = props
  if (!value) {
    return null
  }
  return <>{moment(value).fromNow()}</>
}

export default FromNow
