import React from 'react'
import { CardActionsMenu } from 'src/designSystem'
import ignoreException from 'src/exceptionHandler/ignoreException'
import { CommunityPostReply } from 'src/graphql.generated'
import {
  useHideCommunityPostReplyMutation,
  useUnhideCommunityPostReplyMutation,
} from './hideCommunityPostReplyQueries.generated'

export type Props = {
  reply: Pick<CommunityPostReply, 'replyID' | 'isHidden'>
}

export default function CommunityPostReplyActions(props: Props) {
  const {
    reply: { replyID, isHidden },
  } = props

  const [hideCommunityPostReply] = useHideCommunityPostReplyMutation()
  const [unHidecommunityPostReply] = useUnhideCommunityPostReplyMutation()

  const handleHide = () => {
    const variables = {
      replyID,
    }
    if (isHidden) {
      unHidecommunityPostReply({ variables }).catch(ignoreException)
    } else {
      hideCommunityPostReply({ variables }).catch(ignoreException)
    }
  }
  const actions = [
    {
      label: isHidden ? 'Show message' : 'Hide message',
      onClick: handleHide,
    },
  ]
  return <CardActionsMenu actions={actions} />
}
