import _ from 'lodash'
import React, { Children, useRef } from 'react'
import { FieldProps } from 'formik'
import { LinearProgress, TextField, SelectProps, TextFieldProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { ArrowDropDown } from '@mui/icons-material'

import LoadingFieldIndicator from './LoadingFieldIndicator'
import { GenericFormProps, getError, hasError, isFormLoading, isViewOnly, useUnregisterFieldOnUnmount } from './helpers'
import { commonTextFieldStyles, viewOnlyStyle } from './styles'

type CustomTextFieldType = TextFieldProps['type'] | 'select'

type SelectChildrenType = []

type Props = GenericFormProps &
  FieldProps &
  Pick<TextFieldProps, 'onChange' | 'InputProps' | 'SelectProps' | 'fullWidth' | 'disabled'> & {
    children: SelectChildrenType
    helperText?: string
    label: string
    labelPosition: 'left' | 'top'
    minRows?: number
    multiline: boolean
    multiple: boolean
    placeholder?: string
    renderValue?: SelectProps['renderValue']
    type: CustomTextFieldType
  }

const toArrayWithoutEmptyChildren = (children: SelectChildrenType) => _.compact(Children.toArray(children))

const useStyles = makeStyles((theme) => ({
  ...commonTextFieldStyles(theme),
  linearProgressRoot: {
    position: 'absolute',
    borderRadius: 2,
    bottom: 0,
    left: 0,
    right: 0,
  },
  selectIcon: viewOnlyStyle({
    display: 'none',
  }),
  selectRoot: viewOnlyStyle({
    paddingRight: 0,
  }),
}))

const isLoading = (children: SelectChildrenType, type: CustomTextFieldType) =>
  type === 'select' && toArrayWithoutEmptyChildren(children).length === 0

const parseNumber = (value: string) => {
  const parsed = value.length > 0 ? _.toNumber(value) : value
  return _.isFinite(parsed) ? parsed : undefined
}

const CustomTextField: React.FunctionComponent<Props> = (props) => {
  const {
    children,
    disabled,
    field: { name, value },
    form: { setFieldValue },
    fullWidth,
    helperText,
    label,
    labelPosition,
    minRows,
    multiline,
    multiple,
    placeholder,
    renderValue,
    type,
    SelectProps: selectProps = {},
    onChange,
    InputProps = {},
  } = props

  const idRef = useRef(_.uniqueId('CustomTextField'))
  const classes = useStyles(props)

  useUnregisterFieldOnUnmount(props)

  const valuePlaceholder = multiple ? [] : ''
  const textFieldType = type !== 'select' ? type : undefined

  if (isFormLoading(props)) {
    return <LoadingFieldIndicator {...props} />
  }

  return (
    <TextField
      FormHelperTextProps={{
        classes: {
          root: classes.formHelperTextRoot,
        },
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot,
          input: classes.input,
          ...InputProps?.classes,
        },
        notched: labelPosition === 'left' ? false : undefined,
        autoComplete: 'chrome-off',
        ...InputProps,
      }}
      InputLabelProps={{
        classes: {
          root: classes.labelRoot,
        },
      }}
      SelectProps={{
        ...selectProps,
        classes: {
          icon: classes.selectIcon,
          select: classes.selectRoot,
        },
        multiple,
        renderValue,
        IconComponent: (iconProps) => (
          <>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <ArrowDropDown {...iconProps} />
            {toArrayWithoutEmptyChildren(children).length === 0 && (
              <LinearProgress classes={{ root: classes.linearProgressRoot }} />
            )}
          </>
        ),
      }}
      classes={{
        root: classes.root,
      }}
      disabled={isViewOnly(props) || disabled}
      error={hasError(props)}
      fullWidth={fullWidth}
      helperText={hasError(props) ? getError(props) : helperText}
      id={idRef.current}
      label={label}
      multiline={multiline}
      name={name}
      onChange={(event) => {
        const newValue = type === 'number' ? parseNumber(event.target.value) : event.target.value

        setFieldValue(name, newValue)

        if (onChange) {
          onChange(event)
        }
      }}
      placeholder={placeholder}
      minRows={minRows}
      select={type === 'select'}
      size="small"
      value={_.isNil(value) || isLoading(children, type) ? valuePlaceholder : value}
      variant="outlined"
      type={textFieldType}
    >
      {type === 'select' && children}
    </TextField>
  )
}

CustomTextField.defaultProps = {
  children: [],
  disabled: false,
  errorText: undefined,
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  labelPosition: 'top',
  multiline: false,
  multiple: false,
  placeholder: undefined,
  renderValue: undefined,
  type: 'text',
  unregisterOnUnmount: true,
}

export default CustomTextField
