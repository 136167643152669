import _ from 'lodash'
import { useEffect } from 'react'
import { useGetPrefilledIntakeNoteQuery } from 'src/legacy/lib/graphql/queries.generated'
import { getItem, setItem } from 'src/legacy/lib/localStorageWithTTL'
import { DEFAULT_INTAKE_NOTE_TTL_IN_HOURS } from '../constants'
import { EMPTY_FIELDS, FormikContext, INTAKE_NOTE_SCHEMA, IntakeNoteSchema } from './intakeNoteSchemas'

const usePreloadIntakeNoteValues = (
  uid: string,
  values: FormikContext,
  viewOnly: boolean,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
  const intakeNoteInitialized = getItem(`INTAKE_NOTE_INITIALIZED-${uid}`)

  const prefilledIntakeNoteResult = useGetPrefilledIntakeNoteQuery({
    variables: { uid },
    skip: viewOnly || intakeNoteInitialized,
  })

  const { value: fieldValues = EMPTY_FIELDS } = values

  useEffect(() => {
    if (intakeNoteInitialized || viewOnly) return

    setFieldValue('value', { ...INTAKE_NOTE_SCHEMA.default(), ...fieldValues })

    if (prefilledIntakeNoteResult.loading || !prefilledIntakeNoteResult.data?.prefilledIntakeNote) {
      return
    }

    const prefills = prefilledIntakeNoteResult.data.prefilledIntakeNote || {}

    const updatedFieldValues = Object.entries(prefills)
      .filter(([, value]) => value !== null)
      .reduce((acc, [field, prefilledValue]) => _.set(acc, field, prefilledValue), {
        ...fieldValues,
      } as IntakeNoteSchema)

    const value = INTAKE_NOTE_SCHEMA.cast({ ...updatedFieldValues })

    setFieldValue('value', value)

    setItem(`INTAKE_NOTE_INITIALIZED-${uid}`, true, DEFAULT_INTAKE_NOTE_TTL_IN_HOURS)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefilledIntakeNoteResult, uid])
}

export default usePreloadIntakeNoteValues
