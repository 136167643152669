import _ from 'lodash'
import React, { Children, cloneElement, ReactElement } from 'react'
import { FieldProps } from 'formik'
import { FormControl, RadioGroup } from '@mui/material'

import { GenericFormProps, getError, hasError, isViewOnly, isFormLoading, useUnregisterFieldOnUnmount } from './helpers'
import CustomFormHelperText from './CustomFormHelperText'

type Props = {
  disabled: boolean
  fullWidth: boolean
  helperText?: string
  type: 'number' | 'text'
  className?: string
}

const CustomRadioGroup: React.FunctionComponent<Props & GenericFormProps & FieldProps> = (props) => {
  const {
    disabled,
    children,
    fullWidth,
    field: { name, value },
    form: { setFieldValue },
    helperText,
    type,
    className,
  } = props

  useUnregisterFieldOnUnmount(props)

  return (
    <FormControl
      disabled={isFormLoading(props) || isViewOnly(props) || disabled}
      fullWidth={fullWidth}
      className={className}
    >
      <RadioGroup
        name={name}
        onChange={(event) => {
          const newValue = type === 'number' ? _.toNumber(event.target.value) : event.target.value
          setFieldValue(name, newValue)
        }}
        value={String(value)}
      >
        {Children.map(children, (child) => cloneElement(child as ReactElement, { ...props }))}
      </RadioGroup>
      {(hasError(props) || helperText) && (
        <CustomFormHelperText error={hasError(props)}>
          {hasError(props) ? getError(props) : helperText}
        </CustomFormHelperText>
      )}
    </FormControl>
  )
}

CustomRadioGroup.defaultProps = {
  disabled: false,
  errorText: undefined,
  fullWidth: false,
  helperText: undefined,
  type: 'text',
  unregisterOnUnmount: true,
}

export default CustomRadioGroup
