import React from 'react'
import { Card } from '@mui/material'

import { fireRef } from 'src/firebase'
import { Cards } from 'src/legacy/components/mui'
import { Container } from 'src/designSystem'
import ScrollContainer from 'src/legacy/components/ScrollContainer'
import Coaches from './Coaches'
import ReferralTypes from './ReferralTypes'
import Sources from './Sources/Sources'
import Tags from './Tags/Tags'
import { useCreateCoachMutation } from './adminQueries.generated'

const addReferral = (title: string) => fireRef.child('referralTypes').push({ title })
const deleteReferral = (id: string) => fireRef.child('referralTypes').child(id).remove()

const Admin: React.FunctionComponent = () => {
  const [createCoach] = useCreateCoachMutation()
  return (
    <Container>
      <ScrollContainer>
        <Cards>
          <Card>
            <Coaches addFn={(email) => createCoach({ variables: { email } })} />
          </Card>
          <Card>
            <Sources />
          </Card>
          <Card>
            <ReferralTypes addFn={addReferral} deleteFn={deleteReferral} />
          </Card>
          <Card>
            <Tags />
          </Card>
        </Cards>
      </ScrollContainer>
    </Container>
  )
}

export default Admin
