import { AnyAction } from 'redux'
import { SET_SESSION } from './actions'

export type SessionState = {
  uid?: string
  authRoles?: {
    isAdmin: boolean
    isManager: boolean
    isCareCoordinator: boolean
    isCommunityFacilitator: boolean
  }
  isLoggedIn?: boolean
  refreshAt?: number
  startedAt?: number
  validUntil?: number
}

export default function update(current: SessionState = {}, action: AnyAction): SessionState {
  switch (action.type) {
    case SET_SESSION: {
      const startedAt = current.validUntil !== action.payload.validUntil ? Date.now() : current.startedAt
      return {
        ...action.payload,
        startedAt,
      }
    }

    default:
      return current
  }
}
