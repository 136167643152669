import { useCallback } from 'react'
import _ from 'lodash'
import { GET_PATIENT_HISTORY, GET_PATIENT_HISTORY_VALUES } from 'src/legacy/lib/graphql/queries'
import { GetPatientHistoryQuery, GetPatientHistoryValuesQuery } from 'src/legacy/lib/graphql/queries.generated'
import Big from 'big.js'

import {
  useAddFinancialEligibilityPatientHistoryMutation,
  useAddPatientHistoryMutation,
  GetPatientHistoryAndProgramStartDateDocument,
} from 'src/participantHistory/patientHistoryQueries.generated'

import {
  FinancialEligibilityInsuranceSubscriberInput,
  PatientHistoryType as GraphQLPatientHistoryType,
} from 'src/graphql.generated'
import type { FinancialEligibilityHealthPlanSchema } from 'src/participantHistory/financialEligibility/FinancialEligibilityHealthPlanFields'
import type { PatientHistoryEntry } from 'src/legacy/components/ParticipantRecords/ParticipantHistory/types'

type FormPatientHistoryType = PatientHistoryEntry['type']

type FinancialEligibilityPatientHistoryEntry = PatientHistoryEntry & {
  value: FinancialEligibilityHealthPlanSchema
}

// FIXME: This is hack to remap the form type FINANCIAL_ELIGIBILITY_HEALTH_PLAN before saving the form
// We have a plan to merge FINANCIAL_ELIGIBILITY_* type to one in the near future
const getGraphQLPatientHistoryTypeFromFormPatientHistoryType = (formPatientHistoryType: FormPatientHistoryType) =>
  formPatientHistoryType === 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN'
    ? GraphQLPatientHistoryType.FinancialEligibilityConfirmed
    : formPatientHistoryType

const useAddCommonPatientHistoryEntry = () => {
  const [addPatientHistory] = useAddPatientHistoryMutation()
  return useCallback(
    (patientHistoryEntry: PatientHistoryEntry) =>
      addPatientHistory({
        variables: {
          ...patientHistoryEntry,
          type: getGraphQLPatientHistoryTypeFromFormPatientHistoryType(patientHistoryEntry.type),
        },
        update: (cache, result) => {
          const { uid } = patientHistoryEntry
          const patientHistory = result.data?.addPatientHistory
          const getPatientHistory = cache.readQuery<GetPatientHistoryQuery>({
            query: GET_PATIENT_HISTORY,
            variables: { uid },
          })
          if (getPatientHistory) {
            cache.writeQuery({
              data: {
                patientHistory: _.orderBy([patientHistory, ...getPatientHistory.patientHistory], 'date', 'desc'),
              },
              query: GET_PATIENT_HISTORY,
              variables: { uid },
            })
          }
          const getPatientHistoryValues = cache.readQuery<GetPatientHistoryValuesQuery>({
            query: GET_PATIENT_HISTORY_VALUES,
            variables: {
              uid,
              type: patientHistory?.type,
            },
          })
          if (getPatientHistoryValues) {
            cache.writeQuery({
              data: {
                patientHistory: _.orderBy([...getPatientHistoryValues.patientHistory, patientHistory], 'date', 'asc'),
              },
              query: GET_PATIENT_HISTORY_VALUES,
              variables: {
                uid,
                type: patientHistory?.type,
              },
            })
          }
        },
      }),
    [addPatientHistory]
  )
}

const insuranceSubscriberToGraphQL = (
  insuranceSubscriber: FinancialEligibilityPatientHistoryEntry['value']['insuranceSubscriber']
): FinancialEligibilityInsuranceSubscriberInput | null => {
  if (insuranceSubscriber?.insuranceSubscriberProvided) {
    const { firstname, lastname, memberID } = insuranceSubscriber
    // Typing the union type in Yup doesn't seem to be possible, so a
    // check here that should never throw an error.
    if (!firstname || !lastname || !memberID) throw new Error('Invalid insurance details')
    return { ..._.omit(insuranceSubscriber, 'insuranceSubscriberProvided'), firstname, lastname, memberID }
  }

  return null
}

const useAddFinancialEligibilityPatientHistoryEntry = () => {
  const [adddFinancialEligibilityPatientHistoryMutation] = useAddFinancialEligibilityPatientHistoryMutation({
    refetchQueries: [GetPatientHistoryAndProgramStartDateDocument],
  })

  return useCallback(
    (patientHistoryEntry: FinancialEligibilityPatientHistoryEntry) => {
      const { uid, date, value } = patientHistoryEntry
      const { description, insuranceSubscriber, costEstimate } = value
      const { intakeCostEstimate, month1CostEstimate, month2CostEstimate, month3CostEstimate } = costEstimate

      const costEstimateInCents =
        _.isNumber(intakeCostEstimate) &&
        _.isNumber(month1CostEstimate) &&
        _.isNumber(month2CostEstimate) &&
        _.isNumber(month3CostEstimate)
          ? {
              intakeCostEstimateInCents: new Big(intakeCostEstimate).times(100).toNumber(),
              month1CostEstimateInCents: new Big(month1CostEstimate).times(100).toNumber(),
              month2CostEstimateInCents: new Big(month2CostEstimate).times(100).toNumber(),
              month3CostEstimateInCents: new Big(month3CostEstimate).times(100).toNumber(),
            }
          : null

      return adddFinancialEligibilityPatientHistoryMutation({
        variables: {
          uid,
          date,
          description,
          insuranceSubscriber: insuranceSubscriberToGraphQL(insuranceSubscriber),
          costEstimate: costEstimateInCents,
        },
      })
    },
    [adddFinancialEligibilityPatientHistoryMutation]
  )
}

export default function useAddPatientHistoryEntry() {
  const addCommonPatientHistoryEntry = useAddCommonPatientHistoryEntry()
  const addFinancialEligibilityPatientHistoryEntry = useAddFinancialEligibilityPatientHistoryEntry()

  return useCallback(
    (patientHistoryEntry: PatientHistoryEntry) => {
      if (patientHistoryEntry.type === 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN') {
        return addFinancialEligibilityPatientHistoryEntry(
          patientHistoryEntry as FinancialEligibilityPatientHistoryEntry
        )
      }

      return addCommonPatientHistoryEntry(patientHistoryEntry)
    },
    [addCommonPatientHistoryEntry, addFinancialEligibilityPatientHistoryEntry]
  )
}
