import { useEffect, useRef } from 'react'

interface UseIntervalOptions {
  leading?: boolean
  delayFirstInterval?: number
}

const useInterval = (callback: () => void, tickInterval: number, options?: UseIntervalOptions) => {
  const intervalRef = useRef<number>()
  const timeoutRef = useRef<number>()

  const clear = () => {
    window.clearInterval(intervalRef.current)
    window.clearTimeout(timeoutRef.current)
  }

  const savedCallback = useRef<() => void>(callback)
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])
  useEffect(() => {
    const tick = () => savedCallback.current()
    const leading = options?.leading ?? false
    const delayFirstInterval = options?.delayFirstInterval ?? 0

    if (leading) {
      tick()
    }

    const startInterval = () => {
      intervalRef.current = window.setInterval(tick, tickInterval)
    }

    if (delayFirstInterval) {
      timeoutRef.current = window.setTimeout(() => {
        tick()
        startInterval()
      }, delayFirstInterval)
    } else {
      startInterval()
    }
    return clear
  }, [options?.delayFirstInterval, options?.leading, tickInterval])
}

export default useInterval
