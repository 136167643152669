/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql.generated'

const defaultOptions = {} as const
export type GetEmailsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetEmailsQuery = {
  __typename?: 'Query'
  emails: Array<{
    __typename?: 'Email'
    emailID: string
    to: string
    from?: string | null
    subject?: string | null
    htmlBody?: string | null
    status: Types.EmailStatus
    statusChangedAt: Date
  }>
}

export const GetEmailsDocument = gql`
  query getEmails($uid: ID!) {
    emails(uid: $uid) {
      emailID
      to
      from
      subject
      htmlBody
      status
      statusChangedAt
    }
  }
`

/**
 * __useGetEmailsQuery__
 *
 * To run a query within a React component, call `useGetEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetEmailsQuery(baseOptions: Apollo.QueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options)
}
export function useGetEmailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmailsQuery, GetEmailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEmailsQuery, GetEmailsQueryVariables>(GetEmailsDocument, options)
}
export type GetEmailsQueryHookResult = ReturnType<typeof useGetEmailsQuery>
export type GetEmailsLazyQueryHookResult = ReturnType<typeof useGetEmailsLazyQuery>
export type GetEmailsQueryResult = Apollo.QueryResult<GetEmailsQuery, GetEmailsQueryVariables>
