/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql.generated'

const defaultOptions = {} as const
export type GetEmailTemplatesQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetEmailTemplatesQuery = {
  __typename?: 'Query'
  emailTemplates: Array<{
    __typename?: 'EmailTemplate'
    id: string
    title: string
    description: string
    subject: string
  }>
}

export type SendEmailsMutationVariables = Types.Exact<{
  uids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  emailTemplateInput: Types.EmailTemplateInput
}>

export type SendEmailsMutation = { __typename?: 'Mutation'; sendEmails?: null | null }

export const GetEmailTemplatesDocument = gql`
  query getEmailTemplates {
    emailTemplates {
      id
      title
      description
      subject
    }
  }
`

/**
 * __useGetEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useGetEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>(GetEmailTemplatesDocument, options)
}
export function useGetEmailTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>(
    GetEmailTemplatesDocument,
    options
  )
}
export type GetEmailTemplatesQueryHookResult = ReturnType<typeof useGetEmailTemplatesQuery>
export type GetEmailTemplatesLazyQueryHookResult = ReturnType<typeof useGetEmailTemplatesLazyQuery>
export type GetEmailTemplatesQueryResult = Apollo.QueryResult<GetEmailTemplatesQuery, GetEmailTemplatesQueryVariables>
export const SendEmailsDocument = gql`
  mutation sendEmails($uids: [ID!]!, $emailTemplateInput: EmailTemplateInput!) {
    sendEmails(uids: $uids, emailTemplateInput: $emailTemplateInput)
  }
`
export type SendEmailsMutationFn = Apollo.MutationFunction<SendEmailsMutation, SendEmailsMutationVariables>

/**
 * __useSendEmailsMutation__
 *
 * To run a mutation, you first call `useSendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailsMutation, { data, loading, error }] = useSendEmailsMutation({
 *   variables: {
 *      uids: // value for 'uids'
 *      emailTemplateInput: // value for 'emailTemplateInput'
 *   },
 * });
 */
export function useSendEmailsMutation(
  baseOptions?: Apollo.MutationHookOptions<SendEmailsMutation, SendEmailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendEmailsMutation, SendEmailsMutationVariables>(SendEmailsDocument, options)
}
export type SendEmailsMutationHookResult = ReturnType<typeof useSendEmailsMutation>
export type SendEmailsMutationResult = Apollo.MutationResult<SendEmailsMutation>
export type SendEmailsMutationOptions = Apollo.BaseMutationOptions<SendEmailsMutation, SendEmailsMutationVariables>
