import { Grid, Typography } from '@mui/material'
import { Field } from 'formik'
import React from 'react'
import { CustomCheckbox, CustomTextField } from 'src/form'
import BillingCodes from './intakeNote/BillingCodes'

const TreatmentPlanSection: React.FC<{ title: string }> = (props) => (
  <Grid item xs={12} mt={4}>
    <Typography variant="h3">{props.title}</Typography>
    {props.children}
  </Grid>
)

const TreatmentPlanFields = () => (
  <>
    <TreatmentPlanSection title="Treatment Plan">
      <Field
        component={CustomTextField}
        label="Presenting Problem"
        helperText="Briefly summarize the primary reason(s) the individual is seeking treatment at this time and/or the current challenge or change in circumstances that necessitates an adjustment to the treatment plan"
        fullWidth
        multiline
        name="presentingProblem"
        minRows={4}
      />
      <Field
        component={CustomTextField}
        label="Treatment Goals"
        helperText="Include at least 1 SMART goal"
        fullWidth
        multiline
        name="treatmentGoals"
        minRows={4}
      />
    </TreatmentPlanSection>

    <TreatmentPlanSection title="Billing and Diagnosis Codes">
      <BillingCodes name="billingCodes" />
    </TreatmentPlanSection>

    <TreatmentPlanSection title="Interventions/ Engagement Plan">
      <Field
        fullWidth
        component={CustomCheckbox}
        name="interventionsAndEngagementPlan.participateTreatmentProgram"
        label="Client will participate in Meru Treatment program including daily lessons, communication with therapist and biofeedback"
      />
      <Field
        fullWidth
        component={CustomCheckbox}
        name="interventionsAndEngagementPlan.followupTelehealthSessions"
        label="Follow up telehealth sessions as needed"
      />
      <Field
        fullWidth
        component={CustomCheckbox}
        name="interventionsAndEngagementPlan.utilizeCommunicationChannels"
        label="Therapist will utilize in app chat to communicate with participant and escalate to SMS, email or phone calls in order to re-engage client in treatment as needed"
      />
      <Field
        fullWidth
        component={CustomCheckbox}
        name="interventionsAndEngagementPlan.participantAgreement"
        label="Participant agrees to the discussed Treatment plan"
      />
      <Field
        component={CustomTextField}
        label="Additional Resources Provided (include details)"
        fullWidth
        multiline
        name="interventionsAndEngagementPlan.additionalResources"
        minRows={4}
      />
      <Field
        component={CustomTextField}
        label="Coordination of care with external providers (include details)"
        fullWidth
        multiline
        name="interventionsAndEngagementPlan.coordinationWithExternalProviders"
        minRows={4}
      />
      <Field
        component={CustomTextField}
        label="Other"
        fullWidth
        multiline
        name="interventionsAndEngagementPlan.other"
        minRows={4}
      />
    </TreatmentPlanSection>
    <TreatmentPlanSection title="Obstacles and Action Plan">
      <Field
        component={CustomTextField}
        label="Obstacles and Action Plan"
        helperText="Identify obstacles and create an action plan, including steps the participant and the provider will take to overcome obstacles"
        fullWidth
        multiline
        name="obstaclesAndActionPlan"
        minRows={4}
      />
    </TreatmentPlanSection>
  </>
)

export default TreatmentPlanFields
