import { EnrollmentState, PatientProgramWeek, PatientSortField, PatientSortOrder } from 'src/graphql.generated'
import { useSearchPatientsV2Query } from './searchPatients.generated'

export type ParticipantFilter = {
  states: Array<EnrollmentState>
  query?: string
  sources: Array<string>
  providers: Array<string>
  statesOfResidence: Array<string>
  weeks: Array<PatientProgramWeek>
  tags: Array<string>
  eligibilityUnconfirmed?: boolean
}

export type PaginationOptions = {
  page?: number
  pageSize?: number
}

export type SortOptions = {
  sortBy?: PatientSortField
  sortOrder?: PatientSortOrder
}

export default function useLoadParticipantsWithFilter(
  filter: ParticipantFilter,
  options: PaginationOptions = {},
  sort: SortOptions = {}
) {
  const { query, states, sources, providers, statesOfResidence, weeks, tags, eligibilityUnconfirmed } = filter
  const { pageSize, page } = options
  const { sortOrder, sortBy } = sort
  const variables = {
    query,
    states,
    sources,
    statesOfResidence,
    weeks,
    pageSize,
    page,
    sortBy,
    sortOrder,
    coachIDs: providers,
    tagIDs: tags,
    eligibilityUnconfirmed,
  }
  return useSearchPatientsV2Query({
    variables,
  })
}
