import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import EditMessageProvider from 'src/feed/EditChatMessage/EditMessageProvider'
import ScrollableFeedContainer from './ScrollableFeedContainer/ScrollableFeedContainer'
import useFeedItemsQuery from './useFeedItemsQuery'
import FeedItems from './FeedItems'
import SeeMoreButton from './FeedComponents/SeeMoreButton'
import { MentionResponseContextProvider } from './mentionResponse'
import useListenToLatestCoachMessages from './useListenToLatestCoachMessages'
import useMarkFeedAsSeen from './useMarkFeedAsSeen'
import useChatDisplayRules from './useChatDisplayRules'
import ChatMessageSendingSection from './ChatMessageSendingSection/ChatMessageSendingSection'

export const FEED_ITEM_COUNT_LIMIT = 50

type FeedProps = {
  feedID: string
}

const useStyles = makeStyles({
  feed: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
})

export default function ParticipantFeedCombined({ feedID }: FeedProps) {
  const styles = useStyles()

  const { hasOlder, items, isFetchingOlder, fetchMoreNewer, fetchMoreOlder, lastSeenAt } = useFeedItemsQuery(
    feedID,
    FEED_ITEM_COUNT_LIMIT
  )

  // Fetch newer feed items when there are new chat messages
  useListenToLatestCoachMessages(feedID, fetchMoreNewer)

  // Mark as seen when content or feed changes
  useMarkFeedAsSeen(feedID, items)

  const { disableChat, messageSubmitDisplayType } = useChatDisplayRules(feedID)

  return (
    <div className={styles.feed}>
      <MentionResponseContextProvider feedID={feedID}>
        <EditMessageProvider feedID={feedID}>
          <ScrollableFeedContainer items={items}>
            {items.length > 0 && (
              <>
                {hasOlder && <SeeMoreButton fetchMoreOlder={fetchMoreOlder} isFetchingOlder={isFetchingOlder} />}
                <FeedItems items={items} lastSeenAt={lastSeenAt} hasOlder={hasOlder} />
              </>
            )}
          </ScrollableFeedContainer>

          {!disableChat && (
            <ChatMessageSendingSection
              feedID={feedID}
              messageSubmitDisplayType={messageSubmitDisplayType}
              messageCreatedCallback={fetchMoreNewer}
            />
          )}
        </EditMessageProvider>
      </MentionResponseContextProvider>
    </div>
  )
}
