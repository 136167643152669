import React from 'react'
import { Field } from 'formik'
import { Button } from '@mui/material'
import * as Yup from 'yup'

import { CustomTextField, RequestForm } from 'src/form'
import { InlineForm } from 'src/legacy/components/mui'

const AddTitleFormSchema = Yup.object().required().shape({
  title: Yup.string().required(),
})

type Props = {
  onSubmit: (title: string) => void
}

const AddTitleForm: React.FunctionComponent<Props> = (props) => {
  const { onSubmit } = props
  return (
    <RequestForm
      onSubmit={({ title }) => onSubmit(title)}
      validationSchema={AddTitleFormSchema}
      render={({ isSubmitting }) => (
        <InlineForm variant="dense">
          <Field component={CustomTextField} label="Title" name="title" />
          <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
            Add
          </Button>
        </InlineForm>
      )}
    />
  )
}

export default AddTitleForm
