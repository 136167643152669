import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@mui/styles/makeStyles'
import { Skeleton } from '@mui/material'

import { FromNow } from 'src/date'
import { isSuccess, useFirebase } from 'src/firebase'
import { ConnectionData, OnlineStatus as OnlineStatusData, PrivateUserData } from 'src/legacy/models/firebase'
import { useGetPatientCreatedAtQuery } from 'src/participant/patientQueries.generated'

const PARTICIPANT_IS_ONLINE_TIMEOUT = 60 * 60 * 1000 // if participant's lastActivity is over 60 min ago, timeout

const isActiveConnection = (connection: ConnectionData) => {
  const oldestActivityAllowed = Date.now() - PARTICIPANT_IS_ONLINE_TIMEOUT
  return connection.lastActivity >= oldestActivityAllowed
}

const getLastSeenAt = (onlineStatus: OnlineStatusData | null) => {
  const lastConnection = _.chain(onlineStatus).get('connections').values().maxBy('lastActivity').value()
  if (lastConnection && onlineStatus && lastConnection.lastActivity > onlineStatus.lastSeenAt) {
    return lastConnection.lastActivity
  }
  return _.get(onlineStatus, 'lastSeenAt')
}

const useStyles = makeStyles((theme) => ({
  isOnline: {
    color: theme.palette.primary.main,
    paddingLeft: '1em',
    position: 'relative',

    '&::before': {
      backgroundColor: theme.palette.primary.main,
      border: `0.25em solid ${theme.palette.primary.main}`,
      borderRadius: '0.25em',
      content: "''",
      left: 0,
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
  },
}))

type Props = {
  uid: string
}

const OnlineStatus = ({ uid }: Props) => {
  const onlineStatusRequest = useFirebase<OnlineStatusData>(`onlineStatus/${uid}`)
  const privateUserDataRequest = useFirebase<PrivateUserData>(`privateUserData/${uid}`)
  const patientWithCreatedAt = useGetPatientCreatedAtQuery({ variables: { uid } })

  const classes = useStyles()

  if (!isSuccess(onlineStatusRequest) || !isSuccess(privateUserDataRequest) || patientWithCreatedAt.loading) {
    return <Skeleton />
  }

  const onlineStatus = onlineStatusRequest.data
  const privateUserData = privateUserDataRequest.data

  const isOnline = _.chain(onlineStatus).get('connections').some(isActiveConnection).value()
  if (isOnline) {
    return <span className={classes.isOnline}>Online</span>
  }
  const lastSeenAt = getLastSeenAt(onlineStatus)
  if (lastSeenAt) {
    return (
      <>
        Last seen <FromNow value={lastSeenAt} />
      </>
    )
  }
  const lastLoginAt = _.get(privateUserData, 'lastLoginAt')
  if (lastLoginAt) {
    return (
      <>
        Last seen <FromNow value={lastLoginAt} />
      </>
    )
  }
  const createdAt = patientWithCreatedAt.data?.patient.createdAt
  return (
    <>
      Created at <FromNow value={createdAt} />
    </>
  )
}

OnlineStatus.propTypes = {
  uid: PropTypes.string.isRequired,
}

export default OnlineStatus
