import React, { useCallback } from 'react'
import { Field } from 'formik'
import { Button, CardContent, MenuItem } from '@mui/material'
import * as Yup from 'yup'

import { EmailField, ProgramIDField } from 'src/legacy/components/fields'
import { FormDatePicker, CustomTextField, GraphQLForm } from 'src/form'
import { InlineForm } from 'src/legacy/components/mui'
import { getActiveCoachProfiles } from 'src/legacy/selectors/coachProfiles'
import { useAddTestUserMutation } from 'src/legacy/lib/graphql/queries.generated'
import { isSuccessWithData, useFirebase } from 'src/firebase'
import { UpdateQueryOptions } from '@apollo/client'
import { CoachProfileWithCoachID } from 'src/participant/SetCareTeamForm/useLoadCoachProfiles'

const TestParticipantSchema = Yup.object()
  .shape({
    firstname: Yup.string().required(),
    lastname: Yup.string().required(),
    email: Yup.string().email().required(),
    programID: Yup.string().required(),
    programStartDate: Yup.string().required(),
    primaryCoachID: Yup.string().required(),
  })
  .required()

type TestParticipantFormData = Yup.InferType<typeof TestParticipantSchema>

type Props = {
  onAddTestParticipantSuccess: () => void
}

const TestParticipantForm = ({ onAddTestParticipantSuccess }: Props) => {
  const [addTestParticipantMutation] = useAddTestUserMutation({ onCompleted: onAddTestParticipantSuccess })
  const request = useFirebase<Array<CoachProfileWithCoachID>>('coachProfiles', getActiveCoachProfiles)

  const handleSubmit = useCallback(
    async (opts: UpdateQueryOptions<TestParticipantFormData>): Promise<void> => {
      const { variables } = opts!
      await addTestParticipantMutation({
        variables,
      })
    },
    [addTestParticipantMutation]
  )

  const coachProfiles = isSuccessWithData(request) ? request.data : []

  return (
    <GraphQLForm
      onSubmit={handleSubmit}
      ignoredErrors={['DUPLICATE_KEY']}
      validationSchema={TestParticipantSchema}
      render={({ isSubmitting }) => (
        <CardContent>
          <InlineForm>
            <Field component={CustomTextField} fullWidth label="First Name" name="firstname" />
            <Field component={CustomTextField} fullWidth label="Last Name" name="lastname" />
            <EmailField />
            <ProgramIDField test />
            <Field component={FormDatePicker} fullWidth label="Program Start Date" name="programStartDate" />
            <Field component={CustomTextField} fullWidth label="Primary Provider" name="primaryCoachID" type="select">
              {coachProfiles.map(({ coachID, firstname, lastname }) => (
                <MenuItem key={coachID} value={coachID}>
                  {firstname} {lastname}
                </MenuItem>
              ))}
            </Field>
            <Button color="primary" disabled={isSubmitting} type="submit" variant="contained">
              Add
            </Button>
          </InlineForm>
        </CardContent>
      )}
    />
  )
}

export default TestParticipantForm
