import _ from 'lodash'
import { branch, compose, lifecycle, renderNothing } from 'recompose'
import { withToastManager } from 'react-toast-notifications'

export default (propName = 'data') =>
  branch(
    (props) => _.get(props, `${propName}.error`, false),
    compose(
      withToastManager,
      lifecycle({
        componentDidMount() {
          this.props.toastManager.add('Something went wrong! Please try again.', {
            appearance: 'error',
          })
        },
      }),
      renderNothing
    )
  )
