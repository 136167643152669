import { first, last } from 'lodash'
import { useEffect, useState } from 'react'
import { FeedOrder } from 'src/graphql.generated'
import { GetFeedItemsQuery, useGetFeedItemsLazyQuery } from './feedQueries.generated'
import { getFeedItems } from './feedItemTypes'

type LastSeenAt = GetFeedItemsQuery['feed']['lastSeenAt']

export default function useFeedItemsQuery(feedID: string, limit: number) {
  const [hasOlder, setHasOlder] = useState(false)
  const [isFetchingOlder, setIsFetchingOlder] = useState(false)
  const [lastSeenAt, setLastSeenAt] = useState<LastSeenAt>()

  const [fetch, feedItemsQuery] = useGetFeedItemsLazyQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted: (data) => {
      setLastSeenAt(data.feed.lastSeenAt)
    },
    variables: {
      uid: feedID,
      limit,
      order: FeedOrder.Desc,
    },
  })

  useEffect(() => {
    const fetchInitial = async () => {
      const { data } = await fetch()
      // Here the data contains all items fetched so far (length can also be > limit).
      const newHasOlder = getFeedItems(data).length >= limit
      setHasOlder(newHasOlder)
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchInitial()
  }, [feedID, limit, fetch])

  const items = getFeedItems(feedItemsQuery.data)

  const fetchMoreOlder = async () => {
    setIsFetchingOlder(true)
    await feedItemsQuery
      .fetchMore({
        variables: {
          before: first(items)?.createdAt,
          order: FeedOrder.Desc,
          limit,
        },
      })
      .then(({ data }) => {
        // Here the data only contains additional items fetched (length should be <= limit).
        const newHasOlder = getFeedItems(data).length === limit
        setHasOlder(newHasOlder)
      })
      .finally(() => {
        setIsFetchingOlder(false)
      })
  }

  const fetchMoreNewer = async () => {
    await feedItemsQuery.fetchMore({
      variables: {
        after: last(items)?.createdAt,
        limit: null,
      },
    })
  }

  return {
    hasOlder,
    items,
    isFetchingOlder,
    fetchMoreNewer,
    fetchMoreOlder,
    lastSeenAt,
  }
}
