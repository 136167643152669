import * as Yup from 'yup'
import { parsePhoneNumber } from './formatPhoneNumber'

Yup.addMethod<Yup.StringSchema>(Yup.string, 'phoneNumber', function validate() {
  return this.test('Phone number format test', 'Invalid phone number', (value) => {
    if (value === null || value === undefined) {
      return true
    }
    return parsePhoneNumber(value).isValid()
  })
})
