/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { noop } from 'lodash'
import { Formik, Form } from 'formik'

type Props = {
  initialValues: Record<string, any>
}

const ViewOnlyForm: React.FunctionComponent<Props> = ({ children, initialValues }) => (
  <Formik initialStatus={{ viewOnly: true }} onSubmit={noop} initialValues={initialValues}>
    <Form>{children}</Form>
  </Formik>
)

export default ViewOnlyForm
