import { withScalars } from 'apollo-link-scalars'
import { buildClientSchema } from 'graphql'
import { DateResolver, DateTimeResolver } from 'graphql-scalars'
import schema from './schema.generated.json'

export default function customScalarsLink() {
  return withScalars({
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    schema: buildClientSchema(schema as any),
    typesMap: {
      Date: DateResolver,
      DateTime: DateTimeResolver,
    },
  })
}
