/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

import { CommunityPostItemFragmentDoc } from '../communityPostFragment.generated'

const defaultOptions = {} as const
export type GetCommunityPostQueryVariables = Types.Exact<{
  postID: Types.Scalars['ID']['input']
}>

export type GetCommunityPostQuery = {
  __typename?: 'Query'
  communityPost: {
    __typename: 'CommunityPostQuestion'
    postID: string
    title: string
    label: string
    content?: string | null
    community: { __typename?: 'Community'; name: string }
    communityPostReplies: {
      __typename?: 'CommunityPostReplies'
      id: string
      replyingStatus: string
      numberOfPatientsReplied: number
    }
  }
}

export const GetCommunityPostDocument = gql`
  query getCommunityPost($postID: ID!) {
    communityPost(postID: $postID) {
      ...CommunityPostItem
    }
  }
  ${CommunityPostItemFragmentDoc}
`

/**
 * __useGetCommunityPostQuery__
 *
 * To run a query within a React component, call `useGetCommunityPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityPostQuery({
 *   variables: {
 *      postID: // value for 'postID'
 *   },
 * });
 */
export function useGetCommunityPostQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityPostQuery, GetCommunityPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommunityPostQuery, GetCommunityPostQueryVariables>(GetCommunityPostDocument, options)
}
export function useGetCommunityPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityPostQuery, GetCommunityPostQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCommunityPostQuery, GetCommunityPostQueryVariables>(GetCommunityPostDocument, options)
}
export type GetCommunityPostQueryHookResult = ReturnType<typeof useGetCommunityPostQuery>
export type GetCommunityPostLazyQueryHookResult = ReturnType<typeof useGetCommunityPostLazyQuery>
export type GetCommunityPostQueryResult = Apollo.QueryResult<GetCommunityPostQuery, GetCommunityPostQueryVariables>
