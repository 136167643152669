/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

import { EnrollmentFragmentDoc } from '../participant/patientQueries.generated'

const defaultOptions = {} as const
export type PatientSearchFieldsFragment = {
  __typename?: 'Patient'
  uid: string
  details?: Types.JSONObject | null
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  phone?: string | null
  intakeCallTime?: Date | null
  careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
  indicators?: { __typename?: 'PatientIndicators'; financialEligibilityConfirmed?: boolean | null } | null
  enrollment: {
    __typename?: 'Enrollment'
    id: string
    state: Types.EnrollmentState
    programID: string
    programStartDate?: Date | null
    lastStateTransitionAt: Date
  }
}

export type SearchPatientsV2QueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']['input']>
  coachIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>
  states?: Types.InputMaybe<Array<Types.EnrollmentState> | Types.EnrollmentState>
  sources?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>
  statesOfResidence?: Types.InputMaybe<Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input']>
  page?: Types.InputMaybe<Types.Scalars['Int']['input']>
  pageSize?: Types.InputMaybe<Types.Scalars['Int']['input']>
  sortBy?: Types.InputMaybe<Types.PatientSortField>
  sortOrder?: Types.InputMaybe<Types.PatientSortOrder>
  weeks?: Types.InputMaybe<Array<Types.PatientProgramWeek> | Types.PatientProgramWeek>
  tagIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>
  eligibilityUnconfirmed?: Types.InputMaybe<Types.Scalars['Boolean']['input']>
}>

export type SearchPatientsV2Query = {
  __typename?: 'Query'
  searchPatientsV2: {
    __typename?: 'SearchPatientsResponse'
    patientCount: number
    patients: Array<{
      __typename?: 'Patient'
      uid: string
      details?: Types.JSONObject | null
      email?: string | null
      firstname?: string | null
      lastname?: string | null
      phone?: string | null
      intakeCallTime?: Date | null
      careTeam?: Array<{ __typename?: 'CareTeamMember'; coachID: string; isPrimary: boolean }> | null
      indicators?: { __typename?: 'PatientIndicators'; financialEligibilityConfirmed?: boolean | null } | null
      enrollment: {
        __typename?: 'Enrollment'
        id: string
        state: Types.EnrollmentState
        programID: string
        programStartDate?: Date | null
        lastStateTransitionAt: Date
      }
    }>
  }
}

export const PatientSearchFieldsFragmentDoc = gql`
  fragment PatientSearchFields on Patient {
    uid
    details
    email
    firstname
    lastname
    phone
    intakeCallTime
    careTeam {
      coachID
      isPrimary
    }
    indicators {
      financialEligibilityConfirmed
    }
    enrollment {
      ...Enrollment
    }
  }
  ${EnrollmentFragmentDoc}
`
export const SearchPatientsV2Document = gql`
  query searchPatientsV2(
    $query: String
    $coachIDs: [ID!]
    $states: [EnrollmentState!]
    $sources: [String!]
    $statesOfResidence: [String!]
    $page: Int
    $pageSize: Int
    $sortBy: PatientSortField
    $sortOrder: PatientSortOrder
    $weeks: [PatientProgramWeek!]
    $tagIDs: [ID!]
    $eligibilityUnconfirmed: Boolean
  ) {
    searchPatientsV2(
      query: $query
      coachIDs: $coachIDs
      states: $states
      sources: $sources
      statesOfResidence: $statesOfResidence
      page: $page
      pageSize: $pageSize
      sortBy: $sortBy
      sortOrder: $sortOrder
      weeks: $weeks
      tagIDs: $tagIDs
      eligibilityUnconfirmed: $eligibilityUnconfirmed
    ) {
      patients {
        ...PatientSearchFields
      }
      patientCount
    }
  }
  ${PatientSearchFieldsFragmentDoc}
`

/**
 * __useSearchPatientsV2Query__
 *
 * To run a query within a React component, call `useSearchPatientsV2Query` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsV2Query({
 *   variables: {
 *      query: // value for 'query'
 *      coachIDs: // value for 'coachIDs'
 *      states: // value for 'states'
 *      sources: // value for 'sources'
 *      statesOfResidence: // value for 'statesOfResidence'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      weeks: // value for 'weeks'
 *      tagIDs: // value for 'tagIDs'
 *      eligibilityUnconfirmed: // value for 'eligibilityUnconfirmed'
 *   },
 * });
 */
export function useSearchPatientsV2Query(
  baseOptions?: Apollo.QueryHookOptions<SearchPatientsV2Query, SearchPatientsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<SearchPatientsV2Query, SearchPatientsV2QueryVariables>(SearchPatientsV2Document, options)
}
export function useSearchPatientsV2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPatientsV2Query, SearchPatientsV2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<SearchPatientsV2Query, SearchPatientsV2QueryVariables>(SearchPatientsV2Document, options)
}
export type SearchPatientsV2QueryHookResult = ReturnType<typeof useSearchPatientsV2Query>
export type SearchPatientsV2LazyQueryHookResult = ReturnType<typeof useSearchPatientsV2LazyQuery>
export type SearchPatientsV2QueryResult = Apollo.QueryResult<SearchPatientsV2Query, SearchPatientsV2QueryVariables>
