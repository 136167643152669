import { FastField, Field, Form, Formik } from 'formik'
import React from 'react'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import { CustomRadio, CustomRadioGroup, FormDatePicker } from 'src/form'
import _ from 'lodash'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import SubmitButton from './SubmitButton'

const BURNOUT_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    BURNOUT_SCORE: Yup.number().required(),
  })
  .required()

type BurnoutFormType = Yup.InferType<typeof BURNOUT_FORM_SCHEMA>

const BURNOUT_QUESTIONS = [
  'I enjoy my work. I have no symptoms of burnout.',
  'Occasionally I am under stress, and I don’t always have as much energy as I once did, but I don’t feel burned out.',
  'I am definitely burning out and have one or more symptoms of burnout, such as physical or emotional exhaustion.',
  'The symptoms of burnout that I’m experiencing won’t go away. I think about frustration at work a lot.',
  'I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.',
]

const BurnoutForm = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<BurnoutFormType>
      validationSchema={BURNOUT_FORM_SCHEMA}
      initialValues={BURNOUT_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.Burnout,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />
          <FastField component={CustomRadioGroup} name="BURNOUT_SCORE" type="number">
            {BURNOUT_QUESTIONS.map((question, i) => (
              <CustomRadio key={question} label={question} value={i + 1} />
            ))}
          </FastField>
          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default BurnoutForm
