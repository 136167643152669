import React from 'react'
import { Box, Container, Paper } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ClearIcon from '@mui/icons-material/Clear'
import { LongHumanReadableTime } from 'src/date'
import { Header } from 'src/designSystem'
import { PatientResponse } from 'src/graphql.generated'

type Props = {
  response: Pick<PatientResponse, 'title' | 'category' | 'createdAt'>
  onRemoveResponse?: () => void
}

const useStyles = makeStyles((theme) => ({
  previewContainer: {
    padding: theme.spacing(1),
  },
  clearIcon: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(3),
    cursor: 'pointer',
  },
}))

const ResponsePreview: React.FunctionComponent<Props> = ({ response, onRemoveResponse }) => {
  const { title, category, createdAt } = response
  const classes = useStyles()

  return (
    <Container classes={{ root: classes.previewContainer }}>
      <Box display="flex" alignItems="center">
        <Paper variant="outlined">
          <Header
            title={title ?? undefined}
            iconCategory={category}
            summary={<LongHumanReadableTime value={createdAt} />}
          />
        </Paper>
        {onRemoveResponse && (
          <ClearIcon aria-label="remove-response" onClick={onRemoveResponse} classes={{ root: classes.clearIcon }} />
        )}
      </Box>
    </Container>
  )
}

export default ResponsePreview
