import React, { useEffect, useMemo } from 'react'
import { Field, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { FormDatePicker, GraphQLForm } from 'src/form'
import { UpdateQueryOptions } from '@apollo/client'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import FinancialEligibilityHealthPlanFields, {
  FINANCIAL_ELIGIBILITY_HEALTH_PLAN_FORM_SCHEMA,
} from 'src/participantHistory/financialEligibility/FinancialEligibilityHealthPlanFields'
import IntakeNoteFields from 'src/participantHistory/intakeNote/IntakeNoteFields'
import { PatientHistoryFormType } from 'src/participantHistory/constants'
import { INTAKE_NOTE_SCHEMA } from 'src/participantHistory/intakeNote/intakeNoteSchemas'
import { DateTime } from 'luxon'
import {
  clearFormFromLocalStorage,
  getFormFromLocalStorage,
  saveFormToLocalStorage,
} from 'src/participantHistoryForm/form/persistFormHelpers'
import ParticipantHistoryFormActions from './ParticipantHistoryFormActions'
import { PatientHistorySchema, PatientHistoryEntry } from './types'

const createPatientHistorySchema = (uid: string, formType: PatientHistoryFormType): PatientHistorySchema => {
  switch (formType) {
    case 'INTAKE_NOTE': {
      return Yup.object<PatientHistoryEntry>({
        uid: Yup.string().required().default(uid),
        date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Yup.string().default(formType).required() as Yup.MixedSchema<any>,
        value: INTAKE_NOTE_SCHEMA.default({}).required(),
      }).required()
    }
    case 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN':
      return Yup.object<PatientHistoryEntry>({
        uid: Yup.string().required().default(uid),
        date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        type: Yup.string().default(formType).required() as Yup.MixedSchema<any>,
        value: FINANCIAL_ELIGIBILITY_HEALTH_PLAN_FORM_SCHEMA.default({}).required(),
      }).required()
    default:
      throw new Error('unknown form type')
  }
}

type Props = {
  uid: string
  formType: PatientHistoryFormType
  afterSubmittedCallback: () => void
}

const ParticipantHistoryForm = ({ uid, formType, afterSubmittedCallback }: Props) => {
  const validationSchema = createPatientHistorySchema(uid, formType)

  const addPatientHistoryEntry = useAddPatientHistoryEntry()

  const onSubmit = async (options: UpdateQueryOptions<PatientHistoryEntry>) => {
    await addPatientHistoryEntry(options.variables!)
    clearFormFromLocalStorage(uid, formType)
    afterSubmittedCallback()
  }

  const localStorageFormValues = useMemo(() => getFormFromLocalStorage(uid, formType), [uid, formType])

  return (
    <GraphQLForm
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={localStorageFormValues}
      render={() => <Form uid={uid} formType={formType} />}
    />
  )
}

const Form = ({ uid, formType }: { uid: string; formType: PatientHistoryFormType }) => {
  const { values, isSubmitting } = useFormikContext<PatientHistoryEntry>()

  useEffect(() => {
    saveFormToLocalStorage(uid, formType, values)
  }, [uid, values, formType])

  return (
    <>
      <Field component={FormDatePicker} label="Date of event" name="date" />
      {formType === 'FINANCIAL_ELIGIBILITY_HEALTH_PLAN' && <FinancialEligibilityHealthPlanFields uid={uid} />}
      {formType === 'INTAKE_NOTE' && <IntakeNoteFields uid={uid} />}
      <ParticipantHistoryFormActions historyType={formType} isSubmitting={isSubmitting} />
    </>
  )
}

export default ParticipantHistoryForm
