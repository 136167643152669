import { Button, ButtonGroup, ClickAwayListener, MenuList, Paper, Popper } from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import React, { useContext, useRef, useState } from 'react'
import { EditMessageContext } from 'src/feed/EditChatMessage/EditMessageProvider'
import { MessageType } from '../useChatDisplayRules'

interface Props {
  disabled: boolean
  onSubmit: (messageType: MessageType) => void
}

const SubmitButtonGroup: React.FC<Props> = ({ disabled, onSubmit }) => {
  const { messageToEdit } = useContext(EditMessageContext)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLDivElement>(null)
  const handleClose = () => {
    setOpen(false)
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  return (
    <>
      <ButtonGroup variant="contained" color="primary" ref={anchorRef}>
        <Button type="submit" style={{ borderColor: 'white' }} disabled={disabled}>
          {messageToEdit ? 'Edit' : 'Send'}
        </Button>
        {!messageToEdit && (
          <Button color="primary" size="small" onClick={handleToggle} disabled={disabled}>
            <ArrowDropDown titleAccess="select-sending-method" />
          </Button>
        )}
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-start">
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList>
              <Button
                color="inherit"
                type="button"
                onClick={() => {
                  onSubmit(MessageType.SMS)
                  setOpen(false)
                }}
                disabled={disabled}
              >
                Send SMS
              </Button>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}

export default SubmitButtonGroup
