import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'
import messageBubbleTail from './messageBubbleTail.svg'

type MessageIndicatorProps = {
  count: number
}

const MessageIndicator = ({ count }: MessageIndicatorProps) => {
  const styles = useMessageIndicatorStyles({ count })

  if (count === 0) {
    return null
  }

  return (
    <Box className={styles.container}>
      <Typography variant="caption" className={styles.text}>
        {count}
      </Typography>
    </Box>
  )
}

const BUBBLE_BODY_SIZE = 20
const BUBBLE_TIP_PADDING = 4
const BUBBLE_PADDING = 6

const useMessageIndicatorStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    background: `url("${messageBubbleTail}") top left no-repeat`,
    height: BUBBLE_BODY_SIZE,
    paddingLeft: BUBBLE_TIP_PADDING,
  },
  text: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: BUBBLE_BODY_SIZE / 2,
    fontWeight: theme.typography.fontWeightBold as number,
    padding: `0 ${BUBBLE_PADDING}px`,
  },
}))

export default MessageIndicator
