import { TypePolicies } from '@apollo/client'

export const patientTypePolicies: TypePolicies = {
  Patient: {
    keyFields: (patient) => `Patient:${patient.uid}`,
    fields: {
      indicators: {
        merge(existing, incoming) {
          return { ...existing, ...incoming }
        },
      },
    },
  },
  PatientHistory: {
    keyFields: ['entryID'],
  },
  CommonPatientHistory: {
    keyFields: ['entryID'],
  },
  VideoCallPatientHistory: {
    keyFields: ['entryID'],
  },
}
