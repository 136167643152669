import React from 'react'
import { Field } from 'formik'
import * as Yup from 'yup'

import { CustomTextField } from 'src/form'

export const NOTE_SCHEMA = Yup.object()
  .shape({
    description: Yup.string().required(),
  })
  .required()

export type NoteSchema = Yup.InferType<typeof NOTE_SCHEMA>

const NoteFields = () => (
  <Field component={CustomTextField} label="Description" fullWidth multiline name="value.description" minRows={4} />
)

export default NoteFields
