import React, { Children } from 'react'
import PropTypes from 'prop-types'

const CommaSeparatedList = ({ children }) =>
  Children.map(children, (child, i) => (
    <>
      {i > 0 && ', '}
      {child}
    </>
  ))

CommaSeparatedList.defaultProps = {
  children: null,
}

CommaSeparatedList.propTypes = {
  children: PropTypes.node,
}

export default CommaSeparatedList
