/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql.generated'

const defaultOptions = {} as const
export type CancelInProgramCallByCoachMutationVariables = Types.Exact<{
  zoomMeetingID: Types.Scalars['String']['input']
}>

export type CancelInProgramCallByCoachMutation = { __typename?: 'Mutation'; cancelVideoCallByCoach?: null | null }

export type RescheduleInProgramCallByCoachMutationVariables = Types.Exact<{
  callID: Types.Scalars['String']['input']
  start: Types.Scalars['DateTime']['input']
}>

export type RescheduleInProgramCallByCoachMutation = {
  __typename?: 'Mutation'
  rescheduleInProgramCallByCoach?: null | null
}

export const CancelInProgramCallByCoachDocument = gql`
  mutation cancelInProgramCallByCoach($zoomMeetingID: String!) {
    cancelVideoCallByCoach(zoomMeetingID: $zoomMeetingID)
  }
`
export type CancelInProgramCallByCoachMutationFn = Apollo.MutationFunction<
  CancelInProgramCallByCoachMutation,
  CancelInProgramCallByCoachMutationVariables
>

/**
 * __useCancelInProgramCallByCoachMutation__
 *
 * To run a mutation, you first call `useCancelInProgramCallByCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelInProgramCallByCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelInProgramCallByCoachMutation, { data, loading, error }] = useCancelInProgramCallByCoachMutation({
 *   variables: {
 *      zoomMeetingID: // value for 'zoomMeetingID'
 *   },
 * });
 */
export function useCancelInProgramCallByCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelInProgramCallByCoachMutation,
    CancelInProgramCallByCoachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CancelInProgramCallByCoachMutation, CancelInProgramCallByCoachMutationVariables>(
    CancelInProgramCallByCoachDocument,
    options
  )
}
export type CancelInProgramCallByCoachMutationHookResult = ReturnType<typeof useCancelInProgramCallByCoachMutation>
export type CancelInProgramCallByCoachMutationResult = Apollo.MutationResult<CancelInProgramCallByCoachMutation>
export type CancelInProgramCallByCoachMutationOptions = Apollo.BaseMutationOptions<
  CancelInProgramCallByCoachMutation,
  CancelInProgramCallByCoachMutationVariables
>
export const RescheduleInProgramCallByCoachDocument = gql`
  mutation rescheduleInProgramCallByCoach($callID: String!, $start: DateTime!) {
    rescheduleInProgramCallByCoach(callID: $callID, start: $start)
  }
`
export type RescheduleInProgramCallByCoachMutationFn = Apollo.MutationFunction<
  RescheduleInProgramCallByCoachMutation,
  RescheduleInProgramCallByCoachMutationVariables
>

/**
 * __useRescheduleInProgramCallByCoachMutation__
 *
 * To run a mutation, you first call `useRescheduleInProgramCallByCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRescheduleInProgramCallByCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rescheduleInProgramCallByCoachMutation, { data, loading, error }] = useRescheduleInProgramCallByCoachMutation({
 *   variables: {
 *      callID: // value for 'callID'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useRescheduleInProgramCallByCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RescheduleInProgramCallByCoachMutation,
    RescheduleInProgramCallByCoachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RescheduleInProgramCallByCoachMutation, RescheduleInProgramCallByCoachMutationVariables>(
    RescheduleInProgramCallByCoachDocument,
    options
  )
}
export type RescheduleInProgramCallByCoachMutationHookResult = ReturnType<
  typeof useRescheduleInProgramCallByCoachMutation
>
export type RescheduleInProgramCallByCoachMutationResult = Apollo.MutationResult<RescheduleInProgramCallByCoachMutation>
export type RescheduleInProgramCallByCoachMutationOptions = Apollo.BaseMutationOptions<
  RescheduleInProgramCallByCoachMutation,
  RescheduleInProgramCallByCoachMutationVariables
>
