import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'

const Warning = ({ children }) => (
  <Typography color="error" component="div" variant="inherit">
    {children}
  </Typography>
)

Warning.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Warning
