import React, { useCallback, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { FullScreenLoader } from 'src/designSystem'
import { useLogin } from '../useSessionQueries'
import GoogleSignIn from './GoogleSignIn'

export default function Login() {
  const login = useLogin()
  const { addToast } = useToasts()
  const [loader, setLoader] = useState(false)

  const onSuccessCallback = useCallback(
    async (idToken) => {
      try {
        setLoader(true)
        const user = await login(idToken)
        if (!user) {
          addToast('Login failed! Please try again.')
          setLoader(false)
        }
      } catch (error) {
        addToast('Something went wrong! Please try again.')
        setLoader(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login]
  )

  if (loader) {
    return <FullScreenLoader />
  }
  return <GoogleSignIn onSuccess={onSuccessCallback} />
}
