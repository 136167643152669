import { Box, CardContent, Chip, Divider } from '@mui/material'
import { pipe, find, get, isEmpty } from 'lodash/fp'
import React from 'react'
import { CardContents } from 'src/legacy/components/mui'
import { TeamMeetingIcon } from 'src/legacy/components/Icon'
import { FeedPatientResponseItemFragment } from '../feedQueries.generated'
import { getHealthQuestionnaireScores, Score } from './helpers/hqScoresHelpers'
import { SectionTitle } from '../FeedComponents'
import SectionEntry from './SectionEntry/SectionEntry'

type SectionsProps = {
  response: FeedPatientResponseItemFragment
}

export default function Sections({ response }: SectionsProps) {
  const sections = response.sections.filter((section) => !isEmpty(section.entries))
  const healthQuestionnaireScores = getHealthQuestionnaireScores(response)
  return (
    <>
      {sections.length > 0 && <Divider variant="middle" />}
      <CardContents>
        {sections.map((section) => {
          const { title, type } = section
          const score =
            !healthQuestionnaireScores || !type ? undefined : getSectionScore(type, healthQuestionnaireScores)
          const sectionFormat = getSectionFormat(type)

          return (
            <CardContent key={section.sectionID}>
              {title && <SectionTitle content={title} score={score} />}
              {section.entries.map((entry, index) => (
                <SectionEntry
                  key={entry.entryID}
                  entry={entry}
                  format={sectionFormat}
                  isCompleted={response.isCompleted}
                  programID={response.programID}
                  questionNumber={index}
                />
              ))}
              {section.isSharedWithGroup && <SharedWithGroup />}
            </CardContent>
          )
        })}
      </CardContents>
    </>
  )
}

const SharedWithGroup = () => (
  <Box mt={1.5}>
    <Chip icon={<TeamMeetingIcon />} label="Shared in Group" size="small" />
  </Box>
)

const getSectionScore = (type: string, scores: Score[]): number | undefined =>
  pipe(find({ type }), get('value'))(scores)

const getSectionFormat = (type?: string | null): string => {
  if (!type) {
    return 'default'
  }

  const singleChoiceTags = [
    'BURNOUT_QUESTIONNAIRE',
    'QUIZ_SINGLE_CHOICE',
    'CARD:QUESTION_SINGLE_CHOICE',
    'CARD:BUTTONS_LIST',
  ]
  const multipleChoiceTags = [
    'THINKING_TRAP_SERIES',
    'QUIZ_MULTIPLE_CHOICE',
    'CARD:QUESTION_MULTIPLE_CHOICE',
    'CARD:QUESTIONS_WITH_CHECKBOX',
  ]
  const healthQuestionnaireTags = ['PHQ-9_QUESTIONNAIRE', 'GAD-7_QUESTIONNAIRE']
  if (healthQuestionnaireTags.includes(type)) {
    return 'healthQuestionnaire'
  }
  if (singleChoiceTags.includes(type)) {
    return 'singleChoice'
  }
  if (multipleChoiceTags.includes(type)) {
    return 'multipleChoice'
  }
  return 'default'
}
