import React from 'react'
import { Box, Typography, Button, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useGetCommunityPostQuery } from './communityPostQuery.generated'
import CommunityPostReplies from '../communityPostReplies/CommunityPostReplies'

type Props = {
  postID: string
}

export default function CommunityPostView({ postID }: Props) {
  const styles = useStyles()
  const { data, loading, refetch } = useGetCommunityPostQuery({ variables: { postID }, fetchPolicy: 'cache-first' })
  if (loading) {
    return (
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }
  if (!data) {
    return <CouldNotLoad onRetry={() => refetch()} />
  }

  const { title, content, communityPostReplies } = data.communityPost
  const { replyingStatus, numberOfPatientsReplied } = communityPostReplies

  return (
    <Box px={5} py={3} className={styles.communityContainer}>
      <Box maxWidth="800px" className={styles.contentContainer}>
        <Typography variant="caption">{replyingStatus}</Typography>
        <Typography variant="h1">{title}</Typography>
        <Box py={2} my={1} className={styles.content}>
          <Typography variant="body1">{content}</Typography>
        </Box>
        <Typography variant="caption">{numberOfPatientsReplied} participants responded</Typography>
        <CommunityPostReplies postID={postID} />
      </Box>
    </Box>
  )
}

function CouldNotLoad({ onRetry }: { onRetry: () => unknown }) {
  return (
    <Box m={3} height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h1">Could not load community post</Typography>
      <Box m={2}>
        <Button color="primary" onClick={onRetry}>
          Retry
        </Button>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  communityContainer: {
    height: '100%',
    background: theme.palette.background.paper,
    overflow: 'auto',
  },
  content: {
    borderBottom: `1px solid ${theme.palette.lightGrey}`,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
  },
}))
