import React from 'react'
import { Box } from '@mui/material'
import { Route, Switch, match as Match } from 'react-router-dom'

import { BottomNavigationLinks } from 'src/legacy/components/mui'
import { ConversationChatIcon, EnvelopeIcon, MedicalNotesIcon } from 'src/legacy/components/Icon'

import { ParticipantFeedCombined } from 'src/feed'
import ParticipantRecords from 'src/legacy/components/ParticipantRecords'
import { VideoCallBookingHeader } from 'src/videoCall'
import ParticipantEmails from '../components/ParticipantEmails/ParticipantEmails'

export type Props = {
  match: Match<{ uid: string }>
  uid: string
}

const BOTTOM_NAVIGATION_HEIGHT = 56

const ContentContainer: React.FunctionComponent<{ uid: string }> = ({ children, uid }) => (
  <Box height="100%" pb={`${BOTTOM_NAVIGATION_HEIGHT}px`}>
    {children}
    <BottomNavigationLinks
      actions={[
        {
          icon: <ConversationChatIcon />,
          label: 'Feed',
          to: `/participant/${uid}`,
        },
        {
          icon: <MedicalNotesIcon />,
          label: 'Records',
          to: `/participant/${uid}/records`,
        },
        {
          icon: <EnvelopeIcon />,
          label: 'Emails',
          to: `/participant/${uid}/emails`,
        },
      ]}
    />
  </Box>
)

const Participant: React.FunctionComponent<Props> = ({ match, uid }) => {
  const recordsPath = `${match.url}/records`
  const feedPath = match.url

  return (
    <ContentContainer uid={uid}>
      <Box display="flex" flexDirection="column" height="100%">
        <Route path={[recordsPath, feedPath]} exact>
          <Box flexGrow={0} zIndex={1}>
            <VideoCallBookingHeader uid={uid} />
          </Box>
        </Route>

        <Box flexGrow={1} position="relative">
          <Switch>
            <Route path={`${match.url}/emails`}>
              <ParticipantEmails uid={uid} />
            </Route>
            <Route path={recordsPath}>
              <ParticipantRecords uid={uid} />
            </Route>
            <Route exact path={feedPath}>
              <ParticipantFeedCombined feedID={uid} />
            </Route>
          </Switch>
        </Box>
      </Box>
    </ContentContainer>
  )
}

export default Participant
