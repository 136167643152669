import React, { useEffect, useRef } from 'react'
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Redirect } from 'react-router'
import { useToasts } from 'react-toast-notifications'

import { FormatDateTime } from 'src/date'
import { CustomTooltip, Loader } from 'src/designSystem'
import OnlineStatus from 'src/legacy/components/OnlineStatus/OnlineStatus'
import ParticipantHistoryScoreGraph from 'src/legacy/components/ParticipantHistoryScore/ParticipantHistoryScoreGraph.container'
import PracticesPerWeek from 'src/legacy/components/PracticesPerWeek'
import ParticipantState from 'src/legacy/components/ParticipantState'
import { DailyTasks } from 'src/participant'
import QuickNote from 'src/participant/QuickNote'
import ParticipantActions from 'src/participant/ParticipantActions'
import WeekInfo from 'src/participant/ParticipantInfo/WeekInfo'
import useLoadParticipantInfoData from 'src/participant/ParticipantInfo/useLoadParticipantInfoData'
import ParticipantTags from 'src/participant/ParticipantInfo/ParticipantTags'
import ParticipantSubtitleDetails from 'src/participant/ParticipantInfo/ParticipantSubtitleDetails'
import TechnicalDeviceDetails from './TechnicalDeviceDetails'
import CareTeam from '../CareTeam/CareTeam'

type Props = {
  uid: string
}

const COACHING_PROGRAM_IDS = ['coaching', 'coaching_v2']

const useStyles = makeStyles({
  container: {
    height: '100%',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  preferredName: {
    fontStyle: 'italic',
  },
})

const useHasBeenLoaded = (loading: boolean) => {
  const hasBeenLoadedRef = useRef<boolean>()

  useEffect(() => {
    if (!loading && hasBeenLoadedRef.current === undefined) {
      hasBeenLoadedRef.current = true
    }
  }, [loading])

  return hasBeenLoadedRef.current ?? false
}

const ParticipantInfo: React.FunctionComponent<Props> = (props) => {
  const { uid } = props

  const {
    acceptanceCriteria,
    accessCode,
    careTeam,
    createdAt,
    firstname,
    firstTimeLoginAt,
    isHrvProgram,
    lastname,
    lastWeekNbr,
    preferredName,
    programID,
    programStartDate,
    programEndMetrics,
    state,
    timezone,
    indicators: { completedResponses, engagement },
    loading,
    stateOfResidence,
    birthDate,
    hrvDevice,
    error,
  } = useLoadParticipantInfoData(uid)

  const classes = useStyles()

  const hasBeenLoaded = useHasBeenLoaded(loading)

  const { addToast } = useToasts()

  useEffect(() => {
    if (error) {
      addToast(
        "Failed to retrieve the participant's information. Make sure that you have the required access for this participant",
        {
          appearance: 'error',
        }
      )
    }
  }, [error, addToast])

  if (error) {
    return <Redirect to="/" />
  }

  // !hasBeenLoaded check is to prevent flickering when loading state changes but there's previous data available
  if (loading && !hasBeenLoaded) {
    return <Loader size="large" />
  }

  return (
    <Box className={classes.container} px={3} py={2}>
      <Box>
        <Box display="flex" alignItems="center">
          <Typography noWrap variant="h3">
            {firstname} {lastname}
          </Typography>
          {preferredName && (
            <CustomTooltip title="Preferred name">
              <Typography noWrap variant="h6" ml={1} className={classes.preferredName}>
                ({preferredName})
              </Typography>
            </CustomTooltip>
          )}
        </Box>
        <ParticipantSubtitleDetails timezone={timezone} birthDate={birthDate} stateOfResidence={stateOfResidence} />
        <Typography noWrap variant="caption">
          <OnlineStatus uid={uid} />
        </Typography>
      </Box>

      <Box mt={2}>
        <ParticipantTags uid={uid} />
      </Box>

      {state && (
        <ParticipantActions
          uid={uid}
          engagement={engagement}
          acceptanceCriteria={acceptanceCriteria}
          isHrvProgram={isHrvProgram}
          lastWeekNbr={lastWeekNbr}
          programStartDate={programStartDate}
          programEndMetrics={programEndMetrics}
          state={state}
        />
      )}

      <Box mt={2} position="relative">
        <QuickNote uid={uid} />
      </Box>

      {programStartDate && programID && (
        <Box mt={2}>
          <WeekInfo programStartDate={programStartDate} programID={programID} />
        </Box>
      )}

      {programID && <DailyTasks uid={uid} programID={programID} />}

      <Box mt={2}>
        <Typography color="textSecondary" gutterBottom variant="h6">
          Practices per week
        </Typography>
        <PracticesPerWeek
          programStartDate={programStartDate!}
          completedResponses={completedResponses}
          lastWeekNbr={lastWeekNbr}
        />
      </Box>

      {(!programID || !COACHING_PROGRAM_IDS.includes(programID)) && (
        <>
          <Box mt={2}>
            <ParticipantHistoryScoreGraph uid={uid} type="PHQ9" programStartDate={programStartDate} />
          </Box>

          <Box mt={2}>
            <ParticipantHistoryScoreGraph uid={uid} type="GAD7" programStartDate={programStartDate} />
          </Box>
        </>
      )}

      <Box mt={2}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Careteam</TableCell>
              <TableCell>
                <CareTeam careTeam={careTeam} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>{state && <ParticipantState state={state} />}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Program ID</TableCell>
              <TableCell>{programID}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Access Code</TableCell>
              <TableCell>{accessCode}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Created At</TableCell>
              <TableCell>
                <FormatDateTime value={createdAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>First Login</TableCell>
              <TableCell>
                <FormatDateTime value={firstTimeLoginAt} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Timezone</TableCell>
              <TableCell>{timezone}</TableCell>
            </TableRow>
            <TechnicalDeviceDetails uid={uid} />
            {hrvDevice && (
              <TableRow>
                <TableCell>HRV Device</TableCell>
                <TableCell>{hrvDevice}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}

export default ParticipantInfo
