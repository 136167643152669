import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

// Update hash ('sha256-kAjuT6e2pQ3XWXPYX/r1R9phTrkxeSoHvTY8T77BZ6Y=') in CSP (firebase.json)
// if you make ANY changes to the following script (even cosmetic changes count).
// ID is stored in data-id to ensure the hash stays the same.
const generateHeightMessenger = (id) => `
  <script id="height-messenger" data-id="${id}">
    window.onload = function () {
      window.parent.postMessage({
        id: document.getElementById('height-messenger').dataset['id'],
        height: document.body.scrollHeight
      }, '*');
    }
  </script>
`

const HeightAwareIframe = ({ html }) => {
  const [height, setHeight] = useState(500)
  const idRef = useRef(_.uniqueId('Iframe'))
  useEffect(() => {
    const onMessage = (event) => {
      if (event.data && event.data.id === idRef.current) {
        setHeight(event.data.height)
      }
    }
    window.addEventListener('message', onMessage, false)
    return () => window.removeEventListener('message', onMessage, false)
  }, [])
  const src = `data:text/html;charset=utf-8,${encodeURIComponent(html + generateHeightMessenger(idRef.current))}`
  const style = { border: 0, height, width: '100%' }
  return <iframe src={src} style={style} />
}

HeightAwareIframe.propTypes = {
  html: PropTypes.string.isRequired,
}

export default HeightAwareIframe
