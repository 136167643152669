const parseNumber = (value: string) => {
  const valueAsNumber = Number(value)

  return !Number.isNaN(valueAsNumber) ? valueAsNumber : null
}

const parseCostEstimateString = (costEstimateString: string) => {
  const parts = costEstimateString.split(';')

  if (parts.length !== 4) {
    return null
  }

  const [intake, month1, month2, month3] = parts

  return {
    intakeCostEstimate: parseNumber(intake),
    month1CostEstimate: parseNumber(month1),
    month2CostEstimate: parseNumber(month2),
    month3CostEstimate: parseNumber(month3),
  }
}

const readClipboard = async () => {
  const permission = await navigator.permissions.query({
    name: 'clipboard-read' as PermissionName,
  })

  if (permission.state === 'denied') {
    return null
  }

  return navigator.clipboard.readText()
}

const getCostEstimateValuesFromClipboard = async () => {
  try {
    const clipboardContents = await readClipboard()

    if (!clipboardContents) {
      return null
    }

    return parseCostEstimateString(clipboardContents)
  } catch (error) {
    return null
  }
}

export default getCostEstimateValuesFromClipboard
