/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../graphql.generated'

import { EnrollmentFragmentDoc } from '../participant/patientQueries.generated'

const defaultOptions = {} as const
export type HomeViewParticipantFragment = {
  __typename?: 'Patient'
  uid: string
  email?: string | null
  firstname?: string | null
  lastname?: string | null
  intakeCallTime?: Date | null
  indicators?: {
    __typename?: 'PatientIndicators'
    suicidal?: boolean | null
    newMessages?: { __typename?: 'PatientNewMessageIndicator'; count: number } | null
    completedResponses?: Array<{ __typename?: 'PatientCompletedResponsesIndicator'; date: Date; count: number }> | null
  } | null
  enrollment: {
    __typename?: 'Enrollment'
    id: string
    state: Types.EnrollmentState
    programID: string
    programStartDate?: Date | null
    lastStateTransitionAt: Date
  }
  tags?: Array<{ __typename?: 'PatientTag'; name: string }> | null
}

export type FetchParticipantsForHomeViewQueryVariables = Types.Exact<{
  query?: Types.InputMaybe<Types.Scalars['String']['input']>
  coachIDs?: Types.InputMaybe<Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']>
  states?: Types.InputMaybe<Array<Types.EnrollmentState> | Types.EnrollmentState>
  responsesCountStart: Types.Scalars['Date']['input']
  responsesCountEnd: Types.Scalars['Date']['input']
}>

export type FetchParticipantsForHomeViewQuery = {
  __typename?: 'Query'
  searchPatientsV2: {
    __typename?: 'SearchPatientsResponse'
    patients: Array<{
      __typename?: 'Patient'
      uid: string
      email?: string | null
      firstname?: string | null
      lastname?: string | null
      intakeCallTime?: Date | null
      indicators?: {
        __typename?: 'PatientIndicators'
        suicidal?: boolean | null
        newMessages?: { __typename?: 'PatientNewMessageIndicator'; count: number } | null
        completedResponses?: Array<{
          __typename?: 'PatientCompletedResponsesIndicator'
          date: Date
          count: number
        }> | null
      } | null
      enrollment: {
        __typename?: 'Enrollment'
        id: string
        state: Types.EnrollmentState
        programID: string
        programStartDate?: Date | null
        lastStateTransitionAt: Date
      }
      tags?: Array<{ __typename?: 'PatientTag'; name: string }> | null
    }>
  }
}

export const HomeViewParticipantFragmentDoc = gql`
  fragment HomeViewParticipant on Patient {
    uid
    email
    firstname
    lastname
    indicators {
      newMessages {
        count
      }
      suicidal
      completedResponses(startDate: $responsesCountStart, endDate: $responsesCountEnd) {
        date
        count
      }
    }
    intakeCallTime
    enrollment {
      ...Enrollment
    }
    tags {
      name
    }
  }
  ${EnrollmentFragmentDoc}
`
export const FetchParticipantsForHomeViewDocument = gql`
  query fetchParticipantsForHomeView(
    $query: String
    $coachIDs: [ID!]
    $states: [EnrollmentState!]
    $responsesCountStart: Date!
    $responsesCountEnd: Date!
  ) {
    searchPatientsV2(
      query: $query
      coachIDs: $coachIDs
      states: $states
      sources: []
      statesOfResidence: []
      page: 0
      pageSize: 499
      includeOnlyPrimaryPatients: false
    ) {
      patients {
        ...HomeViewParticipant
      }
    }
  }
  ${HomeViewParticipantFragmentDoc}
`

/**
 * __useFetchParticipantsForHomeViewQuery__
 *
 * To run a query within a React component, call `useFetchParticipantsForHomeViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchParticipantsForHomeViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchParticipantsForHomeViewQuery({
 *   variables: {
 *      query: // value for 'query'
 *      coachIDs: // value for 'coachIDs'
 *      states: // value for 'states'
 *      responsesCountStart: // value for 'responsesCountStart'
 *      responsesCountEnd: // value for 'responsesCountEnd'
 *   },
 * });
 */
export function useFetchParticipantsForHomeViewQuery(
  baseOptions: Apollo.QueryHookOptions<FetchParticipantsForHomeViewQuery, FetchParticipantsForHomeViewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<FetchParticipantsForHomeViewQuery, FetchParticipantsForHomeViewQueryVariables>(
    FetchParticipantsForHomeViewDocument,
    options
  )
}
export function useFetchParticipantsForHomeViewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchParticipantsForHomeViewQuery,
    FetchParticipantsForHomeViewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<FetchParticipantsForHomeViewQuery, FetchParticipantsForHomeViewQueryVariables>(
    FetchParticipantsForHomeViewDocument,
    options
  )
}
export type FetchParticipantsForHomeViewQueryHookResult = ReturnType<typeof useFetchParticipantsForHomeViewQuery>
export type FetchParticipantsForHomeViewLazyQueryHookResult = ReturnType<
  typeof useFetchParticipantsForHomeViewLazyQuery
>
export type FetchParticipantsForHomeViewQueryResult = Apollo.QueryResult<
  FetchParticipantsForHomeViewQuery,
  FetchParticipantsForHomeViewQueryVariables
>
