import { Session } from 'src/graphql.generated'

export const SET_SESSION = 'SET_SESSION'

export const setSession = (session: Session) => ({
  type: SET_SESSION,
  payload: {
    uid: session.uid,
    authRoles: {
      isAdmin: session.isAdmin ?? false,
      isCareCoordinator: session.isCareCoordinator ?? false,
      isManager: session.isManager ?? false,
      isCommunityFacilitator: session.isCommunityFacilitator ?? false,
    },
    isLoggedIn: true,
    refreshAt: session.refreshAt ?? undefined,
    validUntil: session.validUntil ?? undefined,
  },
})
