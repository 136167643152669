import { find } from 'lodash/fp'
import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { MenuItem } from '@mui/material'

import { CoachProfile } from 'src/legacy/models/firebase'
import { isSuccess, useFirebase } from 'src/firebase'
import { useSessionUID } from 'src/session'
import { getActiveCoachProfiles } from 'src/legacy/selectors/coachProfiles'
import { CustomTextField } from 'src/form'

interface CoachProfileWithCoachID extends CoachProfile {
  coachID: string
}

const CalendarOwnerEmailField = () => {
  const { setFieldValue } = useFormikContext()
  const coachID = useSessionUID()
  const request = useFirebase<CoachProfileWithCoachID[]>('coachProfiles', getActiveCoachProfiles)

  useEffect(() => {
    if (isSuccess(request)) {
      const findByCoachID = find<CoachProfileWithCoachID>({ coachID })
      const coachProfile = findByCoachID(request.data)
      if (coachProfile) {
        setFieldValue('calendarOwnerEmail', coachProfile.email)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess(request)])

  const coachProfiles = isSuccess(request) ? request.data! : []
  return (
    <Field component={CustomTextField} fullWidth label="Provider" name="calendarOwnerEmail" type="select">
      {coachProfiles.map((coachProfile) => (
        <MenuItem key={coachProfile.coachID} value={coachProfile.email}>
          {coachProfile.firstname} {coachProfile.lastname}
        </MenuItem>
      ))}
    </Field>
  )
}

export default CalendarOwnerEmailField
