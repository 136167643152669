import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { EngagementType } from 'src/graphql.generated'
import { ParticipantResultsTextRow } from './ParticipantResultsTable'

const ENGAGEMENTS = {
  COMPLETER: 'Completer',
  DROPOUT: 'Dropout',
  NO_SHOW: 'No show',
}

type Props = {
  completedIntros: number
  lastWeekNbr: number
  type: EngagementType
}

const Engagement = ({ type, completedIntros, lastWeekNbr }: Props) => {
  const { setFieldValue, values } = useFormikContext<{ engagement: EngagementType }>()

  useEffect(() => {
    setFieldValue('engagement', type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type])

  return (
    <ParticipantResultsTextRow
      label="Engagement"
      content={ENGAGEMENTS[values.engagement]}
      helperText={`${completedIntros}/${lastWeekNbr} weekly intros completed`}
    />
  )
}

export default Engagement
