import React from 'react'
import PropTypes from 'prop-types'
import { DialogTitle, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Close } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  dialogTitleRoot: {
    padding: theme.spacing(3),
  },
  iconButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}))

const ClosableDialogTitle = ({ children, onClose }) => {
  const classes = useStyles()
  return (
    <DialogTitle className={classes.dialogTitleRoot}>
      <Typography variant="h3" component="span">
        {children}
      </Typography>
      <IconButton className={classes.iconButton} onClick={onClose} size="large">
        <Close titleAccess="close" />
      </IconButton>
    </DialogTitle>
  )
}

ClosableDialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ClosableDialogTitle
