import React from 'react'
import { MomentInput } from 'moment'
import FormatDate from './FormatDate'
import FormatTime from './FormatTime'

type Props = {
  timezone?: string
  value?: MomentInput
}

const FormatDateTime: React.FunctionComponent<Props> = (props) => {
  const { timezone, value } = props
  if (!value) {
    return null
  }
  return (
    <>
      <FormatDate value={value} />
      {' at '}
      <FormatTime timezone={timezone} value={value} />
    </>
  )
}

export default FormatDateTime
