/* eslint-disable react/no-array-index-key */
import { compact } from 'lodash'
import React, { Children, FunctionComponent, ReactNode } from 'react'
import { Box } from '@mui/material'

const toArrayWithoutEmptyChildren = (children?: ReactNode) => compact(Children.toArray(children))

const Cards: FunctionComponent = (props) => {
  const { children } = props
  return (
    <>
      {toArrayWithoutEmptyChildren(children).map((child, i) => (
        <Box key={i} mt={i === 0 ? 0 : 2}>
          {child}
        </Box>
      ))}
    </>
  )
}

export default Cards
