import React from 'react'
import PropTypes from 'prop-types'
import { Chip, darken, lighten } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  error: {
    color: darken(theme.palette.error.main, 0.6),
    backgroundColor: lighten(theme.palette.error.main, 0.9),
  },
  success: {
    color: darken(theme.palette.success.main, 0.6),
    backgroundColor: lighten(theme.palette.success.main, 0.9),
  },
  warning: {
    color: darken(theme.palette.warning.main, 0.6),
    backgroundColor: lighten(theme.palette.warning.main, 0.9),
  },
}))

const ColoredChip = ({ color, ...rest }) => {
  const classes = useStyles()
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Chip className={classes[color]} {...rest} />
}

ColoredChip.defaultProps = {
  color: null,
}

ColoredChip.propTypes = {
  color: PropTypes.oneOf(['success', 'warning']),
}

export default ColoredChip
