import React from 'react'
import moment, { MomentInput } from 'moment'

const removeYear = (date: string) => date.replace(/,?\s?(19|20)\d{2}/, '')

type Props = {
  value?: MomentInput
}

const LongHumanReadableTime: React.FunctionComponent<Props> = (props) => {
  const { value } = props
  if (!value) {
    return null
  }
  const date = moment(value)
  if (moment().startOf('day').isBefore(date)) {
    return <>{date.format('[Today at] LT')}</>
  }
  if (moment().subtract(1, 'day').startOf('day').isSameOrBefore(date)) {
    return <>{date.format('[Yesterday at] LT')}</>
  }
  if (moment().subtract(6, 'day').startOf('day').isSameOrBefore(date)) {
    return <>{date.format('dddd [at] LT')}</>
  }
  return (
    <>
      {removeYear(date.format('LL'))} {date.format('[at] LT')}
    </>
  )
}

export default LongHumanReadableTime
