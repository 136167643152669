import React from 'react'
import { Typography, TypographyProps } from '@mui/material'

const getDisplayNames = (names: Array<string>, threshold: number) => {
  const namesCombined = names.reduce(
    (combinedNames, name) => {
      if (combinedNames.name === '') {
        return { ...combinedNames, name }
      }

      // If the length of the names is too long to display, use the 'others' -postfix to keep it on one line
      if (`${combinedNames.name}, ${name}`.length > threshold) {
        return { ...combinedNames, others: combinedNames.others + 1 }
      }

      return { ...combinedNames, name: `${combinedNames.name}, ${name}` }
    },
    { name: '', others: 0 }
  )

  if (namesCombined.others > 0) {
    return `${namesCombined.name} + ${namesCombined.others} other${namesCombined.others > 1 ? 's' : ''}`
  }

  return namesCombined.name
}

type Props = {
  styling?: string
  names: Array<string>
  threshold: number
  variant?: TypographyProps['variant']
}

const CombineNamesWithThreshold = ({ styling, names, threshold, variant }: Props) => (
  <Typography classes={{ root: styling }} variant={variant}>
    {getDisplayNames(names, threshold)}
  </Typography>
)

export default CombineNamesWithThreshold
