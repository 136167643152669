import React from 'react'
import moment from 'moment'
import { TextField } from '@mui/material'
import { DatePicker as MuiDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

type Props = {
  disabled: boolean
  error: boolean
  fullWidth: boolean
  helperText?: string
  label: string
  labelPosition: 'left' | 'top'
  name: string
  setValue: (name: string, value: string | null) => void
  shouldDisableDate?: (date: string) => boolean
  value: moment.Moment | null
  viewOnly: boolean
}

const DatePicker = ({
  error,
  fullWidth,
  helperText,
  label,
  name,
  value,
  setValue,
  shouldDisableDate,
  viewOnly,
}: Props) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiDatePicker
      disabled={viewOnly}
      label={label}
      value={value ?? moment(value, 'YYYY-MM-DD', true)}
      onChange={(newValue) => {
        if (newValue && moment(newValue).isValid()) {
          setValue(name, moment(newValue).format('YYYY-MM-DD'))
        } else {
          setValue(name, null)
        }
      }}
      renderInput={(props) => (
        <TextField
          {...props}
          error={error}
          fullWidth={fullWidth}
          helperText={helperText}
          margin="normal"
          name={name}
          size="small"
          variant="outlined"
        />
      )}
      shouldDisableDate={shouldDisableDate}
    />
  </LocalizationProvider>
)

export default DatePicker
