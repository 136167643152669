import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Container } from 'src/designSystem'
import CommunityPostsList from './communityPostsList/CommunityPostsList'
import CommunityPostView from './showCommunityPost/CommunityPostView'

type Props = RouteComponentProps<{ postID: string }>

export default function Communities({ match }: Props) {
  const { postID } = match.params
  return (
    <Container sidebar={<CommunityPostsList />} sidebarWidth={470}>
      {postID && <CommunityPostView postID={postID} />}
    </Container>
  )
}
