import { useCallback } from 'react'
import _ from 'lodash'
import { PatientDetailsInput, PatientInput, PrivateUserDataInput } from 'src/graphql.generated'
import { useUpdatePatientMutation } from './patientQueries.generated'

type PatientUpdateInput = PatientInput & {
  details?: PatientDetailsInput
}

export default function useUpdateParticipantData(uid: string) {
  const [updatePatient] = useUpdatePatientMutation()

  const handleUpdate = useCallback(
    (patientUpdate?: PatientUpdateInput, privateUserData?: PrivateUserDataInput) => {
      const { details, ...patient } = patientUpdate ?? {}

      return updatePatient({
        variables: {
          uid,
          patient: _.isEmpty(patient) ? undefined : patient,
          details: _.isEmpty(details) ? undefined : details,
          privateUserData,
        },
      })
    },
    [uid, updatePatient]
  )
  return handleUpdate
}
