import moment from 'moment'

const RESPONSE_DAYS_TO_DISPLAY = 5

const getDateRangeForRecentResponses = () => ({
  responsesCountStart: moment()
    .subtract(RESPONSE_DAYS_TO_DISPLAY - 1, 'days')
    .format('YYYY-MM-DD'),
  responsesCountEnd: moment().format('YYYY-MM-DD'),
})

export default getDateRangeForRecentResponses
