import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { getIn, FastField, FormikHelpers } from 'formik'
import { CustomTextField } from 'src/form'
import * as Yup from 'yup'

export const getScoreValueSchema = (scoreRange: { VALUE_MIN: number; VALUE_MAX: number }) =>
  Yup.number().required().min(scoreRange.VALUE_MIN).max(scoreRange.VALUE_MAX)

type Props<Values extends Record<string, number>> = {
  type: 'GAD7' | 'PHQ9' | 'WHO5'
  values: Values
  setFieldValue: FormikHelpers<Values>['setFieldValue']
  questions: Array<{ key: string; label: string }>
}

const ScoreFields = <Values extends Record<string, number>>({
  type,
  values,
  setFieldValue,
  questions,
}: Props<Values>) => {
  const scoreFieldName = `${type}_SCORE`
  const scoreFieldValue = getIn(values, scoreFieldName)

  const calculatedScore = useMemo(() => {
    const scoreQuestions = questions.map(({ key }) => key)
    const scoreValues = _.pick(values, scoreQuestions)

    return _.reduce(
      scoreValues,
      (acc, value, key) => {
        if (key !== scoreFieldName && typeof value === 'number') {
          return acc + value
        }
        return acc
      },
      0
    )
  }, [scoreFieldName, values, questions])

  useEffect(() => {
    if (calculatedScore !== scoreFieldValue) {
      setFieldValue(scoreFieldName, calculatedScore)
    }
  }, [calculatedScore, scoreFieldValue, scoreFieldName, setFieldValue])

  return (
    <>
      {questions.map(({ key, label }) => {
        const isDisabled = _.endsWith(key, 'SCORE')
        return (
          <FastField
            component={CustomTextField}
            disabled={isDisabled}
            label={label}
            key={key}
            labelPosition="left"
            name={key}
            type="number"
          />
        )
      })}
    </>
  )
}

export default ScoreFields
