import { pipe, get, toPairs, map, orderBy, reject } from 'lodash/fp'
import { FirebaseRequestStatus, useFirebase } from 'src/firebase'

export const getCoachProfiles = pipe(
  get(['firebase', 'data', 'coachProfiles']),
  toPairs,
  map(([id, value]) => ({ ...value, id })),
  orderBy(['firstname', 'lastname'], ['asc', 'asc'])
)

export const getActiveCoachProfiles = pipe(getCoachProfiles, reject('isSuspended'))

type Provider = {
  id: string
  firstname: string
  lastname: string
}

const EMPTY_PROVIDER_LIST: Array<Provider> = []
export default function useLoadProviders() {
  const request = useFirebase<Provider[]>('coachProfiles', getActiveCoachProfiles)

  if (request.status === FirebaseRequestStatus.SUCCESS) {
    return request.data || EMPTY_PROVIDER_LIST
  }

  return EMPTY_PROVIDER_LIST
}
