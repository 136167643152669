import React, { useEffect } from 'react'
import { useLocation } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { init, trackTabActivity, trackNavigation, trackTabClosed, trackUserActivityInTab } from '../api'
import useIsTabActive from './useIsTabActive'
import useBeforeUnload from './useBeforeUnload'
import useIsUserActiveInTab from './useIsUserActiveInTab'

type Props = {
  isLoggedIn?: boolean
  uid?: string
}

declare global {
  interface Window {
    mixpanelTabID: string
  }
}

const NavigationAndTabEvents: React.FC<Props> = (): null => {
  window.mixpanelTabID = window.mixpanelTabID || uuidv4()

  useEffect(() => {
    init(window.mixpanelTabID)
  }, [])

  const location = useLocation()
  useEffect(() => {
    const { pathname } = location
    trackNavigation(pathname)
  }, [location])

  const isTabActive = useIsTabActive()
  useEffect(() => {
    trackTabActivity(isTabActive)
  }, [isTabActive])

  useBeforeUnload(() => {
    trackTabClosed()
  })

  const userInactivityThreshold = 5 * 60 * 1000 // 5 minutes
  const isUserActiveInTab = useIsUserActiveInTab(userInactivityThreshold)
  useEffect(() => {
    trackUserActivityInTab(isUserActiveInTab)
  }, [isUserActiveInTab])

  return null
}

export default NavigationAndTabEvents
