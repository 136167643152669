import { useToasts } from 'react-toast-notifications'
import { ChatMessageLinkType } from 'src/graphql.generated'
import { useContext } from 'react'
import { EditMessageContext } from 'src/feed/EditChatMessage/EditMessageProvider'
import {
  ChatMessageLinkedResponseFragment,
  useCreateChatMessageMutation,
  useCreateSmsMessageMutation,
  useEditChatMessageMutation,
} from '../feedQueries.generated'
import { MessageType } from '../useChatDisplayRules'

const useSubmit = (messageCreatedCallback: () => void) => {
  const { stopEditingMessage } = useContext(EditMessageContext)
  const { addToast } = useToasts()
  const [createSmsMessage, { loading: isSendingSmsMessage }] = useCreateSmsMessageMutation({
    onCompleted: () => messageCreatedCallback(),
    onError(error) {
      addToast(error.message)
    },
  })
  const [createChatMessage, { loading: isSendingChatMessage }] = useCreateChatMessageMutation({
    onError() {
      addToast('Message sending failed! Please try again.')
    },
  })

  const [editChatMessage, { loading: isEditingChatMessage }] = useEditChatMessageMutation({
    onError() {
      addToast('Message editing failed! Please try again.')
    },
    onCompleted: (data) => {
      if (data.editChatMessage) {
        addToast('Message edited successfully!', { appearance: 'success', autoDismissTimeout: 1000 })
        stopEditingMessage()
      }
    },
  })

  const isSendingMessage = isSendingSmsMessage || isSendingChatMessage || isEditingChatMessage

  return {
    createMessage: (
      messageType: MessageType,
      uid: string,
      text: string,
      mentionedResponse?: ChatMessageLinkedResponseFragment
    ) =>
      messageType === MessageType.SMS
        ? createSmsMessage({
            variables: {
              uid,
              text,
            },
          })
        : createChatMessage({
            variables: {
              uid,
              text,
              linksTo: mentionedResponse
                ? { type: ChatMessageLinkType.Response, id: mentionedResponse.responseID }
                : null,
            },
          }),
    editMessage: (uid: string, messageIDHash: string, text: string) =>
      editChatMessage({
        variables: { uid, messageIDHash, text },
      }),
    isSendingMessage,
  }
}

export default useSubmit
