/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

const defaultOptions = {} as const
export type FullPatientFinancialEligibilityCheckFragment = {
  __typename?: 'PatientFinancialEligibilityCheck'
  aperoEligibilityID: string
  coverageActive: boolean
  createdAt: Date
  warnings?: Array<string> | null
  subscriber: {
    __typename?: 'PatientFinancialEligibilityCheckSubscriber'
    birthDate?: Date | null
    firstName: string
    groupDescription?: string | null
    groupNumber?: string | null
    id: string
    lastName: string
  }
}

export type FullPatientCostEstimateFailureFragment = {
  __typename?: 'PatientCostEstimateFailure'
  reason: string
  createdAt: Date
}

export type GetEligibilityInputsQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetEligibilityInputsQuery = {
  __typename?: 'Query'
  patient: {
    __typename?: 'Patient'
    birthDate?: Date | null
    uid: string
    firstname?: string | null
    lastname?: string | null
    details?: Types.JSONObject | null
    credentialingHealthPlan?: string | null
    financialEligibilityCheck?: {
      __typename?: 'PatientFinancialEligibilityCheck'
      aperoEligibilityID: string
      coverageActive: boolean
      createdAt: Date
      warnings?: Array<string> | null
      subscriber: {
        __typename?: 'PatientFinancialEligibilityCheckSubscriber'
        birthDate?: Date | null
        firstName: string
        groupDescription?: string | null
        groupNumber?: string | null
        id: string
        lastName: string
      }
    } | null
    costEstimateFailure?: { __typename?: 'PatientCostEstimateFailure'; reason: string; createdAt: Date } | null
  }
}

export type FinancialEligibilityLookupMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  birthDate?: Types.InputMaybe<Types.Scalars['String']['input']>
  firstname?: Types.InputMaybe<Types.Scalars['String']['input']>
  lastname: Types.Scalars['String']['input']
  memberID?: Types.InputMaybe<Types.Scalars['String']['input']>
  groupNumber?: Types.InputMaybe<Types.Scalars['String']['input']>
  source: Types.Scalars['String']['input']
}>

export type FinancialEligibilityLookupMutation = {
  __typename?: 'Mutation'
  financialEligibilityLookup: {
    __typename?: 'PatientFinancialEligibilityCheck'
    aperoEligibilityID: string
    coverageActive: boolean
    createdAt: Date
    warnings?: Array<string> | null
    subscriber: {
      __typename?: 'PatientFinancialEligibilityCheckSubscriber'
      birthDate?: Date | null
      firstName: string
      groupDescription?: string | null
      groupNumber?: string | null
      id: string
      lastName: string
    }
  }
}

export type CostEstimateLookupQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type CostEstimateLookupQuery = {
  __typename?: 'Query'
  costEstimateLookup:
    | { __typename: 'CostEstimateLookupFailure'; reason: string }
    | {
        __typename: 'CostEstimateLookupSuccess'
        intakeCostEstimateInCents: number
        month1CostEstimateInCents: number
        month2CostEstimateInCents: number
        month3CostEstimateInCents: number
      }
}

export const FullPatientFinancialEligibilityCheckFragmentDoc = gql`
  fragment FullPatientFinancialEligibilityCheck on PatientFinancialEligibilityCheck {
    aperoEligibilityID
    coverageActive
    createdAt
    subscriber {
      birthDate
      firstName
      groupDescription
      groupNumber
      id
      lastName
    }
    warnings
  }
`
export const FullPatientCostEstimateFailureFragmentDoc = gql`
  fragment FullPatientCostEstimateFailure on PatientCostEstimateFailure {
    reason
    createdAt
  }
`
export const GetEligibilityInputsDocument = gql`
  query getEligibilityInputs($uid: ID!) {
    patient(uid: $uid) {
      birthDate
      uid
      firstname
      lastname
      details
      credentialingHealthPlan
      financialEligibilityCheck {
        ...FullPatientFinancialEligibilityCheck
      }
      costEstimateFailure {
        ...FullPatientCostEstimateFailure
      }
    }
  }
  ${FullPatientFinancialEligibilityCheckFragmentDoc}
  ${FullPatientCostEstimateFailureFragmentDoc}
`

/**
 * __useGetEligibilityInputsQuery__
 *
 * To run a query within a React component, call `useGetEligibilityInputsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibilityInputsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibilityInputsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetEligibilityInputsQuery(
  baseOptions: Apollo.QueryHookOptions<GetEligibilityInputsQuery, GetEligibilityInputsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetEligibilityInputsQuery, GetEligibilityInputsQueryVariables>(
    GetEligibilityInputsDocument,
    options
  )
}
export function useGetEligibilityInputsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEligibilityInputsQuery, GetEligibilityInputsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetEligibilityInputsQuery, GetEligibilityInputsQueryVariables>(
    GetEligibilityInputsDocument,
    options
  )
}
export type GetEligibilityInputsQueryHookResult = ReturnType<typeof useGetEligibilityInputsQuery>
export type GetEligibilityInputsLazyQueryHookResult = ReturnType<typeof useGetEligibilityInputsLazyQuery>
export type GetEligibilityInputsQueryResult = Apollo.QueryResult<
  GetEligibilityInputsQuery,
  GetEligibilityInputsQueryVariables
>
export const FinancialEligibilityLookupDocument = gql`
  mutation financialEligibilityLookup(
    $uid: ID!
    $birthDate: String
    $firstname: String
    $lastname: String!
    $memberID: String
    $groupNumber: String
    $source: String!
  ) {
    financialEligibilityLookup(
      uid: $uid
      birthDate: $birthDate
      firstname: $firstname
      lastname: $lastname
      memberID: $memberID
      groupNumber: $groupNumber
      source: $source
    ) {
      ...FullPatientFinancialEligibilityCheck
    }
  }
  ${FullPatientFinancialEligibilityCheckFragmentDoc}
`
export type FinancialEligibilityLookupMutationFn = Apollo.MutationFunction<
  FinancialEligibilityLookupMutation,
  FinancialEligibilityLookupMutationVariables
>

/**
 * __useFinancialEligibilityLookupMutation__
 *
 * To run a mutation, you first call `useFinancialEligibilityLookupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinancialEligibilityLookupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financialEligibilityLookupMutation, { data, loading, error }] = useFinancialEligibilityLookupMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      birthDate: // value for 'birthDate'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      memberID: // value for 'memberID'
 *      groupNumber: // value for 'groupNumber'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useFinancialEligibilityLookupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinancialEligibilityLookupMutation,
    FinancialEligibilityLookupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<FinancialEligibilityLookupMutation, FinancialEligibilityLookupMutationVariables>(
    FinancialEligibilityLookupDocument,
    options
  )
}
export type FinancialEligibilityLookupMutationHookResult = ReturnType<typeof useFinancialEligibilityLookupMutation>
export type FinancialEligibilityLookupMutationResult = Apollo.MutationResult<FinancialEligibilityLookupMutation>
export type FinancialEligibilityLookupMutationOptions = Apollo.BaseMutationOptions<
  FinancialEligibilityLookupMutation,
  FinancialEligibilityLookupMutationVariables
>
export const CostEstimateLookupDocument = gql`
  query costEstimateLookup($uid: ID!) {
    costEstimateLookup(uid: $uid) {
      __typename
      ... on CostEstimateLookupSuccess {
        intakeCostEstimateInCents
        month1CostEstimateInCents
        month2CostEstimateInCents
        month3CostEstimateInCents
      }
      ... on CostEstimateLookupFailure {
        reason
      }
    }
  }
`

/**
 * __useCostEstimateLookupQuery__
 *
 * To run a query within a React component, call `useCostEstimateLookupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostEstimateLookupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostEstimateLookupQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useCostEstimateLookupQuery(
  baseOptions: Apollo.QueryHookOptions<CostEstimateLookupQuery, CostEstimateLookupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CostEstimateLookupQuery, CostEstimateLookupQueryVariables>(CostEstimateLookupDocument, options)
}
export function useCostEstimateLookupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostEstimateLookupQuery, CostEstimateLookupQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CostEstimateLookupQuery, CostEstimateLookupQueryVariables>(
    CostEstimateLookupDocument,
    options
  )
}
export type CostEstimateLookupQueryHookResult = ReturnType<typeof useCostEstimateLookupQuery>
export type CostEstimateLookupLazyQueryHookResult = ReturnType<typeof useCostEstimateLookupLazyQuery>
export type CostEstimateLookupQueryResult = Apollo.QueryResult<
  CostEstimateLookupQuery,
  CostEstimateLookupQueryVariables
>
