import { CardContent } from '@mui/material'
import React from 'react'
import { FormatDateTime } from 'src/date'
import { ViewOnlyForm } from 'src/form'
import { PatientHistoryType } from 'src/graphql.generated'
import { CardTitle, Dash, ExpandableCard } from 'src/legacy/components/mui'
import MeruDate from 'src/legacy/lib/MeruDate'
import IntakeNoteCoachingFields from 'src/participantHistory/IntakeNoteCoachingFields'
import FinancialEligibilityHealthPlanFields, {
  getInitialValuesFromFinancialEligibilityPatientHistoryEntry,
} from 'src/participantHistory/financialEligibility/FinancialEligibilityHealthPlanFields'
import BurnoutFields from '../BurnoutFields'
import CustomFields from '../CustomFields'
import GAD7Fields from '../GAD7Fields'
import MissedIntakeCallFields from '../MissedIntakeCallFields'
import NoteFields from '../NoteFields'
import PHQ9Fields from '../PHQ9Fields'
import ParticipantAcceptedFields from '../ParticipantAcceptedFields'
import ParticipantDeclinedFields from '../ParticipantDeclinedFields'
import ParticipantReEnrollmentTable from '../ParticipantReEnrollmentTable'
import ProgramEndFields from '../ProgramEndFields'
import TreatmentPlanFields from '../TreatmentPlanFields'
import VideoCallPatientHistoryFields from '../VideoCallPatientHistoryFields'
import WHO5Fields from '../WHO5Fields'
import WPAIFields from '../WPAIFields'
import { PATIENT_HISTORY_TYPE_NAMES } from '../constants'
import IntakeNoteFields from '../intakeNote/IntakeNoteFields'
import { PatientHistoryEntryFragment } from '../patientHistoryQueries.generated'
import ParticipantHistoryDelete from './ParticipantHistoryDelete'
import Source from './Source'

type TitleProps = {
  entry: PatientHistoryEntryFragment
}

const Title = ({ entry }: TitleProps) => {
  const { createdAt, entryID, source, type } = entry

  return (
    <CardTitle action={<ParticipantHistoryDelete createdAt={createdAt} entryID={entryID} source={source} />}>
      {type ? PATIENT_HISTORY_TYPE_NAMES[type] : 'Unknown'}
    </CardTitle>
  )
}

const PATIENT_HISTORY_TYPES_WITH_ONLY_DESCRIPTION_FIELD: Set<PatientHistoryType> = new Set([
  PatientHistoryType.AnonymousNote,
  PatientHistoryType.Note,
  PatientHistoryType.PhoneCall,
  PatientHistoryType.Sms,
])

type SubtitleProps = {
  entry: PatientHistoryEntryFragment
  programStartDate?: Date | null | undefined
}

const Subtitle = ({ entry, programStartDate }: SubtitleProps) => (
  <>
    <DateOfEvent entry={entry} programStartDate={programStartDate} />
    <Dash />
    <span>
      Submitted <Source coachID={entry.coachID} value={entry.source} /> on <FormatDateTime value={entry.createdAt} />
    </span>
  </>
)

type DateOfEventProps = {
  entry: PatientHistoryEntryFragment
  programStartDate?: Date | null | undefined
}

const DateOfEvent = ({ entry, programStartDate }: DateOfEventProps) => {
  const dateOfEvent = new MeruDate(entry.date).formatDate()
  const weekNumber = new MeruDate(entry.date).formatWeekNumber(programStartDate, true)
  return <span>Date of event: {weekNumber ? `${dateOfEvent} (week ${weekNumber})` : dateOfEvent}</span>
}

type PatientHistoryEntryProps = {
  entry: PatientHistoryEntryFragment
  uid: string
  programStartDate: Date | null | undefined
}

const getInitialValuesFromPatientHistoryEntry = (entry: PatientHistoryEntryFragment) => {
  if (entry.__typename === 'FinancialEligibilityPatientHistory') {
    return getInitialValuesFromFinancialEligibilityPatientHistoryEntry(entry)
  }

  if (entry.type === PatientHistoryType.TreatmentPlan) {
    return entry.value ?? {}
  }

  return entry
}

// TODO 2020-11-27 -- INTAKE_NOTE version handling can be removed when access to old-style intake notes is not needed
const ParticipantHistoryEntry = ({ entry, uid, programStartDate }: PatientHistoryEntryProps) => (
  <ExpandableCard
    title={<Title entry={entry} />}
    subheader={<Subtitle entry={entry} programStartDate={programStartDate} />}
    subheaderTypographyProps={{ variant: 'caption' }}
  >
    <CardContent>
      <ViewOnlyForm initialValues={getInitialValuesFromPatientHistoryEntry(entry)}>
        {entry.type && PATIENT_HISTORY_TYPES_WITH_ONLY_DESCRIPTION_FIELD.has(entry.type) && <NoteFields />}
        {entry.type === PatientHistoryType.Accepted && <ParticipantAcceptedFields />}
        {entry.type === PatientHistoryType.Burnout && <BurnoutFields />}
        {entry.type === PatientHistoryType.Custom && <CustomFields />}
        {entry.type === PatientHistoryType.Declined && <ParticipantDeclinedFields />}
        {entry.type === PatientHistoryType.MissedIntakeCall && <MissedIntakeCallFields />}
        {entry.type === PatientHistoryType.IntakeNoteCoaching && <IntakeNoteCoachingFields />}
        {entry.type === PatientHistoryType.Phq9 && <PHQ9Fields />}
        {entry.type === PatientHistoryType.Gad7 && <GAD7Fields />}
        {entry.type === PatientHistoryType.Who5 && <WHO5Fields />}
        {entry.type === PatientHistoryType.ProgramEnd && <ProgramEndFields />}
        {entry.type === PatientHistoryType.Wpai && <WPAIFields />}
        {entry.__typename === 'CommonPatientHistory' && entry.type === PatientHistoryType.ParticipantReEnrolled && (
          <ParticipantReEnrollmentTable value={entry.value ?? null} />
        )}
        {entry.__typename === 'VideoCallPatientHistory' && (
          <VideoCallPatientHistoryFields patientHistoryEntry={entry} />
        )}
        {entry.__typename === 'FinancialEligibilityPatientHistory' && (
          <FinancialEligibilityHealthPlanFields uid={uid} />
        )}
        {entry.type === PatientHistoryType.IntakeNote && <IntakeNoteFields uid={uid} />}
        {entry.type === PatientHistoryType.TreatmentPlan && <TreatmentPlanFields />}
      </ViewOnlyForm>
    </CardContent>
  </ExpandableCard>
)

export default ParticipantHistoryEntry
