import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Box, Button, Toolbar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { common } from '@mui/material/colors'
import { useIsAdmin, useIsCommunityFacilitator } from 'src/session'
import DeveloperMenu from './DeveloperMenu'
import TabLinks from './TabLinks'

import logo from './logo.svg'

const APP_BAR_HEIGHT = 56

const useStyles = makeStyles((theme) => ({
  customAppBar: {
    height: APP_BAR_HEIGHT,
    background: common.white,
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    margin: theme.spacing(1, 2),
  },
}))

const useTabStyles = makeStyles({
  indicator: {
    height: 3,
  },
})

const AppBars: React.FunctionComponent = ({ children }) => {
  const classes = useStyles()
  const tabClasses = useTabStyles()
  const isAdmin = useIsAdmin()
  const isCommunityFacilitator = useIsCommunityFacilitator()
  const tabs = [
    { label: 'Home', to: '/participant', alt: '/' },
    { label: 'Participants', to: '/participants' },
  ]
  if (isAdmin) {
    tabs.push({ label: 'Admin', to: '/admin' })
  }
  if (isAdmin || isCommunityFacilitator) {
    tabs.push({ label: 'Communities', to: '/communities' })
  }

  return (
    <>
      <AppBar position="sticky" color="transparent" className={classes.customAppBar} elevation={2}>
        <Toolbar variant="dense">
          <Link to="/participant">
            <img alt="Meru Health" src={logo} width={25} height={25} className={classes.logo} />
          </Link>
          <Box display="flex" flex={1} mx={3}>
            <TabLinks classes={tabClasses} tabs={tabs} />
          </Box>

          {isAdmin && <DeveloperMenu />}
          <Button color="inherit" component={Link} to="/logout">
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      <Box height={`calc(100% - ${APP_BAR_HEIGHT}px)`}>{children}</Box>
    </>
  )
}

export default AppBars
