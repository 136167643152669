import React, { useEffect, useState } from 'react'
import { EditOutlined } from '@mui/icons-material'
import { getItemWithExpirationTime } from 'src/legacy/lib/localStorageWithTTL'
import { CustomTooltip } from 'src/designSystem'
import moment from 'moment'
import prefixUID from 'src/feed/ChatMessageSendingSection/messageLocalStoragePrefixUID'
import { DRAFT_MESSAGE_EVENT_NAME } from 'src/feed/ChatMessageSendingSection/useMessage'

type Props = {
  uid: string
}

const DraftMessageIndicator = ({ uid }: Props) => {
  const [draftMessage, setDraftMessage] = useState<{ expiresAt: number; value: string } | null>(
    getItemWithExpirationTime(prefixUID(uid))
  )

  useEffect(() => {
    const handle = () => {
      const message = getItemWithExpirationTime(prefixUID(uid))
      setDraftMessage(message)
    }

    window.addEventListener(`${DRAFT_MESSAGE_EVENT_NAME}_${uid}`, handle)

    return () => {
      window.removeEventListener(`${DRAFT_MESSAGE_EVENT_NAME}_${uid}`, handle)
    }
  }, [uid])

  if (draftMessage) {
    const hoursUntilExpiration = moment(draftMessage.expiresAt).diff(moment(), 'hours')

    return (
      <CustomTooltip title={`Draft expires in ${hoursUntilExpiration} hours`} placement="right-end">
        <EditOutlined titleAccess="draft message" fontSize="small" />
      </CustomTooltip>
    )
  }

  return null
}

export default DraftMessageIndicator
