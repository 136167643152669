import React from 'react'
import _ from 'lodash'
import { Box, Chip, Grid, Checkbox, MenuItem } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { FastField, useFormikContext } from 'formik'
import { CustomTextField } from 'src/form'
import { BILLING_CODE_NAMES, BillingCode } from './billingCodeConstants'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}))

export type Props = {
  name: string
}

function isMDDCode(code: BillingCode): boolean {
  return BILLING_CODE_NAMES[code].short.startsWith('mdd')
}

function isADJCode(code: BillingCode): boolean {
  return BILLING_CODE_NAMES[code].short.startsWith('adj')
}

export function isCodeVisible(selected: BillingCode[], code: BillingCode) {
  if (selected.includes(code)) {
    return true
  }
  if (isMDDCode(code) && selected.filter(isMDDCode).length > 0) {
    return false
  }
  if (isADJCode(code) && selected.filter(isADJCode).length > 0) {
    return false
  }
  return true
}

const BillingCodes = (props: Props) => {
  const { name } = props
  const context = useFormikContext()

  const { status: { viewOnly = false } = {} } = context

  const codes: BillingCode[] = _.get(context.values, name, [])
  const selectedSet = new Set(codes)
  const styles = useStyles()

  const handleDelete = (code: BillingCode) => {
    const updated = codes.filter((existing) => existing !== code)
    context.setFieldValue(name, updated)
  }

  const sortedCodes = [...codes].sort((a, b) => BILLING_CODE_NAMES[a].long.localeCompare(BILLING_CODE_NAMES[b].long))

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {sortedCodes.length === 0 && (
            <Chip variant="outlined" label="No billing codes selected" classes={{ root: styles.chip }} />
          )}
          {sortedCodes.map((code) => (
            <Chip
              key={code}
              classes={{ root: styles.chip }}
              variant="outlined"
              color={viewOnly ? 'default' : 'primary'}
              disabled={viewOnly}
              label={BILLING_CODE_NAMES[code].long}
              onDelete={() => {
                handleDelete(code)
              }}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <FastField
            component={CustomTextField}
            name={name}
            type="select"
            label="Add a code"
            multiple
            renderValue={(selected: BillingCode[]) => `${selected.length} codes selected`}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  horizontal: 'left',
                  vertical: 'bottom',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            {Object.values(BillingCode)
              .filter((code) => isCodeVisible(codes, code))
              .map((code) => (
                <MenuItem key={code} value={code}>
                  <Checkbox checked={selectedSet.has(code)} color="primary" />
                  {BILLING_CODE_NAMES[code].long}
                </MenuItem>
              ))}
          </FastField>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BillingCodes
