import { Tooltip, TooltipProps } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: theme.typography.body2.fontSize,
  },
}))

const CustomTooltip: React.FC<TooltipProps> = (props) => {
  const styles = useStyles()
  return <Tooltip {...props} arrow classes={{ arrow: styles.arrow, tooltip: styles.tooltip }} />
}

export default CustomTooltip
