import { chain, isNumber, get } from 'lodash'
import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { Skeleton } from '@mui/material'
import { FormatDateAndWeek } from 'src/date'
import MeruDate from 'src/legacy/lib/MeruDate'
import { CommonPatientHistory, PatientHistory } from 'src/graphql.generated'
import { ParticipantResultsTextRow } from './ParticipantResultsTable'
import { ProgramEndMetrics } from './programEndMetricSchemas'

const getProgramEndResult = (record: CommonPatientHistory, programStartDate: string, threshold: number) => {
  if (!record) return null

  const scoreWeek = new MeruDate(record.date).formatWeekNumber(programStartDate, true)
  if (!scoreWeek || scoreWeek < threshold) return null

  return chain(record)
    .get('value')
    .find((_value, key) => key.indexOf('_SCORE') !== -1)
    .value()
}

type Props = {
  type: keyof ProgramEndMetrics
  data: {
    loading: boolean
    patientHistory?: PatientHistory[]
  }
  lastWeekNbr: number
  programStartDate: string
}

const ProgramEndResult = ({ type, data, programStartDate, lastWeekNbr }: Props) => {
  const fieldName = `programEndMetrics.${type}.programEnd`

  // Use the latest available record to calculate
  const programEndRecord = chain(data.patientHistory)
    .filter((historyEntryType) => type.valueOf() === historyEntryType.type)
    .head()
    .value()

  // Use last result if from mid program week or later
  const threshold = Math.ceil(lastWeekNbr / 2)

  const { setFieldValue, values } = useFormikContext<{
    programEndMetrics: ProgramEndMetrics
  }>()

  useEffect(() => {
    const programEndResult = getProgramEndResult(programEndRecord, programStartDate, threshold)
    setFieldValue(fieldName, programEndResult)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.patientHistory])

  const programEndValue = values.programEndMetrics[type]?.programEnd
  const helperText = isNumber(programEndValue) ? (
    <FormatDateAndWeek
      recordDate={get(programEndRecord, 'date')}
      programStartDate={programStartDate}
      dateColor="text.secondary"
    />
  ) : (
    `No score from week ${threshold} or later`
  )

  return (
    <ParticipantResultsTextRow
      label="Last Result"
      content={data.loading ? <Skeleton /> : programEndValue}
      helperText={data.loading ? null : helperText}
    />
  )
}

export default ProgramEndResult
