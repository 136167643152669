/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

import { CommunityPostRepliesItemFragmentDoc } from './communityPostRepliesFragment.generated'

const defaultOptions = {} as const
export type GetPostRepliesQueryVariables = Types.Exact<{
  postID: Types.Scalars['ID']['input']
}>

export type GetPostRepliesQuery = {
  __typename?: 'Query'
  communityPost: {
    __typename: 'CommunityPostQuestion'
    postID: string
    communityPostReplies: {
      __typename: 'CommunityPostReplies'
      id: string
      numberOfPatientsReplied: number
      canReplyToPost: boolean
      replyingStatus: string
      repliedMyself: boolean
      replies?: Array<{
        __typename: 'CommunityPostReplyQuestionAnswer'
        replyID: string
        createdAt: Date
        isHidden: boolean
        message: string
        replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
      }> | null
    }
  }
}

export const GetPostRepliesDocument = gql`
  query getPostReplies($postID: ID!) {
    communityPost(postID: $postID) {
      __typename
      postID
      communityPostReplies(showHidden: true) {
        ...CommunityPostRepliesItem
      }
    }
  }
  ${CommunityPostRepliesItemFragmentDoc}
`

/**
 * __useGetPostRepliesQuery__
 *
 * To run a query within a React component, call `useGetPostRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostRepliesQuery({
 *   variables: {
 *      postID: // value for 'postID'
 *   },
 * });
 */
export function useGetPostRepliesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPostRepliesQuery, GetPostRepliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPostRepliesQuery, GetPostRepliesQueryVariables>(GetPostRepliesDocument, options)
}
export function useGetPostRepliesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPostRepliesQuery, GetPostRepliesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPostRepliesQuery, GetPostRepliesQueryVariables>(GetPostRepliesDocument, options)
}
export type GetPostRepliesQueryHookResult = ReturnType<typeof useGetPostRepliesQuery>
export type GetPostRepliesLazyQueryHookResult = ReturnType<typeof useGetPostRepliesLazyQuery>
export type GetPostRepliesQueryResult = Apollo.QueryResult<GetPostRepliesQuery, GetPostRepliesQueryVariables>
