import React from 'react'
import { times } from 'lodash'
import { Box, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useGetPostRepliesQuery } from './postRepliesQuery.generated'
import CommunityPostReply, { CommunityPostReplySkeleton } from './CommunityPostReply'
import CommunityPostFacilitatorReplyInput from './CommunityPostFacilitatorReplyInput'

type Props = {
  postID: string
}

export default function CommunityPostReplies({ postID }: Props) {
  const { data, loading } = useGetPostRepliesQuery({
    variables: { postID },
    fetchPolicy: 'cache-and-network',
    returnPartialData: true,
  })

  const styles = useStyles()
  const communityPostReplies = data?.communityPost.communityPostReplies
  const replies = communityPostReplies?.replies ?? []

  // If we know how many replies are going to be fetched we can render as many skeletons
  const skeletonCount = (communityPostReplies?.numberOfPatientsReplied ?? 0) - replies.length

  return (
    <Box className={styles.container}>
      <Box py={3} className={styles.messageList}>
        {replies.map((reply) => (
          <CommunityPostReply key={reply.replyID} reply={reply} />
        ))}
        {loading &&
          (skeletonCount > 0 ? <ReplySkeletons skeletonCount={skeletonCount} /> : <CircularProgress size="1rem" />)}
      </Box>
      <Box className={styles.actions}>
        <CommunityPostFacilitatorReplyInput postID={postID} disabled={loading} />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
    flex: 1,
    overflowX: 'hidden',
  },
  messageList: {
    position: 'absolute',
    top: 0,
    bottom: 56,
    width: '100%',
    overflowX: 'auto',
  },
  actions: {
    bottom: 0,
    width: '100%',
    position: 'absolute',
  },
})

function ReplySkeletons({ skeletonCount }: { skeletonCount: number }) {
  return (
    <div role="progressbar" aria-busy aria-live="polite">
      {times(skeletonCount, (index) => (
        <CommunityPostReplySkeleton key={`reply-skeleton-${index}`} index={index} />
      ))}
    </div>
  )
}
