import React, { forwardRef } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { ButtonBase, ButtonBaseProps, Typography } from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import cx from 'classnames'

type Props = ButtonBaseProps

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'inline-flex',
    flexWrap: 'nowrap',
  },
  buttonText: {
    fontWeight: 500,
    transition: theme.transitions.create('color'),
  },
  disabled: {
    color: theme.palette.action.disabled,
  },
}))

const TextMenuButton = forwardRef<HTMLButtonElement, Props>(({ children, className, disabled, ...props }, ref) => {
  const styles = useStyles()

  const buttonClassName = cx(className, disabled && styles.disabled)

  return (
    <ButtonBase ref={ref} className={buttonClassName} disabled={disabled} {...props}>
      <Typography className={styles.buttonText} component="span">
        {children}
      </Typography>
      <ExpandMore />
    </ButtonBase>
  )
})

export default TextMenuButton
