import { useEffect, useRef } from 'react'
import { noop, throttle } from 'lodash'
import { useFirebase } from 'react-redux-firebase'

type Listener = () => void

export default function useListenToLatestCoachMessages(feedID: string, listener: Listener) {
  const throttledListenerRef = useRef<Listener>(noop)
  useEffect(() => {
    throttledListenerRef.current = throttle(listener, 500)
  }, [listener])

  const firebase = useFirebase()
  useEffect(() => {
    const feedRef = firebase.database().ref().child('feedsCoach').child(feedID)

    feedRef
      .orderByChild('createdAt')
      .startAt(Date.now())
      .on('child_added', () => {
        throttledListenerRef.current()
      })
    return () => feedRef.off()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedID])
}
