import React from 'react'
import { Box, Checkbox, FormControlLabel, Radio } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { includes, isBoolean } from 'lodash'
import classNames from 'classnames'
import { FeedPatientResponseItemSectionEntryFragment } from '../../feedQueries.generated'
import SectionEntryValue from './EntryValues/SectionEntryValue'
import ChoiceLabel from './ChoiceLabel'
import { Question } from '../../FeedComponents'

const HQ_SCORE_TAGS = ['PHQ9_SCORE', 'GAD7_SCORE']

type SectionEntryProps = {
  entry: FeedPatientResponseItemSectionEntryFragment
  format: string
  isCompleted: boolean
  programID: string
  questionNumber: number
}

export default function SectionEntry({ entry, format, isCompleted, programID, questionNumber }: SectionEntryProps) {
  const styles = useStyles()
  const { tag, rawValue: value, question } = entry

  // Skip entries with HQ score, the score is shown in the section title
  if (includes(HQ_SCORE_TAGS, tag)) {
    return null
  }

  if (format === 'healthQuestionnaire') {
    const questionContent = `${questionNumber + 1}. ${question}`
    return (
      <Box className={classNames(styles.container, styles.samelineValue)}>
        <Question content={questionContent} />
        <SectionEntryValue entry={entry} programID={programID} />
      </Box>
    )
  }

  if ((format === 'singleChoice' || format === 'multipleChoice') && isBoolean(value)) {
    const isChecked = value
    const controlClassName = classNames(styles.controlRoot, { [styles.checked]: isChecked })
    const control =
      format === 'singleChoice' ? (
        <Radio className={controlClassName} size="small" />
      ) : (
        <Checkbox className={controlClassName} size="small" />
      )
    return (
      <Box mt={0.5}>
        <FormControlLabel
          disabled
          classes={{ root: styles.formControlRoot, label: styles.labelRoot }}
          checked={isChecked}
          label={<ChoiceLabel content={question!} highlighted={isChecked} />}
          control={control}
        />
      </Box>
    )
  }

  return (
    <Box className={classNames(styles.container, styles.nextlineValue)}>
      <Question content={question} />
      <SectionEntryValue entry={entry} isCompleted={isCompleted} programID={programID} />
    </Box>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: theme.spacing(1),
  },
  nextlineValue: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  samelineValue: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  formControlRoot: {
    margin: 0,
  },
  labelRoot: {
    paddingLeft: theme.spacing(1),
    lineHeight: 1.3,
    fontSize: '0.75rem',
  },
  controlRoot: {
    padding: 0,
  },
  checked: {
    // to override disabled coloring
    color: `${theme.palette.text.primary} !important`,
  },
}))
