import React from 'react'
import Bugsnag from '@bugsnag/browser'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { BUGSNAG_KEY } from 'src/config'

if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    appVersion: process.env.VERSION_NUMBER,
    enabledReleaseStages: ['production'],
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: process.env.NODE_ENV,
  })
}

export default Bugsnag
