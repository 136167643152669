/* eslint-disable no-template-curly-in-string */

import { setLocale } from 'yup'

setLocale({
  mixed: {
    notType: 'This is required',
    oneOf: 'This is required',
    required: 'This is required',
  },
  number: {
    max: 'Maximum allowed number is ${max}',
    min: 'Minimum allowed number is ${min}',
  },
  string: {
    email: 'Email address is invalid',
  },
})
