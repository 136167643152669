/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql.generated'

const defaultOptions = {} as const
export type AddPatientMutationVariables = Types.Exact<{
  email: Types.Scalars['EmailAddress']['input']
  firstname: Types.Scalars['String']['input']
  lastname: Types.Scalars['String']['input']
  programID: Types.Scalars['String']['input']
  region: Types.Scalars['String']['input']
  source?: Types.InputMaybe<Types.Scalars['String']['input']>
  referralName: Types.Scalars['String']['input']
}>

export type AddPatientMutation = { __typename?: 'Mutation'; addPatient: { __typename?: 'Patient'; uid: string } }

export type AddTestUserMutationVariables = Types.Exact<{
  email: Types.Scalars['EmailAddress']['input']
  firstname: Types.Scalars['String']['input']
  lastname: Types.Scalars['String']['input']
  primaryCoachID: Types.Scalars['ID']['input']
  programID: Types.Scalars['String']['input']
  programStartDate: Types.Scalars['String']['input']
}>

export type AddTestUserMutation = { __typename?: 'Mutation'; addTestUser: { __typename?: 'Patient'; uid: string } }

export type DeletePatientMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type DeletePatientMutation = {
  __typename?: 'Mutation'
  deletePatient?: { __typename?: 'Patient'; uid: string } | null
}

export type GetPrefilledIntakeNoteQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPrefilledIntakeNoteQuery = {
  __typename?: 'Query'
  prefilledIntakeNote: {
    __typename?: 'PrefilledIntakeNote'
    OBSTACLES?: string | null
    EMPLOYMENT?: { __typename?: 'IntakeNoteEmployment'; STATUS?: Types.IntakeNoteEmploymentStatus | null } | null
    HEALTH_HISTORY?: {
      __typename?: 'IntakeNoteHealthHistory'
      BIPOLAR: boolean
      DEPRESSION: boolean
      DTD: boolean
      GENERALISED_ANXIETY: boolean
      PSYCHOSIS: boolean
      PTSD: boolean
      SUBSTANCE_ABUSE: boolean
      SUICIDALITY: boolean
      THERAPY: boolean
    } | null
  }
}

export type GetPatientsQueryVariables = Types.Exact<{
  uids: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
}>

export type GetPatientsQuery = {
  __typename?: 'Query'
  patients: Array<{
    __typename?: 'Patient'
    details?: Types.JSONObject | null
    firstname?: string | null
    lastname?: string | null
    uid: string
  }>
}

export type GetPatientHistoryQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
}>

export type GetPatientHistoryQuery = {
  __typename?: 'Query'
  patientHistory: Array<
    | {
        __typename: 'CommonPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'FinancialEligibilityPatientHistory'
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
    | {
        __typename: 'VideoCallPatientHistory'
        value?: Types.JSONObject | null
        entryID: string
        coachID?: string | null
        createdAt: Date
        date?: string | null
        source?: string | null
        type?: Types.PatientHistoryType | null
      }
  >
}

export type GetPatientHistoryValuesQueryVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  type: Types.PatientHistoryType
  orderBy?: Types.InputMaybe<Types.PatientHistoryOrderByInput>
}>

export type GetPatientHistoryValuesQuery = {
  __typename?: 'Query'
  patientHistory: Array<
    | { __typename: 'CommonPatientHistory'; value?: Types.JSONObject | null; entryID: string; date?: string | null }
    | { __typename: 'FinancialEligibilityPatientHistory'; entryID: string; date?: string | null }
    | { __typename: 'VideoCallPatientHistory'; value?: Types.JSONObject | null; entryID: string; date?: string | null }
  >
}

export type SetCareteamMutationVariables = Types.Exact<{
  uid: Types.Scalars['ID']['input']
  coachIDs: Array<Types.Scalars['ID']['input']> | Types.Scalars['ID']['input']
  primaryCoachID: Types.Scalars['ID']['input']
}>

export type SetCareteamMutation = {
  __typename?: 'Mutation'
  setCareteam: { __typename?: 'SetCareteamAction'; uid?: string | null }
}

export const AddPatientDocument = gql`
  mutation addPatient(
    $email: EmailAddress!
    $firstname: String!
    $lastname: String!
    $programID: String!
    $region: String!
    $source: String
    $referralName: String!
  ) {
    addPatient(
      email: $email
      firstname: $firstname
      lastname: $lastname
      programID: $programID
      region: $region
      source: $source
      referralName: $referralName
    ) {
      uid
    }
  }
`
export type AddPatientMutationFn = Apollo.MutationFunction<AddPatientMutation, AddPatientMutationVariables>

/**
 * __useAddPatientMutation__
 *
 * To run a mutation, you first call `useAddPatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPatientMutation, { data, loading, error }] = useAddPatientMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      programID: // value for 'programID'
 *      region: // value for 'region'
 *      source: // value for 'source'
 *      referralName: // value for 'referralName'
 *   },
 * });
 */
export function useAddPatientMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPatientMutation, AddPatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddPatientMutation, AddPatientMutationVariables>(AddPatientDocument, options)
}
export type AddPatientMutationHookResult = ReturnType<typeof useAddPatientMutation>
export type AddPatientMutationResult = Apollo.MutationResult<AddPatientMutation>
export type AddPatientMutationOptions = Apollo.BaseMutationOptions<AddPatientMutation, AddPatientMutationVariables>
export const AddTestUserDocument = gql`
  mutation addTestUser(
    $email: EmailAddress!
    $firstname: String!
    $lastname: String!
    $primaryCoachID: ID!
    $programID: String!
    $programStartDate: String!
  ) {
    addTestUser(
      email: $email
      firstname: $firstname
      lastname: $lastname
      primaryCoachID: $primaryCoachID
      programID: $programID
      programStartDate: $programStartDate
    ) {
      uid
    }
  }
`
export type AddTestUserMutationFn = Apollo.MutationFunction<AddTestUserMutation, AddTestUserMutationVariables>

/**
 * __useAddTestUserMutation__
 *
 * To run a mutation, you first call `useAddTestUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTestUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTestUserMutation, { data, loading, error }] = useAddTestUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      primaryCoachID: // value for 'primaryCoachID'
 *      programID: // value for 'programID'
 *      programStartDate: // value for 'programStartDate'
 *   },
 * });
 */
export function useAddTestUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTestUserMutation, AddTestUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddTestUserMutation, AddTestUserMutationVariables>(AddTestUserDocument, options)
}
export type AddTestUserMutationHookResult = ReturnType<typeof useAddTestUserMutation>
export type AddTestUserMutationResult = Apollo.MutationResult<AddTestUserMutation>
export type AddTestUserMutationOptions = Apollo.BaseMutationOptions<AddTestUserMutation, AddTestUserMutationVariables>
export const DeletePatientDocument = gql`
  mutation deletePatient($uid: ID!) {
    deletePatient(uid: $uid) {
      uid
    }
  }
`
export type DeletePatientMutationFn = Apollo.MutationFunction<DeletePatientMutation, DeletePatientMutationVariables>

/**
 * __useDeletePatientMutation__
 *
 * To run a mutation, you first call `useDeletePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePatientMutation, { data, loading, error }] = useDeletePatientMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeletePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePatientMutation, DeletePatientMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeletePatientMutation, DeletePatientMutationVariables>(DeletePatientDocument, options)
}
export type DeletePatientMutationHookResult = ReturnType<typeof useDeletePatientMutation>
export type DeletePatientMutationResult = Apollo.MutationResult<DeletePatientMutation>
export type DeletePatientMutationOptions = Apollo.BaseMutationOptions<
  DeletePatientMutation,
  DeletePatientMutationVariables
>
export const GetPrefilledIntakeNoteDocument = gql`
  query getPrefilledIntakeNote($uid: ID!) {
    prefilledIntakeNote(uid: $uid) {
      OBSTACLES
      EMPLOYMENT {
        STATUS
      }
      HEALTH_HISTORY {
        BIPOLAR
        DEPRESSION
        DTD
        GENERALISED_ANXIETY
        PSYCHOSIS
        PTSD
        SUBSTANCE_ABUSE
        SUICIDALITY
        THERAPY
      }
    }
  }
`

/**
 * __useGetPrefilledIntakeNoteQuery__
 *
 * To run a query within a React component, call `useGetPrefilledIntakeNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrefilledIntakeNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrefilledIntakeNoteQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPrefilledIntakeNoteQuery(
  baseOptions: Apollo.QueryHookOptions<GetPrefilledIntakeNoteQuery, GetPrefilledIntakeNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPrefilledIntakeNoteQuery, GetPrefilledIntakeNoteQueryVariables>(
    GetPrefilledIntakeNoteDocument,
    options
  )
}
export function useGetPrefilledIntakeNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPrefilledIntakeNoteQuery, GetPrefilledIntakeNoteQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPrefilledIntakeNoteQuery, GetPrefilledIntakeNoteQueryVariables>(
    GetPrefilledIntakeNoteDocument,
    options
  )
}
export type GetPrefilledIntakeNoteQueryHookResult = ReturnType<typeof useGetPrefilledIntakeNoteQuery>
export type GetPrefilledIntakeNoteLazyQueryHookResult = ReturnType<typeof useGetPrefilledIntakeNoteLazyQuery>
export type GetPrefilledIntakeNoteQueryResult = Apollo.QueryResult<
  GetPrefilledIntakeNoteQuery,
  GetPrefilledIntakeNoteQueryVariables
>
export const GetPatientsDocument = gql`
  query getPatients($uids: [ID!]!) {
    patients(uids: $uids) {
      details
      firstname
      lastname
      uid
    }
  }
`

/**
 * __useGetPatientsQuery__
 *
 * To run a query within a React component, call `useGetPatientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientsQuery({
 *   variables: {
 *      uids: // value for 'uids'
 *   },
 * });
 */
export function useGetPatientsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options)
}
export function useGetPatientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientsQuery, GetPatientsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GetPatientsDocument, options)
}
export type GetPatientsQueryHookResult = ReturnType<typeof useGetPatientsQuery>
export type GetPatientsLazyQueryHookResult = ReturnType<typeof useGetPatientsLazyQuery>
export type GetPatientsQueryResult = Apollo.QueryResult<GetPatientsQuery, GetPatientsQueryVariables>
export const GetPatientHistoryDocument = gql`
  query getPatientHistory($uid: ID!) {
    patientHistory(uid: $uid, orderBy: date_DESC) {
      __typename
      entryID
      coachID
      createdAt
      date
      source
      type
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`

/**
 * __useGetPatientHistoryQuery__
 *
 * To run a query within a React component, call `useGetPatientHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientHistoryQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetPatientHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>(GetPatientHistoryDocument, options)
}
export function useGetPatientHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>(
    GetPatientHistoryDocument,
    options
  )
}
export type GetPatientHistoryQueryHookResult = ReturnType<typeof useGetPatientHistoryQuery>
export type GetPatientHistoryLazyQueryHookResult = ReturnType<typeof useGetPatientHistoryLazyQuery>
export type GetPatientHistoryQueryResult = Apollo.QueryResult<GetPatientHistoryQuery, GetPatientHistoryQueryVariables>
export const GetPatientHistoryValuesDocument = gql`
  query getPatientHistoryValues(
    $uid: ID!
    $type: PatientHistoryType!
    $orderBy: PatientHistoryOrderByInput = date_ASC
  ) {
    patientHistory(uid: $uid, type: $type, orderBy: $orderBy) {
      __typename
      entryID
      date
      ... on CommonPatientHistory {
        value
      }
      ... on VideoCallPatientHistory {
        value
      }
    }
  }
`

/**
 * __useGetPatientHistoryValuesQuery__
 *
 * To run a query within a React component, call `useGetPatientHistoryValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientHistoryValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientHistoryValuesQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *      type: // value for 'type'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetPatientHistoryValuesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPatientHistoryValuesQuery, GetPatientHistoryValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetPatientHistoryValuesQuery, GetPatientHistoryValuesQueryVariables>(
    GetPatientHistoryValuesDocument,
    options
  )
}
export function useGetPatientHistoryValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPatientHistoryValuesQuery, GetPatientHistoryValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetPatientHistoryValuesQuery, GetPatientHistoryValuesQueryVariables>(
    GetPatientHistoryValuesDocument,
    options
  )
}
export type GetPatientHistoryValuesQueryHookResult = ReturnType<typeof useGetPatientHistoryValuesQuery>
export type GetPatientHistoryValuesLazyQueryHookResult = ReturnType<typeof useGetPatientHistoryValuesLazyQuery>
export type GetPatientHistoryValuesQueryResult = Apollo.QueryResult<
  GetPatientHistoryValuesQuery,
  GetPatientHistoryValuesQueryVariables
>
export const SetCareteamDocument = gql`
  mutation setCareteam($uid: ID!, $coachIDs: [ID!]!, $primaryCoachID: ID!) {
    setCareteam(uid: $uid, coachIDs: $coachIDs, primaryCoachID: $primaryCoachID) {
      uid
    }
  }
`
export type SetCareteamMutationFn = Apollo.MutationFunction<SetCareteamMutation, SetCareteamMutationVariables>

/**
 * __useSetCareteamMutation__
 *
 * To run a mutation, you first call `useSetCareteamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareteamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareteamMutation, { data, loading, error }] = useSetCareteamMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *      coachIDs: // value for 'coachIDs'
 *      primaryCoachID: // value for 'primaryCoachID'
 *   },
 * });
 */
export function useSetCareteamMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCareteamMutation, SetCareteamMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetCareteamMutation, SetCareteamMutationVariables>(SetCareteamDocument, options)
}
export type SetCareteamMutationHookResult = ReturnType<typeof useSetCareteamMutation>
export type SetCareteamMutationResult = Apollo.MutationResult<SetCareteamMutation>
export type SetCareteamMutationOptions = Apollo.BaseMutationOptions<SetCareteamMutation, SetCareteamMutationVariables>
