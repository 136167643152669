import _ from 'lodash'
import React from 'react'
import { Grid, Paper, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { CoachProfileWithCoachID } from '../useLoadCoachProfiles'

type Props = {
  coachType: 'primary' | 'other'
  coachProfiles: Array<CoachProfileWithCoachID>
  careTeamID?: string | Array<string>
  newCareTeamID?: string | Array<string>
}

const CareTeamChanges: React.FC<Props> = ({ coachType, coachProfiles, careTeamID, newCareTeamID }) => {
  const classes = useStyles()

  const careTeamIDs = toDenseArray(careTeamID)
  const newCareTeamIDs = toDenseArray(newCareTeamID)

  // exclude any old members still in the care team to get the added members
  const added = _.difference(newCareTeamIDs, careTeamIDs)

  // exclude any new members still in the care team to get the removed members
  const removed = _.difference(careTeamIDs, newCareTeamIDs)

  if (removed.length === 0 && added.length === 0) {
    return (
      <Paper classes={{ root: classes.paperRoot }} elevation={0}>
        <Typography variant="caption">
          {coachType === 'other' ? 'Other Careteam members' : 'Primary provider'}
        </Typography>
        {careTeamIDs.length !== 0 && (
          <Typography classes={{ root: classes.leftPaddedTypographyRoot }} variant="body1" color="textSecondary">
            No change from {getCoachNames(coachProfiles, careTeamIDs)}
          </Typography>
        )}
      </Paper>
    )
  }

  return (
    <Paper classes={{ root: classes.paperRoot }} elevation={0}>
      <Typography variant="caption">{coachType === 'other' ? 'Other Careteam members' : 'Primary provider'}</Typography>
      {removed.map((removedCoachID) => (
        <RemovedCoachField
          key={removedCoachID}
          name={getCoachName(coachProfiles, removedCoachID)}
          title={`remove ${coachType === 'other' ? 'member' : 'primary'}`}
        />
      ))}
      {added.map((addedCoachID) => (
        <AddedCoachField
          key={addedCoachID}
          name={getCoachName(coachProfiles, addedCoachID)}
          title={`add ${coachType === 'other' ? 'member' : 'primary'}`}
        />
      ))}
    </Paper>
  )
}

const toDenseArray = (value?: string | Array<string>): Array<string> =>
  Array.isArray(value) ? value : ([value].filter(Boolean) as Array<string>)

const getCoachName = (coachProfiles: Array<CoachProfileWithCoachID>, coachID?: string): string => {
  const coach = coachProfiles.find((coachProfile) => coachProfile.coachID === coachID)

  return `${coach?.firstname} ${coach?.lastname}`
}

const getCoachNames = (coachProfiles: Array<CoachProfileWithCoachID>, coachIDs: Array<string>): string =>
  coachIDs.map((coachID) => getCoachName(coachProfiles, coachID)).join(', ')

const RemovedCoachField = ({ name, title }: { name: string; title: string }) => (
  <Grid item classes={{ root: useStyles().inlineGridItem }}>
    <RemoveIcon color="error" titleAccess={title} />
    <Typography color="error" variant="body1">
      {name}
    </Typography>
  </Grid>
)

const AddedCoachField = ({ name, title }: { name: string; title: string }) => {
  const classes = useStyles()
  return (
    <Grid item classes={{ root: classes.inlineGridItem }}>
      <AddIcon color="primary" titleAccess={title} />
      <Typography color="primary" variant="body1">
        {name}
      </Typography>
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    backgroundColor: theme.palette.lightestGrey,
    padding: theme.spacing(1),
    minHeight: '100%',
  },
  typographyCaptionRoot: {
    paddingBottom: theme.spacing(1),
  },
  leftPaddedTypographyRoot: {
    paddingLeft: theme.spacing(0.5),
  },
  inlineGridItem: {
    display: 'inline-flex',
  },
}))

export default CareTeamChanges
