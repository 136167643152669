import React from 'react'
import { Typography } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

type ChoiceLabelProps = {
  content: string
  highlighted: boolean
}

export default function ChoiceLabel(props: ChoiceLabelProps) {
  const { content, highlighted } = props
  const classes = useStyles(props)

  return (
    <Typography variant="caption" className={classes.choiceLabel} color={highlighted ? 'textPrimary' : 'textSecondary'}>
      {content}
    </Typography>
  )
}

const useStyles = makeStyles({
  choiceLabel: {
    lineHeight: 1.3,
    fontWeight: (props: ChoiceLabelProps) => (props.highlighted ? 'bold' : 'normal'),
  },
})
