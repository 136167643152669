import _ from 'lodash'
import React, { useEffect, useState, useCallback } from 'react'
import { ErrorMessage, useFormikContext } from 'formik'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import RichTextEditor, { EditorValue, ToolbarConfig } from 'react-rte'

import { Code } from 'src/legacy/components/mui'
import { SendEmailFormType } from './type'

const colors = {
  highlightDarker: '#5BAF72',
  secondary: '#979797',
}

const iconStyles = {
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 18,
  display: 'inline-block',
  height: 22,
  width: 22,
}

const useStyles = makeStyles((theme) => ({
  richTextEditor: {
    borderColor: theme.palette.divider,
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.body1,
    '& a': {
      textDecoration: 'none !important',
    },
    '& a::after': {
      ...theme.typography.subtitle2,
      color: theme.palette.text.secondary,
      content: '"(links to: " attr(href) ")"',
      verticalAlign: 'super',
    },
    '& a span': {
      textDecoration: 'underline',
    },
    '& .highlight span': {
      ...iconStyles,
      backgroundImage:
        'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDQyIDQ0MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQyIDQ0MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnPjxwYXRoIHN0eWxlPSJmaWxsOiM1QkFGNzI7IiBkPSJNMjYyLjMxNCwzMDkuODU1aC0xMjUuOTJsLTI5Ljk1Nyw4OC44NTVIMzMuMzIyTDE2My44MTMsNDMuMjloNzEuNTkyTDM2NS44OTQsMzk4LjcxaC03Mi42MDhMMjYyLjMxNCwzMDkuODU1eiBNMTU1LjE4MSwyNTYuNTQyaDg5LjM2MmwtNDUuMTg5LTEyOC45NjZMMTU1LjE4MSwyNTYuNTQyeiIvPjwvZz48cmVjdCB4PSIzODguNjc4IiBzdHlsZT0iZmlsbDojNUJBRjcyOyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjQ0MiIvPjwvZz48L3N2Zz4=)',
    },
    '& .secondary span': {
      ...iconStyles,
      backgroundImage:
        'url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgNDQyIDQ0MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDQyIDQ0MjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxnPjxwYXRoIHN0eWxlPSJmaWxsOiM5Nzk3OTc7IiBkPSJNMjYyLjMxNCwzMDkuODU1aC0xMjUuOTJsLTI5Ljk1Nyw4OC44NTVIMzMuMzIyTDE2My44MTMsNDMuMjloNzEuNTkyTDM2NS44OTQsMzk4LjcxaC03Mi42MDhMMjYyLjMxNCwzMDkuODU1eiBNMTU1LjE4MSwyNTYuNTQyaDg5LjM2MmwtNDUuMTg5LTEyOC45NjZMMTU1LjE4MSwyNTYuNTQyeiIvPjwvZz48cmVjdCB4PSIzODguNjc4IiBzdHlsZT0iZmlsbDojOTc5Nzk3OyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjQ0MiIvPjwvZz48L3N2Zz4=)',
    },
    '& .public-DraftEditor-content': {
      maxHeight: 180,
      minHeight: 120,
    },
  },
}))

const customStyleMap = {
  HIGHLIGHT_DARKER_COLOR: {
    color: colors.highlightDarker,
  },
  SECONDARY_COLOR: {
    color: colors.secondary,
  },
}

const exportOptions = {
  inlineStyles: {
    HIGHLIGHT_DARKER_COLOR: {
      attributes: { class: 'highlightColor' },
      style: { color: colors.highlightDarker },
    },
    SECONDARY_COLOR: {
      attributes: { class: 'secondaryColor' },
      style: { color: colors.secondary },
    },
  },
}

const importOptions = {
  customInlineFn: (
    element: Element,
    {
      Style,
    }: {
      Style: (className: string) => {
        type: 'STYLE'
        style: string
      }
    }
  ) => {
    switch (element.className) {
      case 'highlightColor':
        return Style('HIGHLIGHT_DARKER_COLOR')
      case 'secondaryColor':
        return Style('SECONDARY_COLOR')
      default:
        return null
    }
  },
}

const toolbarConfig = {
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN'],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Highlight color', style: 'HIGHLIGHT_DARKER_COLOR', className: 'highlight' },
    { label: 'Secondary color', style: 'SECONDARY_COLOR', className: 'secondary' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
  ],
} as ToolbarConfig

const hasUpdatedDescription = (description: string, value: EditorValue) =>
  description && description !== value.toString('html', exportOptions)

const DescriptionField = () => {
  const classes = useStyles()

  const { setFieldValue, values } = useFormikContext<SendEmailFormType>()

  const [textEditorValue, setTextEditorValue] = useState(RichTextEditor.createEmptyValue())

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetFieldValue = useCallback(
    _.debounce((text) => {
      setFieldValue('description', text.toString('html', exportOptions))
    }, 1000),
    [setFieldValue]
  )

  useEffect(() => {
    debouncedSetFieldValue(textEditorValue)
  }, [textEditorValue, debouncedSetFieldValue])

  useEffect(() => {
    setTextEditorValue((oldValue) => {
      if (hasUpdatedDescription(values.description, oldValue)) {
        return RichTextEditor.createValueFromString(values.description, 'html', importOptions)
      }
      return oldValue
    })
  }, [values.description])

  return (
    <Box mt={4}>
      <RichTextEditor
        className={classes.richTextEditor}
        customStyleMap={customStyleMap}
        onChange={setTextEditorValue}
        toolbarConfig={toolbarConfig}
        value={textEditorValue}
      />
      <Box mt={1}>
        <ErrorMessage
          name="description"
          component={(props) => <Typography color="error" variant="body2" {...props} />}
        />
      </Box>
      <Box mt={1}>
        <Typography color="textSecondary" variant="body2">
          You can use the following variables in your templates: <Code>%firstname%</Code>, <Code>%preferredName%</Code>,{' '}
          <Code>%lastname%</Code> and <Code>%email%</Code>. Preferred name will use first name if the user hasn&apos;t
          set a preferred name.
        </Typography>
      </Box>
    </Box>
  )
}

export default DescriptionField
