import React from 'react'
import { Button, Link } from '@mui/material'

type Props = {
  eligibilityID: string
}

export function AperoEligibilityDetailsLink({ eligibilityID }: Props) {
  const url = `https://app.aperohealth.com/dash/eligibility/${eligibilityID}`
  return (
    <Button component={Link} href={url} target="_blank" variant="outlined">
      Open Details in Apero
    </Button>
  )
}
