import React, { useRef, useState } from 'react'
import { Box, Button, Chip, Popover, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Cancel, KeyboardArrowDown } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: theme.spacing(1),
    textTransform: 'none',
  },
  chipSpacing: {
    padding: theme.spacing(0.5),
  },
  popover: ({ width }: { width?: number }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    width,
  }),
  tagWrapper: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
  },
  actionButtons: {
    textAlign: 'end',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

export type Filters = {
  tags: Array<string>
  unreads: boolean
}

type Props = {
  tags: Array<string>
  updateFilters: (filters: Partial<Filters>) => void
  filters: Filters
}

const ParticipantListFilters = ({ filters, tags, updateFilters }: Props) => {
  const [open, setOpen] = useState(false)

  const anchorRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const styles = useStyles({ width: parentRef.current?.offsetWidth })

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectTag = (tag: string) => () => {
    if (filters.tags.includes(tag)) {
      const updatedFilters = filters.tags.filter((existingTag) => existingTag !== tag)

      updateFilters({ tags: updatedFilters })
    } else {
      updateFilters({ tags: [...filters.tags, tag] })
    }
  }

  const handleClear = () => {
    updateFilters({ tags: [], unreads: false })
  }

  const handleSelectUnreads = () => {
    updateFilters({ unreads: !filters.unreads })
  }

  return (
    <div ref={parentRef}>
      <Box display="inline-flex" alignItems="center" flexWrap="wrap">
        <Button
          color="inherit"
          classes={{ root: styles.button }}
          onClick={() => setOpen((prevOpen) => !prevOpen)}
          endIcon={<KeyboardArrowDown />}
        >
          <Typography ref={anchorRef}>Filter by</Typography>
        </Button>
        {filters.tags.map((tag) => (
          <Box className={styles.chipSpacing} key={tag}>
            <Chip
              color="primary"
              label={tag}
              onDelete={handleSelectTag(tag)}
              deleteIcon={<Cancel titleAccess="delete filter" />}
            />
          </Box>
        ))}
        {filters.unreads && (
          <Box className={styles.chipSpacing}>
            <Chip
              color="primary"
              label="Unreads"
              onDelete={handleSelectUnreads}
              deleteIcon={<Cancel titleAccess="delete filter" />}
            />
          </Box>
        )}

        <Popover
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box className={styles.tagWrapper}>
            <Box>
              <Typography>Tags</Typography>
              <Box className={styles.popover}>
                {tags.map((tag) => (
                  <Box className={styles.chipSpacing} key={tag}>
                    <Chip
                      clickable
                      color={filters.tags.includes(tag) ? 'primary' : undefined}
                      label={tag}
                      variant={filters.tags.includes(tag) ? undefined : 'outlined'}
                      onClick={handleSelectTag(tag)}
                    />
                  </Box>
                ))}
                {tags.length === 0 && (
                  <Box className={styles.chipSpacing}>
                    <Chip label="No tags available" variant="outlined" />
                  </Box>
                )}
              </Box>
            </Box>

            <Box>
              <Typography>Indicators</Typography>
              <Box className={styles.popover}>
                <Box className={styles.chipSpacing}>
                  <Chip
                    clickable
                    color="primary"
                    label="Unreads"
                    variant={filters.unreads ? undefined : 'outlined'}
                    onClick={handleSelectUnreads}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={styles.actionButtons}>
              <Button color="inherit" onClick={handleClear}>
                Clear
              </Button>
              <Button color="primary" onClick={handleClose}>
                Close
              </Button>
            </Box>
          </Box>
        </Popover>
      </Box>
    </div>
  )
}

export default ParticipantListFilters
