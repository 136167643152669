import React, { ReactNode } from 'react'
import moment from 'moment'
import { ItemWithSideInfo } from 'src/timeline'
import MessageMenu from './MessageMenu'

export type Linkable = {
  id: string
}

export type MessageContent = {
  id: string
  authorID: string
  createdAt: number
  updatedAt?: number
}

export type MessageProps = {
  message: MessageContent
  direction: 'incoming' | 'outgoing'
  authorName?: ReactNode
  menuItems?: ReactNode
  actions?: ReactNode
}

const Message: React.FC<MessageProps> = ({ message, direction, authorName, menuItems, actions, children }) => {
  const { createdAt, updatedAt } = message
  const messageTime = moment(updatedAt || createdAt)
  const alignment = direction === 'incoming' ? 'left' : 'right'
  return (
    <ItemWithSideInfo displayedTime={messageTime} metadata={authorName} alignItem={alignment} actions={actions}>
      {menuItems && <MessageMenu>{menuItems}</MessageMenu>}
      {children}
    </ItemWithSideInfo>
  )
}

export default Message
