/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../graphql.generated'

const defaultOptions = {} as const
export type CreateCoachMutationVariables = Types.Exact<{
  email: Types.Scalars['String']['input']
}>

export type CreateCoachMutation = {
  __typename?: 'Mutation'
  createCoach: { __typename?: 'CoachProfile'; id: string; email: string }
}

export const CreateCoachDocument = gql`
  mutation createCoach($email: String!) {
    createCoach(email: $email) {
      id
      email
    }
  }
`
export type CreateCoachMutationFn = Apollo.MutationFunction<CreateCoachMutation, CreateCoachMutationVariables>

/**
 * __useCreateCoachMutation__
 *
 * To run a mutation, you first call `useCreateCoachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachMutation, { data, loading, error }] = useCreateCoachMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateCoachMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCoachMutation, CreateCoachMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateCoachMutation, CreateCoachMutationVariables>(CreateCoachDocument, options)
}
export type CreateCoachMutationHookResult = ReturnType<typeof useCreateCoachMutation>
export type CreateCoachMutationResult = Apollo.MutationResult<CreateCoachMutation>
export type CreateCoachMutationOptions = Apollo.BaseMutationOptions<CreateCoachMutation, CreateCoachMutationVariables>
