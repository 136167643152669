import React from 'react'
import { Field } from 'formik'
import { InputAdornment, Grid } from '@mui/material'
import { CustomTextField } from 'src/form'

type Props = {
  name?: string
}

const PriceField = ({ name, label }: { name: string; label: string }) => (
  <Field
    name={name}
    label={label}
    component={CustomTextField}
    type="number"
    fullWidth
    InputProps={{
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
  />
)

const CostEstimateFields = ({ name = 'costEstimate' }: Props) => (
  <>
    <Grid container spacing={2} sx={{ mb: 1 }}>
      <Grid item xs={4}>
        <PriceField name={`${name}.intakeCostEstimate`} label="Intake cost estimate" />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <PriceField name={`${name}.month1CostEstimate`} label="Month 1 cost estimate" />
      </Grid>
      <Grid item xs={4}>
        <PriceField name={`${name}.month2CostEstimate`} label="Month 2 cost estimate" />
      </Grid>
      <Grid item xs={4}>
        <PriceField name={`${name}.month3CostEstimate`} label="Month 3 cost estimate" />
      </Grid>
    </Grid>
  </>
)

export default CostEstimateFields
