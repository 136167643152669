import { FeedItemFragment, GetFeedItemsQuery } from './feedQueries.generated'

// Filter out feed item types that don't have itemID defined
// That's because codegen adds types for all union types. Even for those we are
// not yet selecting. Those are not in feed items so we can safely filter those
// types out. This can be removed when all the types have been added.
type WithItemIds<T> = T extends { itemID: string } ? T : never

/**
 * Selected feed item
 */
export type FeedItem = WithItemIds<FeedItemFragment>

export const getFeedItems = (data: GetFeedItemsQuery | undefined) =>
  data?.feed.__typename === 'FeedSuccess' ? (data.feed.items as FeedItem[]) : []
