import { MenuItem } from '@mui/material'
import { Field } from 'formik'
import React from 'react'
import { CustomTextField } from 'src/form'

const GENDER_IDENTITY_SELECTIONS = [
  { value: 'FEMALE', label: 'Female' },
  { value: 'MALE', label: 'Male' },
  { value: 'TRANSGENDER_FEMALE_TO_MALE', label: 'Transgender Male' },
  { value: 'TRANSGENDER_MALE_TO_FEMALE', label: 'Transgender Female' },
  { value: 'NON_BINARY', label: 'Non Binary' },
  { value: 'GENDER_FLUID', label: 'Gender Fluid' },
  { value: 'PREFER_NOT_DISCLOSE', label: 'Prefer Not Disclose' },
]

export const GenderIdentityField = () => (
  <Field component={CustomTextField} fullWidth label="Gender Identity" name="patient.genderIdentity" type="select">
    {GENDER_IDENTITY_SELECTIONS.map(({ value, label }) => (
      <MenuItem key={value} value={value}>
        {label}
      </MenuItem>
    ))}
  </Field>
)
