import React, { KeyboardEvent, FormEvent, useEffect, useRef } from 'react'
import { Button, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import Textarea, { TextareaAutosizeProps } from 'react-textarea-autosize'
import { trackChatInputFocus, trackChatSend } from 'src/mixpanel'

export type TypingFieldProps = TextareaAutosizeProps & {
  value: string
  variant?: 'dark' | 'light'
  onSubmit: () => void
  onTyping: (value: string) => void
  grabFocus?: boolean
  disabled?: boolean
  linkedContent?: React.ReactNode
}

export default function TypingField(props: TypingFieldProps) {
  const { grabFocus, value, onSubmit, onTyping, disabled, linkedContent, ...extraTextAreaProps } = props
  const styles = useStyles(props)
  const handleSubmit = (event?: FormEvent) => {
    event?.preventDefault()
    onSubmit()
    trackChatSend()
  }
  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    // Send with RETURN and add new line with SHIFT + RETURN
    const { key, shiftKey } = event.nativeEvent
    if (key === 'Enter' && !shiftKey) {
      event.preventDefault()
      if (!disabled) {
        handleSubmit()
      }
    }
  }

  const inputRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (grabFocus && inputRef.current !== null) {
      inputRef.current.focus()
    }
  }, [grabFocus])
  return (
    <div className={styles.container}>
      {linkedContent}
      <form onSubmit={handleSubmit} className={styles.formContent}>
        <Textarea
          ref={inputRef}
          minRows={1}
          maxRows={6}
          className={styles.textarea}
          onChange={(event) => onTyping(event.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={() => trackChatInputFocus(true)}
          onBlur={() => trackChatInputFocus(false)}
          placeholder="Type a message..."
          value={value}
          {...extraTextAreaProps}
        />
        <Box className={styles.controls}>
          <Button type="submit" color="primary" variant="contained" disabled={disabled}>
            Send
          </Button>
        </Box>
      </form>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.1)',
    background: ({ variant = 'light' }: TypingFieldProps) =>
      variant === 'light' ? theme.palette.common.white : '#F5F7F6',
  },
  formContent: {
    display: 'flex',
  },
  textarea: {
    flex: 1,
    ...theme.typography.body1,
    padding: theme.spacing(2, 3),
    background: 'transparent',
    border: 'none',
    resize: 'none',
    outline: 'none',
  },
  controls: {
    alignSelf: 'flex-end',
    padding: theme.spacing(1, 3),
  },
}))
