import moment, { Moment } from 'moment'

export default function formatRelativeTime(time: Moment, from: Moment = moment()) {
  const defaultFormat = time.year() === from.year() ? 'ddd, MMM Do' : 'MMM Do, YYYY'
  return time.calendar(from, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastDay: '[Yesterday]',
    nextWeek: defaultFormat,
    lastWeek: () => `[${time.from(from)}]`,
    sameElse: defaultFormat,
  })
}
