import React from 'react'

import { PrivateUserData } from 'src/legacy/models/firebase'
import { isSuccess, useFirebase } from 'src/firebase'
import { FormatTime } from 'src/date'

type Props = {
  uid: string
  time?: number
}

const ParticipantTime: React.FunctionComponent<Props> = (props) => {
  const { uid, time } = props
  const request = useFirebase<PrivateUserData>(`privateUserData/${uid}`)
  if (!isSuccess(request) || !time) {
    return null
  }
  return (
    <>
      <FormatTime timezone={request.data?.timezone} value={time} /> for participant
    </>
  )
}

export default ParticipantTime
