/* eslint-disable  @typescript-eslint/no-explicit-any, camelcase */
/* Generated types. Do not edit by hand */
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../graphql.generated'

import { CommunityPostReplyFragmentFragmentDoc } from './communityPostReplyFragment.generated'
import { CommunityPostRepliesItemFragmentDoc } from './communityPostRepliesFragment.generated'

const defaultOptions = {} as const
export type ReplyToCommunityPostMutationVariables = Types.Exact<{
  postID: Types.Scalars['ID']['input']
  reply: Types.CommunityPostReplyInput
}>

export type ReplyToCommunityPostMutation = {
  __typename?: 'Mutation'
  replyToCommunityPost?:
    | {
        __typename: 'CommunityPostReplySended'
        reply: {
          __typename: 'CommunityPostReplyQuestionAnswer'
          replyID: string
          createdAt: Date
          isHidden: boolean
          message: string
          replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
        }
        communityPost: {
          __typename?: 'CommunityPostQuestion'
          communityPostReplies: {
            __typename: 'CommunityPostReplies'
            id: string
            numberOfPatientsReplied: number
            canReplyToPost: boolean
            replyingStatus: string
            repliedMyself: boolean
            replies?: Array<{
              __typename: 'CommunityPostReplyQuestionAnswer'
              replyID: string
              createdAt: Date
              isHidden: boolean
              message: string
              replyAuthor?: { __typename?: 'CommunityFacilitator'; name: string } | null
            }> | null
          }
        }
      }
    | { __typename: 'CommunityPostReplySendingPrevented'; reason: string }
    | null
}

export const ReplyToCommunityPostDocument = gql`
  mutation replyToCommunityPost($postID: ID!, $reply: CommunityPostReplyInput!) {
    replyToCommunityPost(postID: $postID, reply: $reply) {
      __typename
      ... on CommunityPostReplySended {
        reply {
          ...CommunityPostReplyFragment
        }
        communityPost {
          communityPostReplies {
            ...CommunityPostRepliesItem
          }
        }
      }
      ... on CommunityPostReplySendingPrevented {
        reason
      }
    }
  }
  ${CommunityPostReplyFragmentFragmentDoc}
  ${CommunityPostRepliesItemFragmentDoc}
`
export type ReplyToCommunityPostMutationFn = Apollo.MutationFunction<
  ReplyToCommunityPostMutation,
  ReplyToCommunityPostMutationVariables
>

/**
 * __useReplyToCommunityPostMutation__
 *
 * To run a mutation, you first call `useReplyToCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyToCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyToCommunityPostMutation, { data, loading, error }] = useReplyToCommunityPostMutation({
 *   variables: {
 *      postID: // value for 'postID'
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useReplyToCommunityPostMutation(
  baseOptions?: Apollo.MutationHookOptions<ReplyToCommunityPostMutation, ReplyToCommunityPostMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReplyToCommunityPostMutation, ReplyToCommunityPostMutationVariables>(
    ReplyToCommunityPostDocument,
    options
  )
}
export type ReplyToCommunityPostMutationHookResult = ReturnType<typeof useReplyToCommunityPostMutation>
export type ReplyToCommunityPostMutationResult = Apollo.MutationResult<ReplyToCommunityPostMutation>
export type ReplyToCommunityPostMutationOptions = Apollo.BaseMutationOptions<
  ReplyToCommunityPostMutation,
  ReplyToCommunityPostMutationVariables
>
