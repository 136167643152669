import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useGetCommunityPostsItemsQuery } from './communityPostsQuery.generated'
import { PostItem, PostItemSkeleton } from './PostItem'

export default function CommunityPostsList() {
  const { data, loading } = useGetCommunityPostsItemsQuery()

  if (loading && !data) {
    return (
      <div role="progressbar" aria-busy aria-live="polite">
        <PostItemSkeleton />
        <PostItemSkeleton />
        <PostItemSkeleton />
      </div>
    )
  }

  return (
    <Box overflow="scroll">
      {data?.communityPosts.posts.map((post) => (
        <PostItemLink postID={post.postID} key={post.postID}>
          <PostItem
            title={post.title}
            label={post.community.name}
            replyingStatus={post.communityPostReplies.replyingStatus}
          />
        </PostItemLink>
      ))}
    </Box>
  )
}

function PostItemLink({ postID, children }: { postID: string; children: React.ReactNode }) {
  const path = `/communities/${postID}`
  const isActive = Boolean(useRouteMatch({ path }))
  const styles = useStyles({ isActive })
  return (
    <Link to={path} className={styles.link} aria-current={isActive ? 'page' : undefined}>
      {children}
    </Link>
  )
}

const useStyles = makeStyles({
  link: ({ isActive }: { isActive: boolean }) => ({
    textDecoration: 'none !important',
    backgroundColor: isActive ? 'rgba(109, 182, 108, 0.08)' : undefined,
  }),
})
