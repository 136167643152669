import React from 'react'
import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow } from '@mui/material'

import { Loader } from 'src/designSystem'
import { ReferralType } from 'src/legacy/models/firebase'
import { isSuccess, useFirebase } from 'src/firebase'
import { getReferralTypes } from 'src/legacy/selectors/referralTypes'
import { DeleteButton, EmptyPlaceholder, TableCellIcon } from 'src/legacy/components/mui'
import AddTitleForm from './AddTitleForm'

type Props = {
  addFn: (title: string) => void
  deleteFn: (referralTypeID: string) => void
}

interface ReferralTypeWithID extends ReferralType {
  referralTypeID: string
}

const ReferralTypes: React.FunctionComponent<Props> = (props) => {
  const { addFn, deleteFn } = props
  const request = useFirebase<ReferralTypeWithID[]>('referralTypes', getReferralTypes)
  if (!isSuccess(request)) {
    return <Loader size="large" />
  }
  if (!request.data) {
    return <EmptyPlaceholder />
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Referral Type</TableCell>
          <TableCellIcon>Delete</TableCellIcon>
        </TableRow>
      </TableHead>
      <TableBody>
        {request.data.map((referralType) => (
          <TableRow key={referralType.referralTypeID}>
            <TableCell>{referralType.title}</TableCell>
            <TableCellIcon>
              <DeleteButton onDelete={() => deleteFn(referralType.referralTypeID)} title="Delete Referral Type" />
            </TableCellIcon>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell align="right" colSpan={2}>
            <Box display="flex" justifyContent="flex-end">
              <AddTitleForm onSubmit={addFn} />
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  )
}

export default ReferralTypes
