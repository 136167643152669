/* eslint-disable max-len */
import { Box, Grid, Typography } from '@mui/material'
import { FastField, useFormikContext } from 'formik'
import _ from 'lodash'
import React from 'react'
import { TREATMENT_PLAN_ENABLED } from 'src/config'
import { CustomCheckbox, CustomRadio, CustomRadioGroup, CustomTextField } from 'src/form'
import { IntakeNoteEmploymentStatus } from 'src/graphql.generated'
import AutofillIntakeNoteButton from './AutofillIntakeNoteButton'
import BillingCodes from './BillingCodes'
import ExclusionCriteriaFields from './ExclusionCriteriaFields'
import intakeNoteAutofillEnabled from './intakeNoteAutofillEnabled'
import { FormikContext, IntakeNoteSchema } from './intakeNoteSchemas'
import usePreloadIntakeNoteValues from './usePreloadIntakeNoteValues'

const EMPTY_PREFILL_INPUT = '-'

type Props = {
  uid: string
}

function isOtherReasonVisible(schema: Partial<IntakeNoteSchema>, path: keyof IntakeNoteSchema) {
  const options = schema?.[path]
  return Boolean(_.get(options, 'OTHER_REASON', ''))
}

type ParticipantProvidedValueProps = {
  values: FormikContext
  label: string
  name: string
}

const ParticipantProvidedValue = (props: ParticipantProvidedValueProps) => {
  const { values, label, name } = props

  const value = _.get(values, name, EMPTY_PREFILL_INPUT)

  return (
    <Box mt={SECTION_ITEM_MARGIN_TOP}>
      <Typography variant="h4">{label}</Typography>
      <Typography variant="body1" color="textSecondary">
        {value}
      </Typography>
    </Box>
  )
}

type GridItem = {
  name: string
  label: string
}
type FieldGridProps = {
  title: string
  items: GridItem[]
}

// The order of these fields
const EMPLOYMENT_ITEMS = [
  IntakeNoteEmploymentStatus.Working,
  IntakeNoteEmploymentStatus.Student,
  IntakeNoteEmploymentStatus.Disabled,
  IntakeNoteEmploymentStatus.Retired,
  IntakeNoteEmploymentStatus.Unemployed,
]

const EMPLOYMENT_ITEM_LABELS = {
  WORKING: 'Working',
  STUDENT: 'Student',
  DISABLED: 'Disabled',
  RETIRED: 'Retired',
  UNEMPLOYED: 'Unemployed',
}

const healthHistoryItems: Array<GridItem> = [
  { name: 'value.HEALTH_HISTORY.THERAPY', label: 'Therapy' },
  { name: 'value.HEALTH_HISTORY.SUICIDALITY', label: 'Suicidality' },
  { name: 'value.HEALTH_HISTORY.GENERALISED_ANXIETY', label: 'Generalised Anxiety' },
  { name: 'value.HEALTH_HISTORY.DEPRESSION', label: 'Depression' },
  { name: 'value.HEALTH_HISTORY.BIPOLAR', label: 'Bipolar' },
  { name: 'value.HEALTH_HISTORY.PTSD', label: 'PTSD' },
  { name: 'value.HEALTH_HISTORY.DTD', label: 'DTD' },
  { name: 'value.HEALTH_HISTORY.PSYCHOSIS', label: 'Psychosis' },
  { name: 'value.HEALTH_HISTORY.SUBSTANCE_ABUSE', label: 'Substance abuse' },
]

const exclusionCriteria = new Set([
  'value.HEALTH_HISTORY.SUICIDALITY',
  'value.HEALTH_HISTORY.BIPOLAR',
  'value.HEALTH_HISTORY.PTSD',
  'value.HEALTH_HISTORY.DTD',
  'value.HEALTH_HISTORY.PSYCHOSIS',
])

const achievementItems: Array<GridItem> = [
  { name: 'value.EXPECTED_ACHIEVEMENTS.CONTROL_EMOTIONS_AND_BEHAVIOUR', label: 'Control over emotions and behavior' },
  { name: 'value.EXPECTED_ACHIEVEMENTS.SELF_ESTEEM_AND_CONFIDENCE', label: 'Self-esteem and confidence' },
  { name: 'value.EXPECTED_ACHIEVEMENTS.SENSE_OF_CALM', label: 'Sense of calm' },
  { name: 'value.EXPECTED_ACHIEVEMENTS.MOTIVATION_AND_ENERGY', label: 'Motivation and energy' },
  { name: 'value.EXPECTED_ACHIEVEMENTS.OTHER', label: 'Other' },
]

const SECTION_ITEM_MARGIN_TOP = 3
const INPUT_FIELD_BOX_MARGIN_TOP = 1

const FieldGrid = (props: FieldGridProps) => {
  const { title, items } = props
  return (
    <Box mt={SECTION_ITEM_MARGIN_TOP} mx={0}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        {items.map((item) => (
          <Grid item xs={4} key={item.name}>
            <FastField component={CustomCheckbox} name={item.name} label={item.label} condensed />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

type IntakeNoteSectionProps = {
  title: string
}

const IntakeNoteSection: React.FunctionComponent<IntakeNoteSectionProps> = (props) => (
  <>
    <Grid item xs={12} mt={4}>
      <Typography variant="h2">{props.title}</Typography>
      {props.children}
    </Grid>
  </>
)

function shouldShowTreatmentPlan(values: IntakeNoteSchema, viewOnly: boolean) {
  const { EXPECTED_ACHIEVEMENTS, TREATMENT_GOALS, OBSTACLES_AND_ACTION_PLAN } = values
  if (!viewOnly) {
    return TREATMENT_PLAN_ENABLED === 'false'
  }
  return TREATMENT_PLAN_ENABLED === 'false' || EXPECTED_ACHIEVEMENTS || TREATMENT_GOALS || OBSTACLES_AND_ACTION_PLAN
}

const IntakeNoteFields = (props: Props) => {
  const { uid } = props
  const { setFieldValue, values, status = {} } = useFormikContext<FormikContext>()
  const { viewOnly = false } = status
  const { value: fieldValues = {} as IntakeNoteSchema } = values
  const autofillIntakeNoteButtonVisible = !viewOnly && intakeNoteAutofillEnabled()

  usePreloadIntakeNoteValues(uid, values, viewOnly, setFieldValue)

  return (
    <Box>
      {autofillIntakeNoteButtonVisible && <AutofillIntakeNoteButton sx={{ mb: 2 }} />}
      <IntakeNoteSection title="Identifying information and treatment expectations">
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <FastField
            fullWidth
            multiline
            resizable
            minRows={3}
            component={CustomTextField}
            label="Identifying Information"
            name="value.IDENTIFYING_INFO"
          />
        </Box>
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <FastField
            fullWidth
            multiline
            resizable
            minRows={3}
            component={CustomTextField}
            label="Presenting Problem / Reason for Referral"
            name="value.EXPECTATIONS_AND_INTERESTS"
            helperText="Include treatment expectations"
          />
        </Box>
      </IntakeNoteSection>
      <IntakeNoteSection title="Clinical Assessment">
        <Box mt={SECTION_ITEM_MARGIN_TOP} mx={0}>
          <Typography variant="h4">Are you currently…</Typography>
          <FastField component={CustomRadioGroup} name="value.EMPLOYMENT.STATUS">
            {EMPLOYMENT_ITEMS.map((employmentStatus) => (
              <CustomRadio
                key={employmentStatus}
                label={EMPLOYMENT_ITEM_LABELS[employmentStatus]}
                value={employmentStatus}
              />
            ))}
          </FastField>
        </Box>
        <ExclusionCriteriaFields
          title="Health History"
          items={healthHistoryItems}
          exclusionCriteria={exclusionCriteria}
        />
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <FastField
            fullWidth
            multiline
            resizable
            minRows={3}
            component={CustomTextField}
            name="value.TIMELINE_OF_SYMPTOMS_AND_TREATMENT"
            label="Clinical formulation and timeline of symptoms"
            helperText="Symptom diagnosis justification and functioning"
          />
        </Box>
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FastField
                fullWidth
                type="number"
                component={CustomTextField}
                name="value.MAJOR_DEPRESSION_EPISODES"
                label="Previous MDD episodes"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <FastField
            fullWidth
            multiline
            resizable
            minRows={3}
            component={CustomTextField}
            name="value.SUICIDE_RISK"
            label="Suicide Risk Assessment"
            helperText="Previous suicide attempts, hospitalizations, current SI, intent, protective factors and safety plan"
          />
        </Box>
        <Box mt={SECTION_ITEM_MARGIN_TOP}>
          <Typography variant="h4">Billing code and diagnosis</Typography>
          <BillingCodes name="value.BILLING_CODES" />
        </Box>
      </IntakeNoteSection>
      {shouldShowTreatmentPlan(fieldValues, viewOnly) && (
        <IntakeNoteSection title="Treatment plan">
          <FieldGrid title="What would you like to have more of in your life?" items={achievementItems} />
          {isOtherReasonVisible(fieldValues, 'EXPECTED_ACHIEVEMENTS') && (
            <ParticipantProvidedValue
              name="value.EXPECTED_ACHIEVEMENTS.OTHER_REASON"
              label="Reason for selecting other"
              values={values}
            />
          )}
          <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
            <FastField
              fullWidth
              multiline
              resizable
              minRows={3}
              component={CustomTextField}
              name="value.TREATMENT_GOALS"
              label="Treatment goals"
              helperText="Translate the answers provided by participant to concrete goals"
            />
          </Box>
          <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
            <FastField
              fullWidth
              multiline
              resizable
              minRows={3}
              component={CustomTextField}
              name="value.OBSTACLES_AND_ACTION_PLAN"
              label="Obstacles and Action Plan"
              helperText="Use an importance and confidence scale from 1-10 to understand readiness, what is motivating them, identify possible obstacles and set an action plan"
            />
          </Box>
        </IntakeNoteSection>
      )}
      {viewOnly && fieldValues.INTERNAL_EXTERNAL_STRESSORS && (
        <Box mt={INPUT_FIELD_BOX_MARGIN_TOP}>
          <FastField
            fullWidth
            multiline
            resizable
            minRows={3}
            component={CustomTextField}
            name="value.INTERNAL_EXTERNAL_STRESSORS"
            label="Internal and External stressors"
          />
        </Box>
      )}
      <ParticipantProvidedValue
        name="value.OBSTACLES"
        values={values}
        label="Is there something that you want your therapist to know?"
      />
      <IntakeNoteSection title="Confirmation and Next steps">
        <FastField
          fullWidth
          component={CustomCheckbox}
          name="value.PARTICIPANT_INELIGIBLE"
          label="The participant is not eligible for the program"
          helperText="The participant is not eligible for the treatment, incomplete intake notes can be saved"
        />
        <FastField
          fullWidth
          component={CustomCheckbox}
          name="value.STARTING_DATE_CONFIRMED"
          label="The participant is eligible for the program"
          helperText="Remember to agree on a starting date with the participant"
        />
        <FastField
          component={CustomCheckbox}
          fullWidth
          helperText="Review confidentiality and reporting requirements for suspected or known abuse of minors, elderly or concern about imminent harm to self or others."
          label="Reviewed confidentiality and exceptions"
          name="value.CONSENT.REVIEWED_CONFIDENTIALITY_AND_EXCEPTIONS"
        />
        <FastField
          component={CustomCheckbox}
          fullWidth
          label="Therapist obtained verbal consent for treatment"
          name="value.CONSENT.VERBAL_CONSENT_FOR_TREATMENT"
        />
        <FastField
          fullWidth
          component={CustomCheckbox}
          name="value.CREATE_QUICK_NOTE"
          label="Create quick note"
          helperText="Add stressors, goals, and other talking points to be used during treatment"
        />
      </IntakeNoteSection>
    </Box>
  )
}

export default IntakeNoteFields
