import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
}))

type Props = {
  actions: JSX.Element[]
  open: boolean
}

const SolidOverlayDialog: React.FunctionComponent<Props> = ({ actions, children, open }) => {
  const classes = useStyles()
  return (
    <Dialog BackdropProps={{ classes }} fullWidth maxWidth="xs" open={open}>
      <DialogContent>
        <DialogContentText color="textPrimary">{children}</DialogContentText>
      </DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  )
}

export default SolidOverlayDialog
