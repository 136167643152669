import _ from 'lodash'
import React from 'react'
import { Field, useFormikContext } from 'formik'

import { CustomCheckbox, CustomTextField } from 'src/form'

const CustomFields = () => {
  const { values } = useFormikContext<{ value: Record<string, string | boolean> }>()
  return (
    <>
      {_.map(values.value, (value, label) => {
        const name = `value["${label}"]`
        if (_.isBoolean(value)) {
          return <Field component={CustomCheckbox} fullWidth key={label} label={label} name={name} />
        }
        return (
          <Field component={CustomTextField} fullWidth key={label} label={label} multiline name={name} minRows={4} />
        )
      })}
    </>
  )
}

export default CustomFields
