import { Field, Form, Formik } from 'formik'
import React from 'react'
import { FormDatePicker } from 'src/form'
import * as Yup from 'yup'
import { PatientHistoryType } from 'src/graphql.generated'
import _ from 'lodash'
import { DateTime } from 'luxon'
import useAddPatientHistoryEntry from 'src/participantHistory/useAddPatientHistoryEntry'
import ScoreFields, { getScoreValueSchema } from './ScoreFields'
import SubmitButton from './SubmitButton'

const WHO5_QUESTIONS = [
  {
    key: 'WHO5_QUESTION_1',
    label: 'I have felt cheerful and in good spirits.',
  },
  {
    key: 'WHO5_QUESTION_2',
    label: 'I have felt calm and relaxed.',
  },
  {
    key: 'WHO5_QUESTION_3',
    label: 'I have felt active and vigorous.',
  },
  {
    key: 'WHO5_QUESTION_4',
    label: 'I woke up feeling fresh and rested.',
  },
  {
    key: 'WHO5_QUESTION_5',
    label: 'My daily life has been filled with things that interest me.',
  },
  {
    key: 'WHO5_SCORE',
    label: 'Score',
  },
]

const SCORE_RANGE = {
  VALUE_MIN: 0,
  VALUE_MAX: 5,
}
const ScoreValueSchema = getScoreValueSchema(SCORE_RANGE)

const WHO5_FORM_SCHEMA = Yup.object()
  .shape({
    date: Yup.string().default(DateTime.now().toFormat('yyyy-MM-dd')).required(),
    WHO5_QUESTION_1: ScoreValueSchema,
    WHO5_QUESTION_2: ScoreValueSchema,
    WHO5_QUESTION_3: ScoreValueSchema,
    WHO5_QUESTION_4: ScoreValueSchema,
    WHO5_QUESTION_5: ScoreValueSchema,
    WHO5_SCORE: Yup.number().required().min(0).max(25),
  })
  .required()
type WHO5FormType = Yup.InferType<typeof WHO5_FORM_SCHEMA>

const WHO5Form = ({ uid }: { uid: string }) => {
  const addPatientHistoryEntry = useAddPatientHistoryEntry()
  return (
    <Formik<WHO5FormType>
      validationSchema={WHO5_FORM_SCHEMA}
      initialValues={WHO5_FORM_SCHEMA.default()}
      onSubmit={async (values, { resetForm }) => {
        const valuesWithoutDate = _.omit(values, ['date'])
        await addPatientHistoryEntry({
          uid,
          date: values.date,
          type: PatientHistoryType.Who5,
          value: {
            ...valuesWithoutDate,
          },
        })
        resetForm()
      }}
    >
      {({ isSubmitting, values, setFieldValue }) => (
        <Form>
          <Field component={FormDatePicker} label="Date of event" name="date" />

          <ScoreFields
            type="WHO5"
            values={_.omit(values, ['date'])}
            setFieldValue={setFieldValue}
            questions={WHO5_QUESTIONS}
          />

          <SubmitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  )
}

export default WHO5Form
