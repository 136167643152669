import React from 'react'
import { FastField, useFormikContext } from 'formik'
import _ from 'lodash'
import { Box, Typography, Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Info } from '@mui/icons-material'
import { CustomCheckbox } from 'src/form'
import { CustomTooltip } from 'src/designSystem'

const useStyles = makeStyles((theme) => ({
  exclusionIcon: {
    color: theme.palette.info.main,
  },
}))

const ExclusionWarning = () => {
  const styles = useStyles()
  return (
    <CustomTooltip title="Risk of exclusion" placement="left">
      <Info titleAccess="Risk of exclusion" classes={{ root: styles.exclusionIcon }} />
    </CustomTooltip>
  )
}

type GridItem = {
  name: string
  label: string
}

export type Props = {
  title: string
  items: GridItem[]
  exclusionCriteria: Set<string>
}

const SECTION_ITEM_MARGIN_TOP = 3

const ExclusionCriteriaFields = (props: Props) => {
  const { title, items, exclusionCriteria } = props
  const ctx = useFormikContext<unknown>()
  return (
    <Box mt={SECTION_ITEM_MARGIN_TOP} mx={0}>
      <Typography variant="h4">{title}</Typography>
      <Grid container>
        {items.map((item) => {
          const isSelected = _.get(ctx.values, item.name, false)
          const isExclusionCriterion = exclusionCriteria.has(item.name)
          return (
            <Grid item xs={4} key={item.name}>
              <Box display="flex" justifyContent="space-between" alignItems="center" pr={6}>
                <FastField component={CustomCheckbox} name={item.name} label={item.label} condensed />
                {isExclusionCriterion && isSelected && <ExclusionWarning />}
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default ExclusionCriteriaFields
