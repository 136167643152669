import React from 'react'
import { TableCell, TableCellProps } from '@mui/material'
import { ParticipantTableColumn } from './constants'
import useParticipantTableContext from './useParticipantTableContext'

interface Props extends TableCellProps {
  id: ParticipantTableColumn
}

const HideableTableCell: React.FC<Props> = (props) => {
  const { id, children, ...rest } = props
  const isColumnDisabled = useParticipantTableContext()

  if (isColumnDisabled(id)) {
    return null
  }
  return <TableCell {...rest}>{children}</TableCell>
}

export default HideableTableCell
