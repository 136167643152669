import PhoneNumber from 'awesome-phonenumber'

const COUNTRY_CODE = 'US'
const E164 = 'e164'
const NATIONAL = 'national'

export const parsePhoneNumber = (phoneNumber: string) => new PhoneNumber(phoneNumber, COUNTRY_CODE)

export const convertPhoneNumberToE164 = (phone: string | null | undefined) => {
  if (phone === undefined) return undefined

  if (phone === '' || phone === null) {
    return null
  }
  return parsePhoneNumber(phone).getNumber(E164)
}

export const convertPhoneNumberToNational = (phone: string | null | undefined) => {
  if (!phone) {
    return undefined
  }
  return parsePhoneNumber(phone).getNumber(NATIONAL)
}
