import { Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Check, ErrorOutline } from '@mui/icons-material'
import React from 'react'
import { SmsMessageStatus } from 'src/graphql.generated'

const errorStatuses = [SmsMessageStatus.Failed, SmsMessageStatus.Undelivered]

const sendingStatuses = [
  SmsMessageStatus.Accepted,
  SmsMessageStatus.Queued,
  SmsMessageStatus.Sending,
  SmsMessageStatus.Scheduled,
]

type Props = {
  status: SmsMessageStatus
}

const SmsStatusDisplay = ({ status }: Props) => {
  const styles = useStyles()

  if (errorStatuses.includes(status)) {
    return (
      <Typography variant="caption" color="error" className={styles.wrapIcon}>
        <ErrorOutline /> Failed to send
      </Typography>
    )
  }

  if (sendingStatuses.includes(status)) {
    return (
      <Typography variant="caption" className={styles.wrapIcon}>
        sending...
      </Typography>
    )
  }

  if (status === SmsMessageStatus.Sent) {
    return (
      <Typography variant="caption" color="primary" className={styles.wrapIcon}>
        <Check /> sent
      </Typography>
    )
  }

  if (status === SmsMessageStatus.Canceled) {
    return (
      <Typography variant="caption" className={styles.wrapIcon}>
        canceled
      </Typography>
    )
  }

  return null
}

const useStyles = makeStyles(() => ({
  wrapIcon: {
    alignItems: 'center',
    verticalAlign: 'middle',
    display: 'inline-flex',
  },
}))

export default SmsStatusDisplay
