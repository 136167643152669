// adapted from https://usehooks.com/useWindowSize/

import { useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'

type WindowSize = {
  width: number | undefined
  height: number | undefined
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    const throttledHandleResize = throttle(handleResize, 250)
    window.addEventListener('resize', throttledHandleResize)
    handleResize()
    return () => window.removeEventListener('resize', throttledHandleResize)
  }, [])

  return windowSize
}

const useWindowWidthEffect = (callback: (width: number) => void, extraDeps: unknown[] = []) => {
  const savedCallback = useRef<(width: number) => void>(callback)
  const { width } = useWindowSize()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (width) {
      savedCallback.current(width)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, ...extraDeps])
}

export default useWindowWidthEffect
