import { useMemo } from 'react'
import qs, { ParsedQs } from 'qs'
import _ from 'lodash'
import { useLocation } from 'react-router'
import { PatientSortField, PatientSortOrder, EnrollmentState, PatientProgramWeek } from 'src/graphql.generated'
import { ParticipantFilter } from './useLoadParticipantsWithFilter'

function isValidSortField(value?: unknown): value is PatientSortField {
  return typeof value === 'string' && Object.values(PatientSortField).includes(value as PatientSortField)
}

function isValidSortOrder(value?: unknown): value is PatientSortOrder {
  return typeof value === 'string' && Object.values(PatientSortOrder).includes(value as PatientSortOrder)
}

function isValidState(value: unknown): value is EnrollmentState {
  return typeof value === 'string' && Object.values(EnrollmentState).includes(value as EnrollmentState)
}

function isValidWeek(value: string): value is PatientProgramWeek {
  return Object.values(PatientProgramWeek).includes(value as PatientProgramWeek)
}

const filterValidStates = (
  // eslint-disable-next-line
  states: undefined | string | ParsedQs | any[] // Typescript doesn't work properly on unions of array types
): Array<EnrollmentState> => {
  if (Array.isArray(states)) {
    return states.filter(isValidState)
  }

  if (typeof states === 'string') {
    return isValidState(states) ? [states] : []
  }

  return []
}
const paramsToArray = (
  // eslint-disable-next-line
  value: undefined | string | ParsedQs | any[]
): Array<string> => {
  if (Array.isArray(value)) {
    return value
  }

  if (typeof value === 'string') {
    return [value]
  }

  return []
}

type QueryParams = {
  filter: ParticipantFilter
  pagination: {
    page: number
    pageSize: number
  }
  sort: {
    sortBy?: PatientSortField
    sortOrder?: PatientSortOrder
  }
}

export default function useParseSearchQuery(): QueryParams {
  const location = useLocation()

  const {
    statesOfResidence,
    providers,
    query,
    states,
    sources,
    weeks,
    tags,
    page,
    pageSize,
    sortBy,
    sortOrder,
    eligibilityUnconfirmed,
  } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const filter = useMemo(
    () => ({
      query: typeof query === 'string' ? query : undefined,
      states: filterValidStates(states),
      sources: paramsToArray(sources),
      providers: paramsToArray(providers),
      statesOfResidence: paramsToArray(statesOfResidence),
      weeks: paramsToArray(weeks).filter(isValidWeek),
      tags: paramsToArray(tags),
      eligibilityUnconfirmed: eligibilityUnconfirmed === 'true' ? true : undefined,
    }),
    [query, states, sources, providers, statesOfResidence, weeks, tags, eligibilityUnconfirmed]
  )

  return {
    filter,
    pagination: {
      page: page ? Number(page) : 0,
      pageSize: pageSize ? Number(pageSize) : 10,
    },
    sort: {
      sortBy: isValidSortField(sortBy) ? sortBy : undefined,
      sortOrder: isValidSortOrder(sortOrder) ? sortOrder : undefined,
    },
  }
}

type QueryUpdate = {
  [key in keyof QueryParams]?: Partial<QueryParams[key]>
}

export function updateQueryString(params: QueryParams, updates: QueryUpdate): string {
  const merged = _.mergeWith({}, params, updates, (original, updated) => {
    if (_.isArray(original)) {
      return updated
    }
    if (_.isObject(original)) {
      return { ...original, ...updated }
    }
    return updated
  })
  const updatedParams = Object.assign({}, ...Object.values(merged))
  const query = qs.stringify(updatedParams, { arrayFormat: 'brackets' })
  return query
}
