import { Button, ButtonProps, Tooltip } from '@mui/material'
import { useFormikContext } from 'formik'
import React from 'react'
import useAutofillIntakeNote from './useAutofillIntakeNote'

type Props = Omit<ButtonProps, 'children'>

export default function AutofillIntakeNoteButton(props: Props) {
  const autofillIntakeNote = useAutofillIntakeNote()
  const { submitForm } = useFormikContext()

  const autofillIntakeNoteAndSubmit = async () => {
    autofillIntakeNote()
    await submitForm()
  }

  return (
    <Tooltip title="Double click to autofill and submit">
      <Button onClick={autofillIntakeNote} onDoubleClick={autofillIntakeNoteAndSubmit} variant="outlined" {...props}>
        Autofill
      </Button>
    </Tooltip>
  )
}
