import React, { useEffect } from 'react'
import { FieldProps } from 'formik'
import { Checkbox, FormControl, FormControlLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import LoadingFieldIndicator from './LoadingFieldIndicator'

import { GenericFormProps, getError, hasError, isFormLoading, isViewOnly, useUnregisterFieldOnUnmount } from './helpers'
import { commonCheckboxStyles, errorStyle } from './styles'
import CustomFormHelperText from './CustomFormHelperText'

type Props = {
  disabled: boolean
  fullWidth: boolean
  condensed?: boolean
  helperText?: string
  label: string
}

const useStyles = makeStyles((theme) => ({
  ...commonCheckboxStyles(theme),
  checkbox: errorStyle({
    color: theme.palette.error.main,
  }),
  control: (props: Props) =>
    props.condensed
      ? {
          marginTop: 0,
        }
      : {},
}))

const CustomCheckbox: React.FunctionComponent<Props & GenericFormProps & FieldProps> = (props) => {
  const {
    disabled,
    fullWidth,
    field: { name, value = false },
    form: { setFieldValue },
    helperText,
    label,
  } = props

  const classes = useStyles(props)

  useEffect(() => {
    setFieldValue(name, value, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useUnregisterFieldOnUnmount(props)
  if (isFormLoading(props)) {
    return <LoadingFieldIndicator {...props} isCheckbox condensed={props.condensed} />
  }
  return (
    <FormControl
      disabled={isViewOnly(props) || disabled}
      fullWidth={fullWidth}
      classes={{
        root: classes.control,
      }}
    >
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        checked={value}
        control={<Checkbox className={classes.checkbox} color="primary" />}
        label={label}
        name={name}
        onChange={(_, checked) => setFieldValue(name, checked)}
        value={name}
      />
      {(hasError(props) || helperText) && (
        <CustomFormHelperText error={hasError(props)}>
          {hasError(props) ? getError(props) : helperText}
        </CustomFormHelperText>
      )}
    </FormControl>
  )
}

CustomCheckbox.defaultProps = {
  disabled: false,
  errorText: undefined,
  fullWidth: false,
  helperText: undefined,
  label: undefined,
  unregisterOnUnmount: true,
}

export default CustomCheckbox
