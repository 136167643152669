import _ from 'lodash'
import moment from 'moment'

const isValidDate = (date) => moment(date, 'YYYY-MM-DD', true).isValid()

const isValidDateTime = (date) => _.isString(date) && moment(date, moment.ISO_8601).isValid()

const TIMESTAMP_BOUNDARIES = [1451599200000, 4102437600000] // [2016-01-01, 2100-01-01]
const isValidTimestamp = (timestamp) =>
  _.isNumber(timestamp) && timestamp > TIMESTAMP_BOUNDARIES[0] && timestamp < TIMESTAMP_BOUNDARIES[1]

/**
 * Helper class for formatting dates in Meru Health programs.
 *
 * Date is relative to client time zone.
 *
 * The day changes at 3 AM in Meru Health program.
 */
class MeruDate {
  static now() {
    const meruDate = new MeruDate()
    meruDate.moment = moment().subtract(3, 'hours')
    return meruDate
  }

  constructor(date) {
    if (isValidDate(date)) {
      this.moment = moment(date, 'YYYY-MM-DD', true)
    } else if (isValidDateTime(date)) {
      this.moment = moment(date, moment.ISO_8601).subtract(3, 'hours')
    } else if (isValidTimestamp(date)) {
      this.moment = moment(date).subtract(3, 'hours')
    } else {
      this.moment = null
    }
  }

  formatDate() {
    return this.moment ? this.moment.format('l') : null
  }

  formatDayNumber(programStartDate) {
    if (!this.moment || !isValidDate(programStartDate)) {
      return null
    }
    const dayNumber = this.moment.add(1, 'day').diff(programStartDate, 'days')
    return dayNumber > 0 ? dayNumber : 0
  }

  formatISODate() {
    return this.moment ? this.moment.format('YYYY-MM-DD') : null
  }

  formatMonthDay() {
    return this.moment ? this.moment.format('MMM Do') : null
  }

  formatWeekDayNumber(programStartDate) {
    if (!this.moment || !isValidDate(programStartDate)) {
      return null
    }
    return ((this.moment.day() + 7 - moment(programStartDate, 'YYYY-MM-DD', true).day()) % 7) + 1
  }

  formatWeekNumber(programStartDate, showNegative = false) {
    if (!this.moment || !isValidDate(programStartDate)) {
      return null
    }
    // Add 1 week as at Meru the week of programStartDate is week 1
    const relativeWeekNumber = Math.floor(this.moment.diff(programStartDate, 'weeks', true)) + 1
    return showNegative ? relativeWeekNumber : Math.max(relativeWeekNumber, 0)
  }

  subtract(...args) {
    if (this.moment) {
      this.moment.subtract(...args)
    }
    return this
  }

  valueOf() {
    return this.moment ? this.moment.valueOf() : null
  }

  hasProgramStarted(programStartDate) {
    return this.formatDayNumber(programStartDate) > 0
  }

  daysFromProgramEnd(programStartDate, lastWeekNbr) {
    // Using with "new MeruDate(date) without time will give one day less than expected (see tests)
    // Has expected behavior with MeruDate.now()
    const currentDayNumber = this.formatDayNumber(programStartDate)
    if (!lastWeekNbr || !currentDayNumber) return null
    const lastDayNumber = lastWeekNbr * 7
    return currentDayNumber - lastDayNumber
  }
}

export default MeruDate
