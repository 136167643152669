import React from 'react'
import { FastField } from 'formik'
import * as Yup from 'yup'

import { CustomRadio, CustomRadioGroup } from 'src/form'

export const BURNOUT_SCHEMA = Yup.object()
  .shape({
    BURNOUT_SCORE: Yup.number().required(),
  })
  .default({})
  .required()

export type BurnoutSchema = Yup.InferType<typeof BURNOUT_SCHEMA>

const BURNOUT_QUESTIONS = [
  'I enjoy my work. I have no symptoms of burnout.',
  'Occasionally I am under stress, and I don’t always have as much energy as I once did, but I don’t feel burned out.',
  'I am definitely burning out and have one or more symptoms of burnout, such as physical or emotional exhaustion.',
  'The symptoms of burnout that I’m experiencing won’t go away. I think about frustration at work a lot.',
  'I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.',
]

const BurnoutFields = () => (
  <FastField component={CustomRadioGroup} name="value.BURNOUT_SCORE" type="number">
    {BURNOUT_QUESTIONS.map((question, i) => (
      <CustomRadio key={question} label={question} value={i + 1} />
    ))}
  </FastField>
)

export default BurnoutFields
